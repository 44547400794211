import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Icon from "../../common/theme/Icons";
import { Link } from "../../common/Link";

export default class ResultStep extends Component {
  getSameOperation = () => {
    let rows = [];

    rows.push(
      <Row key="order-service-same" className="section-field">
        <Col xs={12}>Order another service of same type:</Col>
      </Row>,
    );

    let operation = this.props.conf.operation;

    rows.push(
      <Row key="order-service-same-link" className="section-field-indent">
        <Col xs={12}>
          <Link onClick={() => this.props.onNextOperation(operation.id)}>
            {"Order a " + operation.description}
          </Link>
        </Col>
      </Row>,
    );

    return rows;
  };

  getNextOperations = () => {
    let rows = [];

    if (
      this.props.conf.object.result.nextOperations == null ||
      this.props.conf.object.result.nextOperations.length === 0
    ) {
      return null;
    }

    rows.push(
      <Row key="order-service-common" className="section-field">
        <Col xs={12}>Order a service of a type commonly related:</Col>
      </Row>,
    );

    for (
      let i = 0;
      i < this.props.conf.object.result.nextOperations.length;
      i++
    ) {
      let operation = this.props.conf.object.result.nextOperations[i];

      rows.push(
        <Row
          key={"order-service-common-" + operation.description}
          className="section-field-indent"
        >
          <Col xs={12}>
            <Link onClick={() => this.props.onNextOperation(operation.id)}>
              {"Order a " + operation.description}
            </Link>
          </Col>
        </Row>,
      );
    }

    return rows;
  };

  render() {
    return (
      <Container fluid={true}>
        <Row className="section-message">
          <Col xs={12}>
            <Icon
              color="primary"
              iconsize="40"
              prefix="fas"
              name="check-circle"
            />
            <span>Your order has been placed successfully!</span>
          </Col>
        </Row>
        <Row className="section-title">
          <Col xs={12}>Result</Col>
        </Row>
        <Row className="section-description">
          <Col xs={12}>
            The order has been created and you can now follow the progress in
            the orders page of Abion Core.
          </Col>
        </Row>
        <Row className="section-description">
          <Col xs={12}>
            Please note that the processing time may vary between less than a
            minute to several days depending on type of order.
          </Col>
        </Row>
        <Row className="section-title">
          <Col xs={12}>Next steps</Col>
        </Row>
        {this.getSameOperation()}
        {this.getNextOperations()}
      </Container>
    );
  }
}
