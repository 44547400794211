import React, { Component } from "react";
import { Grid } from "./Grid";
import styled from "styled-components";
import { DropdownButton, InfoButton } from "../../common/Buttons";
import { SwitchCheckbox } from "../../common/theme/Theme";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import Colors from "../../common/theme/Colors";

const StyledFilter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  widhth: 50px;
`;

const StatusStyle = styled.div`
  .status {
    border-radius: 10px 10px 10px 10px;
    height: 30px;
    width: 50px;
    vertical-align: middle;
    border: 1px solid;
  }

  .selectable {
    cursor: pointer;
  }

  .status-cell {
    position: relative;
    margin-bottom: 0px;
    width: 80px;
  }

  .info {
    position: absolute;
    top: -5px;
    right: 8px;
    background: transparent;
    color: black;

    hover {
      background: transparent;
    }
  }

  .td {
    padding-right: 7px;
  }

  .color-AVAILABLE {
    background-color: ${Colors.searchAvailableBackground};
    border-color: ${Colors.searchAvailableBorder};
  }

  .color-OCCUPIED {
    background-color: ${Colors.searchOccupiedBackground};
    border-color: ${Colors.searchOccupiedBorder};
  }

  .color-OWNED {
    background-color: ${Colors.searchOwnedBackground};
    border-color: ${Colors.searchOwnedBorder};
  }

  .selected {
    border: solid ${Colors.secondary};
  }
`;

export class SearchGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parameters: {
        columnsPerRow: 10,
        columnsPerRowLabel: this.getColumnsLabel(10),
      },
      displayCountries: false,
      rows: this.props.rows,
      columns: this.props.colums,
    };
    this.props.parameterFunction(
      this.getExportParameters(
        this.state.parameters.columnsPerRow,
        this.state.displayCountries,
      ),
    );
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.rows) !== JSON.stringify(this.props.rows)) {
      this.setColumnsPerRow(10);
      this.setState({
        rows: this.props.rows,
        columns: this.props.columns,
      });
    }
  }

  getTooltip = (tooltip) => {
    return <Tooltip style={{ position: "fixed" }}>{tooltip}</Tooltip>;
  };

  getSelectedStyle(domainName) {
    if (this.props.isSelected(domainName)) {
      return " selected";
    }
    return "";
  }

  getDetailButton = (row) => {
    switch (row.status.value) {
      case "AVAILABLE":
        return "";
      default:
        return (
          <InfoButton
            className={"info"}
            onClick={() => this.props.detailFunction(row)}
            icon={"info-circle"}
            background={Colors.tertiary}
          />
        );
    }
  };

  getColumnsLabel = (columns) => {
    let name = columns + " Column";
    if (columns > 1) {
      name += "s";
    }
    return name;
  };

  getExportParameters(columns, displayCountries) {
    let parameters = "&displayCountries=" + displayCountries;
    if (columns > 0) {
      parameters += "&tldColumns=" + columns;
    }
    return parameters;
  }

  setColumnsPerRow(columns) {
    this.setColumnsPerRowWithLabel(columns, this.getColumnsLabel(columns));
  }

  setColumnsPerRowWithLabel = (columns, label) => {
    this.setState({
      parameters: Object.assign({}, this.state.parameters, {
        columnsPerRow: columns,
        columnsPerRowLabel: label,
      }),
    });
    this.props.parameterFunction(
      this.getExportParameters(columns, this.state.displayCountries),
    );
  };

  setDisplayCountries(displayCountries) {
    this.setState({ displayCountries: displayCountries });
    this.props.parameterFunction(
      this.getExportParameters(
        this.state.parameters.columnsPerRow,
        displayCountries,
      ),
    );
  }

  render() {
    if (this.props.show) {
      let selectable = "";

      if (this.props.session.hasRole("ORDER_CREATE")) {
        selectable = "selectable";
      }

      let options = [];

      for (let option = 1; option <= 20; option++) {
        options.push(
          <Dropdown.Item
            key={"column-" + option}
            onClick={() => this.setColumnsPerRow(option)}
          >
            {this.getColumnsLabel(option)}
          </Dropdown.Item>,
        );
      }
      options.push(
        <Dropdown.Item
          key="column-all-columns"
          onClick={() => this.setColumnsPerRowWithLabel(0, "All Columns")}
        >
          {"All Columns"}
        </Dropdown.Item>,
      );

      let filters = [
        <StyledFilter key={"styledfilter"}>
          {SwitchCheckbox(
            "Display Countries",
            this.state.displayCountries,
            (o) => this.setDisplayCountries(o.target.checked),
          )}
          <DropdownButton
            title={this.state.parameters.columnsPerRowLabel}
            style={{ verticalAlign: "top" }}
          >
            {options}
          </DropdownButton>
        </StyledFilter>,
      ];

      const columns = [];
      columns.push({
        label: "",
        name: "keyword",
        style: { width: "200px" },
      });

      if (this.state.columns) {
        this.state.columns.forEach((item) => {
          columns.push({
            key: item.tld,
            label: item.tld,
            descriptionLabel: item.country,
            name: item.tld,
            style: { width: "110px" },
            contentFunction: (row) => {
              let r = row.columns[item.tld];
              if (r) {
                let tooltip =
                  r.domainName + " is " + r.status.label.toLowerCase();

                return (
                  <StatusStyle>
                    <OverlayTrigger
                      overlay={this.getTooltip(tooltip)}
                      placement="bottom"
                      delay={500}
                    >
                      <div className={"status-cell"}>
                        <div
                          className={
                            "status " +
                            selectable +
                            " color-" +
                            r.status.value +
                            this.getSelectedStyle(r.domainName)
                          }
                          onClick={() => this.props.toggleDomain(r.domainName)}
                        />
                        {this.getDetailButton(r)}
                      </div>
                    </OverlayTrigger>
                  </StatusStyle>
                );
              }
              return null;
            },
          });
        });
      }

      return (
        <Grid
          loading={this.props.loading}
          actions={this.props.actions}
          columns={columns}
          displayDescriptionLabel={this.state.displayCountries}
          filters={filters}
          rows={this.state.rows}
          show={this.props.show}
          columnsPerRow={this.state.parameters.columnsPerRow}
        />
      );
    }
    return null;
  }
}
