import React, { Component } from "react";
import EditDialog from "../common/Dialog";
import { Col, Row } from "react-bootstrap";
import Selector from "../common/Selector";
import { InputWithLabel } from "../common/theme/Theme";

class UserDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conf: null,
    };
    this.updateField = this.updateField.bind(this);
    this.createElements = this.createElements.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.conf && prevProps.conf !== this.props.conf) {
      this.setState({ conf: this.props.conf });
    }
  }

  updateField(field, value) {
    const conf = this.state.conf;
    conf.object[field] = value;
    this.setState({ conf: conf });
  }

  updateOrganisationField = (value) => {
    const conf = this.state.conf;
    conf.object.organisations = value.map((o) => {
      return { id: o.value };
    });
    this.setState({ conf: conf });
  };

  getOrganisationDefaultValue = () => {
    if (this.state.conf.object.organisations == null) {
      return null;
    }
    return this.state.conf.object.organisations.map((o) => {
      return {
        value: o.id,
        label: o.fullDescription,
      };
    });
  };

  filterOrganisations(organisations) {
    let orgOptions = organisations
      .filter((org) => org.accessible)
      .map((o) => {
        return {
          value: o.id,
          label: o.fullDescriptionWithIds,
        };
      });
    return orgOptions;
  }

  getOrganisationOptions = (freetext, callback) => {
    let url = this.props.session.getApplication().links.organisations;
    url += "?freetext=" + freetext;

    this.props.session.backendGet(url).then((response) => {
      callback(this.filterOrganisations(response));
    });
  };

  createElements() {
    let elements = [
      <Row key="e-mail" name={"email-row"}>
        <Col sm={12} name={"email-container"}>
          <InputWithLabel
            label={"E-mail"}
            type="email"
            name="Email"
            placeholder="Email"
            value={this.state.conf.object.email || ""}
            onChange={(e) => this.updateField("email", e.target.value)}
            required="required"
          />
        </Col>
      </Row>,
      <Row key="first-name" name={"names-row"}>
        <Col sm={6} name={"fn-container"}>
          <InputWithLabel
            label={"First name"}
            type="text"
            name="First Name"
            placeholder="First Name"
            value={this.state.conf.object.firstName || ""}
            onChange={(e) => this.updateField("firstName", e.target.value)}
          />
        </Col>
        <Col sm={6} name={"ln-container"}>
          <InputWithLabel
            label={"Last Name"}
            type="text"
            name="Last Name"
            placeholder="Last Name"
            value={this.state.conf.object.lastName || ""}
            onChange={(e) => this.updateField("lastName", e.target.value)}
          />
        </Col>
      </Row>,
    ];

    if (!this.state.conf.object.id) {
      elements.push(
        <Selector
          label={"Roles"}
          name="roles"
          options={this.state.conf.roles}
          placeholder="Select Roles"
          isMulti
          closeMenuOnSelect={false}
          removeSelected={false}
          defaultValue={this.state.conf.object.roles}
          onChange={(selection) => this.updateField("roles", selection)}
          required={true}
        />,
      );

      elements.push(
        <Selector
          label={"Organisations"}
          isMulti
          ignoreAccents={false}
          removeSelected={false}
          required={true}
          isAsync
          loadOptions={this.getOrganisationOptions}
          defaultValue={this.getOrganisationDefaultValue}
          onChange={this.updateOrganisationField}
          placeholder="Select Organisations"
        />,
      );
    }

    const options = [
      { label: "Enabled", value: true },
      { label: "Disabled", value: false },
    ];

    elements.push(
      <Row name={"two-fa-row"}>
        <Col sm={12} name={"two-fa-container"}>
          <Selector
            label={"Two Factor Authentication"}
            name="twoFactorAuthEnabled"
            options={options}
            defaultValue={this.state.conf.object.twoFactorAuthEnabled}
            onChange={(selection) =>
              this.updateField("twoFactorAuthEnabled", selection.value)
            }
            required={true}
          />
        </Col>
      </Row>,
    );
    return elements;
  }

  render() {
    if (!this.state.conf) {
      return null;
    }

    const elements = this.createElements();

    return (
      <EditDialog
        content={elements}
        show={this.props.showDialog}
        title={this.state.conf.object.id ? "Edit user" : "Create user"}
        name={"user"}
        onCancel={this.props.closeDialog}
        onConfirm={this.props.handleSubmit}
        submit={this.props.submit}
        errorMessage={this.props.errorMessage}
      />
    );
  }
}

export default UserDialog;
