import React, { Component } from "react";

export default class PaymentParameter extends Component {
  getSrc = () => {
    let url = this.props.session.getPayUrl();
    url = url + "?objecttype=" + this.props.objectType;
    url = url + "&objectid=" + this.props.objectId;
    let returnUrl = this.props.session.getHostUrl() + "/payconfirm.html";
    url = url + "&returnurl=" + encodeURIComponent(returnUrl);
    return url;
  };

  onEvent = (event) => {
    if (event.data === "payment success") {
      this.props.onSuccess();
      window.removeEventListener("message", this.onEvent);
    }
  };

  render() {
    window.addEventListener("message", this.onEvent);
    return (
      <iframe
        style={{ width: "100%", height: "90%", border: "none" }}
        src={this.getSrc()}
      />
    );
  }
}
