import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { FormatDateMonospace } from "../../common/Utils";
import { DetailsDialog, DialogSection } from "../../common/Dialog";
import ReadOnlyForm from "../../common/ReadOnlyForm";

export default class DomainMonitorHitDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
  }

  componentDidMount() {
    this.reload();
  }

  componentDidUpdate(prevProps) {
    if (this.props.url && prevProps.url !== this.props.url) {
      this.reload();
    }
  }

  reload = () => {
    this.setState({ data: null });

    if (this.props.url) {
      this.props.session.backendGet(
        this.props.url,
        (response) => {
          this.setState({ data: response });
        },
        (errorMessage) => {
          this.props.window.showErrorFunc(errorMessage);
        },
      );
    }
  };

  getHost = (current, previous) => {
    if (current) {
      const split = current.split("|");
      return split[0];
    } else if (previous) {
      const split = previous.split("|");
      return split[0];
    }
  };

  getRecord = (type, record) => {
    if (record === null) {
      return "(null)";
    }
    if (type === "Web Page") {
      return (
        <img
          src={"data:image/png;base64," + record}
          alt={""}
          style={{ maxWidth: "100%", border: "1px solid grey" }}
        />
      );
    }

    const split = record.split("|");
    let elements = [];
    if (type === "Zone") {
      for (let i = 2; i < split.length; i++) {
        elements.push(
          <div key={split[i]}>
            {split[i]}
            <br></br>
          </div>,
        );
      }
    } else {
      split.forEach((v) =>
        elements.push(
          <div key={v}>
            {v}
            <br></br>
          </div>,
        ),
      );
    }
    return <div key="record">{elements}</div>;
  };

  getTypeForPtrRecord = (current, previous) => {
    if (current) {
      const split = current.split("|");
      return split[1];
    } else if (previous) {
      const split = previous.split("|");
      return split[1];
    }
  };

  getRecords = () => {
    let parts = [];
    const type = this.state.data.hitType;
    const current = this.state.data.current;
    const previous = this.state.data.previous;

    if (type === "Zone") {
      parts = [
        {
          label: "Host",
          value: this.getHost(current, previous),
        },
        {
          label: "Record Type",
          value: this.getTypeForPtrRecord(current, previous),
        },
        {
          label: "New Value",
          value: this.getRecord(type, current),
        },
        {
          label: "Previous Value",
          value: this.getRecord(type, previous),
        },
      ];
    } else {
      parts.push({
        label: "New " + type,
        value: this.getRecord(type, current),
      });
      if (type === "Web Page") {
        parts.push({
          label: "",
          value: "",
        });
      }
      parts.push({
        label: "Previous " + type,
        value: this.getRecord(type, previous),
      });
    }

    return parts;
  };

  getBasic = () => {
    let basicInfo = [
      {
        label: "Domain Name",
        value: this.state.data.monitorName,
      },
      {
        label: "Change Type",
        value: this.state.data.hitType,
      },
      {
        label: "Discovered Date",
        value: FormatDateMonospace(this.state.data.discovered),
      },
      {
        label: "",
        value: "",
      },
    ];

    const content = basicInfo.concat(this.getRecords());

    return (
      <DialogSection title="">
        <ReadOnlyForm wide="true" content={content} />
      </DialogSection>
    );
  };

  render() {
    let parts = [];
    let title = "";

    if (this.state.data) {
      parts.push(this.getBasic());
      title = this.state.data.monitorName + " - " + this.state.data.hitType;
    }

    let body = <Container fluid={true}>{parts}</Container>;

    return (
      <DetailsDialog
        show={this.props.show}
        title={title}
        body={body}
        onClose={() => this.props.onClose()}
        width="lg"
        height="lg"
      />
    );
  }
}
