import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { DropdownButton } from "../common/Buttons";
import styled from "styled-components";

const StyledSpan = styled.span`
  .dropdown {
    vertical-align: top;
    margin-right: 20px;
  }
`;

// This is a copy of enum PortfolioCategory and should be kept in sync with it
const ALL_TITLE = "All Types";
const LEGAL_CATEGORY = "LEGAL";
const LEGAL_TITLE = "Legal";
const DIGITAL_CATEGORY = "DIGITAL";
const DIGITAL_TITLE = "Domains";
const TECHNICAL_CATEGORY = "TECHNICAL";
const TECHNICAL_TITLE = "Web Security";
const OBP_CATEGORY = "OBP";
const OBP_TITLE = "Brand Protection";

export default class OperationFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: ALL_TITLE,
    };
  }

  appendSeparator = (items) => {
    items.push(<Dropdown.Divider key={"divider-" + items.length} />);
  };

  appendAll = (items) => {
    items.push(
      <Dropdown.Item
        key="all"
        onClick={() => {
          this.setState({ title: ALL_TITLE });
          this.props.onChange("");
        }}
      >
        {ALL_TITLE}
      </Dropdown.Item>,
    );
  };

  appendCategory = (items, category, title) => {
    let ops = this.props.session.getPortfolioOperations();
    let ids = "";

    for (let i = 0; i < ops.length; i++) {
      if (ops[i].category === category) {
        ids += ops[i].id + ",";
      }
    }

    items.push(
      <Dropdown.Item
        key={"items-" + title}
        onClick={() => {
          this.setState({ title: title });
          this.props.onChange(ids);
        }}
      >
        {title}
      </Dropdown.Item>,
    );
  };

  appendOperations = (items, category, title) => {
    let ops = this.props.session.getPortfolioOperations();

    for (let i = 0; i < ops.length; i++) {
      if (ops[i].category === category) {
        items.push(
          <Dropdown.Item
            key={"operation-item-" + i}
            onClick={() => {
              this.setState({ title: ops[i].description });
              this.props.onChange(ops[i].id);
            }}
          >
            {title + " - " + ops[i].description}
          </Dropdown.Item>,
        );
      }
    }
  };

  render() {
    let items = [];

    this.appendAll(items);

    this.appendSeparator(items);

    this.appendCategory(items, LEGAL_CATEGORY, LEGAL_TITLE);
    this.appendCategory(items, DIGITAL_CATEGORY, DIGITAL_TITLE);
    this.appendCategory(items, TECHNICAL_CATEGORY, TECHNICAL_TITLE);
    this.appendCategory(items, OBP_CATEGORY, OBP_TITLE);

    this.appendSeparator(items);
    this.appendOperations(items, LEGAL_CATEGORY, LEGAL_TITLE);
    this.appendSeparator(items);
    this.appendOperations(items, DIGITAL_CATEGORY, DIGITAL_TITLE);
    this.appendSeparator(items);
    this.appendOperations(items, TECHNICAL_CATEGORY, TECHNICAL_TITLE);
    this.appendSeparator(items);
    this.appendOperations(items, OBP_CATEGORY, OBP_TITLE);

    return (
      <StyledSpan>
        <DropdownButton
          title={this.state.title}
          style={{ verticalAlign: "top" }}
        >
          {items}
        </DropdownButton>
      </StyledSpan>
    );
  }
}
