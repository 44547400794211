import React, { Component } from "react";
import EditDialog from "../../common/Dialog";
import { InputWithLabel } from "../../common/theme/Theme";
import Selector from "../../common/Selector";

class SettingDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conf: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.conf && prevProps.conf !== this.props.conf) {
      this.setState({ conf: this.props.conf });
    }
  }

  updateField(field, value) {
    const conf = this.state.conf;
    conf.object[field] = value;
    this.setState({ conf: conf });
  }

  render() {
    if (!this.state.conf) {
      return null;
    }

    const elements = [
      <Selector
        label={"Primary Nameserver"}
        name="mName"
        options={this.state.conf.mNames}
        searchable={false}
        placeholder="MName"
        defaultValue={this.state.conf.object.mName}
        onChange={(selection) => this.updateField("mName", selection.value)}
        required={true}
      />,
      <InputWithLabel
        label={"Refresh"}
        type="number"
        min="0"
        name="refresh"
        placeholder="Refresh"
        value={this.state.conf.object.refresh}
        onChange={(e) => this.updateField("refresh", e.target.value)}
        required={true}
      />,
      <InputWithLabel
        label={"Retry"}
        type="number"
        min="0"
        name="retry"
        placeholder="Retry"
        value={this.state.conf.object.retry}
        onChange={(e) => this.updateField("retry", e.target.value)}
        required={true}
      />,
      <InputWithLabel
        label={"Expire"}
        type="number"
        min="0"
        name="expire"
        placeholder="Expire"
        value={this.state.conf.object.expire}
        onChange={(e) => this.updateField("expire", e.target.value)}
        required={true}
      />,
      <InputWithLabel
        label={"TTL"}
        type="number"
        min="0"
        name="ttl"
        placeholder="TTL"
        value={this.state.conf.object.ttl}
        onChange={(e) => this.updateField("ttl", e.target.value)}
        required={true}
      />,
    ];

    return (
      <EditDialog
        content={elements}
        show={this.props.showDialog}
        title="Edit Settings"
        onCancel={this.props.onCancel}
        onConfirm={() => this.props.onConfirm(this.state.conf.object)}
        submit={this.props.submit}
        errorMessage={this.props.errorMessage}
      />
    );
  }
}

export default SettingDialog;
