import styled from "styled-components";
import Colors from "./theme/Colors";
import { BorderColor } from "./theme/Theme";
import { rgba } from "polished";

const TabControlPanel = styled.div`
  position: relative;
  padding: 15px;
  background: ${Colors.white};
  border-left: 1px solid ${BorderColor};
  border-right: 1px solid ${BorderColor};
  border-bottom: 1px solid ${BorderColor};
  border-top: 1px solid ${BorderColor};
  margin-bottom: 15px;

  .form-group {
    margin-bottom: 0;
  }

  .alert {
    margin: 0;
  }

  &.side-padding {
    padding-left: 15px;
    padding-right: 15px;
  }

  &.no-margin {
    margin: 0;
  }
`;

const TabContent = styled.div`
  background: ${Colors.white};
  margin-top: 15px;
  box-shadow: 0 3px 7px -3px ${rgba(Colors.black, 0.15)};

  &.clearbg {
    background: transparent;
  }

  &.no-shadow {
    box-shadow: none;
  }

  &.padded,
  .padded {
    padding: 50px;
  }
  &.padded-mini,
  .padded-mini {
    padding: 15px;
  }
  &.padded-small,
  .padded-small {
    padding: 30px;
  }
  &.padded-none {
    padding: 0;
    margin-top: 0;
  }
  &.no-margin {
    margin: 0;
  }
`;

export { TabControlPanel, TabContent };
