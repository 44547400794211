import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import ZoneList from "./zone/ZoneList";
import ZonePage from "./zone/ZonePage";
import PublishPage from "./publish/PublishPage";
import BulkPage from "./bulk/BulkPage";

class DnsAdmin extends Component {
  constructor(props) {
    super(props);
    this.setRedirect = this.setRedirect.bind(this);
  }

  setRedirect() {
    const modules = this.props.session.getModules().dnsadmin;
    return modules.menuItems[0].path;
  }

  render() {
    return (
      <Switch>
        <Route
          exact={true}
          path="/dnsadmin/zones"
          render={(props) => (
            <ZoneList
              session={this.props.session}
              window={this.props.window}
              {...props}
            />
          )}
        />
        <Route
          path="/dnsadmin/zones/:zoneId"
          render={(props) => (
            <ZonePage
              session={this.props.session}
              window={this.props.window}
              {...props}
            />
          )}
        />
        <Route
          path="/dnsadmin/publish"
          render={(props) => (
            <PublishPage
              session={this.props.session}
              window={this.props.window}
              {...props}
            />
          )}
        />
        <Route
          path="/dnsadmin/bulk"
          render={(props) => (
            <BulkPage
              session={this.props.session}
              window={this.props.window}
              {...props}
            />
          )}
        />
      </Switch>
    );
  }
}

export default DnsAdmin;
