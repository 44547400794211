import React, { Component } from "react";
import { SearchList } from "./SearchList";
import { SearchGrid } from "./SearchGrid";
import { BlockingActionButton, MainActionButton } from "../../common/Buttons";
import DomainDetailsDialog from "./DomainDetailsDialog";
import CreateOrderDialog from "../../order/CreateOrderDialog";
import fileDownload from "js-file-download";
import { PortsAlert } from "../../common/PortsAlert";

export class SearchResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      currentRows: [],
      selectedDomains: [],
      gridColumns: [],
      showDetailsDialog: false,
      showCreateRegisterDialog: false,
      showCreateInvestigateDialog: false,
      showCreateMonitorDialog: false,
      exportParameters: "",
      domain: null,
      exportRunAt: "",
      skippedKeywords: null,
    };
    this.isSelected = this.isSelected.bind(this);
    this.toggleAll = this.toggleAll.bind(this);
    this.tableRef = React.createRef();
    this.export = this.export.bind(this);
    this.warning = this.warning.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.parameters.searchText &&
      (JSON.stringify(prevProps.parameters) !==
        JSON.stringify(this.props.parameters) ||
        (this.props.queryRunning && !this.state.loading))
    ) {
      this.reload({});
    }
  }

  reload = (newState) => {
    let futureState = Object.assign({}, this.state, newState);

    this.setState(
      Object.assign(futureState, {
        loading: true,
        currentRows: [],
        gridColumns: [],
        selectedDomains: [],
        skippedKeywords: null,
      }),
    );

    let url = this.getUrl(this.props.parameters);

    this.props.session.backendGet(
      url,
      (response) => {
        this.reloadHelper(response, futureState);
        this.props.queryFunction();
        this.reloadFinalizer(futureState);
      },
      (errorMessage) => {
        this.props.window.showErrorFunc(errorMessage);
        this.props.queryFunction();
        this.reloadFinalizer(futureState);
      },
    );
  };

  reloadHelper = (response, futureState) => {
    let col = [];

    if (response.columns) {
      col = response.columns;
    }

    this.setState(
      Object.assign(futureState, {
        currentRows: response.objects,
        gridColumns: col,
        skippedKeywords: response.skippedKeywords,
      }),
    );
  };

  reloadFinalizer = (futureState) => {
    this.setState(
      Object.assign(futureState, {
        loading: false,
      }),
    );
  };

  openDetailsDialog = (domain) => {
    this.setState({
      showDetailsDialog: true,
      domain: domain,
    });
  };

  isSelected(domain) {
    return this.state.selectedDomains.includes(domain);
  }

  toggleDomain(domain) {
    if (this.props.session.hasRole("ORDER_CREATE")) {
      let selectedDomains = this.state.selectedDomains;

      if (selectedDomains.includes(domain)) {
        selectedDomains = selectedDomains.filter((o) => o !== domain);
      } else {
        selectedDomains.push(domain);
      }

      this.setState({ selectedDomains: selectedDomains });
    }
  }

  toggleAll(event) {
    let selectedDomains = [];
    if (event.target.checked) {
      selectedDomains = this.tableRef.current.state.currentRows
        .filter((o) => o.status.value !== "OWNED")
        .map((o) => o.domainName);
    }

    this.setState({ selectedDomains: selectedDomains });
  }

  getUrl = (parameters) => {
    let url = "";
    if (parameters.grid) {
      url =
        this.props.session.getModules().domainadmin.links.searchtoolSearchGrid;
    } else {
      url =
        this.props.session.getModules().domainadmin.links.searchtoolSearchList;
    }
    url += "?search=" + encodeURIComponent(parameters.searchText);
    url += "&extensionGroup=" + parameters.extensionGroup;
    url += "&grid=" + parameters.grid;

    return url;
  };

  getExportUrl = (parameters) => {
    let url =
      this.props.session.getModules().domainadmin.links.searchtoolExport;

    url += "?search=" + encodeURIComponent(parameters.searchText);
    url += "&extensionGroup=" + parameters.extensionGroup;
    url += "&grid=" + parameters.grid;
    url += this.state.exportParameters;

    return url;
  };

  export = () => {
    let url = this.getExportUrl(this.props.parameters);

    this.props.session.backendGetFile(
      url,
      (response) => {
        fileDownload(response, "searchtool.xlsx");
        this.setState({ exportRunAt: Date.now() });
      },
      (error) => {
        this.props.window.showErrorFunc(error);
        this.setState({ exportRunAt: Date.now() });
      },
    );
  };

  parameterFunction = (exportParameters) => {
    this.setState({ exportParameters: exportParameters });
  };

  warning = () => {
    if (this.state.skippedKeywords) {
      return (
        <PortsAlert
          variant="warning"
          style={{ marginTop: "5px", marginBottom: "10px" }}
        >
          <strong>
            Note, due to search size limits only the first brands/keywords are
            included in the result, the excluded ones are listed below. You can
            copy them to the search bar to run another query.
          </strong>
          <br /> {this.state.skippedKeywords}
        </PortsAlert>
      );
    }
  };

  hasSelectedDomains = () => {
    return (
      this.state.selectedDomains != null &&
      this.state.selectedDomains.length > 0
    );
  };

  isRegisterEnabled = () => {
    return (
      this.props.session.hasRole("ORDER_CREATE") &&
      this.props.session.hasRole("DOMAIN_ADMIN_EDIT") &&
      this.hasSelectedDomains()
    );
  };

  isInvestigateEnabled = () => {
    return (
      this.props.session.hasRole("ORDER_CREATE") && this.hasSelectedDomains()
    );
  };

  isMonitorEnabled = () => {
    return (
      this.props.session.hasRole("ORDER_CREATE") && this.hasSelectedDomains()
    );
  };

  getRegisterTooltip = () => {
    if (
      this.props.session.hasRole("ORDER_CREATE") &&
      this.props.session.hasRole("DOMAIN_ADMIN_EDIT") &&
      this.hasSelectedDomains()
    ) {
      return "Open the order dialog and fill in details to order new domain registrations for selected domains.";
    }
    return "";
  };

  getInvestigateTooltip = () => {
    if (
      this.props.session.hasRole("ORDER_CREATE") &&
      this.hasSelectedDomains()
    ) {
      return "Open the order dialog and fill in details to order a domain investigation for selected domains, it will look into ways of acquiring already occupied domains.";
    }
    return "";
  };

  getMonitorTooltip = () => {
    if (
      this.props.session.hasRole("ORDER_CREATE") &&
      this.hasSelectedDomains()
    ) {
      return "Open the order dialog and fill in details to order new domain monitor for selected domains.";
    }
    return "";
  };

  render() {
    let actions = [];

    actions.push(
      <MainActionButton
        key="register_domains"
        onClick={() => this.setState({ showCreateRegisterDialog: true })}
        text="Register Selected Domains"
        icon="plus"
        prefix="fas"
        tooltip={this.getRegisterTooltip()}
        disabled={!this.isRegisterEnabled()}
        disabledTooltip="You start by searching and selecting domains, then this function will be available."
        invisible={this.props.session.hasRole("SYS_ADMIN")}
      />,
    );

    actions.push(
      <MainActionButton
        key="investigate_domains"
        onClick={() => this.setState({ showCreateInvestigateDialog: true })}
        text="Investigate Selected Domains"
        icon="plus"
        prefix="fas"
        tooltip={this.getInvestigateTooltip()}
        disabled={!this.isInvestigateEnabled()}
        disabledTooltip="You start by searching and selecting domains, then this function will be available."
        invisible={this.props.session.hasRole("SYS_ADMIN")}
      />,
    );

    actions.push(
      <MainActionButton
        key="monitor_domains"
        onClick={() => this.setState({ showCreateMonitorDialog: true })}
        text="Monitor Selected Domains"
        icon="plus"
        prefix="fas"
        tooltip={this.getMonitorTooltip()}
        disabled={!this.isMonitorEnabled()}
        disabledTooltip="You start by searching and selecting domains, then this function will be available."
        invisible={this.props.session.hasRole("SYS_ADMIN")}
      />,
    );

    actions.push(
      <BlockingActionButton
        key="export"
        onClick={this.export}
        text={"Export"}
        icon={"cloud-download"}
        prefix={"fas"}
        tooltip="Exports the search result to an Excel file. Note that if there are excluded brands/keywords they will not be exported."
        reload={this.state.exportRunAt}
      />,
    );

    let registerParameters = {};
    let investigateParameters = {};
    let monitorParameters = {};

    if (this.state.selectedDomains) {
      registerParameters.domainNames = this.state.selectedDomains.join("\n");
      registerParameters.skipSearchToolNote = "1";

      investigateParameters.description =
        "Investigate Domains from Search Tool";
      investigateParameters.configuration =
        this.state.selectedDomains.join("\n");

      monitorParameters.description = "Monitor Domains from Search Tool";
      monitorParameters.configuration = this.state.selectedDomains.join("\n");
    }

    return (
      <div>
        <SearchList
          actions={actions}
          parameterFunction={this.parameterFunction}
          rows={this.state.currentRows}
          session={this.props.session}
          isSelected={(domain) => this.isSelected(domain)}
          toggleDomain={(domain) => this.toggleDomain(domain)}
          toggleAll={(event) => this.toggleAll(event)}
          detailFunction={(domain) => this.openDetailsDialog(domain)}
          show={
            this.state.gridColumns.length === 0 &&
            this.state.currentRows.length > 0
          }
          loading={this.state.loading}
          ref={this.tableRef}
        />
        <SearchGrid
          actions={actions}
          parameterFunction={this.parameterFunction}
          rows={this.state.currentRows}
          parameters={this.props.parameters}
          session={this.props.session}
          isSelected={(domain) => this.isSelected(domain)}
          toggleDomain={(domain) => this.toggleDomain(domain)}
          detailFunction={(domain) => this.openDetailsDialog(domain)}
          show={
            this.state.gridColumns.length > 0 &&
            this.state.currentRows.length > 0
          }
          loading={this.state.loading}
          columns={this.state.gridColumns}
        />
        <DomainDetailsDialog
          session={this.props.session}
          window={this.props.window}
          show={this.state.showDetailsDialog}
          domain={this.state.domain}
          onClose={() => this.setState({ showDetailsDialog: false })}
        />
        <CreateOrderDialog
          session={this.props.session}
          show={this.state.showCreateRegisterDialog}
          onClose={() => this.setState({ showCreateRegisterDialog: false })}
          step="OPERATION"
          operation="DOMAIN-REGISTER_DOMAIN"
          parameters={registerParameters}
        />
        <CreateOrderDialog
          session={this.props.session}
          show={this.state.showCreateInvestigateDialog}
          onClose={() => this.setState({ showCreateInvestigateDialog: false })}
          step="OPERATION"
          operation="MANUAL-DOMAIN_INVESTIGATION"
          parameters={investigateParameters}
        />
        <CreateOrderDialog
          session={this.props.session}
          show={this.state.showCreateMonitorDialog}
          onClose={() => this.setState({ showCreateMonitorDialog: false })}
          step="OPERATION"
          operation="SUBSCRIPTION-MONITOR_DOMAIN"
          parameters={monitorParameters}
        />
        {this.warning()}
      </div>
    );
  }
}
