import React, { Component } from "react";
import { TableBase, TableFilter } from "../../common/Tables";
import styled from "styled-components";
import StatusFilter from "./StatusFilter";
import { Form } from "react-bootstrap";
import { TableButton } from "../../common/Buttons";
import Colors from "../../common/theme/Colors";

const StyledFilter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export class SearchList extends Component {
  lastFilterText;
  lastFilterStatus;
  columns;

  constructor(props) {
    super(props);
    this.state = {
      currentRows: [],
      allRows: this.props.rows,
    };
    this.toggleOne = this.toggleOne.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.rows) !== JSON.stringify(this.props.rows)) {
      this.setState({
        allRows: this.props.rows,
        currentRows: this.filter(
          this.lastFilterStatus,
          this.lastFilterText,
          this.props.rows,
        ),
      });
    }
  }

  onFilterFromStatus = (status) => {
    this.lastFilterStatus = status;
    this.onFilter();
  };

  onFilterFromText = (freeText) => {
    this.lastFilterText = freeText;
    this.onFilter();
  };

  onFilterStatus = (status, row) => {
    if (status) {
      return status === row.status.value;
    }
    return true;
  };

  onFilter() {
    this.setState({
      currentRows: this.filter(
        this.lastFilterStatus,
        this.lastFilterText,
        this.state.allRows,
      ),
    });
  }

  filter(status, freetext, rows) {
    if (this.columns) {
      let columns = this.columns.filter((o) => o.filterable);

      if (freetext) {
        freetext = freetext.toLowerCase().trim();
      }

      let newRows = rows.filter((row) => {
        if (!this.onFilterStatus(status, row)) {
          return false;
        }

        if (!freetext || columns.length === 0) {
          return true;
        }

        for (let i = 0; i < columns.length; i++) {
          let filter = JSON.stringify(
            this.getFilter(columns[i], row),
          ).toLowerCase();
          if (filter.includes(freetext)) {
            return true;
          }
        }
        return false;
      });

      this.props.parameterFunction(this.getExportParameters());

      return newRows;
    }
    return rows;
  }

  getFilter = (column, row) => {
    if (column.filterFunction) {
      return column.filterFunction(row);
    } else {
      return row[column.name];
    }
  };

  getDetailButton = (row) => {
    switch (row.status.value) {
      case "AVAILABLE":
        return "";
      default:
        return (
          <TableButton
            onClick={() => this.props.detailFunction(row)}
            icon="eye"
            prefix={"fas"}
            text="Details"
            className={"secondary"}
          />
        );
    }
  };

  getPublishStatusStyle(value) {
    if (value === "AVAILABLE") {
      return {
        color: Colors.warning,
      };
    } else if (value === "OCCUPIED") {
      return {
        color: Colors.error,
      };
    } else {
      return {
        color: Colors.success,
      };
    }
  }

  getExportParameters() {
    let urlParameters = "";

    if (this.lastFilterStatus) {
      urlParameters += "&status=" + this.lastFilterStatus;
    }

    if (this.lastFilterText) {
      urlParameters += "&filterText=" + encodeURIComponent(this.lastFilterText);
    }

    return urlParameters;
  }

  toggleOne(event) {
    let domain = event.target.id;
    this.props.toggleDomain(domain);
  }

  render() {
    if (this.props.show) {
      let parts = [];

      parts.push(
        <StatusFilter
          key={"statusFilter"}
          session={this.props.session}
          url={this.props.session.getModules().domainadmin.links.status}
          onChange={(o) => this.onFilterFromStatus(o)}
        />,
      );

      parts.push(
        <TableFilter
          key={"tableFilter"}
          onChange={(o) => this.onFilterFromText(o)}
        />,
      );

      let filters = [<StyledFilter key={"styledfilter"}>{parts}</StyledFilter>];

      const checkboxStyle = {
        margin: 0,
      };

      this.columns = [];

      if (this.props.session.hasRole("ORDER_CREATE")) {
        this.columns.push({
          label: (
            <Form.Check style={checkboxStyle} onChange={this.props.toggleAll} />
          ),
          style: { width: "50px" },
          contentFunction: (r) => {
            if (r.hasOwnProperty("status")) {
              return (
                <Form.Check
                  style={checkboxStyle}
                  id={r.domainName}
                  disabled={r.status.value === "OWNED"}
                  checked={this.props.isSelected(r.domainName)}
                  onChange={this.toggleOne}
                />
              );
            }
          },
        });
      }

      this.columns.push({
        label: "Domain",
        name: "domainName",
        filterable: true,
      });

      this.columns.push({
        label: "Country",
        name: "country",
        filterable: true,
      });

      this.columns.push({
        label: "Status",
        style: { width: "200px" },
        filterable: true,
        filterFunction: (r) => r.status,
        contentFunction: (r) => {
          if (r.hasOwnProperty("status")) {
            return (
              <div style={this.getPublishStatusStyle(r.status.value)}>
                {r.status.label}
              </div>
            );
          }
        },
      });

      this.columns.push({
        style: {
          width: "150px",
        },
        contentFunction: (r) => {
          if (r.hasOwnProperty("status")) {
            return this.getDetailButton(r);
          }
        },
      });
      return (
        <TableBase
          loading={this.props.loading}
          list={this.props.list}
          actions={this.props.actions}
          filters={filters}
          columns={this.columns}
          rows={this.state.currentRows}
          rowCount={this.state.currentRows.length}
          onExpand={this.onExpand}
        />
      );
    }
    return null;
  }
}
