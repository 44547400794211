import React, { Component } from "react";
import styled from "styled-components";
import PaymentParameter from "../../common/PaymentParameter";
import Colors from "../../common/theme/Colors";

const StyledDiv = styled.div`
  width: 100%;
  height: 95%;

  .note {
    margin: 15px 15px 15px 15px;
    font-size: 15px;
    color: ${Colors.tertiary};
  }
`;

export default class PaymentParameterStep extends Component {
  render() {
    if (this.props.conf.object.result == null) {
      return null;
    }

    return (
      <StyledDiv>
        <div className="note">
          Pay the order by card using the form below. Note that if you close the
          dialog, the order will still be created. You can pay it later under
          the orders page of Abion Core.
        </div>
        <PaymentParameter
          objectType="order"
          objectId={this.props.conf.object.result.orderId}
          onSuccess={this.props.onSuccess}
          session={this.props.session}
        />
      </StyledDiv>
    );
  }
}
