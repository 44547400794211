import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { DetailsDialog, DialogSection } from "../../common/Dialog";

export default class DomainWatchHitDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
  }

  componentDidMount() {
    this.reload();
  }

  componentDidUpdate(prevProps) {
    if (this.props.url && prevProps.url !== this.props.url) {
      this.reload();
    }
  }

  reload = () => {
    this.setState({ data: null });

    if (this.props.url) {
      this.props.session.backendGet(
        this.props.url,
        (response) => {
          this.setState({ data: response });
        },
        (errorMessage) => {
          this.props.window.showErrorFunc(errorMessage);
        },
      );
    }
  };

  getWhois = () => {
    return (
      <DialogSection title="Whois">
        <pre>{this.state.data.whois}</pre>
      </DialogSection>
    );
  };

  getZoneFile = () => {
    return (
      <DialogSection title="Zone File">
        <pre>{this.state.data.zoneFile}</pre>
      </DialogSection>
    );
  };

  render() {
    let parts = [];
    let title = "";

    if (this.state.data) {
      parts.push(this.getWhois());
      parts.push(this.getZoneFile());
      title = this.state.data.domain;
    }

    let body = <Container fluid={true}>{parts}</Container>;

    return (
      <DetailsDialog
        show={this.props.show}
        title={title}
        body={body}
        onClose={() => this.props.onClose()}
        width="lg"
        height="lg"
      />
    );
  }
}
