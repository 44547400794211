import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { DropdownButton } from "../common/Buttons";
import styled from "styled-components";

const StyledSpan = styled.span`
  .dropdown {
    vertical-align: top;
    margin-right: 20px;
  }
`;
const ALL_TITLE = "All Types";

export default class ProjectTypeFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: ALL_TITLE,
    };
  }

  render() {
    let selectableTypes = this.props.session
      .getApplication()
      .projectTypes.filter((o) => o.typeCase === this.props.caseModule);

    let items = [];
    items.push(
      <Dropdown.Item
        key="all_project_types"
        onClick={() => {
          this.setState({ title: ALL_TITLE });
          this.props.onChange(selectableTypes.map((o) => o.name).join(","));
        }}
      >
        {ALL_TITLE}
      </Dropdown.Item>,
    );

    selectableTypes.forEach((type) => {
      items.push(
        <Dropdown.Item
          key={type.name}
          onClick={() => {
            this.setState({ title: type.displayName });
            this.props.onChange(type.name);
          }}
        >
          {type.displayName}
        </Dropdown.Item>,
      );
    });

    return (
      <StyledSpan>
        <DropdownButton
          title={this.state.title}
          style={{ verticalAlign: "top" }}
        >
          {items}
        </DropdownButton>
      </StyledSpan>
    );
  }
}
