import React, { Component } from "react";
import { Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import {
  ClearFix,
  Header,
  HeaderMeta,
  HeaderMetaIcon,
  HeaderMetaLabel,
  HeaderMetaLastRow,
  HeaderMetaText,
  Organisation,
  PaddedContent,
  TitleWrapper,
  TruncatedTitle,
} from "../../common/theme/Theme";
import { FormatDateMonospace } from "../../common/Utils";
import styled from "styled-components";
import Colors from "../../common/theme/Colors";
import { InternalLink } from "../../common/Link";
import { ActionButton, DeleteButton } from "../../common/Buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { darken } from "polished";
import UpdateCommentsDialog from "../../common/UpdateCommentsDialog";
import UpdateDomainLifecycleDialog from "../../common/UpdateDomainLifecycleDialog";
import GenerateAuthCodeDialog from "./GenerateAuthCodeDialog";
import ChangeAccountDialog from "../../common/ChangeAccountDialog";
import Icon from "../../common/theme/Icons";

const CommentsArea = styled.div`
  height: 90px;
  border-style: solid;
  border-color: ${Colors.border};
  border-width: thin;
  margin-top: 5px;
  white-space: pre-wrap;
  text-align: left;
`;

const Comment = styled.div`
  height: 55px;
  overflow-y: scroll;
`;

const DomainButtons = styled.div`
  margin-top: 15px;
  display: block;
`;

const IconButtons = styled.div`
  position: absolute;
  bottom:5px;
  right:50%;
`;

const IconButton = styled(FontAwesomeIcon)`
  && {
    color: ${Colors.tertiary};
    font-size: 20px;
    cursor: pointer;
    &:hover {
      color: ${darken(0.5, Colors.tertiary)};
    }
  }
`;

class DomainHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      domain: props.domain,
      generateAuthCodeDialogShow: false,
      updateLifecycleDialogShow: false,
      changeAccountDomainDialogShow: false,
      showUpdateCommentsDialog: false,
      errorMessage: null,
      submit: null,
    };
  }

  reload = () => {
    let url = this.state.domain.links.self;
    this.props.session.backendGet(url, (response) => {
      this.setState({
        domain: response
      });
    });
  }

  showUpdateLifecycleDialog = () => {
    this.setState({
      updateLifecycleDialogShow: true,
      errorMessage: null,
      submit: null,
    });
  };

  hideUpdateLifecycleDialog = () => {
    this.reload();
    this.setState({
      updateLifecycleDialogShow: false,
      errorMessage: null,
      submit: null,
    });
  };

  showChangeAccountDialog = () => {
    this.setState({
      changeAccountDomainDialogShow: true,
      errorMessage: null,
      submit: null,
    });
  };

  hideChangeAccountDialog = () => {
    this.setState({
      changeAccountDomainDialogShow: false,
      errorMessage: null,
      submit: null,
    });
  };

  showGenerateAuthCodeDialog = () => {
    this.setState({
      generateAuthCodeDialogShow: true,
      errorMessage: null,
      submit: null,
    });
  };

  hideGenerateAuthCodeDialog = () => {
    this.setState({
      generateAuthCodeDialogShow: false,
      errorMessage: null,
      submit: null,
    });
  };

  showUpdateCommentsDialog = () => {
    this.setState({
      showUpdateCommentsDialog: true,
      errorMessage: null,
      submit: null,
    });
  };

  hideUpdateCommentsDialog = () => {
    this.reload();
    this.setState({
      showUpdateCommentsDialog: false,
      submit: false,
      errorMessage: null,
    });
  };

  updateComments = (newComments) => {
    const message = "Comments updated successfully";
    const callback = () => {
      this.hideUpdateCommentsDialog();
      this.props.window.showSuccessFunc(message);
    };
    const errorFun = (msg) => {
      this.setState({ submit: false, errorMessage: msg });
    };
    this.setState({ submit: true });
    this.props.session.backendPut(
        this.state.domain.links.comments,
        {
          comments: newComments,
        },
        callback,
        errorFun,
    );
  };

  updateLifecycle = (resource) => {
    const errorFun = (msg) => {
      this.setState({ errorMessage: msg, submit: false });
    };
    const callback = () => {
      this.hideUpdateLifecycleDialog();
      this.setState({ errorMessage: null });
      let text;
      if (
          resource.relatedSubscriptionIds &&
          resource.relatedSubscriptionIds.length > 0
      ) {
        text = "Domain and related services are";
      } else {
        text = "Domain is";
      }
      this.props.window.showSuccessFunc(text + " updated successfully!");
    };
    this.setState({ submit: true });
    let url = this.state.domain.links.lifecycle;
    this.props.session.backendPut(url, resource, callback, errorFun);
  };

  getDisabledLifecycleTooltip = (
      action,
      renew,
      hasRoleOrderEditDomainEdit,
      lifecycleUpdatable
  ) => {
    if (!hasRoleOrderEditDomainEdit) {
      return (
          "Your user account does not have privileges to " +
          action +
          " the domain on expiration date, contact your client manager to activate this functionality"
      );
    } else if (!lifecycleUpdatable) {
      if (renew) {
        return "The cancellation process has started. If you want to renew the domain, please contact your client manager as soon as possible. There is no guarantee that we can still renew the domain.";
      } else {
        return "The renewal process has started. You will be able to cancel the domain on expiration date after the initiated renewal has been completed. ";
      }
    }
    return "";
  };

  getDisabledTransferTooltip = (hasRoleOrderEditDomainEdit, domain) => {
    if (hasRoleOrderEditDomainEdit) {
      if (domain && !domain.authCodeGenerationAvailible) {
        return "Transfer Out is not possible with this domain, please contact your client manager for help with the operation";
      } else if (domain && domain.localContact) {
        return "Transfer Out is not possible with this domain as it has Local Contact enabled, please contact your client manager for help with the operation";
      } else {
        return "";
      }
    }
    return "Your user account does not have privileges to transfer out this domain, contact your client manager to activate this functionality";
  };

  getActionButtons = () => {
    let parts = [];
    let hasRoleOrderEditDomainEdit =
        this.props.session.hasRole("ORDER_EDIT") &&
        this.props.session.hasRole("DOMAIN_ADMIN_EDIT");

    if (this.state.domain) {
      parts.push(
          <ActionButton
              text={"Change Account"}
              icon={"edit"}
              prefix={"fas"}
              onClick={this.showChangeAccountDialog}
              disabled={!hasRoleOrderEditDomainEdit}
              disabledTooltip={
                "Your user account does not have privileges to change account on the domain, contact your client manager to activate this functionality"
              }
          />
      );

      let lifecycleUpdatable = this.state.domain.dateLifecycleUpdatable;
      let renew = this.state.domain.onExpire !== "Renew";
      let action = renew ? "Renew" : "Cancel";
      let disabledLifecycleDomainTooltip = this.getDisabledLifecycleTooltip(
          action,
          renew,
          hasRoleOrderEditDomainEdit,
          lifecycleUpdatable
      );
      let className = renew ? "" : "delete";

      parts.push(
          <ActionButton
              text={renew ? "Renew on Expiry" : "Cancel on Expiry"}
              tooltip={action + " the domain on expiration date"}
              icon={renew ? "rotate-right" : "trash-can-xmark"}
              prefix={"fas"}
              className={className}
              disabled={!hasRoleOrderEditDomainEdit || !lifecycleUpdatable}
              disabledTooltip={disabledLifecycleDomainTooltip}
              onClick={this.showUpdateLifecycleDialog}
          />
      );
    }

    parts.push(
        <DeleteButton
            key="Transfer Out"
            text={"Transfer Out"}
            tooltip={
              "Will generate a Authorization Code, which is used to transfer the domain."
            }
            icon={"arrow-right-from-arc"}
            prefix={"fas"}
            disabled={
                !hasRoleOrderEditDomainEdit ||
                !this.state.domain ||
                !this.state.domain.authCodeGenerationAvailible ||
                this.state.domain.localContact
            }
            disabledTooltip={this.getDisabledTransferTooltip(
                hasRoleOrderEditDomainEdit,
                this.state.domain
            )}
            onClick={this.showGenerateAuthCodeDialog}
        />
    );
    return parts;
  };

  getDomainBasicInfo() {
    const MetaCheck = (props) => {
      return (
          <HeaderMeta key={props.label} >
            <HeaderMetaLabel>{props.label}</HeaderMetaLabel>
            <HeaderMetaIcon
                className={
                  props.enabled ? "serviceStatusEnabled" : "serviceStatusDisabled"
                }
            >
              <IconCheck enabled={props.enabled} />
            </HeaderMetaIcon>
          </HeaderMeta>
      );
    };

    const IconCheck = (props) => {
      if (props.enabled) {
        return <Icon name="check" iconsize={20} />;
      } else {
        return <Icon name="times" iconsize={20} />;
      }
    };
    let fields = [];
    let row1 = [];
    row1.push(
        <MetaCheck
            key="local-contact"
            label={"Local Contact"}
            enabled={this.state.domain.localContact}
        />
    );
    row1.push(
        <MetaCheck
            key="private-whois"
            label={"Private Whois"}
            enabled={this.state.domain.privateWhois}
        />
    );
    row1.push(
        <MetaCheck
            key="registry-lock"
            label={"Registry Lock"}
            enabled={this.state.domain.registryLockType !== null}
        />
    );
    fields.push(
      <Row key="basic-row-1">
        {row1}
      </Row>,
      <Row key="basic-row-2">
        <HeaderMetaLastRow xs={4}>
          <HeaderMetaLabel>Expiry Date</HeaderMetaLabel>
          <HeaderMetaText>
            {FormatDateMonospace(this.state.domain.expires)}
          </HeaderMetaText>
        </HeaderMetaLastRow>
        <HeaderMetaLastRow xs={4}>
          <HeaderMetaLabel>On Expiry</HeaderMetaLabel>
          <HeaderMetaText>{this.state.domain.onExpire}</HeaderMetaText>
        </HeaderMetaLastRow>
        <HeaderMetaLastRow xs={4}>
          <HeaderMetaLabel>Zone</HeaderMetaLabel>
          {this.getZone(this.state.domain.zone)}
        </HeaderMetaLastRow>
      </Row>,
    );
    return <ClearFix>{fields}</ClearFix>;
  }

  getZone = (zone) => {
    if (zone && zone.accountPrivileged && zone.rolePrivileged) {
      return <InternalLink to= {"/dnsadmin/zones/" + zone.id} text={zone.name} className="header-link" />
    } else {
      let text;
      let tooltip;
      if (!zone) {
        text = "N/A";
        tooltip = (<Tooltip style={{ whiteSpace: "pre-line", wordBreak: "break-word" }}>
          Zone does not exist
        </Tooltip>)
      } else if (!zone.accountPrivileged || !zone.rolePrivileged) {
        text = zone.name;
        tooltip = (<Tooltip style={{ whiteSpace: "pre-line", wordBreak: "break-word" }}>
          Your user account does not have privileges to view the zone. Contact your client manager to activate this functionality.
        </Tooltip>)
      }
     return <OverlayTrigger
          placement="bottom"
          overlay={tooltip}>
       <HeaderMetaText className ="lower">{text}</HeaderMetaText>
      </OverlayTrigger>
    }
  }

  getComments() {
    let fields = [];
    fields.push(
        <Row>
          <HeaderMetaLastRow xs={12}>
            <HeaderMetaLabel>Comments</HeaderMetaLabel>
            <CommentsArea>
              <Comment>
                {this.state.domain.comments}
              </Comment>
              {this.getCommentButton()}
            </CommentsArea>
          </HeaderMetaLastRow>
        </Row>);
    return <ClearFix>{fields}</ClearFix>;
  }
  
  getCommentButton = () => {
    let buttons = [];
    const tooltip = (
        <Tooltip style={{ position: "fixed" }}>Edit comments</Tooltip>
    );
    buttons.push(
        <OverlayTrigger overlay={tooltip} placement={"bottom"}>
          <IconButton icon={["fas", "pen"]} onClick={this.showUpdateCommentsDialog} />
        </OverlayTrigger>
    );
    return <IconButtons>{buttons}</IconButtons>;
  };

  render() {
    return (
        <div>
          <Header>
            <Container fluid>
              <Row>
                <Col xs={5}>
                  <Row>
                    <TitleWrapper>
                      <TruncatedTitle
                          data={this.state.domain.name}
                          isTitle={true}
                          breakAfter={50}
                      ></TruncatedTitle>
                      <Organisation>{this.state.domain.organisation}</Organisation>
                    </TitleWrapper>
                  </Row>
                  <Row>
                    <Col>
                      <ClearFix />
                      <DomainButtons>{this.getActionButtons()}</DomainButtons>
                    </Col>
                  </Row>
                </Col>
                <Col xs={5}>
                  <PaddedContent padding={"0px 20px 0 0"} >
                    {this.getDomainBasicInfo()}
                  </PaddedContent>
                </Col>
                <Col xs={2}>
                  <PaddedContent padding={"0px 20px 0 0"}>
                    {this.getComments()}
                  </PaddedContent>
                </Col>
              </Row>
            </Container>
          </Header>
          <UpdateCommentsDialog
              session={this.props.session}
              window={this.props.window}
              comments={this.state.domain.comments}
              show={this.state.showUpdateCommentsDialog}
              onCancel={this.hideUpdateCommentsDialog}
              onSave={this.updateComments}
              submit={this.state.submit}
              errorMessage={this.state.errorMessage}
          />
          <UpdateDomainLifecycleDialog
              session={this.props.session}
              window={this.props.window}
              show={this.state.updateLifecycleDialogShow}
              errorMessage={this.state.errorMessage}
              submit={this.state.submit}
              domain={this.state.domain}
              onConfirm={this.updateLifecycle}
              onClose={this.hideUpdateLifecycleDialog}
          />
          <GenerateAuthCodeDialog
              session={this.props.session}
              window={this.props.window}
              show={this.state.generateAuthCodeDialogShow}
              errorMessage={this.state.errorMessage}
              submit={this.state.submit}
              domain={this.state.domain}
              onClose={this.hideGenerateAuthCodeDialog}
          />
          {this.state.changeAccountDomainDialogShow && (
              <ChangeAccountDialog
                  session={this.props.session}
                  window={this.props.window}
                  show={this.state.changeAccountDomainDialogShow}
                  errorMessage={this.state.errorMessage}
                  submit={this.state.submit}
                  domainSubscriptionsUrl={this.state.domain.links.subscriptions}
                  changeAccountUrl={this.state.domain.links.changeAccount}
                  onClose={this.hideChangeAccountDialog}
              />
          )}
        </div>
    );
  }
}

export default DomainHeader;
