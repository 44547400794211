import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { ActionButton, BlockingMainActionButton } from "../common/Buttons";
import CertificateDownloadDialog from "./CertificateDownloadDialog";
import { FormatDateMonospace, FormatList } from "../common/Utils";
import fileDownload from "js-file-download";
import ChangeAccountDialog from "../common/ChangeAccountDialog";
import { DetailsDialog, DialogSection } from "../common/Dialog";
import ReadOnlyForm from "../common/ReadOnlyForm";

class CertificateDetailsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      details: null,
      showDownloadDialog: false,
      submit: false,
      errorMessage: null,
      changeAccountDialogShow: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && prevProps.show !== this.props.show) {
      this.loadDetails();
    }
  }

  loadDetails = () => {
    const detailsUrl =
      this.props.session.getModules().certadmin.links.certificates +
      "/" +
      this.props.certificateId;
    this.props.session.backendGet(detailsUrl, (response) => {
      this.setState({
        details: response,
      });
    });
  };

  getBasicField = (field) => {
    return this.state.details[field];
  };

  getValueDescription = (number, label) => {
    let description = number + " " + label;
    if (number > 1) {
      return description + "s";
    }
    return description;
  };

  getContentField = (field) => {
    if (this.state.details.content) {
      return this.state.details.content[field];
    }
  };

  getContactField = (field) => {
    if (this.state.details.contact) {
      return this.state.details.contact[field];
    }
  };

  onReissue = () => {
    let parameters = {
      reissuedCertificateId: this.state.details.id,
    };

    this.props.onClose();
    this.props.onReissue(parameters);
  };

  onRenew = () => {
    let parameters = {
      renewedCertificateId: this.state.details.id,
    };

    this.props.onClose();
    this.props.onRenew(parameters);
  };

  showDownloadDialog = () => {
    this.props.onClose();
    this.setState({
      showDownloadDialog: true,
      submit: false,
      errorMessage: null,
    });
  };

  onDownload = (format, extension) => {
    if (!format || !extension) {
      this.setState({
        errorMessage: "Please select one option to download",
        submit: false,
      });
      return;
    }
    let certificateFiles = new Map(Object.entries(this.state.details.files));
    if (!certificateFiles || !certificateFiles.get(format)) {
      this.setState({
        submit: false,
        errorMessage: "Certificate not found",
      });
      return;
    }
    this.setState({ submit: true });
    let commonName = this.state.details.commonName;
    if (!commonName) {
      commonName = "certificate";
    } else {
      commonName = commonName.replace("@", "_");
      commonName = commonName.replace("-", "_");
      commonName = commonName.replace(" ", "_");
      commonName = commonName.replace("*", "wildcard");
    }
    fileDownload(
      atob(certificateFiles.get(format)),
      commonName + "." + extension,
    );
    this.closeDownloadDialog();
  };

  closeDownloadDialog = () => {
    this.props.onClose();
    this.setState({
      details: null,
      showDownloadDialog: false,
      submit: false,
      errorMessage: null,
    });
  };

  showChangeAccountDialog = () => {
    this.props.onClose();
    this.setState({
      changeAccountDialogShow: true,
      errorMessage: null,
      submit: null,
    });
  };

  hideChangeAccountDialog = () => {
    this.props.onClose();
    this.setState({
      changeAccountDialogShow: false,
      errorMessage: null,
      submit: null,
    });
  };

  onChangeAccount = () => {
    this.props.onChangeAccount();
    this.setState({
      changeAccountDialogShow: false,
      errorMessage: null,
      submit: null,
    });
  };

  getBasicInfo = () => {
    const content = [
      {
        label: "Organisation",
        value: this.getBasicField("organisation"),
      },
      {
        label: "Type",
        value: this.getBasicField("certificateTypeDescription"),
      },
      {
        label: "Service Validity Period",
        value: this.getValueDescription(this.getBasicField("months"), "month"),
      },
      {
        label: "Service Expiry Date",
        value: FormatDateMonospace(this.getBasicField("invoiceDate")),
      },
      {
        label: "On Service Expiry",
        value: this.getBasicField("onExpire"),
      },
    ];

    return (
      <DialogSection title="Basic Info">
        <ReadOnlyForm wide="true" content={content} />
      </DialogSection>
    );
  };

  formatSans = (sans) => {
    sans.sort();
    return FormatList(sans);
  };

  getContent = () => {
    const content = [
      this.getContentField("commonName") && {
        label: "Common Name",
        value: this.getContentField("commonName"),
      },
      this.getContentField("sans") && {
        label: "Subject Alternative Names",
        value: this.formatSans(this.getContentField("sans")),
      },
      this.getContentField("organization") && {
        label: "Organization",
        value: this.getContentField("organization"),
      },
      this.getContentField("organizationalUnit") && {
        label: "Organizational Unit",
        value: this.getContentField("organizationalUnit"),
      },
      this.getContentField("locality") && {
        label: "City / Locality",
        value: this.getContentField("locality"),
      },
      this.getContentField("state") && {
        label: "State / Province",
        value: this.getContentField("state"),
      },
      this.getContentField("country") && {
        label: "Country",
        value: this.getContentField("country").label,
      },
      {
        label: "Expiry Date",
        value: FormatDateMonospace(this.getBasicField("expiryDate")),
      },
    ];

    return (
      <DialogSection title="Certificate Contents">
        <ReadOnlyForm wide="true" content={content} />
      </DialogSection>
    );
  };

  getContact = () => {
    const content = [
      this.getContactField("firstName") && {
        label: "First Name",
        value: this.getContactField("firstName"),
      },
      this.getContactField("lastName") && {
        label: "Last Name",
        value: this.getContactField("lastName"),
      },
      this.getContactField("jobTitle") && {
        label: "Job Title",
        value: this.getContactField("jobTitle"),
      },
      this.getContactField("phone") && {
        label: "Phone",
        value: this.getContactField("phone"),
      },
      this.getContactField("email") && {
        label: "E-Mail",
        value: this.getContactField("email"),
      },
    ];

    return (
      <DialogSection title="Contact Person">
        <ReadOnlyForm wide="true" content={content} />
      </DialogSection>
    );
  };

  getComments = () => {
    let comments = this.getBasicField("comments");

    if (comments === null) {
      return null;
    }

    const content = [
      {
        label: "Comments",
        value: comments,
      },
    ];

    return (
      <DialogSection title="">
        <ReadOnlyForm wide="true" content={content} />
      </DialogSection>
    );
  };

  getBody = () => {
    return (
      <Container key="parts" fluid={true}>
        {this.getBasicInfo()}
        {this.getContent()}
        {this.getContact()}
        {this.getComments()}
      </Container>
    );
  };

  isReissueButtonDisabled = (hasRole, typeRenewable) => {
    return !hasRole || !typeRenewable;
  };

  isRenewButtonDisabled = (hasRole, dateRenewable, typeRenewable) => {
    return !hasRole || !dateRenewable || !typeRenewable;
  };

  getDisabledReissueTooltip = (hasRole, typeRenewable) => {
    if (!hasRole) {
      return "Your user account does not have privileges to reissue the certificate, contact your client manager to activate this functionality";
    } else if (!typeRenewable) {
      return (
        "The reissue of type " +
        this.getBasicField("certificateTypeDescription") +
        " is not supported"
      );
    }
    return "";
  };

  getRenewTooltip = (hasRole, dateRenewable, typeRenewable) => {
    if (!hasRole) {
      return "Your user account does not have privileges to reissue the certificate, contact your client manager to activate this functionality";
    } else if (!typeRenewable) {
      return (
        "The renewal of type " +
        this.getBasicField("certificateTypeDescription") +
        " is not supported, please order a new certificate instead"
      );
    } else if (!dateRenewable) {
      return "You can renew the certificate when 90 days are remaining of the service period. If you have a multi year service new one year certificates will be reissued within the service period.";
    }
    return "";
  };

  onUpdateLifecycle = (lifecycle) => {
    const url = this.state.details.links.lifecycle;
    const serviceDescription = this.state.details.commonName;
    this.props.onClose();
    this.props.onUpdateLifecycle(serviceDescription, lifecycle, url);
  };

  render() {
    if (!this.state.details) {
      return null;
    }

    let title = this.state.details.commonName
      ? this.state.details.commonName
      : "Certificate Details";

    let hasRoleRenew =
      this.props.session.hasRole("ORDER_CREATE") &&
      this.props.session.hasRole("CERT_ADMIN_EDIT");

    let buttons = [];

    buttons.push(
      <BlockingMainActionButton
        key="download"
        text={"Download"}
        onClick={this.showDownloadDialog}
        disabledTooltip={"Please contact your client manager for more details"}
        disabled={!this.state.details.downloadable}
      />,
    );

    buttons.push(
      <ActionButton
        key="reissue"
        text="Reissue"
        onClick={this.onReissue}
        disabled={this.isReissueButtonDisabled(
          hasRoleRenew,
          this.state.details.typeRenewable,
        )}
        tooltip={
          "You can reissue an existing certificate if you need to change e.g. the common name"
        }
        disabledTooltip={this.getDisabledReissueTooltip(
          hasRoleRenew,
          this.state.details.typeRenewable,
        )}
      />,
    );

    buttons.push(
      <ActionButton
        key="renew"
        text="Renew"
        onClick={this.onRenew}
        disabled={this.isRenewButtonDisabled(
          hasRoleRenew,
          this.state.details.dateRenewable,
          this.state.details.typeRenewable,
        )}
        disabledTooltip={this.getRenewTooltip(
          hasRoleRenew,
          this.state.details.dateRenewable,
          this.state.details.typeRenewable,
        )}
      />,
    );

    buttons.push(
      <ActionButton
        text={"Change Account"}
        disabledTooltip={
          "Your user account does not have privileges to change account on the certificate, contact your client manager to activate this functionality"
        }
        disabled={!this.state.details.sessionAccountChangeable}
        onClick={() => this.showChangeAccountDialog()}
      />,
    );

    let hasRoleLifecycle =
      this.props.session.hasRole("ORDER_EDIT") &&
      this.props.session.hasRole("CERT_ADMIN_EDIT");
    let text = this.state.details.onExpire === "Renew" ? "Cancel" : "Renew";

    buttons.push(
      <ActionButton
        text={text + " On Service Expiry"}
        tooltip={text + " the certificate service on service expiration date"}
        disabledTooltip={
          "Your user account does not have privileges to change lifecycle on the certificate service, contact your client manager to activate this functionality"
        }
        disabled={!hasRoleLifecycle}
        onClick={() => this.onUpdateLifecycle(text)}
      />,
    );
    return (
      <div>
        <DetailsDialog
          show={this.props.show}
          title={title}
          actions={buttons}
          body={this.getBody()}
          onClose={this.props.onClose}
          height="lg"
          width="lg"
        />
        <CertificateDownloadDialog
          session={this.props.session}
          window={this.props.window}
          show={this.state.showDownloadDialog}
          errorMessage={this.state.errorMessage}
          onDownload={this.onDownload}
          onClose={this.closeDownloadDialog}
        />

        {this.state.changeAccountDialogShow && (
          <ChangeAccountDialog
            session={this.props.session}
            window={this.props.window}
            show={this.state.changeAccountDialogShow}
            errorMessage={this.state.errorMessage}
            submit={this.state.submit}
            changeAccountUrl={this.state.details.links.changeAccount}
            onClose={this.hideChangeAccountDialog}
            onChangeAccount={this.onChangeAccount}
          />
        )}
      </div>
    );
  }
}

export default CertificateDetailsDialog;
