import React, { Component } from "react";
import { DropdownButton } from "../common/Buttons";
import { Dropdown } from "react-bootstrap";
import styled from "styled-components";

const OrganisationFilterStyle = styled.span`
  .dropdown {
    vertical-align: top;
    margin-right: 20px;
  }

  .level1 {
    padding-left: 40px;
  }

  .level2 {
    padding-left: 60px;
  }

  .level1::before,
  .level2::before {
    content: "\\2043\\202F\\202F";
  }

  .dropdown-menu {
    max-height: 400px;
    max-width: 450px;
    overflow: auto;
  }
`;
const ALL_ORGANISATIONS = "All Organisations";

export default class OrganisationFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: ALL_ORGANISATIONS,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.initialTitle !== this.props.initialTitle &&
      this.props.initialTitle
    ) {
      this.setState({ title: this.props.initialTitle });
    }
  }

  render() {
    let orgs = this.props.session.getApplication().organisations;

    if (!orgs || orgs.length === 0) {
      return null;
    }

    let items = [];

    items.push(
      <Dropdown.Item
        key="all_organisations"
        onClick={() => {
          this.setState({ title: ALL_ORGANISATIONS });
          this.props.onChange("", null);
        }}
      >
        {ALL_ORGANISATIONS}
      </Dropdown.Item>,
    );

    for (let i = 0; i < orgs.length; i++) {
      items.push(
        <Dropdown.Item
          key={i + orgs[i].description}
          className={"level" + orgs[i].level}
          onClick={() => {
            this.setState({ title: orgs[i].description });
            this.props.onChange(orgs[i].accountIds, orgs[i].description);
          }}
        >
          {orgs[i].description}
        </Dropdown.Item>,
      );
    }

    return (
      <OrganisationFilterStyle>
        <DropdownButton
          title={this.state.title}
          style={{ verticalAlign: "top" }}
        >
          {items}
        </DropdownButton>
      </OrganisationFilterStyle>
    );
  }
}
