import React, { Component } from "react";
import { MainActionButton } from "../common/Buttons";
import { TableFrontend } from "../common/Tables";
import UserOrganisationDialog from "./UserOrganisationDialog";

class OrganisationTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
    };
  }

  showDialog = () => {
    this.setState({ showDialog: true });
  };

  closeDialog = () => {
    this.setState({ showDialog: false });
    this.props.onChange();
  };

  getOrganisationCellStyle(organisation) {
    if (!organisation) {
      return;
    }

    let style = {};
    style.textAlign = "left";

    const type = organisation.organisationId.type;
    switch (type) {
      case "CASE_ACCOUNT":
      case "CHILD_ACCOUNT":
        style.marginLeft = "30px";
        break;
      case "CASE_CHILD_ACCOUNT":
        style.marginLeft = "60px";
        break;
      default:
        break;
    }

    return style;
  }

  render() {
    const columns = [
      {
        label: "Organisation",
        contentFunction: (r) => (
          <div style={this.getOrganisationCellStyle(r)}>{r.description}</div>
        ),
      },
    ];

    const actions = [
      <MainActionButton
        key="edit-organisation"
        onClick={this.showDialog}
        prefix={"fas"}
        icon={"edit"}
        text="Edit Organisations"
      />,
    ];

    return (
      <div>
        <UserOrganisationDialog
          session={this.props.session}
          window={this.props.window}
          showDialog={this.state.showDialog}
          closeDialog={this.closeDialog}
          url={this.props.user.links.configuration}
        />
        <TableFrontend
          session={this.props.session}
          window={this.props.window}
          actions={actions}
          columns={columns}
          url={this.props.user.links.organisations}
        />
      </div>
    );
  }
}

export default OrganisationTab;
