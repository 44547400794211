import React, { Component } from "react";
import {
  Col,
  Container,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
} from "react-bootstrap";
import { MainActionButton } from "../../common/Buttons";
import styled from "styled-components";
import { BorderColor } from "../../common/theme/Theme";
import Colors from "../../common/theme/Colors";
import BulkDialog from "./BulkDialog";

const PageStyle = styled.div`
  .body {
    padding: 0px 10px 10px 10px;
    background: ${Colors.white};
    border: 1px solid ${BorderColor};
  }

  .sectionTitle {
    margin-top: 30px;
    margin-bottom: 5px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 1.5px;
  }

  .sectionBody {
    padding: 15px 0px 10px 0px;
  }

  .sectionColumn {
    display: inline-block;
    vertical-align: top;
    padding-right: 40px;
  }

  .operationRadio input {
    margin-right: 5px;
  }
`;

export default class BulkPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      operation: "zoneQuery",
      zoneNames: "",
      zoneMname: "",
      zoneRefresh: "",
      zoneRetry: "",
      zoneExpire: "",
      zoneTtl: "",
      zoneArchive: null,
      zoneFileNameRegex: "",
      zoneFileNameReplacement: "",
      zoneFileFilter: "",
      recordName: "",
      recordTtl: "",
      recordType: "",
      recordData: "",
      redirectType: "",
      redirectCertificate: "",
      redirectName: "",
      redirectPath: "",
      redirectDestination: "",
      serverIp: "",
      executing: false,
      commandUrl: null,
      commandBody: null,
    };
  }

  componentDidMount() {
    let views = [
      {
        label: "Home",
        url: "/",
      },
      {
        label: "Bulk",
        url: null,
      },
    ];

    this.props.window.setBreadcrumbViews(views);
    this.props.window.setPageTip(null);
  }

  execute = () => {
    let url =
      this.props.session.getModules().dnsadmin.links["bulk"] +
      "/" +
      this.state.operation;
    let body = { zoneNames: this.state.zoneNames.split(/\r?\n/) };

    if (["zoneUpdate"].includes(this.state.operation)) {
      body.mname = this.state.zoneMname;
      body.refresh = this.state.zoneRefresh;
      body.retry = this.state.zoneRetry;
      body.expire = this.state.zoneExpire;
      body.ttl = this.state.zoneTtl;
    }

    if (["zoneImport"].includes(this.state.operation)) {
      body.archive = this.state.zoneArchive;
      body.fileNameRegex = this.state.zoneFileNameRegex;
      body.fileNameReplacement = this.state.zoneFileNameReplacement;
      body.fileFilter = this.state.zoneFileFilter;
    }

    if (
      ["recordQuery", "recordCreate", "recordDelete"].includes(
        this.state.operation,
      )
    ) {
      body.name = this.state.recordName;
      body.ttl = this.state.recordTtl;
      body.type = this.state.recordType;
      body.data = this.state.recordData;
    }

    if (
      ["redirectQuery", "redirectCreate", "redirectDelete"].includes(
        this.state.operation,
      )
    ) {
      body.type = this.state.redirectType;
      body.certificate = this.state.redirectCertificate;
      body.name = this.state.redirectName;
      body.path = this.state.redirectPath;
      body.destination = this.state.redirectDestination;
    }

    if (
      ["serverQuery", "serverCreate", "serverDelete"].includes(
        this.state.operation,
      )
    ) {
      body.ip = this.state.serverIp;
    }

    this.setState({
      executing: true,
      commandUrl: url,
      commandBody: body,
    });
  };

  close = () => {
    this.setState({
      executing: false,
    });
  };

  getOperationDescription(operation) {
    var descriptions = {
      zoneQuery: "List Zones",
      zoneUpdate: "Update Zones",
      zoneDisable: "Disable Zones",
      zoneImport: "Import Zones",
      zoneRevert: "Revert Zones",
      zonePublish: "Publish Zones",
      zoneStartDnsSec: "Start DNSSEC",
      zoneStopDnsSec: "Stop DNSSEC",
      recordQuery: "Search Records",
      recordCreate: "Add Records",
      recordDelete: "Remove Records",
      redirectQuery: "Search Redirects",
      redirectCreate: "Add Redirects",
      redirectDelete: "Remove Redirects",
      serverQuery: "Search Servers",
      serverCreate: "Add Servers",
      serverDelete: "Remove Servers",
    };
    return descriptions[operation];
  }

  getZoneOperationSection() {
    return (
      <div className="sectionColumn">
        {this.getOperationRadio("zoneQuery")}
        {this.getOperationRadio("zoneUpdate")}
        {this.getOperationRadio("zoneDisable")}
        {this.getOperationRadio("zoneImport")}
        {this.getOperationRadio("zoneRevert")}
        {this.getOperationRadio("zonePublish")}
        {this.getOperationRadio("zoneStartDnsSec")}
        {this.getOperationRadio("zoneStopDnsSec")}
      </div>
    );
  }

  getRecordOperationSection() {
    return (
      <div className="sectionColumn">
        {this.getOperationRadio("recordQuery")}
        {this.getOperationRadio("recordCreate")}
        {this.getOperationRadio("recordDelete")}
      </div>
    );
  }

  getRedirectOperationSection() {
    return (
      <div className="sectionColumn">
        {this.getOperationRadio("redirectQuery")}
        {this.getOperationRadio("redirectCreate")}
        {this.getOperationRadio("redirectDelete")}
      </div>
    );
  }

  getServerOperationSection() {
    return (
      <div className="sectionColumn">
        {this.getOperationRadio("serverQuery")}
        {this.getOperationRadio("serverCreate")}
        {this.getOperationRadio("serverDelete")}
      </div>
    );
  }

  getZoneNamesSection() {
    return (
      <div className="sectionColumn">
        <FormGroup>
          <FormLabel>Zone Names</FormLabel>
          <FormControl
            as="textarea"
            rows="10"
            cols="60"
            value={this.state.zoneNames}
            onChange={(e) => this.setState({ zoneNames: e.target.value })}
          />
        </FormGroup>
      </div>
    );
  }

  getZoneUpdateSection() {
    if (!["zoneUpdate"].includes(this.state.operation)) {
      return null;
    }

    return (
      <div className="sectionColumn">
        {this.getParameterField("zoneMname", "Zone MName")}
        {this.getParameterField("zoneRefresh", "Zone Refresh")}
        {this.getParameterField("zoneRetry", "Zone Retry")}
        {this.getParameterField("zoneExpire", "Zone Expire")}
        {this.getParameterField("zoneTtl", "Zone TTL")}
      </div>
    );
  }

  getZoneImportSection() {
    if (!["zoneImport"].includes(this.state.operation)) {
      return null;
    }

    return (
      <div className="sectionColumn">
        <FormGroup>
          <FormLabel>Zone File Zip Archive</FormLabel>
          <input
            type="file"
            accept=".zip"
            onChange={(e) => {
              const file = e.target.files[0];
              const reader = new FileReader();
              reader.readAsBinaryString(file);
              reader.onloadend = () => {
                this.setState({ zoneArchive: btoa(reader.result) });
              };
            }}
          />
        </FormGroup>
        {this.getParameterField("zoneFileNameRegex", "File Name Regex")}
        {this.getParameterField(
          "zoneFileNameReplacement",
          "File Name Replacement",
        )}
        {this.getParameterField("zoneFileFilter", "Filter Out Records", true)}
      </div>
    );
  }

  getRecordSection() {
    if (
      !["recordQuery", "recordCreate", "recordDelete"].includes(
        this.state.operation,
      )
    ) {
      return null;
    }

    return (
      <div className="sectionColumn">
        {this.getParameterField("recordName", "Record Name")}
        {this.getParameterField("recordTtl", "Record TTL")}
        {this.getParameterField("recordType", "Record Type")}
        {this.getParameterField("recordData", "Record Data")}
      </div>
    );
  }

  getRedirectSection() {
    if (
      !["redirectQuery", "redirectCreate", "redirectDelete"].includes(
        this.state.operation,
      )
    ) {
      return null;
    }

    return (
      <div className="sectionColumn">
        {this.getParameterField("redirectType", "Redirect Type")}
        {this.getParameterField(
          "redirectCertificate",
          "Redirect Managed Certificate",
        )}
        {this.getParameterField("redirectName", "Redirect Name")}
        {this.getParameterField("redirectPath", "Redirect Path")}
        {this.getParameterField("redirectDestination", "Redirect Destination")}
      </div>
    );
  }

  getServerSection() {
    if (
      !["serverQuery", "serverCreate", "serverDelete"].includes(
        this.state.operation,
      )
    ) {
      return null;
    }

    return (
      <div className="sectionColumn">
        {this.getParameterField("serverIp", "Server IP")}
      </div>
    );
  }

  getOperationRadio(operation) {
    return (
      <div className="operationRadio">
        <input
          type="radio"
          name="operation"
          value={operation}
          checked={this.state.operation === operation}
          onChange={(e) => this.setState({ operation: e.target.value })}
        />
        {this.getOperationDescription(operation)}
      </div>
    );
  }

  getParameterField(value, description, multiline) {
    let control = null;

    if (multiline) {
      control = (
        <FormControl
          as="textarea"
          size="60"
          rows="4"
          value={this.state[value]}
          onChange={(e) => {
            let newState = {};
            newState[value] = e.target.value;
            this.setState(newState);
          }}
        />
      );
    } else {
      control = (
        <FormControl
          type="text"
          size="60"
          value={this.state[value]}
          onChange={(e) => {
            let newState = {};
            newState[value] = e.target.value;
            this.setState(newState);
          }}
        />
      );
    }

    return (
      <FormGroup>
        <FormLabel>{description}</FormLabel>
        {control}
      </FormGroup>
    );
  }

  render() {
    return (
      <PageStyle>
        <Container fluid={true}>
          <Row className="body">
            <Col xs={12}>
              <div className="sectionTitle">Operation</div>
              <div className="sectionBody">
                {this.getZoneOperationSection()}
                {this.getRecordOperationSection()}
                {this.getRedirectOperationSection()}
                {this.getServerOperationSection()}
              </div>
              <div className="sectionTitle">Parameters</div>
              <div className="sectionBody">
                {this.getZoneNamesSection()}
                {this.getZoneUpdateSection()}
                {this.getZoneImportSection()}
                {this.getRecordSection()}
                {this.getRedirectSection()}
                {this.getServerSection()}
              </div>
              <div className="sectionBody">
                <MainActionButton
                  onClick={this.execute}
                  type="button"
                  text="Execute Bulk Operation"
                />
              </div>
            </Col>
          </Row>
        </Container>
        <BulkDialog
          session={this.props.session}
          window={this.props.window}
          executing={this.state.executing}
          title={this.getOperationDescription(this.state.operation)}
          commandUrl={this.state.commandUrl}
          commandBody={this.state.commandBody}
          onClose={this.close}
        />
      </PageStyle>
    );
  }
}
