import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { TableBase } from "../../common/Tables";
import { DetailsDialog } from "../../common/Dialog";

export default class DomainDetailsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      whois: null,
      whoisError: false,
      nameservers: [],
    };
  }

  componentDidMount() {
    this.reload();
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && prevProps.show !== this.props.show) {
      this.reload();
    }
  }

  reload = () => {
    this.reloadWhois();
    this.reloadNameservers();
  };

  reloadWhois = () => {
    this.setState({ whois: null, whoisError: false });
    if (this.props.domain?.links.whois) {
      this.props.session.backendGet(
        this.props.domain.links.whois,
        (response) => {
          this.setState({ whois: response.whois });
        },
        () => {
          this.setState({ whoisError: true });
        },
      );
    }
  };

  reloadNameservers = () => {
    this.setState({ nameservers: [] });
    if (this.props.domain?.links.nameservers) {
      this.props.session.backendGet(
        this.props.domain.links.nameservers,
        (response) => {
          this.setState({ nameservers: response.objects });
        },
      );
    }
  };

  getNameservers = () => {
    const nameserverColumns = [
      {
        label: "Name Server",
        name: "nameserver",
        style: { width: "200px" },
      },
    ];

    return [
      <Row key="header">
        <Col xs={12}>
          <div className="section-title">Nameservers</div>
        </Col>
      </Row>,
      <Row key="nameservers">
        <Col xs={12}>
          <TableBase
            columns={nameserverColumns}
            rows={this.state.nameservers}
            noTableHeader
          />
        </Col>
      </Row>,
    ];
  };

  getWhois = () => {
    let content = "Loading, please wait";
    if (this.state.whoisError) {
      content = "Failed fetching data. Please try again later.";
    } else if (this.state.whois !== null) {
      content = this.state.whois;
    }

    return [
      <Row key="whois">
        <Col xs={12}>
          <div className="section-title">Whois</div>
        </Col>
      </Row>,
      <Row key="whois-content">
        <Col xs={12}>
          <pre>{content}</pre>
        </Col>
      </Row>,
    ];
  };

  getBody = () => {
    let parts = [];
    if (this.props.domain) {
      parts.push(this.getNameservers());
      parts.push(this.getWhois());
    }
    return <Container fluid={true}>{parts}</Container>;
  };

  render() {
    let title = "";
    if (this.props.domain) {
      title = this.props.domain.domainName;
    }

    return (
      <div>
        <DetailsDialog
          show={this.props.show}
          title={title}
          body={this.getBody()}
          onClose={() => this.props.onClose()}
          width="lg"
          height="lg"
        />
      </div>
    );
  }
}
