import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import DomainList from "./domain/DomainList";
import ContactList from "./contact/ContactList";
import SearchTool from "./searchtool/SearchTool";
import NameserverSetList from "./nameserver/NameserverSetList";
import DomainPage from "./domain/DomainPage";

class DomainAdmin extends Component {
  render() {
    return (
      <Switch>
        <Route
          exact={true}
          path="/domainadmin/domains"
          render={(props) => (
            <DomainList
              session={this.props.session}
              window={this.props.window}
              {...props}
            />
          )}
        />
        <Route
          path="/domainadmin/domains/:domainId"
          render={(props) => (
            <DomainPage
              session={this.props.session}
              window={this.props.window}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/domainadmin/contacts"
          render={(props) => (
            <ContactList
              session={this.props.session}
              window={this.props.window}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/domainadmin/nameserversets"
          render={(props) => (
            <NameserverSetList
              session={this.props.session}
              window={this.props.window}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/domainadmin/searchtool"
          render={(props) => (
            <SearchTool
              session={this.props.session}
              window={this.props.window}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/domainadmin/searchtool/:domain"
          render={(props) => (
            <SearchTool
              session={this.props.session}
              window={this.props.window}
              {...props}
            />
          )}
        />
      </Switch>
    );
  }
}

export default DomainAdmin;
