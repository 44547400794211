import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { DialogSection } from "../../common/Dialog";
import ReadOnlyForm from "../../common/ReadOnlyForm";
import styled from "styled-components";
import Colors from "../../common/theme/Colors";
import { ActionButton } from "../../common/Buttons";
import CreateOrderDialog from "../../order/CreateOrderDialog";

const DivStyle = styled.div`
  border: 1px solid ${Colors.border};
  background-color: ${Colors.white};
  table {
    margin-top: 15px;
  }
  img {
    max-height: 700px;
    max-width: 700px;
  }

  .section-title {
    color: ${Colors.tertiary};
    margin-top: 10px;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 1.5px;
  }

  > .container-fluid > .actions {
    padding: 15px 0px 10px 0px;
  }
`;

class ContactsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      createOrderDialogOwnerTransferShow: false,
      createOrderDialogSwitchContactShow: false,
    };
  }

  getActionButtons = () => {
    let parts = [];
    let hasRoleEdit =
        this.props.session.hasRole("ORDER_CREATE") &&
        this.props.session.hasRole("DOMAIN_ADMIN_EDIT");
    parts.push(
        <ActionButton
            key="OwnerTransfer"
            onClick={() => {
              this.setState({ createOrderDialogOwnerTransferShow: true });
            }}
            text="Owner Transfer"
            icon={"edit"}
            prefix={"fas"}
            disabled={!hasRoleEdit}
            tooltip="Open the order dialog and fill in details to order a domain owner transfer, it allows you to change all contact information on a domain, including owner related contact information."
            disabledTooltip="Your user account does not have privileges to order owner transfers, contact your client manager to activate this functionality"
        />
    );
    parts.push(
        <ActionButton
            key="Change Contacts"
            onClick={() => {
              this.setState({ createOrderDialogSwitchContactShow: true });
            }}
            text="Change Contacts"
            icon={"edit"}
            prefix={"fas"}
            disabled={!hasRoleEdit}
            tooltip="Open the order dialog and fill in details to order a domain contact change, it allows you to change the contact information on the domain, that is not owner related contact information."
            disabledTooltip="Your user account does not have privileges to order contact changes, contact your client manager to activate this functionality"
        />
    );
    return (<Row key="contact-actions" className="actions">
      <Col xs={12}>{parts}</Col>
    </Row>);
  };
  getContact = (label, contact) => {
    let content = [
      {
        label: "Name",
        value: contact.name,
      },
      {
        label: "Organisation",
        value: contact.org,
      },
      {
        label: "Street",
        value: [contact.street1, contact.street2].filter((o) => o).join("\n"),
      },
      {
        label: "Postal Code",
        value: contact.postalCode,
      },
      {
        label: "City",
        value: contact.city,
      },
      {
        label: "Country",
        value: contact.countryCode,
      },
      {
        label: "Email",
        value: contact.email,
      },
      {
        label: "Voice",
        value: contact.voice,
      },
      {
        label: "Extensions",
        valueStyle: { paddingRight: "40px", minWidth: "300px" },
        value: Array.from(
          contact.extensions,
          (o) => o.type + ": " + o.value,
        ).join("\n"),
      },
    ];

    return (
      <DialogSection inline="true" title={label}>
        <ReadOnlyForm content={content} />
      </DialogSection>
    );
  };

  render() {
    let parameters = {};
    parameters.domainNames = this.props.domain.name;

    return (
        <DivStyle>
          <Container key="parts" fluid={true}>
            {this.getActionButtons()}
            <Row key="contact-data">
              <Col xs={12}>
                {Object.keys(this.props.domain.contacts).map((key) => {
                  const contact = this.props.domain.contacts[key];
                  return this.getContact(key, contact);
                })}
              </Col>
            </Row>
          </Container>
          <CreateOrderDialog
              session={this.props.session}
              show={this.state.createOrderDialogOwnerTransferShow}
              onClose={() =>
                  this.setState({ createOrderDialogOwnerTransferShow: false })
              }
              step="DOMAIN_NAMES"
              operation="DOMAIN-OWNER_TRANSFER"
              parameters={parameters}
          />
          <CreateOrderDialog
              session={this.props.session}
              show={this.state.createOrderDialogSwitchContactShow}
              onClose={() =>
                  this.setState({ createOrderDialogSwitchContactShow: false })
              }
              step="DOMAIN_NAMES"
              operation="DOMAIN-SWITCH_CONTACT"
              parameters={parameters}
          />
        </DivStyle>

    );
  }
}

export default ContactsTab;
