import React, { Component } from "react";
import { ActionButton } from "../common/Buttons";
import { FormatDateMonospace, FormatList } from "../common/Utils";
import ChangeAccountDialog from "../common/ChangeAccountDialog";
import { DetailsDialog, DialogSection } from "../common/Dialog";
import ReadOnlyForm from "../common/ReadOnlyForm";
import { Container } from "react-bootstrap";

class CertificateDetailsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changeAccountDialogShow: false,
    };
  }

  showChangeAccountDialog = () => {
    this.props.onClose();
    this.setState({
      changeAccountDialogShow: true,
      errorMessage: null,
      submit: null,
    });
  };

  hideChangeAccountDialog = () => {
    this.props.onClose();
    this.setState({
      changeAccountDialogShow: false,
    });
  };

  onChangeAccount = () => {
    this.props.onChangeAccount();
    this.setState({
      changeAccountDialogShow: false,
    });
  };

  getBody = () => {
    return (
      <Container key="parts" fluid={true}>
        {this.getBasicInfo()}
        {this.getContent()}
        {this.getComments()}
      </Container>
    );
  };

  onUpdateLifecycle = (lifecycle) => {
    const url = this.props.certificate.links.lifecycle;
    const serviceDescription = this.props.certificate.commonName;
    this.props.onClose();
    this.props.onUpdateLifecycle(serviceDescription, lifecycle, url);
  };

  getActionButtons = () => {
    let buttons = [];
    let changeAccountButtonDisabled =
      !this.props.certificate.sessionAccountChangeable;

    let disabledChangeAccountTooltip;
    if (changeAccountButtonDisabled) {
      disabledChangeAccountTooltip =
        "Your user account does not have privileges to change account on the certificate, contact your client manager to activate this functionality";
    }
    buttons.push(
      <ActionButton
        text={"Change Account"}
        disabledTooltip={disabledChangeAccountTooltip}
        disabled={changeAccountButtonDisabled}
        onClick={() => this.showChangeAccountDialog()}
      />,
    );

    let hasRoleLifecycle =
      this.props.session.hasRole("ORDER_EDIT") &&
      this.props.session.hasRole("CERT_ADMIN_EDIT");
    let text = this.props.certificate.onExpire === "Renew" ? "Cancel" : "Renew";

    buttons.push(
      <ActionButton
        text={text + " On Service Expiry"}
        tooltip={text + " the certificate service on service expiration date"}
        disabledTooltip={
          "Your user account does not have privileges to change lifecycle on the certificate service, contact your client manager to activate this functionality"
        }
        disabled={!hasRoleLifecycle}
        onClick={() => this.onUpdateLifecycle(text)}
      />,
    );

    return <>{buttons}</>;
  };

  getBasicInfo = () => {
    const content = [
      {
        label: "Organisation",
        value: this.props.certificate.organisation,
      },
      {
        label: "Type",
        value: this.props.certificate.certificateTypeDescription,
      },
      {
        label: "Service Validity Period",
        value: this.props.certificate.months + " months",
      },
      {
        label: "Service Expiry Date",
        value: FormatDateMonospace(this.props.certificate.invoiceDate),
      },
      {
        label: "On Service Expiry",
        value: this.props.certificate.onExpire,
      },
    ];

    return (
      <DialogSection title="Basic Info">
        <ReadOnlyForm wide="true" content={content} />
      </DialogSection>
    );
  };

  getContent = () => {
    let sans = [];
    if (this.props.certificate.sans) {
      let sans = this.props.certificate.sans;
      sans.sort();
    }

    const content = [
      this.props.certificate.commonName && {
        label: "Common Name",
        value: this.props.certificate.commonName,
      },
      this.props.certificate.sans && {
        label: "Subject Alternative Names",
        value: FormatList(sans),
      },
      {
        label: "Expiry Date",
        value: FormatDateMonospace(this.props.certificate.expiryDate),
      },
    ];

    return (
      <DialogSection title="Certificate Contents">
        <ReadOnlyForm wide="true" content={content} />
      </DialogSection>
    );
  };

  getComments = () => {
    let comments = this.props.certificate.comments;

    if (comments === null) {
      return null;
    }

    let content = [
      {
        label: "Comments",
        value: comments,
      },
    ];

    return (
      <DialogSection title="">
        <ReadOnlyForm wide="true" content={content} />
      </DialogSection>
    );
  };

  render() {
    if (!this.props.certificate) {
      return null;
    }

    let title = this.props.certificate.commonName
      ? this.props.certificate.commonName
      : "Certificate Details";
    let body = this.getBody();

    return (
      <div>
        <DetailsDialog
          show={this.props.show}
          title={title}
          actions={this.getActionButtons()}
          body={body}
          onClose={() => this.props.onClose()}
          height="lg"
          width="lg"
        />
        {this.state.changeAccountDialogShow && (
          <ChangeAccountDialog
            session={this.props.session}
            window={this.props.window}
            show={this.state.changeAccountDialogShow}
            errorMessage={this.state.errorMessage}
            submit={this.state.submit}
            changeAccountUrl={this.props.certificate.links.changeAccount}
            onClose={this.hideChangeAccountDialog}
            onChangeAccount={this.onChangeAccount}
          />
        )}
      </div>
    );
  }
}

export default CertificateDetailsDialog;
