import React, { Component } from "react";
import { BlockingMainActionButton, DropdownButton } from "../../common/Buttons";
import ExtensionGroupDropdown from "./ExtensionGroupDropdown";
import { BorderColor } from "../../common/theme/Theme";
import styled from "styled-components";
import Colors from "../../common/theme/Colors";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";

const Header = styled.div`
  background: ${Colors.white};
  border: 1px solid ${BorderColor};
  padding: 20px;
  margin-bottom: 5px;
  display: flex;

  .textarea {
    resize: vertical;
    min-height: 34px;
    height: 34px;
    margin-right: 10px;
    overflow: hidden;
    border-color: #ccc;
    border-radius: 4px;
    width: 100%;
    padding: 5px;

    &:focus {
      border-color: #ccc;
      border-width: 1px;
      box-shadow:
        inset 0 1px 1px rgb(0 0 0 / 8%),
        0 0 8px rgb(102 175 233 / 60%);
    }

    &:focus-visible {
      outline: none;
    }
  }

  .query {
    height: 34px;
    min-width: 200px;
    text-align: center;
  }

  .dropdown-menu {
    top: 36px;
  }
`;

export class SearchBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      extensionGroup: props.extensionGroup,
      searchString: props.searchText,
      autoLayout: true,
      grid: false,
      layoutSelection: "Auto Layout",
    };
    this.query = this.query.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.setState({
        searchString: this.props.searchString,
        extensionGroup: this.props.extensionGroup,
      });
    }
  }

  query = () => {
    let extensionGroup = this.state.extensionGroup;
    let searchString = this.state.searchString;
    let grid = this.state.grid;
    let autoLayout = this.state.autoLayout;

    this.props.onChange(searchString, extensionGroup, autoLayout, grid);
  };

  handleKeypress = (e) => {
    const shiftDown = e.shiftKey;

    if (!shiftDown && (e.key === "Enter" || e.charCode === 13)) {
      e.preventDefault();
      this.query();
    }
  };

  getTooltip = (tooltip) => {
    return (
      <Tooltip id="tooltip" style={{ position: "fixed" }}>
        {tooltip}
      </Tooltip>
    );
  };

  render() {
    return (
      <Header>
        <OverlayTrigger
          overlay={this.getTooltip(
            'Search tool will check the brands/keywords entered against the TLDs selected in dropdown. It also supports specifying TLDs (for example ".com") and domain names (for example "mydomain.se").',
          )}
          placement="bottom"
          delay={500}
        >
          <textarea
            className="textarea"
            rows="1"
            cols="60"
            placeholder={"Enter brands/keywords, TLDs and/or domains"}
            value={this.state.searchString}
            onChange={(o) => this.setState({ searchString: o.target.value })}
            onKeyPress={(e) => this.handleKeypress(e)}
          />
        </OverlayTrigger>
        <ExtensionGroupDropdown
          {...this.props}
          onChange={(o) => this.setState({ extensionGroup: o })}
        />

        <DropdownButton
          title={this.state.layoutSelection}
          style={{
            verticalAlign: "top",
            marginRight: "10px",
          }}
        >
          <Dropdown.Item
            key={0}
            onClick={() =>
              this.setState({
                autoLayout: true,
                layoutSelection: "Auto Layout",
              })
            }
          >
            {"Auto Layout"}
          </Dropdown.Item>
          <Dropdown.Item
            key={1}
            onClick={() =>
              this.setState({
                autoLayout: false,
                grid: false,
                layoutSelection: "List Layout",
              })
            }
          >
            {"List Layout"}
          </Dropdown.Item>
          <Dropdown.Item
            key={2}
            onClick={() =>
              this.setState({
                autoLayout: false,
                grid: true,
                layoutSelection: "Grid Layout",
              })
            }
          >
            {"Grid Layout"}
          </Dropdown.Item>
        </DropdownButton>

        <BlockingMainActionButton
          icon={"search"}
          className={"query"}
          onClick={this.query}
          prefix={"fas"}
          text="Search"
          disabled={
            this.state.searchString == null ||
            this.state.searchString.length === 0
          }
          reload={this.props.queryTimeAt}
          type={"button"}
          taskRunning={this.props.queryRunning}
        />
      </Header>
    );
  }
}
