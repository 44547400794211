import React, { Component } from "react";
import { HashRouter as Router } from "react-router-dom";
import Window from "./Window";
import { Loader } from "./theme/Theme";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      errorMessage: null,
    };
  }

  componentDidMount() {
    this.props.session.backendGet(
      "api/rest",
      (response) => {
        this.props.session.setApplication(response);
        this.setState({ loaded: true });
      },
      (errorMessage) => {
        this.setState({ loaded: true, errorMessage: errorMessage });
      },
    );
  }

  render() {
    if (this.state.errorMessage == null) {
      return (
        <Loader loaded={this.state.loaded}>
          <Router>
            <Window session={this.props.session} />
          </Router>
        </Loader>
      );
    } else {
      return (
        <div style={{ padding: "20px" }}>
          <h3>Initialization Error</h3>
          <p>{this.state.errorMessage}</p>
        </div>
      );
    }
  }
}

export default App;
