import React, { Component } from "react";
import { rgba } from "polished";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import {
  ActionButton,
  DeleteButton,
  MainActionButton,
} from "../common/Buttons";
import Colors from "../common/theme/Colors";
import {
  ClearFix,
  Header,
  Organisation,
  PaddedContent,
} from "../common/theme/Theme";
import UserDialog from "./UserDialog";
import { MessageDialog } from "../common/Dialog";

const PageTitle = styled.h1`
  font-size: 24px;
  font-weight: 600;
  color: ${Colors.tertiary};
  margin: 0;

  .type {
    font-weight: 100;
    margin-left: 5px;
    color: ${rgba(Colors.tertiary, 0.75)};
  }
  span {
    font-weight: 300;
  }
`;

const Username = styled(Organisation)`
  && {
    margin: 10px 0;
  }
`;

const Meta = styled.div`
  display: inline-block;
  text-align: center;
  margin-left: 15px;
  width: 150px;
  border-radius: 4px;
`;

const MetaLabel = styled.span`
  display: block;
  font-size: 0.85em;
  margin-bottom: 3px;
  color: ${rgba(Colors.black, 0.5)};
`;

const MetaText = styled.span`
  display: block;
  text-transform: uppercase;
  font-size: 12px;
  padding: 5px 0px 0px 0px;
  border-radius: 4px;

  &.enabled {
    color: ${Colors.success};
    font-weight: bold;
  }

  &.disabled {
    font-weight: bold;
  }
`;

const UserButtons = styled.div`
  margin-top: 15px;
  display: block;
`;

class UserHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conf: null,
      showDialog: false,
      user: null,
      submit: null,
      errorMessage: null,
      apiKeyShow: false,
      apiKeyMessage: null,
    };
    this.editUser = this.editUser.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
    this.resetApiKey = this.resetApiKey.bind(this);
    this.resetTwoFactorAuth = this.resetTwoFactorAuth.bind(this);
    this.loginUser = this.loginUser.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({ user: this.props.user });
    this.reloadData();
  }

  reloadData() {
    if (this.props.user) {
      let url = this.props.user.links.self;
      this.props.session.backendGet(url, (response) => {
        this.setState({ user: response });
      });
    }
  }

  getButtons() {
    if (this.props.user) {
      let buttons = [];
      buttons.push(
        <MainActionButton
          key={this.props.user.userName + "_edit"}
          icon={"edit"}
          prefix={"fas"}
          onClick={this.editUser}
          text="Edit User"
        />,
      );

      if (!this.props.user.enabled) {
        buttons.push(this.getEnableButton());
      }

      if (this.props.user.links.resetPassword) {
        buttons.push(this.getResetPasswordButton());
      }

      if (this.props.user.links.resetApiKey) {
        buttons.push(this.getResetApiKeyButton());
      }

      if (this.props.user.twoFactorAuthEnabled) {
        buttons.push(this.getResetTwoFactorAuthButton());
      }

      if (this.props.user.enabled) {
        buttons.push(this.getDisableButton());
      }

      if (this.props.session.hasRole("SYS_ADMIN")) {
        buttons.push(
          <ActionButton
            key="login-as-user"
            icon={"user"}
            prefix={"fas"}
            onClick={this.loginUser}
            text="Login as User"
          />,
        );
      }

      return <div>{buttons}</div>;
    }
  }

  getResetPasswordButton() {
    const message =
      "Are you sure you want to reset password? " +
      "The reset link will be sent to the email address " +
      this.props.user.email +
      ".";
    return (
      <ActionButton
        key={this.props.user.userName + "_reset"}
        icon={"redo-alt"}
        prefix={"fas"}
        onClick={() =>
          this.props.window.showConfirmFunc(message, this.resetPassword)
        }
        disabled={!this.props.user.enabled}
        disabledTooltip="Reset password on disabled user is not allowed. Please enable the user first."
        text="Reset Password"
      />
    );
  }

  getResetApiKeyButton() {
    const message =
      "Are you sure you want to reset the API key? The previous key will immediately stop working (and any integrations using it).";
    return (
      <ActionButton
        key={this.props.user.userName + "_reset"}
        icon={"redo-alt"}
        prefix={"fas"}
        onClick={() =>
          this.props.window.showConfirmFunc(message, this.resetApiKey)
        }
        text="Reset API Key"
      />
    );
  }

  getResetTwoFactorAuthButton() {
    const message =
      "Are you sure you want to reset two factor authentication? The user will be instructed to set it up on the next login";
    return (
      <ActionButton
        key={this.props.user.userName + "_resetTwoFactorAuth"}
        icon={"redo-alt"}
        prefix={"fas"}
        onClick={() =>
          this.props.window.showConfirmFunc(message, this.resetTwoFactorAuth)
        }
        text="Reset Two Factor Authentication"
      />
    );
  }

  getEnableButton() {
    const message = "Are you sure you want to enable the user?";
    return (
      <MainActionButton
        key={this.props.user.userName + "_enable"}
        icon={"check"}
        prefix={"fas"}
        onClick={() =>
          this.props.window.showConfirmFunc(message, this.enableUser)
        }
        text="Enable User"
      />
    );
  }

  getDisableButton() {
    const message = "Are you sure you want to disable the user?";
    return (
      <DeleteButton
        key={this.props.user.userName + "_enable"}
        icon={"stop-circle"}
        prefix={"fas"}
        onClick={() =>
          this.props.window.showConfirmFunc(message, this.disableUser)
        }
        text="Disable User"
      />
    );
  }

  editUser() {
    let confUrl = this.props.user.links.configuration;
    this.props.session.backendGet(confUrl, (response) => {
      this.setState({ conf: response, showDialog: true });
    });
  }

  handleSubmit() {
    if (this.state.conf.object.id) {
      this.setState({ submit: true });
      const url = this.state.conf.object.links.self;
      const message = "User was updated successfully";
      this.props.session.backendPut(
        url,
        this.state.conf.object,
        () => {
          this.closeDialog();
          this.setState({ submit: false });
          this.reloadData();
          this.props.onChange();
          this.props.window.showSuccessFunc(message);
        },
        (msg) => {
          this.setState({ submit: false, errorMessage: msg });
        },
      );
    }
  }

  resetPassword() {
    let confUrl = this.props.user.links.resetPassword;
    const successFun = () => {
      this.props.window.showSuccessFunc(
        "The reset link was sent to " + this.props.user.email,
      );
      this.reloadData();
      this.props.onChange();
    };

    this.props.session.backendPost(
      confUrl,
      {},
      successFun,
      this.props.window.showErrorFunc,
    );
  }

  resetApiKey() {
    this.props.session.backendPost(
      this.props.user.links.resetApiKey,
      {},
      (o) => this.showApiKeyMessage(o.value),
      this.props.window.showErrorFunc,
    );
  }

  showApiKeyMessage = (apiKey) => {
    let message =
      "The new API key is displayed below. Use copy/paste to transfer it to your client.\n\nNote that this is the only time it is displayed, you won't be able to retrieve it later.\n\n" +
      apiKey;
    this.setState({ apiKeyShow: true, apiKeyMessage: message });
  };

  closeApiKeyMessage = () => {
    this.setState({ apiKeyShow: false, apiKeyMessage: null });
  };

  resetTwoFactorAuth() {
    let confUrl = this.props.user.links.resetTwoFactorAuth;
    const successFun = () => {
      this.props.window.showSuccessFunc(
        "Two factor authentication was reset successfully",
      );
      this.reloadData();
      this.props.onChange();
    };

    this.props.session.backendPost(
      confUrl,
      {},
      successFun,
      this.props.window.showErrorFunc,
    );
  }

  disableUser = () => {
    let url = this.props.user.links.disable;
    const successFun = () => {
      this.props.window.showSuccessFunc("User is disabled successfully");
      this.reloadData();
      this.props.onChange();
    };

    this.props.session.backendPost(
      url,
      {},
      successFun,
      this.props.window.showErrorFunc,
    );
  };

  enableUser = () => {
    let url = this.props.user.links.enable;
    const successFun = () => {
      this.props.window.showSuccessFunc("User is enabled successfully");
      this.reloadData();
      this.props.onChange();
    };

    this.props.session.backendPost(
      url,
      {},
      successFun,
      this.props.window.showErrorFunc,
    );
  };

  loginUser() {
    const callbackAction = () => {
      document.location.href = "/";
    };
    const callbackConfirm = () => {
      this.props.session.backendPost(
        this.props.user.links.login,
        {},
        callbackAction,
        this.props.window.showErrorFunc,
      );
    };
    const message =
      "You will be logged in as this user for the rest of this session (until you logout), do you want to proceed?";
    this.props.window.showConfirmFunc(message, callbackConfirm);
  }

  closeDialog() {
    this.setState({ showDialog: false, errorMessage: null });
  }

  getUserSettings = () => {
    let fields = [];

    const extIdp =
      this.props.user.externalIdp !== null &&
      this.props.user.externalIdp.length > 0;
    const extIdpClassName = extIdp ? "enabled" : "disabled";
    const extIdpText = extIdp ? this.props.user.externalIdp : "Local Account";
    fields.push(
      <Meta key="external-identify-provider">
        <MetaLabel>External Identity Provider</MetaLabel>
        <MetaText className={extIdpClassName}>{extIdpText}</MetaText>
      </Meta>,
    );

    const apiUser = this.props.user.links.resetApiKey;
    const twoFaEnabled = this.props.user.twoFactorAuthEnabled;
    const twoFaClassName = twoFaEnabled ? "enabled" : "disabled";
    let twoFaText;
    if (twoFaEnabled) {
      twoFaText = "Enabled";
    } else if (extIdp || apiUser) {
      twoFaText = "N/A";
    } else {
      twoFaText = "Disabled";
    }
    fields.push(
      <Meta key="two-factor-identification">
        <MetaLabel>Two Factor Authentication</MetaLabel>
        <MetaText className={twoFaClassName}>{twoFaText}</MetaText>
      </Meta>,
    );

    const statusClassName = this.props.user.enabled ? "enabled" : "disabled";
    const statusText = this.props.user.enabled ? "Enabled" : "Disabled";
    fields.push(
      <Meta key="status">
        <MetaLabel>Status</MetaLabel>
        <MetaText className={statusClassName}>{statusText}</MetaText>
      </Meta>,
    );

    return fields;
  };

  render() {
    const UserInfo = (props) => {
      if (props.user) {
        return (
          <div>
            <PageTitle>
              {(props.user.firstName ? props.user.firstName : "") +
                " " +
                (props.user.lastName ? props.user.lastName : "")}
            </PageTitle>
            <Username>{props.user.email}</Username>
          </div>
        );
      } else {
        return null;
      }
    };
    return (
      <div>
        <UserDialog
          session={this.props.session}
          showDialog={this.state.showDialog}
          closeDialog={this.closeDialog}
          conf={this.state.conf}
          window={this.props.window}
          handleSubmit={this.handleSubmit}
          submit={this.state.submit}
          errorMessage={this.state.errorMessage}
        />
        <MessageDialog
          title="Reset API Key"
          width="lg"
          onClose={this.closeApiKeyMessage}
          show={this.state.apiKeyShow}
          message={this.state.apiKeyMessage}
        />
        <Header>
          <Container fluid>
            <Row>
              <Col xs={6}>
                <Row>
                  <UserInfo user={this.props.user} />
                </Row>
              </Col>
              <Col xs={6}>
                <Row>
                  <PaddedContent
                    padding={"0px 20px 0 0"}
                    style={{ display: "flex" }}
                  >
                    {this.getUserSettings()}
                  </PaddedContent>
                </Row>
              </Col>
            </Row>
            <Row>
              <UserButtons>
                <ClearFix />
                {this.getButtons()}
              </UserButtons>
            </Row>
          </Container>
        </Header>
      </div>
    );
  }
}

export default UserHeader;
