import React from "react";

export const FormatInteger = (value) => {
  if (value == null || value === "") {
    return "";
  } else if (typeof value === "string") {
    value = parseInt(value);
  }

  let numberFormat = new Intl.NumberFormat("sv-SE", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return numberFormat.format(value);
};

export const FormatIntegerMonospace = (value) => {
  return <span className={"monospace"}>{FormatInteger(value)}</span>;
};

export const FormatFloat = (value, fractionDigits) => {
  if (value == null || value === "") {
    return "";
  } else if (typeof value === "string") {
    value = parseFloat(value);
  }
  let digits = 2;
  if (fractionDigits !== null || fractionDigits !== "undefined") {
    digits = fractionDigits;
  }

  let numberFormat = new Intl.NumberFormat("sv-SE", {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });
  return numberFormat.format(value);
};

export const FormatFloatMonospace = (value, currency) => {
  if (currency && (value == null || value === "")) {
    return <span className={"monospace"}></span>;
  } else if (currency) {
    return (
      <span className={"monospace"}>
        {FormatFloat(value, 2) + " " + currency}
      </span>
    );
  } else {
    return <span className={"monospace"}>{FormatFloat(value, 2)}</span>;
  }
};

export const FormatFloatWithFractionDigitsMonospace = (
  value,
  currency,
  fractionDigits,
) => {
  if (currency && (value == null || value === "")) {
    return <span className={"monospace"}></span>;
  } else if (currency) {
    return (
      <span className={"monospace"}>
        {FormatFloat(value, fractionDigits) + " " + currency}
      </span>
    );
  } else {
    return (
      <span className={"monospace"}>{FormatFloat(value, fractionDigits)}</span>
    );
  }
};

export const FormatInput = (value) => {
  if (value === null || typeof value == "undefined") {
    return "";
  }
  return value;
};

export const FormatDate = (value) => {
  if (value == null || value === "") {
    return "";
  } else if (typeof value === "string") {
    value = Date.parse(value);
  }

  let dateFormat = new Intl.DateTimeFormat("sv-SE");
  return dateFormat.format(value);
};

export const FormatDateMonospace = (value) => {
  return <span className={"monospace"}>{FormatDate(value)}</span>;
};

export const FormatDateTime = (value) => {
  if (value == null || value === "") {
    return "";
  } else if (typeof value === "string") {
    value = Date.parse(value);
  }

  let dateFormat = new Intl.DateTimeFormat("sv-SE", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  });
  return dateFormat.format(value);
};

export const FormatDateTimeMonospace = (value) => {
  return <span className={"monospace"}>{FormatDateTime(value)}</span>;
};

export const FormatList = (values) => {
  const elements = [];
  values.forEach((v) =>
    elements.push(
      <div key={v}>
        {v}
        <br></br>
      </div>,
    ),
  );
  return <div>{elements}</div>;
};

export const FormatTruncatedList = (values) => {
  let elements = [];
  if (values === null) {
    return <div>{elements}</div>;
  }
  values.forEach((v) =>
    elements.push(
      <div key={v}>
        {v}
        <br></br>
      </div>,
    ),
  );
  if (elements.length > 4) {
    elements = elements.slice(0, 4);
    elements.push(
      <div>
        ...<br></br>
      </div>,
    );
  }
  return <div>{elements}</div>;
};

export const FormatOrganisation = (value, organisationPrefix) => {
  if (organisationPrefix && value && value.startsWith(organisationPrefix)) {
    return value.substring(organisationPrefix.length);
  } else {
    return value;
  }
};

export const FromByteArrayToBase64 = (byteArray) => {
  return btoa(
    new Uint8Array(byteArray).reduce(
      (data, byte) => data + String.fromCharCode(byte),
      "",
    ),
  );
};

export const FormatDiscountDescriptionMonospace = (discountDescription) => {
  let value = "";

  if (discountDescription) {
    value = "(-" + discountDescription + ")";
  }

  if (value === "") {
    return <span className={"monospace"}></span>;
  } else {
    return <span className={"monospace"}>{value}</span>;
  }
};

export const IsSet = (value) => {
  if (value === null || typeof value === "undefined") {
    return false;
  }
  return true;
};
