import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { InformationField } from "../../common/Information";
import Selector from "../../common/Selector";

export default class ContactField extends Component {
  render() {
    let type = this.props.type;
    let label = null;

    if (this.props.conf.parameters[type + "Contacts"] == null) {
      return null;
    }

    switch (type) {
      case "registrant":
        label = "Registrant Contact";
        break;
      case "admin":
        label = "Administrative Contact";
        break;
      case "billing":
        label = "Billing Contact";
        break;
      case "tech":
        label = "Technical Contact";
        break;
      default:
    }

    return (
      <Row className="section-field">
        <Col xs={12}>
          <InformationField
            tooltip={this.props.conf.parameters[type + "ContactHint"]}
          >
            <Selector
              label={label}
              placeholder="Select contact"
              options={this.props.conf.parameters[type + "Contacts"]}
              defaultValue={
                this.props.conf.object.parameters[type + "ContactId"]
              }
              onChange={(v) =>
                this.props.onParameterChange(type + "ContactId", v.value)
              }
            />
          </InformationField>
        </Col>
      </Row>
    );
  }
}
