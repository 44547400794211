import React, { Component } from "react";
import { InputWithLabel } from "../common/theme/Theme";
import { FormatInput } from "../common/Utils";
import EditDialog from "../common/Dialog";

export class MessageDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
    };
  }

  onSend = () => {
    this.props.onSend(this.state.message);
    this.setState({ message: null });
  };

  render() {
    let element = [
      <InputWithLabel
        key="input"
        label={"Message"}
        type="textarea"
        name="message"
        rows="7"
        placeholder="Enter message here"
        value={FormatInput(this.state.message)}
        onChange={(e) => this.setState({ message: e.target.value })}
        autoFocus={true}
      />,
    ];

    return (
      <EditDialog
        session={this.props.session}
        content={element}
        show={this.props.show}
        title={"Send Message"}
        onCancel={this.props.onCancel}
        onConfirm={this.onSend}
        onConfirmLabel={"Send"}
        submit={this.props.submit}
        errorMessage={this.props.errorMessage}
      />
    );
  }
}
