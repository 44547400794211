import React from "react";
import { Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import styled from "styled-components";
import Colors from "../common/theme/Colors";

const DivStyle = styled.div`
  margin: 15px 0px 25px;
  border: 1px solid ${Colors.border};
  background-color: ${Colors.white};

  .title {
    padding: 25px 35px;
    font-size: 18px;
    font-weight: 900;
    text-transform: uppercase;
    border-bottom: 1px solid ${Colors.border};
  }

  table {
    width: 100%;
    cursor: default;
  }

  tr:hover {
    font-weight: 600;
  }

  tr:not(:first-child) {
    border-top: 1px solid ${Colors.border};
  }

  tr.row-recent {
    background-color: ${Colors.recentlyUpdated};
  }

  td {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 35px;
    padding-right: 35px;
  }

  td a {
    text-decoration: none;
    cursor: pointer;
  }
`;

const HelpCategory = (categories, onClickFile) => {
  const getTooltip = (fileType) => {
    let tooltip;
    if (fileType === "URL") {
      tooltip = "Open URL in a separate tab";
    } else {
      tooltip = "Download Document as PDF";
    }

    return (
      <Tooltip id={tooltip} style={{ position: "fixed" }}>
        {tooltip}
      </Tooltip>
    );
  };

  const getOnClickEvent = (document, onClickFile) => {
    if (document.fileType === "URL") {
      return () => window.open(document.fileUrl, "_blank");
    } else {
      return () => onClickFile(document.links.file, document.fileName);
    }
  };

  const getDocumentRow = (document, onClickFile) => {
    return (
      <tr
        className={document.recentlyUpdated ? "row-recent" : ""}
        key={document.id}
      >
        <td>
          <div>
            <div>
              <OverlayTrigger
                overlay={getTooltip(document.fileType)}
                placement="bottom"
                delay={500}
              >
                {
                  <a onClick={getOnClickEvent(document, onClickFile)}>
                    {document.documentName}
                  </a>
                }
              </OverlayTrigger>
            </div>
          </div>
        </td>
      </tr>
    );
  };

  const getCategoryTable = (category, onClickFile) => {
    return (
      <table>
        <tbody>
          {category.documents &&
            category.documents.map((document) => {
              return getDocumentRow(document, onClickFile);
            })}
        </tbody>
      </table>
    );
  };

  return (
    <Col xs={6}>
      {categories &&
        categories.map((category) => {
          return (
            <DivStyle key={category.label}>
              <Container fluid={true}>
                <Row>
                  <div key={category.label} className="title">
                    {category.label}
                  </div>
                  {getCategoryTable(category, onClickFile)}
                </Row>
              </Container>
            </DivStyle>
          );
        })}
    </Col>
  );
};
export default HelpCategory;
