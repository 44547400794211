import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { InputWithLabel } from "../../common/theme/Theme";
import { FormatInput } from "../../common/Utils";

export default class ManualContactStep extends Component {
  render() {
    return (
      <Container fluid={true}>
        <Row className="section-title">
          <Col>Contact Information</Col>
        </Row>
        <Row className="section-description">
          <Col xs={12}>
            Please provide us with your contact information. Abion will contact
            you and collect the remaining information before proceeding with the
            service.
          </Col>
        </Row>
        <Row className="section-field">
          <Col md={6}>
            <InputWithLabel
              label="First Name"
              type="text"
              name="firstName"
              placeholder="First Name"
              value={FormatInput(this.props.conf.object.parameters.firstName)}
              onChange={(e) =>
                this.props.onParameterChange("firstName", e.target.value.trim())
              }
            />
          </Col>
          <Col md={6}>
            <InputWithLabel
              label="Last Name"
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={FormatInput(this.props.conf.object.parameters.lastName)}
              onChange={(e) =>
                this.props.onParameterChange("lastName", e.target.value.trim())
              }
            />
          </Col>
        </Row>
        <Row className="section-field">
          <Col md={12}>
            <InputWithLabel
              label="Job Title"
              type="text"
              name="jobTitle"
              placeholder="Job Title"
              value={FormatInput(this.props.conf.object.parameters.jobTitle)}
              onChange={(e) =>
                this.props.onParameterChange("jobTitle", e.target.value.trim())
              }
            />
          </Col>
        </Row>
        <Row className="section-field">
          <Col md={12}>
            <InputWithLabel
              label="Phone"
              type="text"
              name="phone"
              placeholder="Phone"
              value={FormatInput(this.props.conf.object.parameters.phone)}
              onChange={(e) =>
                this.props.onParameterChange("phone", e.target.value.trim())
              }
            />
          </Col>
        </Row>
        <Row className="section-field">
          <Col md={12}>
            <InputWithLabel
              label="E-mail"
              type="email"
              name="Email"
              placeholder="E-mail"
              value={FormatInput(this.props.conf.object.parameters.email)}
              onChange={(e) =>
                this.props.onParameterChange("email", e.target.value.trim())
              }
            />
          </Col>
        </Row>
      </Container>
    );
  }
}
