import { rgba } from "polished";
import { Tabs } from "react-bootstrap";
import { RoutedTabs } from "react-router-tabs";
import styled from "styled-components";
import Colors from "./theme/Colors";
import { BorderColor } from "./theme/Theme";

const PageTabs = styled(Tabs)`
  ul {
    background: ${Colors.white};
    border-bottom: 1px solid ${BorderColor};

    li {
      border-bottom: 2px solid transparent;

      a,
      a:hover,
      a:focus {
        border: none;
        background: transparent;
      }

      a {
        padding: 16px 25px 12px 25px;
        text-transform: uppercase;
        color: ${rgba(Colors.black, 0.4)};
        font-weight: 500;

        span {
          svg {
            margin-right: 5px;
          }
        }

        &:hover {
          color: ${Colors.secondary};
        }
      }

      &.active {
        border-bottom: 2px solid ${Colors.primary};

        a,
        a:hover,
        a:focus {
          border: none;
          color: ${Colors.primary};
          font-weight: 500;
        }
      }
    }
  }
`;

const PageTabsNew = styled(RoutedTabs)`
  && {
    background: ${Colors.white};
    border-left: 1px solid ${BorderColor};
    border-right: 1px solid ${BorderColor};
    border-top: 1px solid ${BorderColor};
    border-bottom: none;

    a {
      position: relative;
      display: inline-block;
      padding: 16px 25px 12px 25px;
      text-transform: uppercase;
      color: ${rgba(Colors.black, 0.4)};
      font-weight: 500;
      text-decoration: none;
      border-bottom: 2px solid transparent;

      svg {
        margin-right: 5px;
      }

      &.active {
        color: ${Colors.primary};
        border-bottom: 2px solid ${Colors.primary};
        font-weight: 500;
      }

      &:hover,
      &:focus {
        text-decoration: none;
      }

      &:hover {
        color: ${Colors.secondary};
      }

      &.active:hover {
        color: ${Colors.primary};
      }
    }
  }
`;

export { PageTabsNew };
export default PageTabs;
