import React, { Component } from "react";
import { FromByteArrayToBase64 } from "./Utils";

export class TrademarkLogo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      body: null,
    };
  }

  componentDidMount() {
    this.loadImage();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.url !== this.props.url) {
      this.loadImage();
    }
  }

  loadImage = () => {
    this.props.session.backendGetFile(this.props.url, (response) => {
      this.setState({ body: response });
    });
  };

  render() {
    if (!this.state.body || this.state.body.length === 0) {
      return null;
    }

    return (
      <img
        src={"data:image/png;base64," + FromByteArrayToBase64(this.state.body)}
        alt={""}
        style={{ maxWidth: "100%", maxHeight: "80px" }}
      />
    );
  }
}
