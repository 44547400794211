import React, { Component } from "react";
import { Col } from "react-bootstrap";
import DashboardSummary from "./DashboardSummary";
import Colors from "../common/theme/Colors";

export default class TechnicalSummaryItem extends Component {
  getItems = () => {
    let portfolio = this.props.session.hasRole("PORTFOLIO");
    let internal = !this.props.data.countries;

    let tooltipPortfolio = internal ? "managed by Abion" : "in your portfolio";
    let tooltipClick = portfolio
      ? " Click for additional details."
      : internal
        ? ""
        : " Request role Portfolio to see additional details.";

    return [
      [
        "Certificates",
        this.props.data.technicalRights,
        portfolio ? "/portfolio/certificates" : null,
        "The number of SSL certificates " +
          tooltipPortfolio +
          "." +
          tooltipClick,
      ],
      [
        "Services",
        this.props.data.technicalSubscriptions,
        portfolio ? "/portfolio/technicalServices" : null,
        "The number of web security services " +
          tooltipPortfolio +
          ". This includes ECP, mail, web hosting, servers and many more." +
          tooltipClick,
      ],
    ];
  };

  render() {
    return (
      <Col>
        <DashboardSummary
          image={require("../common/img/dashboard/technical.svg").default}
          title="Web Security"
          color={Colors.categoryTechnical}
          items={this.getItems()}
        />
      </Col>
    );
  }
}
