import React, { Component } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router";
import { toast, ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import Dashboard from "../dashboard/Dashboard";
import DnsAdmin from "../dnsadmin/DnsAdmin";
import Portfolio from "../portfolio/Portfolio";
import Project from "../project/Project";
import Monitoring from "../monitoring/Monitoring";
import UserAdmin from "../useradmin/UserAdmin";
import { ConfirmDialog } from "./Dialog";
import PrimaryMenu from "./Menu";
import Icon from "./theme/Icons";
import { PaddedContent } from "./theme/Theme";
import TopBar from "./TopBar";
import Wrapper, { Main } from "./Wrapper";
import CertAdmin from "../certadmin/CertAdmin";
import DomainAdmin from "../domainadmin/DomainAdmin";
import Order from "../order/Order";
import Invoice from "../invoice/Invoice";
import SessionTimeoutControl from "./SessionTimeoutControl";
import HelpPage from "../help/HelpPage";
import Colors from "./theme/Colors";
import { HelpMessageDialog } from "../help/HelpMessageDialog";

const MessageWrapper = styled.div`
  display: block;
`;

const MessageText = styled.div`
  display: table-cell;
  vertical-align: middle;
  font-weight: 400;
  line-height: 1.75rem;
`;
const MessageIcon = styled.div`
  display: table-cell;
  vertical-align: middle;
  padding-right: 15px;
  font-weight: 500;
  height: 100%;
`;

const Toast = styled(ToastContainer)`
  .Toastify__toast-icon {
    display: none;
  }

  .toast-error {
    background-color: ${Colors.popupErrorBackground};
    color: ${Colors.popupError};
    border-radius: 4px;
    padding: 5px;
  }

  .toast-info {
    background: ${Colors.popupInfoBackground};
    color: ${Colors.popupInfo};
    border-radius: 4px;
    padding: 15px;
    width: 700px;
  }

  .toast-success {
    background: ${Colors.popupSuccessBackground};
    color: ${Colors.popupSuccess};
    border-radius: 4px;
    padding: 5px;
  }
`;

const SuccessMessage = (props) => (
  <MessageWrapper>
    <PaddedContent padding={"5px 10px"}>
      <MessageIcon>
        <Icon name={"check-circle"} iconsize={24} />
      </MessageIcon>
      <MessageText>{props.message}</MessageText>
    </PaddedContent>
  </MessageWrapper>
);

const ErrorMessage = (props) => (
  <MessageWrapper>
    <PaddedContent padding={"5px 10px"}>
      <MessageIcon>
        <Icon name={"exclamation-triangle"} iconsize={24} />
      </MessageIcon>
      <MessageText>{props.message}</MessageText>
    </PaddedContent>
  </MessageWrapper>
);

class Window extends Component {
  constructor(props) {
    super(props);
    this.setBreadcrumbViews = this.setBreadcrumbViews.bind(this);
    this.showSuccessMessage = this.showSuccessMessage.bind(this);
    this.showErrorMessage = this.showErrorMessage.bind(this);
    this.showConfirmation = this.showConfirmation.bind(this);
    this.closeConfirmation = this.closeConfirmation.bind(this);
    this.state = {
      breadcrumbViews: [],
      // pageTipStatus-- 0: no page tip, 1: closed page tip: 2: open page tip
      pageTipStatus: 0,
      pageTipMessage: null,
    };
  }

  getWindow() {
    return {
      setBreadcrumbViews: this.setBreadcrumbViews,
      setPageTip: this.setPageTip,
      showPageTip: this.showPageTip,
      hidePageTip: this.hidePageTip,
      showSuccessFunc: this.showSuccessMessage,
      showErrorFunc: this.showErrorMessage,
      showConfirmFunc: this.showConfirmation,
      dismissPageTip: this.dismissPageTip,
    };
  }

  componentDidMount() {
    document.body.style.backgroundColor = Colors.bodyBackground;
  }

  setBreadcrumbViews(views) {
    this.setState({ breadcrumbViews: views });
  }

  setPageTip = (message) => {
    if (this.state.pageTipStatus === 2) {
      toast.dismiss();
    }

    this.setState({
      pageTipStatus: message ? 1 : 0,
      pageTipMessage: message,
    });
  };

  showPageTip = () => {
    toast.info(this.state.pageTipMessage, {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: 15000,
      transition: Zoom,
      hideProgressBar: true,
      closeButton: false,
      onClose: this.hidePageTip,
      className: "toast-info",
    });

    this.setState({
      pageTipStatus: 2,
    });
  };

  dismissPageTip = () => {
    toast.dismiss();
  };

  hidePageTip = () => {
    this.setPageTip(this.state.pageTipMessage);
  };

  showSuccessMessage(message) {
    toast.success(<SuccessMessage message={message} />, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
      hideProgressBar: true,
      closeButton: false,
      className: "toast-success",
    });
  }

  showErrorMessage(message) {
    toast.error(<ErrorMessage message={message} />, {
      position: toast.POSITION.TOP_RIGHT,
      hideProgressBar: true,
      closeButton: false,
      className: "toast-error",
    });
  }

  showConfirmation(message, onConfirm) {
    const func = () => {
      this.closeConfirmation();
      onConfirm();
    };

    this.refs.confirm.handleUpdate({
      confirmConf: {
        showConfirm: true,
        message: message,
        onConfirm: func,
      },
    });
  }

  closeConfirmation() {
    this.refs.confirm.handleUpdate({ confirmConf: { showConfirm: false } });
  }

  getPaths = () => {
    const modules = this.props.session.getMenu();
    let paths = [];

    for (const element of modules) {
      for (let j = 0; j < element.menuItems.length; j++) {
        paths.push(element.menuItems[j].path);
      }
      for (let j = 0; j < element.toolbarItems.length; j++) {
        paths.push(element.toolbarItems[j].path);
      }
    }

    return paths;
  };

  getHomePath = () => {
    return this.getPaths()[0];
  };

  checkPath = (path, component) => {
    let paths = this.getPaths();

    for (const element of paths) {
      if (element.startsWith(path)) {
        return component;
      }
    }
    return <Redirect to={this.getHomePath()} />;
  };

  render() {
    return (
      <div className="App">
        <Wrapper bsPrefix="container" fluid={true}>
          <TopBar
            session={this.props.session}
            window={this.getWindow()}
            pageTipStatus={this.state.pageTipStatus}
            breadcrumbViews={this.state.breadcrumbViews}
          />
          <PrimaryMenu
            session={this.props.session}
            window={this.getWindow()}
            pageTipStatus={this.state.pageTipStatus}
            className="d-none d-md-block"
          />
          <Main style={{ overflow: "initial" }}>
            <Switch>
              <Route
                exact
                path="/"
                render={() => <Redirect to={this.getHomePath()} />}
              />
              <Route
                path="/dashboard"
                render={() =>
                  this.checkPath(
                    "/dashboard",
                    <Dashboard
                      session={this.props.session}
                      window={this.getWindow()}
                    />,
                  )
                }
              />
              <Route
                path="/portfolio"
                render={() =>
                  this.checkPath(
                    "/portfolio",
                    <Portfolio
                      session={this.props.session}
                      window={this.getWindow()}
                    />,
                  )
                }
              />
              <Route
                path="/project"
                render={() =>
                  this.checkPath(
                    "/project",
                    <Project
                      session={this.props.session}
                      window={this.getWindow()}
                    />,
                  )
                }
              />
              <Route
                path="/order"
                render={() =>
                  this.checkPath(
                    "/order",
                    <Order
                      session={this.props.session}
                      window={this.getWindow()}
                    />,
                  )
                }
              />
              <Route
                path="/invoice"
                render={() =>
                  this.checkPath(
                    "/invoice",
                    <Invoice
                      session={this.props.session}
                      window={this.getWindow()}
                    />,
                  )
                }
              />
              <Route
                path="/monitoring"
                render={() =>
                  this.checkPath(
                    "/monitoring",
                    <Monitoring
                      session={this.props.session}
                      window={this.getWindow()}
                    />,
                  )
                }
              />
              <Route
                path="/domainadmin"
                render={() =>
                  this.checkPath(
                    "/domainadmin",
                    <DomainAdmin
                      session={this.props.session}
                      window={this.getWindow()}
                    />,
                  )
                }
              />
              <Route
                path="/dnsadmin"
                render={() =>
                  this.checkPath(
                    "/dnsadmin",
                    <DnsAdmin
                      session={this.props.session}
                      window={this.getWindow()}
                    />,
                  )
                }
              />
              <Route
                path="/certadmin"
                render={() =>
                  this.checkPath(
                    "/certadmin",
                    <CertAdmin
                      session={this.props.session}
                      window={this.getWindow()}
                    />,
                  )
                }
              />
              <Route
                path="/useradmin"
                render={() =>
                  this.checkPath(
                    "/useradmin",
                    <UserAdmin
                      session={this.props.session}
                      window={this.getWindow()}
                    />,
                  )
                }
              />
              <Route
                path="/help"
                render={() =>
                  this.checkPath(
                    "/help",
                    <HelpPage
                      session={this.props.session}
                      window={this.getWindow()}
                    />,
                  )
                }
              />
              <Redirect from="*" to="/" />
            </Switch>
            <ConfirmDialog ref={"confirm"} onCancel={this.closeConfirmation} />
            <HelpMessageDialog
              session={this.props.session}
              window={this.getWindow()}
            />
            <SessionTimeoutControl
              session={this.props.session}
              window={this.getWindow()}
            />
            <Toast />
          </Main>
        </Wrapper>
      </div>
    );
  }
}

export default withRouter(Window);
