import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { InputWithLabel } from "../../common/theme/Theme";

export default class CreditsParameterStep extends Component {
  render() {
    return (
      <Container fluid={true}>
        <Row className="section-title">
          <Col md={12}>Parameters</Col>
        </Row>
        <Row className="section-description">
          <Col xs={12}>Enter the amount of credits you wish to purchase.</Col>
        </Row>
        <Row className="section-field">
          <Col md={12}>
            <InputWithLabel
              label="Credits"
              type="number"
              placeholder="Credits"
              value={this.props.conf.object.parameters.credits}
              onChange={(e) =>
                this.props.onParameterChange("credits", e.target.value)
              }
            />
          </Col>
        </Row>
      </Container>
    );
  }
}
