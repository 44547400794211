import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Flag from "react-world-flags";
import styled from "styled-components";
import Colors from "../../common/theme/Colors";
import { rgba } from "polished";
import { FormatInput } from "../../common/Utils";

const Columns = styled.div`
  columns: 2 auto;

  .border {
    border-style: solid !important;
    border-width: 3px !important;
    border-color: ${Colors.secondary} !important;
  }

  .radio + .radio {
    margin-top: -5px;
  }

  .radio {
    margin-bottom: 10px;
    margin-top: 10px;
  }
`;

const Region = styled.div`
  border-radius: 10px 10px 10px 10px;
  background-color: ${rgba(Colors.categoryLegal, 0.15)};
  height: 60px;
  margin-bottom: 10px;
  display: flex;
  vertical-align: middle;
  align-items: center;
  cursor: pointer;

  .row {
    margin-right: 5px;
    margin-left: 5px;
  }

  .flagdiv {
    width: 90px;
  }

  .auto {
    width: auto;
  }

  .checkboxdiv {
    width: 10px;
  }
`;

const toBeDiscussed = "ToBeDiscussed";
const select = "select";

export default class TrademarkRegisterRegionStep extends Component {
  constructor(props) {
    super(props);
    this.isSelected = this.isSelected.bind(this);
    this.toggleOne = this.toggleOne.bind(this);
  }

  getBody = () => {
    if (
      this.props.conf.object.parameters.regionMode &&
      this.props.conf.object.parameters.regionMode === select
    ) {
      return this.getRegionSelector();
    }
    return null;
  };

  getRegionSelector() {
    let rows = [];

    rows.push(
      <Row key="region-header" className="section-title">
        <Col md={12}>Choices</Col>
      </Row>,
      <Row key="region-description" className="section-description">
        <Col xs={12}>Select one or more regions below.</Col>
      </Row>,
    );
    if (this.props.conf.parameters.regionlist) {
      let regions = this.props.conf.parameters.regionlist.map((region) => (
        <Region
          className={this.getStyle(this.isSelected(region.regionCode))}
          key={region.regionCode}
          id={region.regionCode}
          onClick={() => this.toggleOne(region.regionCode)}
        >
          <Row>
            <Col md={3} className="flagdiv">
              <Flag code={region.regionCode} height="auto" />
            </Col>
            <Col md={10} className="auto">
              {region.name}
            </Col>
          </Row>
        </Region>
      ));

      if (regions) {
        rows.push(<Columns key="regions">{regions}</Columns>);
      }
    }
    return rows;
  }

  onRegionTypeChange = (e) => {
    this.props.onParameterChange("regionMode", e.target.value);
    this.props.onParameterChange("regions", this.encode([]));
  };

  isSelected(regionCode) {
    let selectedRegions = this.decode(
      this.props.conf.object.parameters.regions,
    );
    return selectedRegions.includes(regionCode);
  }

  toggleOne(regionCodeToToggle) {
    let selectedRegions = this.decode(
      this.props.conf.object.parameters.regions,
    );

    if (selectedRegions.includes(regionCodeToToggle)) {
      selectedRegions = selectedRegions.filter((o) => o !== regionCodeToToggle);
    } else {
      selectedRegions.push(regionCodeToToggle);
    }
    this.props.onParameterChange("regions", this.encode(selectedRegions));
  }

  componentDidMount() {
    if (!this.props.conf.object.parameters.regions) {
      this.props.onParameterChange("regions", this.encode([]));
    }
  }

  render() {
    return (
      <Container fluid={true}>
        <Row className="section-title">
          <Col xs={12}>Countries or Regions</Col>
        </Row>
        <Row className="section-description">
          <Col xs={12}>
            Select the countries and/or regions where you would like have
            trademark protection or make the selection together with Port
            Group’s legal team (recommended). If a country or region is not in
            the list – do not worry - we will still be able to assist you with
            the application.
          </Col>
        </Row>
        <Row className="section-field">
          <Col md={12}>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  name="mode"
                  value={FormatInput(toBeDiscussed)}
                  onChange={this.onRegionTypeChange}
                  checked={
                    this.props.conf.object.parameters.regionMode ===
                    toBeDiscussed
                  }
                />
                Select regions together with Abion (recommended)
              </label>
            </div>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  name="mode"
                  value={FormatInput(select)}
                  onChange={this.onRegionTypeChange}
                  checked={
                    this.props.conf.object.parameters.regionMode === select
                  }
                />
                Select regions manually
              </label>
            </div>
          </Col>
        </Row>
        {this.getBody()}
      </Container>
    );
  }

  decode(regionsAsString) {
    if (regionsAsString === "") {
      return [];
    }
    return regionsAsString.split(",");
  }

  encode(regions) {
    return regions.toString();
  }

  getStyle(selected) {
    if (selected) {
      return "border";
    }
    return "";
  }
}
