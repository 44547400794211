import React, { Component } from "react";
import styled from "styled-components";
import { animateScroll } from "react-scroll";
import Colors from "../../common/theme/Colors";
import { DetailsDialog } from "../../common/Dialog";
import { MainActionButton } from "../../common/Buttons";

const StyledDiv = styled.div`
  .rowSuccess,
  .rowError,
  .doneSuccess,
  .doneError {
    font-family: monospace;
    white-space: pre;
  }

  .rowSuccess {
  }

  .rowError {
    color: ${Colors.error};
  }

  .doneSuccess {
    padding-bottom: 20px;
    color: ${Colors.success};
  }

  .doneError {
    padding-bottom: 20px;
    color: ${Colors.warning};
  }

  .outputHeader {
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .outputTable td {
    padding: 0px 8px 4px 0px;
    font-family: monospace;
  }
`;

export default class BulkDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      done: false,
      output: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.executing && !prevProps.executing) {
      this.execute();
    }
  }

  execute = () => {
    let xhr = new XMLHttpRequest();
    let outerThis = this;

    this.setState({
      done: false,
      output: "",
    });

    xhr.onreadystatechange = function () {
      if (xhr.readyState > 2) {
        if (xhr.seenBytes < xhr.responseText.length) {
          let newOutput = xhr.response.substr(xhr.seenBytes);
          outerThis.setState({ output: outerThis.state.output + newOutput });
          xhr.seenBytes = xhr.responseText.length;

          animateScroll.scrollToBottom({
            containerId: "modal-body",
            duration: 0,
          });
        }
      }

      if (xhr.readyState === 4) {
        outerThis.setState({ done: true });

        setTimeout(
          () =>
            animateScroll.scrollToBottom({
              containerId: "modal-body",
              duration: 0,
            }),
          1000,
        );
      }
    };

    xhr.addEventListener("error", function (e) {
      alert("Unexpected error: " + e);
    });

    xhr.seenBytes = 0;
    xhr.open("POST", this.props.commandUrl);
    xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
    this.props.session.backendXhrSend(
      xhr,
      JSON.stringify(this.props.commandBody),
    );
  };

  download = () => {
    const regex = /(<([^>]+)>)/gi;
    const result = this.state.output.replace(regex, "");

    const blob = new Blob([result], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = "result.txt";
    link.href = url;
    link.click();
  };

  render() {
    const content = [];
    content.push(
      <StyledDiv
        key="body"
        dangerouslySetInnerHTML={{ __html: this.state.output }}
      />,
    );

    let buttons = [];

    buttons.push(
      <MainActionButton
        key="download result"
        text={"Download Result"}
        onClick={this.download}
        disabled={!this.state.done}
      />,
    );

    return (
      <DetailsDialog
        show={this.props.executing}
        title={this.props.title}
        actions={buttons}
        body={content}
        onClose={() => this.props.onClose()}
        onCloseDisabled={!this.state.done}
        height="lg"
        width="xl"
      />
    );
  }
}
