import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import DocumentList from "./DocumentList";

class Invoice extends Component {
  render() {
    return (
      <Switch>
        <Route
          exact={true}
          path={"/invoice"}
          render={() => <Redirect replace to={"/invoice/invoices"} />}
        />
        <Route
          exact={true}
          path="/invoice/invoices"
          render={(props) => (
            <DocumentList
              session={this.props.session}
              window={this.props.window}
              {...props}
            />
          )}
        />
      </Switch>
    );
  }
}

export default Invoice;
