import { Component } from "react";
import { Tab, TabList, TabPanel, Tabs as ReactTabs } from "react-tabs";
import Colors from "./theme/Colors";
import styled from "styled-components";
import "react-tabs/style/react-tabs.css";

const StyledTabs = styled(ReactTabs)`
  .react-tabs__tab:focus:after {
    background: none;
  }
  .react-tabs__tab--selected {
    border: 0px transparent;
    color: ${Colors.primary};
    border-bottom: 2px solid ${Colors.primary};
    font-weight: 500;
  }
`;

class Tabs extends Component {
  render() {
    return (
      <StyledTabs>
        <TabList style={{ textTransform: "uppercase" }}>
          {this.props.tabs.map((m) => (
            <Tab key={"tab_" + m.description}>{m.description}</Tab>
          ))}
        </TabList>
        {this.props.tabs.map((m) => (
          <TabPanel key={"tabpanel_" + m.description}>{m.panel}</TabPanel>
        ))}
      </StyledTabs>
    );
  }
}

export { Tabs };
