import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { DropdownButton } from "../../common/Buttons";
import styled from "styled-components";

const FilterStyle = styled.span`
  .dropdown {
    vertical-align: top;
    margin-right: 10px;
  }
`;

export default class ExtensionGroupDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      rows: [],
      title: "",
    };
  }

  componentDidMount() {
    this.reload();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.extensionGroup) !==
      JSON.stringify(this.props.extensionGroup)
    ) {
      this.setExtensionGroup(this.props.extensionGroup);
    }
  }

  setExtensionGroup = (selectedExtensionGroup) => {
    let toSelect = this.state.rows.filter(
      (r) => r.value === selectedExtensionGroup,
    );
    if (toSelect && toSelect[0]) {
      let extensionGroup = toSelect[0].value;
      let extensionGroupDescription = toSelect[0].label;

      this.setState({ title: extensionGroupDescription });
      this.props.onChange(extensionGroup);
    }
  };

  reload = (newState) => {
    this.setState({ loading: true });
    let futureState = Object.assign({}, this.state, newState);
    let url = this.props.session.getModules().domainadmin.links.extensiongroups;

    this.props.session.backendGet(url, (response) => {
      this.setState(
        Object.assign(futureState, {
          loading: false,
          rows: response.objects,
        }),
      );

      if (response.objects.length > 0) {
        let extensionGroup = response.objects[0].value;
        let extensionGroupDescription = response.objects[0].label;

        if (this.props.extensionGroup) {
          this.setExtensionGroup(this.props.extensionGroup);
        } else {
          this.setState({ title: extensionGroupDescription });
          this.props.onChange(extensionGroup);
        }
      }
    });
  };

  render() {
    let items = [];

    for (let i = 0; i < this.state.rows.length; i++) {
      let extensionGroup = this.state.rows[i].value;
      let extensionGroupDescription = this.state.rows[i].label;

      items.push(
        <Dropdown.Item
          key={i}
          onClick={() => {
            this.setState({ title: extensionGroupDescription });
            this.props.onChange(extensionGroup);
          }}
        >
          {extensionGroupDescription}
        </Dropdown.Item>,
      );
    }

    return (
      <FilterStyle>
        <DropdownButton title={this.state.title}>{items}</DropdownButton>
      </FilterStyle>
    );
  }
}
