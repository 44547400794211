import { rgba } from "polished";
import React, { Component } from "react";
import styled from "styled-components";
import Colors from "../../common/theme/Colors";

const ZoneFile = styled.textarea`
  && {
    font-family: monospace, monospace;
    resize: none;
    overflow: auto;
    background-color: ${Colors.tertiary};
    border: none;
    color: ${Colors.primary};
    width: 100%;
    padding: 20px;
    margin: 0;
    height: auto;
    display: inherit;

    &.readonly {
      color: ${rgba(Colors.white, 0.35)};
    }
  }
`;

class ZoneFileTextArea extends Component {
  render() {
    let rows = 20;

    if (this.props.disabled && this.props.value !== null) {
      let lines = this.props.value.split(/\r\n|\r|\n/);
      let size = lines.length + 5;
      if (size > rows) {
        rows = size;
      }
    }

    return <ZoneFile rows={rows} {...this.props} />;
  }
}

export default ZoneFileTextArea;
