import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { InformationField } from "../../common/Information";
import { InputWithLabel } from "../../common/theme/Theme";
import Selector from "../../common/Selector";
import { FormatInput } from "../../common/Utils";

export default class CustomField extends Component {
  render() {
    let field = this.props.field;
    let value = this.props.value;
    let onChange = this.props.onChange;
    let control = null;
    let type = "text";

    if (this.props.type) {
      type = this.props.type;
    }

    if (field.options == null) {
      control = (
        <InputWithLabel
          type={type}
          label={field.description}
          value={FormatInput(value)}
          onChange={(e) => onChange(field.id, e.target.value)}
        />
      );
    } else {
      control = (
        <Selector
          label={field.description}
          placeholder="Select option"
          options={field.options}
          defaultValue={FormatInput(value)}
          onChange={(v) => onChange(field.id, v.value)}
        />
      );
    }

    return (
      <Row className="section-field">
        <Col xs={12}>
          <InformationField tooltip={field.explanation}>
            {control}
          </InformationField>
        </Col>
      </Row>
    );
  }
}
