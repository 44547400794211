import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import Information from "../../common/Information";
import Colors from "../../common/theme/Colors";
import { rgba } from "polished";
import { FormatInput } from "../../common/Utils";

const Columns = styled.div`
  columns: 3;

  .border {
    border-style: solid !important;
    border-width: 3px !important;
    border-color: ${Colors.secondary} !important;
  }
`;

const Classes = styled.div`
  border-radius: 10px 10px 10px 10px;
  background-color: ${rgba(Colors.categoryLegal, 0.15)};
  height: 85px;
  margin-bottom: 10px;
  display: flex;
  vertical-align: middle;
  align-items: center;

  .row {
    margin-right: 5px;
    margin-left: 5px;
    display: flex;
    vertical-align: middle;
    align-items: center;
  }

  .checkboxdiv {
    width: 10px;
  }

  .centerbox {
    width: 210px;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const toBeDiscussed = "ToBeDiscussed";
const select = "select";

export default class TrademarkRegisterClassStep extends Component {
  constructor(props) {
    super(props);
    this.isSelected = this.isSelected.bind(this);
  }

  getBody = () => {
    if (
      this.props.conf.object.parameters.classMode &&
      this.props.conf.object.parameters.classMode === select
    ) {
      return this.getClassSelector();
    }
    return null;
  };

  getClassSelector() {
    let rows = [];

    rows.push(
      <Row key="region-header" className="section-title">
        <Col md={12}>Choices</Col>
      </Row>,
      <Row key="region-description" className="section-description">
        <Col xs={12}>Select one or more classes below.</Col>
      </Row>,
    );
    if (this.props.conf.parameters.classlist) {
      let classes = this.props.conf.parameters.classlist.map((c) => (
        <Classes
          key={c.classNumber}
          className={this.getStyle(this.isSelected(c.classNumber.toString()))}
          onClick={() => this.toggleOne(c.classNumber.toString())}
        >
          <Row>
            <Col md={10} className="centerbox">
              {c.name}
            </Col>
            <Col md={2} className="information">
              <Information tooltip={c.description} />
            </Col>
          </Row>
        </Classes>
      ));

      if (classes) {
        rows.push(<Columns key={classes}>{classes}</Columns>);
      }
    }
    return rows;
  }

  onClassTypeChange = (e) => {
    this.props.onParameterChange("classMode", e.target.value);
    this.props.onParameterChange("classes", this.encode([]));
  };

  isSelected(classCode) {
    let selectedClasses = this.decode(
      this.props.conf.object.parameters.classes,
    );
    return selectedClasses.includes(classCode);
  }

  toggleOne(classToToggle) {
    let selectedClasses = this.decode(
      this.props.conf.object.parameters.classes,
    );

    if (selectedClasses.includes(classToToggle)) {
      selectedClasses = selectedClasses.filter((o) => o !== classToToggle);
    } else {
      selectedClasses.push(classToToggle);
    }
    this.props.onParameterChange("classes", this.encode(selectedClasses));
  }

  componentDidMount() {
    if (!this.props.conf.object.parameters.classes) {
      this.props.onParameterChange("classes", this.encode([]));
    }
  }

  render() {
    return (
      <Container fluid={true}>
        <Row className="section-title">
          <Col xs={12}>Goods and Services</Col>
        </Row>
        <Row className="section-description">
          <Col xs={12}>
            Decide the goods and services to be covered by the trademark by
            selecting the corresponding classes manually or make the selection
            of classes together with Port Group’s legal team without any extra
            fee (recommended).
          </Col>
        </Row>
        <Row className="section-field">
          <Col md={12}>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  name="mode"
                  value={FormatInput(toBeDiscussed)}
                  onChange={this.onClassTypeChange}
                  checked={
                    this.props.conf.object.parameters.classMode ===
                    toBeDiscussed
                  }
                />
                Select classes together with Abion (recommended)
              </label>
            </div>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  name="mode"
                  value={FormatInput(select)}
                  onChange={this.onClassTypeChange}
                  checked={
                    this.props.conf.object.parameters.classMode === select
                  }
                />
                Select classes manually
              </label>
            </div>
          </Col>
        </Row>
        {this.getBody()}
      </Container>
    );
  }

  decode(classesAsString) {
    if (classesAsString === "") {
      return [];
    }
    return classesAsString.split(",");
  }

  encode(classesAsString) {
    return classesAsString.toString();
  }

  getStyle(selected) {
    if (selected) {
      return "border";
    }
    return "";
  }
}
