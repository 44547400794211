import React, { Component } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import styled from "styled-components";
import Colors from "../common/theme/Colors";

const DivStyle = styled.div`
  margin: 0px 0px;
  border: 1px solid ${Colors.border};
  background-color: ${Colors.white};

  .title {
    padding: 25px 35px;
    font-size: 18px;
    font-weight: 900;
    text-transform: uppercase;
    border-bottom: 1px solid ${Colors.border};
  }

  table {
    width: 100%;
    cursor: default;
  }

  tr:hover {
    font-weight: 600;
  }
  tr:not(:first-child) {
    border-top: 1px solid ${Colors.border};
  }
  tr.row-recent {
    background-color: ${Colors.recentlyUpdated};
  }

  td {
    padding: 5px;
  }
  td.column-link {
    cursor: pointer;
  }
  td:first-child {
    padding-left: 35px;
  }
  td:last-child {
    width: 70px;
    padding-right: 15px;
    text-align: right;
  }
  td:not(:first-child):not(:last-child) {
    padding-right: 35px;
    text-align: right;
  }

  td a {
    text-decoration: none;
  }

  .actionNumber {
    border-radius: 20px;
    text-align: center;
    background-color: crimson;
    color: white;
    padding: 0 7px;
  }
`;

export default class DashboardTable extends Component {
  getTitle = () => {
    let title = null;

    if (this.props.title) {
      title = (
        <div key="title" className="title">
          {this.props.title}
        </div>
      );
    }

    return title;
  };

  getBody = () => {
    return this.getTable();
  };

  getTable = () => {
    return (
      <table>
        <tbody>{this.props.items.map((o) => this.getRow(o))}</tbody>
      </table>
    );
  };

  getRow = (item) => {
    let description = item[0];
    let unit = item[1];
    let tooltip = item[2];
    let path = item[3];
    let recent = item[4];
    let count = item[5];
    let actionCount = 0;
    let actionPath;
    let actionTooltip;
    if (item[6]) {
      actionCount = item[6];
      actionTooltip = item[7];
      actionPath = item[8];
    }

    let key = "row-" + description;

    let row = (
      <tr key={key} className={recent ? "row-recent" : ""}>
        <td>{description}</td>
        {this.getGeneralCountColumn(count, unit, tooltip, path, key)}
        {this.getActionCountColumn(actionCount, actionTooltip, actionPath, key)}
      </tr>
    );
    return row;
  };

  getGeneralCountColumn = (count, unit, tooltip, path, key) => {
    const overlay = this.getOverlayTrigger(
      <span>
        {count}&nbsp;&nbsp;&nbsp;{unit}
      </span>,
      tooltip,
    );
    const column = path ? (
      <LinkContainer key={key} to={path}>
        <div>{overlay}</div>
      </LinkContainer>
    ) : (
      overlay
    );
    return <td className={path ? "column-link" : ""}>{column}</td>;
  };

  getActionCountColumn = (actionCount, actionTooltip, actionPath, key) => {
    let actionColumn;
    if (actionCount > 0) {
      const overlay = this.getOverlayTrigger(
        <span className="actionNumber">{actionCount}</span>,
        actionTooltip,
      );
      actionColumn = actionPath ? (
        <LinkContainer key={key} to={actionPath}>
          <div>{overlay}</div>
        </LinkContainer>
      ) : (
        overlay
      );
    }
    return <td className={actionPath ? "column-link" : ""}>{actionColumn}</td>;
  };

  getOverlayTrigger = (element, tooltip) => {
    if (!tooltip) {
      return element;
    } else {
      return (
        <OverlayTrigger
          overlay={this.getTooltip(tooltip)}
          placement="bottom"
          delay={500}
        >
          {element}
        </OverlayTrigger>
      );
    }
  };

  getTooltip = (tooltip) => {
    return <Tooltip style={{ position: "fixed" }}>{tooltip}</Tooltip>;
  };

  render() {
    return (
      <DivStyle style={this.props.style}>
        {this.getTitle()}
        {this.getBody()}
      </DivStyle>
    );
  }
}
