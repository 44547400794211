import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { DropdownButton } from "../common/Buttons";
import styled from "styled-components";

const RiskFilterStyle = styled.span`
  .dropdown {
    vertical-align: top;
    margin-left: 0px !important;
    margin-right: 20px;
  }
`;

const ITEMS = [
  ["", "Any Risk"],
  ["WHITE", "White Risk"],
  ["GREEN", "Green Risk"],
  ["YELLOW", "Yellow Risk"],
  ["RED", "Red Risk"],
];

export default class RiskFilter extends Component {
  render() {
    let items = [];
    let index = 0;

    for (let i = 0; i < ITEMS.length; i++) {
      items.push(
        <Dropdown.Item
          key={ITEMS[i][1]}
          onClick={() => this.props.onChange(ITEMS[i][0])}
        >
          {ITEMS[i][1]}
        </Dropdown.Item>,
      );

      if (ITEMS[i][0] === this.props.value) {
        index = i;
      }
    }

    return (
      <RiskFilterStyle>
        <DropdownButton
          title={ITEMS[index][1]}
          style={{ verticalAlign: "top" }}
        >
          {items}
        </DropdownButton>
      </RiskFilterStyle>
    );
  }
}
