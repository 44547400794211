import React, { Component } from "react";
import styled from "styled-components";
import Colors from "../common/theme/Colors";
import { MessageDialog } from "../common/Dialog";

const StyledDiv = styled.div`
  .item {
    width: 50px;
    height: 50px;
    border-radius: 4px;
    border: 1px solid ${Colors.riskborder};
    margin: 10px;
    display: inline-block;
    cursor: pointer;
  }
  .item-selected,
  .item:hover {
    border-width: 4px;
  }

  .item-WHITE {
  }

  .item-GREEN {
    background-color: ${Colors.riskGreenBackground};
    border-color: ${Colors.riskGreenBorder};
  }

  .item-YELLOW {
    background-color: ${Colors.riskYellowBackground};
    border-color: ${Colors.riskYellowBorder};
  }

  .item-RED {
    background-color: ${Colors.riskRedBackground};
    border-color: ${Colors.riskRedBorder};
  }
`;

export default class UpdateRiskDialog extends Component {
  getDescription = () => {
    if (this.props.nbrOfHits == 1) {
      return "Select the risk below.";
    } else {
      return (
        "Select the risk below for the " +
        this.props.nbrOfHits +
        " selected hits."
      );
    }
  };

  getItem = (value) => {
    let className = "item item-" + value;
    if (value === this.props.value) {
      className += " item-selected";
    }
    return (
      <div className={className} onClick={() => this.props.onSave(value)} />
    );
  };

  getBody = () => {
    return (
      <StyledDiv>
        {this.getItem("WHITE")}
        {this.getItem("GREEN")}
        {this.getItem("YELLOW")}
        {this.getItem("RED")}
      </StyledDiv>
    );
  };

  render() {
    return (
      <MessageDialog
        show={this.props.show}
        title="Update Risk"
        description={this.getDescription()}
        body={this.getBody()}
        onClose={this.props.onCancel}
      />
    );
  }
}
