import React, { Component } from "react";
import PaymentParameter from "./PaymentParameter";
import { DetailsDialog } from "./Dialog";
import { Col, Container, Row } from "react-bootstrap";
import Icon from "./theme/Icons";

export default class PaymentDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
    };
  }

  onSuccess = () => {
    this.setState({
      success: true,
    });
  };

  onClose = () => {
    this.setState({
      success: false,
    });
    this.props.onClose();
  };

  getSuccessPart = () => {
    if (this.props.type === "order") {
      return (
        <Container fluid={true}>
          <Row className="section-message">
            <Col xs={12}>
              <Icon
                color="primary"
                iconsize="40"
                prefix="fas"
                name="check-circle"
              />
              <span>Your order has been paid successfully!</span>
            </Col>
          </Row>
          <Row className="section-description">
            <Col xs={12}>
              You can now follow the progress in the orders page of Abion Core.
            </Col>
          </Row>
        </Container>
      );
    } else if (this.props.type === "invoice") {
      return (
        <Container fluid={true}>
          <Row className="section-message">
            <Col xs={12}>
              <Icon
                color="primary"
                iconsize="40"
                prefix="fas"
                name="check-circle"
              />
              <span>Your invoice has been paid successfully!</span>
            </Col>
          </Row>
          <Row className="section-description">
            <Col xs={12}>
              Please note that it can take a few days before the updated status
              is reflected in Abion Core.
            </Col>
          </Row>
        </Container>
      );
    }
  };

  getBody = () => {
    let body = [];
    if (this.state.success) {
      body.push(this.getSuccessPart());
    } else {
      body.push(
        <PaymentParameter
          objectType={this.props.type}
          objectId={this.props.objectId}
          session={this.props.session}
          onSuccess={this.onSuccess}
        />,
      );
    }
    return body;
  };

  render() {
    if (this.props.objectId == null) {
      return null;
    }

    return (
      <DetailsDialog
        show={this.props.show}
        title="Payment"
        body={this.getBody()}
        onClose={this.onClose}
        width="lg"
        height="lg"
      />
    );
  }
}
