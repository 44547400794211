import React, { Component } from "react";
import { Col } from "react-bootstrap";
import DashboardTable from "./DashboardTable";

export default class MonitoringItem extends Component {
  getItems = () => {
    return [
      this.getTrademarkWatchItem(),
      this.getDomainWatchItem(),
      this.getWebContentWatchItem(),
      this.getDomainMonitorItem(),
    ];
  };

  getTrademarkWatchItem = () => {
    let role = this.props.session.hasRole("WATCH");
    let exists = this.props.data.trademarkWatchCount != null;
    let count = exists ? this.props.data.trademarkWatchCount : 0;
    let age = this.props.data.trademarkWatchAge;
    let hits = age != null;
    let recent = hits && age <= 7;

    return [
      "Trademark Watches",
      "new hits",
      (!exists
        ? "No trademark watches configured."
        : !hits
          ? "No hits yet."
          : "" +
            count +
            " new hits during last 30 days, most recent hit is " +
            age +
            " days old.") +
        (role
          ? " Click for additional details."
          : " Request role Watch to see additional details."),
      role ? "/monitoring/watches/trademarkwatchhits" : null,
      recent,
      count,
    ];
  };

  getDomainWatchItem = () => {
    let role = this.props.session.hasRole("WATCH");
    let exists = this.props.data.domainWatchCount != null;
    let count = exists ? this.props.data.domainWatchCount : 0;
    let age = this.props.data.domainWatchAge;
    let hits = age != null;
    let recent = hits && age <= 7;

    return [
      "Domain Watches",
      "new hits",
      (!exists
        ? "No domain watches configured."
        : !hits
          ? "No hits yet."
          : "" +
            count +
            " new hits during last 30 days, most recent hit is " +
            age +
            " days old.") +
        (role
          ? " Click for additional details."
          : " Request role Watch to see additional details."),
      role ? "/monitoring/watches/domainwatchhits" : null,
      recent,
      count,
    ];
  };

  getWebContentWatchItem = () => {
    let role = this.props.session.hasRole("WATCH");
    let exists = this.props.data.webContentWatchCount != null;
    let count = exists ? this.props.data.webContentWatchCount : 0;
    let age = this.props.data.webContentWatchAge;
    let hits = age != null;
    let recent = hits && age <= 7;

    return [
      "Web Content Watches",
      "new hits",
      (!exists
        ? "No web content watches configured."
        : !hits
          ? "No hits yet."
          : "" +
            count +
            " new hits during last 30 days, most recent hit is " +
            age +
            " days old.") +
        (role
          ? " Click for additional details."
          : " Request role Watch to see additional details."),
      role ? "/monitoring/watches/webcontentwatchhits" : null,
      recent,
      count,
    ];
  };

  getDomainMonitorItem = () => {
    let role = this.props.session.hasRole("WATCH");
    let exists = this.props.data.domainMonitorCount != null;
    let count = exists ? this.props.data.domainMonitorCount : 0;
    let age = this.props.data.domainMonitorAge;
    let hits = age != null;
    let recent = hits && age <= 7;

    return [
      "Domain Monitors",
      "new hits",
      (!exists
        ? "No domain monitor configured."
        : !hits
          ? "No hits yet."
          : "" +
            count +
            " new hits during last 30 days, most recent hit is " +
            age +
            " days old.") +
        (role
          ? " Click for additional details."
          : " Request role to see additional details."),
      role ? "/monitoring/watches/domainmonitorhits" : null,
      recent,
      count,
    ];
  };

  render() {
    if (this.props.data.quote) {
      return null;
    }

    return (
      <Col>
        <DashboardTable
          title="Monitoring"
          items={this.getItems()}
          style={this.props.style}
        />
      </Col>
    );
  }
}
