import React, { Component } from "react";
import styled from "styled-components";
import fileDownload from "js-file-download";
import { Container, Row } from "react-bootstrap";
import HelpCategory from "./HelpCategory";

const PageStyle = styled.div`
  .container-fluid {
    max-width: 1200px;
  }
`;

export default class HelpPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      categories: null,
    };
  }

  componentDidMount() {
    this.reloadData();

    let views = [
      {
        label: "Home",
        url: "/",
      },
      {
        label: "Help",
        url: null,
      },
    ];

    this.props.window.setBreadcrumbViews(views);
    this.props.window.setPageTip(null);
  }

  getCategoriesByColumn = (categories, column) => {
    return (
      categories && categories.filter((category) => category.column === column)
    );
  };

  onClickFile = (fileLink, fileName) => {
    this.props.session.backendGetFile(fileLink, (r) =>
      fileDownload(r, fileName),
    );
  };

  reloadData = () => {
    let url = this.props.session.getModules().help.links.categories;
    this.props.session.backendGet(
      url,
      (response) => {
        this.setState({
          categories: response.categories,
        });
      },
      (msg) => {
        this.props.window.showErrorFunc(msg);
      },
    );
  };

  render() {
    let helpCategoriesFirstColumn = this.getCategoriesByColumn(
      this.state.categories,
      0,
    );
    let helpCategoriesSecondColumn = this.getCategoriesByColumn(
      this.state.categories,
      1,
    );
    return (
      <PageStyle>
        <Container fluid={true}>
          <Row>
            {helpCategoriesFirstColumn &&
              helpCategoriesFirstColumn.length > 0 &&
              HelpCategory(helpCategoriesFirstColumn, this.onClickFile)}
            {HelpCategory(helpCategoriesSecondColumn, this.onClickFile)}
          </Row>
        </Container>
      </PageStyle>
    );
  }
}
