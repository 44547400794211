import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { ExternalLink } from "../common/Link";
import fileDownload from "js-file-download";
import styled from "styled-components";
import Colors from "../common/theme/Colors";
import DOMPurify from "dompurify";

const DivStyle = styled.div`
  border: 1px solid ${Colors.border};
  background-color: ${Colors.white};
  table {
    margin-top: 15px;
  }
  img {
    max-height: 700px;
    max-width: 700px;
  }
  .container-fluid {
    padding: 20px;
  }
  .section-title {
    color: ${Colors.tertiary};
    margin-top: 20px;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 1.5px;
  }
  .section-space {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  .field-label {
    min-width: 200px;
    padding-right: 30px;
    font-weight: 700;
  }
  .field-value {
    padding-right: 20px;
    word-break: break-word;
    white-space: pre-wrap;
  }
  .download-link {
    cursor: pointer;
    text-decoration: underline;
    color: ${Colors.tertiary};
  }
`;

class ProjectInformationTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
    };
  }

  componentDidMount(prevProps) {
    this.reloadInformationList();
  }

  reloadInformationList = () => {
    this.setState({
      rows: [],
    });

    const rows = [];

    this.props.session.backendGet(
      this.props.project.links.informations,
      (response) => {
        response.forEach((info) => {
          if (info.fileType === "TEXT") {
            rows.push(this.getRowText(info));
          } else if (info.fileType === "HTML") {
            rows.push(this.getRowHtml(info));
          } else if (info.fileType === "IMAGE") {
            rows.push(this.getRowImage(info));
          } else if (info.fileType === "FILE") {
            rows.push(this.getRowFile(info));
          }
        });
        this.setState({
          rows: rows,
        });
      },
    );
  };

  getRowText(info) {
    let className = /\r|\n/.exec(info.textContent) ? "section-space" : "";
    return (
      <Row key={info.id} className={className}>
        <Col className="field-label" xs={2}>
          {info.name}
        </Col>
        <Col className="field-value" xs={10}>
          {info.textContent}
        </Col>
      </Row>
    );
  }

  getRowHtml(info) {
    return (
      <Row key={info.id} className="section-space">
        <Col xs={12}>
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(info.htmlContent),
            }}
          ></div>
        </Col>
      </Row>
    );
  }

  getRowImage(info) {
    const imageSource =
      "data:image/" +
      info.imageExtension +
      ";base64," +
      info.base64ImageContent;

    return (
      <Row key={info.id} className="section-space">
        <Col xs={12}>
          <img className="image" src={imageSource} alt=""></img>
        </Col>
      </Row>
    );
  }

  getRowFile(info) {
    return (
      <Row key={info.id} className="section-space">
        <Col xs={12}>
          <ExternalLink
            onClick={() => this.onDownload(info)}
            tooltip={"Click to download"}
            text={info.fileName}
            className={"download-link"}
          />
        </Col>
      </Row>
    );
  }

  onDownload = (fileInformation) => {
    const url = fileInformation.links.file;
    const fileName = fileInformation.fileName;

    this.props.session.backendGetFile(
      url,
      (response) => {
        fileDownload(response, fileName);
      },
      (error) => {
        this.props.window.showErrorFunc(error);
      },
    );
  };

  render() {
    return (
      <DivStyle>
        <Container fluid={true}>{this.state.rows}</Container>
      </DivStyle>
    );
  }
}

export default ProjectInformationTab;
