import React, { Component } from "react";
import { Form } from "react-bootstrap";
import EditDialog from "../common/Dialog";

export default class CertificateDownloadDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formats: null,
      selectedFormat: null,
      selectedExtension: null,
    };
  }

  componentDidUpdate() {
    if (!this.state.formats && this.props.show) {
      this.loadFormats();
    }
  }

  loadFormats = () => {
    let url = this.props.session.getModules().certadmin.links.formats;
    this.props.session.backendGet(url, (response) => {
      this.setState({
        formats: response,
      });
    });
  };

  onChange = (event, format, extension) => {
    if (event.target.value) {
      this.setState({
        selectedFormat: format,
        selectedExtension: extension,
      });
    }
  };

  onCancel = () => {
    this.resetDialog();
    this.props.onClose();
  };

  resetDialog = () => {
    this.setState({
      options: null,
      selectedFormat: null,
      selectedExtension: null,
    });
  };

  onDownload = () => {
    this.props.onDownload(
      this.state.selectedFormat,
      this.state.selectedExtension,
    );
  };

  getContent = () => {
    let content = [];
    this.state.formats.forEach((o) => {
      content.push(
        <Form.Check
          type="radio"
          name="groupOptions"
          onChange={(e) => this.onChange(e, o.name, o.extension)}
          label={o.description}
        />,
      );
    });
    return content;
  };

  render() {
    if (!this.state.formats || this.state.formats.length < 1) {
      return null;
    }

    const content = this.getContent();

    return (
      <EditDialog
        title="Download Certificate"
        content={content}
        show={this.props.show}
        onCancel={this.onCancel}
        onConfirm={this.onDownload}
        onConfirmLabel="Download"
        errorMessage={this.props.errorMessage}
        submit={this.props.submit}
      />
    );
  }
}
