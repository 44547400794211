import React, { Component } from "react";
import { ActionButton, MainActionButton } from "../common/Buttons";
import { Col, Container, Row } from "react-bootstrap";
import { FormatDateMonospace, FormatOrganisation } from "../common/Utils";
import ChangeAccountDialog from "../common/ChangeAccountDialog";
import Colors from "../common/theme/Colors";
import { DetailsDialog, DialogSection } from "../common/Dialog";
import ReadOnlyForm from "../common/ReadOnlyForm";

class ServiceDetailsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      service: null,
      subscription: null,
      errorMessage: null,
      changeAccountDialogShow: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && prevProps.show !== this.props.show) {
      this.reload();
    }
  }

  reload = () => {
    this.setState({
      service: this.props.service,
      subscription: null,
      errorMessage: null,
    });

    if (this.props.service?.links.subscription) {
      this.props.session.backendGet(
        this.props.service.links.subscription,
        (response) => {
          this.setState({ subscription: response });
        },
        (errorMessage) => {
          this.setState({ errorMessage: errorMessage });
        },
      );
    }
  };

  getBody = () => {
    return (
      <Container fluid={true}>
        {this.getBasicInfo()}
        {this.getParameters()}
      </Container>
    );
  };

  getBasicInfo = () => {
    const content = [
      {
        label: "Description",
        value: this.state.service.description,
      },
      {
        label: "Service Type",
        value: this.state.service.type,
      },
      {
        label: "Expiry Date",
        value: FormatDateMonospace(this.state.service.expiryDate),
      },
      {
        label: "On Expiry",
        value: this.state.service.onExpiration,
      },
      {
        label: "Invoice Period",
        value: this.state.service.unitCount + " months",
      },
      {
        value: "",
        label: "",
      },
      {
        label: "Organisation",
        value: FormatOrganisation(
          this.state.service.organisation,
          this.props.session.getApplication().organisationPrefix,
        ),
      },
    ];

    return (
      <DialogSection title="Basic Info">
        <ReadOnlyForm wide="true" content={content} />
      </DialogSection>
    );
  };

  getParameters = () => {
    if (this.state.errorMessage) {
      return (
        <Row>
          <Col xs={12}>
            <pre>Failed fetching data. Please try again later.</pre>
          </Col>
        </Row>
      );
    }

    let content = [];

    if (
      this.state.subscription?.parameters &&
      Object.keys(this.state.subscription.parameters).length !== 0
    ) {
      Object.keys(this.state.subscription.parameters).forEach((key) => {
        let value = this.state.subscription.parameters[key];
        if (value === null || value === "" || value === "No") {
          content.push({
            label: key,
            value: "No",
            valueStyle: { color: Colors.failed },
          });
        } else {
          let color;
          if (value === "Yes") {
            color = Colors.success;
          }
          content.push({
            label: key,
            value: value,
            valueStyle: { color: color },
          });
        }
      });
    }

    if (content.length === 0) {
      return;
    }

    return (
      <DialogSection title="Parameters">
        <ReadOnlyForm wide="true" content={content} />
      </DialogSection>
    );
  };

  onUpgrade = () => {
    let parameters = {
      upgradeSubscriptionId: this.state.subscription.id,
    };
    this.props.onClose();
    this.props.onUpgrade(
      parameters,
      this.state.subscription.upgradeOperationId,
      this.state.subscription.upgradeStep,
    );
  };

  onUpdateLifecycle = (lifecycle) => {
    const url = this.state.subscription.links.lifecycle;
    const serviceDescription = this.state.service.description;
    const serviceType = this.state.service.type;
    this.props.onClose();
    this.props.onUpdateLifecycle(
      serviceType,
      serviceDescription,
      lifecycle,
      url,
    );
  };

  showChangeAccountDialog = () => {
    this.props.onClose();
    this.setState({
      changeAccountDialogShow: true,
      errorMessage: null,
      submit: null,
    });
  };

  hideChangeAccountDialog = () => {
    this.props.onClose();
    this.setState({
      changeAccountDialogShow: false,
    });
  };

  onChangeAccount = () => {
    this.props.onChangeAccount();
    this.setState({
      changeAccountDialogShow: false,
    });
  };

  getActionButtons = () => {
    let buttons = [];

    let changeAccountButtonDisabled;
    let disabledChangeAccountTooltip;
    if (this.state.subscription === null) {
      disabledChangeAccountTooltip =
        "Change account of type " +
        this.state.service.type +
        " is not supported";
      changeAccountButtonDisabled = true;
    } else if (!this.state.subscription.sessionAccountChangeable) {
      disabledChangeAccountTooltip =
        "Your user account does not have privileges to change account on the service, contact your client manager to activate this functionality";
      changeAccountButtonDisabled = true;
    }
    buttons.push(
      <ActionButton
        text={"Change Account"}
        disabledTooltip={disabledChangeAccountTooltip}
        disabled={changeAccountButtonDisabled}
        onClick={() => this.showChangeAccountDialog()}
      />,
    );

    let disabledUpgradeTooltip;

    if (
      this.state.subscription === null ||
      !this.state.subscription.typeUpgradable
    ) {
      disabledUpgradeTooltip =
        "The upgrade of type " + this.state.service.type + " is not supported";
    } else if (!this.state.subscription.sessionUpgradable) {
      disabledUpgradeTooltip =
        "Your user account does not have privileges to upgrade the service, contact your client manager to activate this functionality";
    }
    buttons.push(
      <MainActionButton
        key="upgrade"
        text="Upgrade"
        onClick={this.onUpgrade}
        disabled={
          this.state.subscription === null ||
          !this.state.subscription.typeUpgradable ||
          !this.state.subscription.sessionUpgradable
        }
        disabledTooltip={disabledUpgradeTooltip}
      />,
    );

    let disabledOnExpiryTooltip;
    let text = this.state.service.onExpiration === "Renew" ? "Cancel" : "Renew";
    let className =
      this.state.service.onExpiration === "Renew" ? "delete" : "main";

    if (this.state.subscription === null) {
      disabledOnExpiryTooltip =
        text + " of type " + this.state.service.type + " is not supported";
    } else if (!this.state.subscription.sessionLifecycleUpdatable) {
      disabledOnExpiryTooltip =
        "Your user account does not have privileges to update the service, contact your client manager to activate this functionality";
    }

    buttons.push(
      <MainActionButton
        key="on-expiry"
        text={text + " On Expiry"}
        tooltip={text + " the service on expiration date"}
        onClick={() => this.onUpdateLifecycle(text)}
        className={className}
        disabled={
          this.state.subscription === null ||
          !this.state.subscription.sessionLifecycleUpdatable
        }
        disabledTooltip={disabledOnExpiryTooltip}
      />,
    );

    return buttons;
  };

  render() {
    if (!this.state.service) {
      return null;
    }

    return (
      <div>
        <DetailsDialog
          show={this.props.show}
          title="Service Details"
          actions={this.getActionButtons()}
          body={this.getBody()}
          onClose={() => this.props.onClose()}
          width="lg"
          height="lg"
        />

        {this.state.changeAccountDialogShow && (
          <ChangeAccountDialog
            session={this.props.session}
            window={this.props.window}
            show={this.state.changeAccountDialogShow}
            errorMessage={this.state.errorMessage}
            submit={this.state.submit}
            changeAccountUrl={this.state.service.links.changeAccount}
            onClose={this.hideChangeAccountDialog}
            onChangeAccount={this.onChangeAccount}
          />
        )}
      </div>
    );
  }
}

export default ServiceDetailsDialog;
