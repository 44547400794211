import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import Colors from "../common/theme/Colors";
import {
  ClearFix,
  Header,
  HeaderMeta,
  HeaderMetaLabel,
  HeaderMetaText,
  Organisation,
  PaddedContent,
  TitleWrapper,
  TruncatedTitle,
} from "../common/theme/Theme";
import { ProjectMessage } from "./ProjectMessage";

const PanelDesc = styled.textarea`
  width: 100%;
  white-space: pre-wrap;
  background-color: transparent;
  text-align: left;
  resize: none;
  height: 240px;
  border-style: solid;
  border-color: ${Colors.border};
  border-width: thin;
  margin-top: 5px;
  padding-left: 6px;
`;

class ProjectHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
    };
  }

  componentDidMount() {
    this.reloadMessages();
  }

  reloadMessages = () => {
    this.props.session.backendGet(
      this.props.project.links.messages,
      (response) => {
        this.setState({ messages: response });
      },
      () => {},
    );
  };

  getDescriptionPanel() {
    if (this.props.project.description) {
      return (
        <HeaderMeta xs={12}>
          <HeaderMetaLabel>Description</HeaderMetaLabel>
          <PanelDesc readonly disabled>
            {this.props.project.description}
          </PanelDesc>
        </HeaderMeta>
      );
    }
  }

  getProjectMeta() {
    let fields = [];

    fields.push(
      <Row key="projectMeta-row-1">
        <HeaderMeta xs={6}>
          <HeaderMetaLabel>
            {this.props.caseModule ? "Case Number" : "Project Number"}
          </HeaderMetaLabel>
          <HeaderMetaText>{this.props.project.projectNumber}</HeaderMetaText>
        </HeaderMeta>
        <HeaderMeta xs={6}>
          <HeaderMetaLabel>Type</HeaderMetaLabel>
          <HeaderMetaText>{this.props.project.type}</HeaderMetaText>
        </HeaderMeta>
      </Row>,
      <Row key="projectMeta-row-2">
        <HeaderMeta xs={6}>
          <HeaderMetaLabel>Responsible</HeaderMetaLabel>
          <HeaderMetaText>{this.props.project.responsible}</HeaderMetaText>
        </HeaderMeta>
        <HeaderMeta xs={6}>
          <HeaderMetaLabel>Status</HeaderMetaLabel>
          <HeaderMetaText>{this.props.project.status}</HeaderMetaText>
        </HeaderMeta>
      </Row>,
    );
    return <ClearFix>{fields}</ClearFix>;
  }

  getProjectDescription() {
    let fields = [];
    fields.push(<Row>{this.getDescriptionPanel()}</Row>);
    return <ClearFix>{fields}</ClearFix>;
  }

  getProjectMessages = () => {
    return (
      <ProjectMessage
        session={this.props.session}
        window={this.props.window}
        url={this.props.project.links.messages}
        projectId={this.props.project.projectId}
      />
    );
  };

  render() {
    return (
      <Header>
        <Container fluid>
          <Row>
            <Col xs={4}>
              <Row>
                <TitleWrapper>
                  <TruncatedTitle
                    data={this.props.title}
                    isTitle={true}
                    breakAfter={50}
                  ></TruncatedTitle>
                  <Organisation>{this.props.org}</Organisation>
                </TitleWrapper>
              </Row>
            </Col>
            <Col xs={3}>
              <PaddedContent padding={"0px 20px 0 0"}>
                {this.getProjectMeta()}
              </PaddedContent>
            </Col>
            <Col xs={2}>
              <PaddedContent padding={"0px 20px 0 0"}>
                {this.getProjectDescription()}
              </PaddedContent>
            </Col>
            <Col xs={3}>
              <PaddedContent padding={"0px 0 20px 0"}>
                {this.getProjectMessages()}
              </PaddedContent>
            </Col>
          </Row>
        </Container>
      </Header>
    );
  }
}

export default ProjectHeader;
