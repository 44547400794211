import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Selector from "../../common/Selector";
import { InformationField } from "../../common/Information";
import { FormatInput } from "../../common/Utils";

export default class DomainDelegateParameterStep extends Component {
  getField = () => {
    if (this.props.conf.object.parameters.nameserverCustom === "1") {
      return (
        <Row className="section-field">
          <Col md={12}>
            <textarea
              rows="10"
              placeholder="Enter nameservers, one per line"
              value={FormatInput(
                this.props.conf.object.parameters.nameserverText,
              )}
              onChange={(e) =>
                this.props.onParameterChange("nameserverText", e.target.value)
              }
            />
          </Col>
        </Row>
      );
    } else {
      return (
        <Row className="section-field">
          <Col xs={12}>
            <InformationField tooltip="Nameservers are setup in advance under menu nameservers and then they become selectable in this field">
              <Selector
                placeholder="Select nameservers"
                options={this.props.conf.parameters.nameservers}
                defaultValue={FormatInput(
                  this.props.conf.object.parameters.nameserverId,
                )}
                onChange={(v) =>
                  this.props.onParameterChange("nameserverId", v.value)
                }
              />
            </InformationField>
          </Col>
        </Row>
      );
    }
  };

  render() {
    return (
      <Container fluid={true}>
        <Row className="section-title">
          <Col xs={12}>Parameters</Col>
        </Row>
        <Row className="section-description">
          <Col xs={12}>
            Enter the new nameservers below, using either a pre-defined
            nameserver set or custom nameservers.
          </Col>
        </Row>
        <Row className="section-field">
          <Col md={12}>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  checked={
                    this.props.conf.object.parameters.nameserverCustom !== "1"
                  }
                  onChange={() =>
                    this.props.onParameterChange("nameserverCustom", "0")
                  }
                />
                Select a pre-defined nameserver set
              </label>
            </div>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  checked={
                    this.props.conf.object.parameters.nameserverCustom === "1"
                  }
                  onChange={() =>
                    this.props.onParameterChange("nameserverCustom", "1")
                  }
                />
                Enter custom nameservers manually
              </label>
            </div>
          </Col>
        </Row>
        <Row className="section-title">
          <Col xs={12}>Nameservers</Col>
        </Row>
        {this.getField()}
      </Container>
    );
  }
}
