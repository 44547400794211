import React, { Component } from "react";
import { TableBackend, TableFilter } from "../common/Tables";
import { TableButton } from "../common/Buttons";
import { FormatDateMonospace, FormatOrganisation } from "../common/Utils";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import styled from "styled-components";
import { SwitchCheckbox } from "../common/theme/Theme";
import OrganisationFilter from "../common/OrganisationFilter";
import OperationFilter from "../common/OperationFilter";
import Colors from "../common/theme/Colors";
import ActionDialog from "./ActionDialog";
import PaymentDialog from "../common/PaymentDialog";
import AuthCodeDialog from "../common/AuthCodeDialog";

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .dropdown-menu {
    max-height: 400px;
    overflow: auto;
  }

  .dropdown {
    vertical-align: top;
    margin-right: 20px;
  }

  .notificationHighlightBackground {
    animation-name: highlightBackground;
    animation-duration: 2s;
    animation-iteration-count: 3;
    animation-direction: invert;
    animation-timing-function: ease-in-out;
  }

  @keyframes highlightBackground {
    0% {
      background-color: ${Colors.white};
    }
    50% {
      background-color: ${Colors.recentlyUpdated};
    }
    100% {
      background-color: ${Colors.white};
    }
  }
`;

export default class OrderList extends Component {
  lastState;
  first = true;
  allRows = new Map();
  updatedRows = new Map();

  constructor(props) {
    super(props);
    this.state = {
      parameters: {
        accountIds: "",
        operationIds: "",
        freetext: "",
        onlyActive: true,
      },
      showCreateOrderDialog: false,
      actionDialogShow: false,
      actionDialogData: null,
      paymentDialogShow: false,
      authCodeDialogShow: false,
      orderId: null,
      orderLineId: null,
      authCode: null,
      authCodeUrl: null,
      errorMessage: null,
    };
  }

  componentDidMount() {
    let views = [
      {
        label: "Home",
        url: "/",
      },
      {
        label: "Orders",
        url: null,
      },
    ];

    this.props.window.setBreadcrumbViews(views);
    this.props.window.setPageTip(null);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getUrl = (sorting, expanding, parameters) => {
    let url = this.props.session.getModules().order.links.orderLines;
    url += "?sort=" + sorting;
    url += "&accountIdFilter=" + parameters.accountIds;
    url += "&operationIdFilter=" + parameters.operationIds;
    url += "&freetextFilter=" + encodeURIComponent(parameters.freetext);
    url += "&onlyActiveFilter=" + parameters.onlyActive;
    if (!parameters.onlyActive) {
      url += expanding ? "" : "&page[limit]=15";
    }
    return url;
  };
  
  getAuthCodeUrl = (orderLineId) => {
	let authUrl = this.props.session.getModules().order.links.orderLines  + "/" + orderLineId + "/authCode";
	return authUrl;
  }

  onResponse = (response) => {
    const newQuery = new Map(
      response.objects.map((x) => [x.id, JSON.stringify(x)]),
    );
    if (
      response.objects.length > 0 &&
      this.lastState === this.state.parameters.onlyActive
    ) {
      const newQuery = new Map(
        response.objects.map((x) => [x.id, JSON.stringify(x)]),
      );

      this.updatedRows = this.getAddedAndUpdatedRows(this.allRows, newQuery);
    }

    this.lastState = this.state.parameters.onlyActive;
    newQuery.forEach((value, key) => this.allRows.set(key, value));
    this.first = false;
    return response;
  };

  getAddedAndUpdatedRows(oldMap, newMap) {
    let result = new Map();
    newMap.forEach(function (value, key, map) {
      const oldValue = oldMap.get(key);
      if (value !== oldValue) {
        result.set(key, value);
      }
    });
    return result;
  }

  getActionButton = (order) => {
    if (order.action == null) {
      return null;
    } else {
      return (
        <TableButton
          icon="eye"
          prefix="fas"
          className="secondary"
          text="Action Details"
          tooltip="There is an action that needs to be performed by the customer for the order to proceed, use this button to see the details"
          onClick={() => this.openActionDialog(order)}
        />
      );
    }
  };

  openActionDialog = (order) => {
    this.setState({
      actionDialogShow: true,
      actionDialogData: order.action,
      orderId: order.orderId,
      orderLineId: order.id,
    });
  };

  hideActionDialog = () => {
    this.setState({
      actionDialogShow: false,
      actionDialogData: null,
    });
  };

  showPaymentDialog = () => {
    this.setState({
      actionDialogShow: false,
      actionDialogData: null,
      paymentDialogShow: true,
      authCodeDialogShow: false
    });
  };

  hidePaymentDialog = () => {
    this.setState({
      paymentDialogShow: false,
      orderId: null,
    });
    this.reloadPage();
  };
  
  openAuthCodeDialog = () => {
	let authCodeUrl = this.getAuthCodeUrl(this.state.orderLineId);
	this.setState({
      actionDialogShow: false,
      actionDialogData: null,
      paymentDialogShow: false,
      authCodeDialogShow: true,
      authCodeUrl: authCodeUrl,
      authCode: null,
      errorMessage: null,
	});
  };
  
  onUpdateAuthCode = (newAuthCode) => {
	this.props.session.backendPut(
		this.state.authCodeUrl,
		{authCode: newAuthCode},
		() => {
			this.hideAuthCodeDialog();
			const message = "Authcode updated successfully";
			this.props.window.showSuccessFunc(message);
			this.reloadPage();
			this.setState({
				authCode: newAuthCode,
				errorMessage: null, 
			});
		},
		(errorMessage) => {
			this.setState({
				errorMessage: errorMessage,
			});
		}
	);
  };
  
  hideAuthCodeDialog = () => {
    this.setState({
      authCodeDialogShow: false,
      orderId: null,
      authCodeUrl: null,
      authCode: null,
      errorMessage: null,
    });
  };
  
  reloadPage = () => {
    this.setState({
      parameters: Object.assign({}, this.state.parameters, {
        reloadFlag: !this.state.parameters.reloadFlag,
      }),
    });
  };

  render() {
    if (this.state.parameters.onlyActive) {
      if (!this.interval) {
        this.interval = setInterval(
          () =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                time: Date.now(),
              }),
            }),
          10000,
        );
      }
    } else {
      clearInterval(this.interval);
      this.interval = null;
    }

    const actions = [];

    const filters = [
      <StyledDiv key="filters">
        <OverlayTrigger
          overlay={this.getTooltip(
            "Active orders are orders in progress or completed recently",
          )}
          placement="bottom"
          delay={500}
        >
          {SwitchCheckbox(
            "Only display active orders",
            this.state.parameters.onlyActive,
            (o) =>
              this.setState({
                parameters: Object.assign({}, this.state.parameters, {
                  onlyActive: o.target.checked,
                }),
              }),
          )}
        </OverlayTrigger>
        <OrganisationFilter
          session={this.props.session}
          onChange={(o) =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                accountIds: o,
              }),
            })
          }
        />
        <OperationFilter
          session={this.props.session}
          onChange={(o) =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                operationIds: o,
              }),
            })
          }
        />
        <TableFilter
          value={this.state.parameters.freetext}
          onChange={(o) =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                freetext: o,
              }),
            })
          }
        />
      </StyledDiv>,
    ];

    const columns = [
      {
        label: "Product Name",
        name: "productName",
        sortable: true,
      },
      {
        label: "Type",
        name: "operation",
        style: { width: "190px" },
      },
      {
        label: "Organisation",
        name: "organisation",
        sortable: true,
        contentFunction: (r) =>
          FormatOrganisation(
            r.organisation,
            this.props.session.getApplication().organisationPrefix,
          ),
      },
      {
        label: "Project",
        name: "project",
      },
      {
        label: "State",
        name: "state",
        style: { width: "150px" },
        contentFunction: (r) => (
          <div style={this.getStateStyle(r.state)}>{r.state}</div>
        ),
      },
      {
        label: "Customer Action",
        contentFunction: (r) => this.getActionButton(r),
      },
      {
        label: "Created By",
        name: "createdBy",
        sortable: true,
        style: { width: "150px" },
      },
      {
        label: "Created At",
        name: "createdAt",
        sortable: true,
        style: { width: "150px" },
        contentFunction: (r) => FormatDateMonospace(r.createdAt),
      },
      {
        label: "Completed At",
        name: "completedAt",
        sortable: true,
        style: { width: "150px" },
        contentFunction: (r) => FormatDateMonospace(r.completedAt),
      },
    ];

    return (
      <StyledDiv>
        <TableBackend
          session={this.props.session}
          window={this.props.window}
          actions={actions}
          filters={filters}
          columns={columns}
          sorting="-createdAt"
          parameters={this.state.parameters}
          urlFunction={this.getUrl}
          disableLoading={this.state.parameters.onlyActive && !this.first}
          disableExpanding={this.state.parameters.onlyActive}
          rowClassFunction={(row) => this.rowDecorated(row)}
          responseFunction={(response) => this.onResponse(response)}
        />
        <ActionDialog
          show={this.state.actionDialogShow}
          onClose={this.hideActionDialog}
          data={this.state.actionDialogData}
          onPayAction={this.showPaymentDialog}
          onAuthCodeAction={this.openAuthCodeDialog}
          objectId={this.state.orderId}
        />
        <PaymentDialog
          show={this.state.paymentDialogShow}
          type="order"
          objectId={this.state.orderId}
          session={this.props.session}
          onClose={this.hidePaymentDialog}
        />
        <AuthCodeDialog
          show={this.state.authCodeDialogShow}
          type="order"
          objectId={this.state.orderId}
          session={this.props.session}
          onSave={this.onUpdateAuthCode}
          onClose={this.hideAuthCodeDialog}
          authCode={this.state.authCode}
          errorMessage={this.state.errorMessage}
        />
      </StyledDiv>
    );
  }

  rowDecorated(row) {
    if (this.state.parameters.onlyActive && this.updatedRows.has(row.id)) {
      return "notificationHighlightBackground";
    }
  }

  getTooltip = (tooltip) => {
    return <Tooltip style={{ position: "fixed" }}>{tooltip}</Tooltip>;
  };

  getStateStyle(state) {
    if (state === "In Progress") {
      return {
        color: Colors.warning,
      };
    } else if (state === "Complete") {
      return {
        color: Colors.success,
      };
    } else if (state === "Cancelled") {
      return {
        color: Colors.failed,
      };
    } else {
      return {};
    }
  }
}
