import Keycloak from "keycloak-js";
import React from "react";
import { createRoot } from "react-dom/client";
import Session from "./common/Session";
import App from "./common/App";
import "babel-polyfill";
import "whatwg-fetch";
import "./common/theme/css/custom_bootstrap.css";
import "font-awesome/css/font-awesome.min.css";
import "./index.css";

const keycloak = new Keycloak("api/rest/keycloak");

keycloak.init({ onLoad: "check-sso" }).then((authenticated) => {
  if (authenticated) {
    let session = new Session(keycloak);

    const container = document.getElementById("root");
    const root = createRoot(container);
    root.render(<App session={session} />);
  } else {
    let idp = getIdp();

    if (idp) {
      keycloak.login({ idpHint: idp });
    } else {
      keycloak.login();
    }
  }
});

function getIdp() {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) === "idp") {
      return decodeURIComponent(pair[1]);
    }
  }
  return null;
}
