import React, { Component } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import styled from "styled-components";
import Icon from "./theme/Icons";

const StyledIcon = styled(Icon)`
  margin: 10px;
`;

const StyledDiv = styled.div`
  > div {
    margin-right: 50px;
  }

  > svg {
    float: right;
  }
`;

export default class Information extends Component {
  getTooltip = (tooltip) => {
    return (
      <Tooltip style={{ whiteSpace: "pre-line", wordBreak: "break-word" }}>
        {tooltip}
      </Tooltip>
    );
  };

  render() {
    let iconsize = 20;
    if (this.props.iconsize) {
      iconsize = this.props.iconsize;
    }
    return (
      <OverlayTrigger
        placement="bottom"
        overlay={this.getTooltip(this.props.tooltip)}
        delay={500}
      >
        <StyledIcon prefix={"far"} name={"info-circle"} iconsize={iconsize} />
      </OverlayTrigger>
    );
  }
}

export class InformationField extends Component {
  render() {
    if (this.props.tooltip) {
      return (
        <StyledDiv>
          <Information tooltip={this.props.tooltip} />
          <div>{this.props.children}</div>
        </StyledDiv>
      );
    } else {
      return (
        <StyledDiv>
          <svg style={{ height: "0px" }} />
          <div>{this.props.children}</div>
        </StyledDiv>
      );
    }
  }
}
