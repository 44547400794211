import React, { Component } from "react";
import { Col } from "react-bootstrap";
import DashboardTable from "./DashboardTable";

export default class OngoingItem extends Component {
  getItems = () => {
    return [this.getProjectItem(), this.getOrderLineItem(), this.getCaseItem()];
  };

  getProjectItem = () => {
    let role = this.props.session.hasRole("PROJECT");
    let exists = this.props.data.projectCount != null;
    let count = exists ? this.props.data.projectCount : 0;
    let age = this.props.data.projectAge;
    let recent = exists && age <= 7;
    let tooltip =
      (!exists
        ? "No projects in progress."
        : "" +
          count +
          " projects in progress, most recent modification is " +
          age +
          " days old.") +
      (role
        ? " Click for additional details."
        : " Request role Project to see additional details.");

    let pendingActionCount =
      this.props.data.projectPendingActionCount != null
        ? this.props.data.projectPendingActionCount
        : 0;
    let pendingActionTooltip =
      pendingActionCount +
      " messages waiting for reply." +
      (role
        ? " Click for additional details."
        : " Request role Project to see additional details.");

    return [
      "Projects",
      "in progress",
      tooltip,
      role ? "/project/projects" : null,
      recent,
      count,
      pendingActionCount,
      pendingActionTooltip,
      role ? "/project/projects?onlyPending=true" : null,
    ];
  };

  getCaseItem = () => {
    let role = this.props.session.hasRole("CASE");
    let exists = this.props.data.caseCount != null;
    let count = exists ? this.props.data.caseCount : 0;
    let age = this.props.data.caseAge;
    let recent = exists && age <= 7;
    let tooltip =
      (!exists
        ? "No cases in progress."
        : "" +
          count +
          " cases in progress, most recent modification is " +
          age +
          " days old.") +
      (role
        ? " Click for additional details."
        : " Request role Case to see additional details.");

    let pendingActionCount =
      this.props.data.casePendingActionCount != null
        ? this.props.data.casePendingActionCount
        : 0;
    let pendingActionTooltip =
      pendingActionCount +
      " messages waiting for reply." +
      (role
        ? " Click for additional details."
        : " Request role Case to see additional details.");

    return [
      "Cases",
      "in progress",
      tooltip,
      role ? "/monitoring/cases" : null,
      recent,
      count,
      pendingActionCount,
      pendingActionTooltip,
      role ? "/monitoring/cases?onlyPending=true" : null,
    ];
  };

  getOrderLineItem = () => {
    let role = this.props.session.hasRole("ORDER_VIEW");
    let exists = this.props.data.orderLineCount != null;
    let count = exists ? this.props.data.orderLineCount : 0;
    let age = this.props.data.orderLineAge;
    let recent = exists && age <= 7;

    return [
      "Orders",
      "in progress",
      (!exists
        ? "No orders in progress."
        : "" +
          count +
          " orders in progress, most recent modification is " +
          age +
          " days old.") +
        (role
          ? " Click for additional details."
          : " Request role Order to see additional details."),
      role ? "/order/orderLines" : null,
      recent,
      count,
    ];
  };

  render() {
    if (this.props.data.quote) {
      return null;
    }

    return (
      <Col>
        <DashboardTable title="In Progress" items={this.getItems()} />
      </Col>
    );
  }
}
