import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Selector from "../../common/Selector";
import { InputWithLabel } from "../../common/theme/Theme";
import AccountField from "./AccountField";
import { FormatInput } from "../../common/Utils";
import { PortsAlert } from "../../common/PortsAlert";

export default class CertificateParameterStep extends Component {
  getPeriods = () => {
    let periods = [];

    for (let i = 0; i < this.props.conf.parameters.periods.length; i++) {
      if (
        this.props.conf.parameters.periods[i].parentValue ===
        this.props.conf.object.parameters.certificateTypeId
      ) {
        periods.push(this.props.conf.parameters.periods[i]);
      }
    }

    return periods;
  };

  getPeriodNote = () => {
    if (
      this.props.conf.object.parameters.periodId == null ||
      this.props.conf.object.parameters.periodId <= 12
    ) {
      return null;
    }

    return (
      <PortsAlert
        key="period-alert"
        variant="warning"
        style={{ marginTop: "20px", marginBottom: "0px" }}
      >
        <strong>Note:</strong> Certificates ordered with a validity period
        exceeding 1 year will be delivered as multiple 1 year certificates to be
        installed once a year. For further details or questions contact{" "}
        <a href="mailto:tech@portsgroup.com">tech@portsgroup.com</a>.
      </PortsAlert>
    );
  };

  getCommentsNote = () => {
    if (
      this.props.conf.object.parameters.comments == null ||
      this.props.conf.object.parameters.comments.length === 0
    ) {
      return null;
    }

    return (
      <PortsAlert
        key="comments-alert"
        variant="warning"
        style={{ marginTop: "20px", marginBottom: "0px" }}
      >
        <strong>Note:</strong> The comments will be shown on the SSL
        Certificates page. Abion will not receive any notifications about the
        comments.
      </PortsAlert>
    );
  };

  render() {
    let items = [];

    items.push(
      <Container key="content" fluid={true}>
        <Row className="section-title">
          <Col xs={12}>Basic Info</Col>
        </Row>
        <AccountField {...this.props} />
        <Row className="section-field">
          <Col md={12}>
            <Selector
              label={"Type"}
              placeholder="Select certificate type"
              options={this.props.conf.parameters.certificateTypes}
              defaultValue={FormatInput(
                this.props.conf.object.parameters.certificateTypeId,
              )}
              onChange={(v) =>
                this.props.onParameterChange("certificateTypeId", v.value)
              }
            />
          </Col>
        </Row>
        <Row className="section-field">
          <Col md={12}>
            <Selector
              label={"Validity Period"}
              placeholder="Number of validity years"
              options={this.getPeriods()}
              defaultValue={FormatInput(
                this.props.conf.object.parameters.periodId,
              )}
              onChange={(v) =>
                this.props.onParameterChange("periodId", v.value)
              }
            />
          </Col>
        </Row>
        <Row className="section-field">
          <Col md={12}>
            <InputWithLabel
              label={"Comments"}
              type="textarea"
              rows="7"
              placeholder="Enter comments here"
              defaultValue={FormatInput(
                this.props.conf.object.parameters.comments,
              )}
              onChange={(e) =>
                this.props.onParameterChange("comments", e.target.value)
              }
            />
          </Col>
        </Row>
      </Container>,
    );

    let periodNote = this.getPeriodNote();
    let commentsNote = this.getCommentsNote();

    if (periodNote != null) {
      items.push(periodNote);
    }

    if (commentsNote != null) {
      items.push(commentsNote);
    }

    return items;
  }
}
