import React, { Component } from "react";
import EditDialog from "../common/Dialog";
import { Col, Row } from "react-bootstrap";

import styled from "styled-components";
import { InputLabel, InputWithLabel } from "../common/theme/Theme";
import Colors from "../common/theme/Colors";
import { PortsAlert } from "../common/PortsAlert";

const StyledEditDialog = styled(EditDialog)`
  .custom-col-left {
    padding-right: 0;
  }
  .custom-col-right {
    padding-left: 0;
  }

  .file-label {
    padding-top: 5px;
    padding-bottom: 5px;
    display: block;
  }

  .file-name-label {
    border: none;
    border-bottom: 1px solid ${Colors.primary};
    box-shadow: none;
    border-radius: 0;
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    color: ${Colors.tertiary};
    height: 34px;
    padding: 6px 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .custom-file-label {
    border: 1px solid ${Colors.border};
    border-radius: 3px;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    float: right;
    font-weight: 400;
    font-size: 14px;
  }
  .custom-file-input {
    display: none;
  }
`;

class DocumentDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      document: null,
      allowedTypes: null,
      errorMessage: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.show &&
      prevProps.show !== this.props.show &&
      this.props.conf
    ) {
      this.setState({
        document: this.props.conf.object,
        allowedTypes: this.getAllowedTypeString(
          this.props.conf.supportedFileTypes,
        ),
      });
    }
  }

  getAllowedTypeString = (types) => {
    let list = [];
    if (!types) {
      return "";
    }
    types.forEach((item) => list.push("." + item));
    return list.join(", ");
  };

  getField = (field) => {
    return this.state.document[field];
  };

  updateField = (field, value) => {
    let document = this.state.document;
    document[field] = value;
    this.setState({ document: document });
  };

  updateFile = (file) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onloadstart = () => {
      this.updateField("fileName", "Loading...");
    };
    reader.onloadend = () => {
      this.updateField("fileBody", btoa(reader.result));
      this.updateField("fileName", file.name.trim());
    };
  };

  onFieldChange = (field) => {
    switch (field) {
      case "name":
        return (e) => {
          this.updateField("name", e.target.value.trim());
        };
      case "file":
        return (e) => {
          const file = e.target.files[0];
          this.updateFile(file);
        };
      default:
        console.error("Unexpected field: " + field);
    }
  };

  getBody = () => {
    return [
      <Row key="name">
        <Col md={12}>
          <InputWithLabel
            label={"Name"}
            placeholder="Leave this empty to use the name of the file"
            name={"name"}
            onChange={this.onFieldChange("name")}
            defaultValue={this.getField("name")}
          />
        </Col>
      </Row>,
      <Row key="file">
        <Col md={12}>
          <InputLabel className="file-label">File</InputLabel>
        </Col>
        <Col md={9} className={"custom-col-left"}>
          <label className="file-name-label">
            {this.state.document.fileName}
          </label>
        </Col>
        <Col md={3} className={"custom-col-right"}>
          <input
            type="file"
            className="custom-file-input"
            id="files"
            onChange={this.onFieldChange("file")}
            accept={this.state.allowedTypes}
          />
          <label className="custom-file-label" htmlFor="files">
            Choose File
          </label>
        </Col>
      </Row>,
      <Row key="warning">
        <Col md={12}>
          <PortsAlert variant="warning" style={{ marginTop: "20px" }}>
            <strong>Note:</strong> The maximum accepted file size is 100MB. The
            accepted file extensions are {this.state.allowedTypes}
          </PortsAlert>
        </Col>
      </Row>,
    ];
  };

  onCancel = () => {
    this.setState({
      document: null,
      errorMessage: null,
    });
    this.props.onCancel();
  };

  onSave = () => {
    this.setState({ errorMessage: null });

    let errorMessage;
    if (
      !this.state.document.fileName ||
      (!this.state.document.id && !this.state.document.fileBody)
    ) {
      errorMessage = "File is missing";
      this.setState({ errorMessage: errorMessage });
      return;
    }

    if (!errorMessage) {
      this.props.onSave();
    }
  };

  getErrorMessage = () => {
    if (this.state.errorMessage) {
      return this.state.errorMessage;
    }
    if (this.props.errorMessage) {
      return this.props.errorMessage;
    }
    return null;
  };

  render() {
    if (!this.state.document) {
      return null;
    }

    return (
      <StyledEditDialog
        content={this.getBody()}
        show={this.props.show}
        title={this.state.document.id ? "Edit Document" : "Create Document"}
        onCancel={this.onCancel}
        onConfirm={this.onSave}
        onConfirmLabel="Save"
        submit={this.props.submit}
        errorMessage={this.getErrorMessage()}
      />
    );
  }
}

export default DocumentDialog;
