import React, { Component } from "react";
import { TableBackend, TableFilter } from "../../common/Tables";
import {
  FormatDateMonospace,
  FormatIntegerMonospace,
  FormatOrganisation,
} from "../../common/Utils";
import OrganisationFilter from "../../common/OrganisationFilter";
import { LinkContainer } from "react-router-bootstrap";
import { TableButton } from "../../common/Buttons";

export default class DomainMonitorList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parameters: { accountIds: "", freetext: "" },
    };
  }

  componentDidMount() {
    let views = [
      {
        label: "Home",
        url: "/",
      },
      {
        label: "Watches",
        url: "/monitoring/watches",
      },
      {
        label: "Domain Monitors",
        url: null,
      },
    ];

    this.props.window.setBreadcrumbViews(views);
    this.props.window.setPageTip(null);
  }

  getUrl = (sorting, expanding, parameters) => {
    let url = this.props.session.getModules().monitoring.links.domainmonitors;
    url += "?sort=" + sorting;
    url += expanding ? "" : "&page[limit]=15";
    url += "&accountIdFilter=" + parameters.accountIds;
    url += "&freetextFilter=" + encodeURIComponent(parameters.freetext);
    return url;
  };

  getActionButton = (id) => {
    return (
      <LinkContainer
        to={{
          pathname: "/monitoring/watches/domainmonitorhits",
          state: { domainMonitorId: id },
        }}
      >
        <TableButton
          text="Hits"
          className="secondary"
          prefix="fas"
          icon="bullseye"
        />
      </LinkContainer>
    );
  };

  render() {
    const filters = [
      <OrganisationFilter
        key="organisation-filter"
        session={this.props.session}
        onChange={(o) =>
          this.setState({
            parameters: Object.assign({}, this.state.parameters, {
              accountIds: o,
            }),
          })
        }
      />,
      <TableFilter
        key="table-filter"
        value={this.state.parameters.freetext}
        onChange={(o) =>
          this.setState({
            parameters: Object.assign({}, this.state.parameters, {
              freetext: o,
            }),
          })
        }
      />,
    ];

    const columns = [
      {
        label: "Domain Name",
        name: "description",
        sortable: true,
        contentFunction: (r) => r.description,
      },
      {
        label: "Organisation",
        name: "organisation",
        sortable: true,
        contentFunction: (r) =>
          FormatOrganisation(
            r.organisation,
            this.props.session.getApplication().organisationPrefix,
          ),
      },
      {
        label: "Hits Total",
        name: "hitsTotal",
        style: { textAlign: "right" },
        contentFunction: (r) => FormatIntegerMonospace(r.hitsTotal),
      },
      {
        label: "Hits 30 Days",
        name: "hitsThirtyDays",
        style: { textAlign: "right" },
        contentFunction: (r) => FormatIntegerMonospace(r.hitsThirtyDays),
      },
      {
        label: "Hits 7 Days",
        name: "hitsSevenDays",
        style: { textAlign: "right" },
        contentFunction: (r) => FormatIntegerMonospace(r.hitsSevenDays),
      },
      {
        label: "Last Discovered",
        name: "lastDiscovered",
        style: { textAlign: "right" },
        contentFunction: (r) => FormatDateMonospace(r.lastDiscovered),
      },
      {
        label: null,
        name: "hits",
        style: { width: "150px" },
        contentFunction: (r) => {
          return this.getActionButton(r.id);
        },
      },
    ];

    return (
      <TableBackend
        session={this.props.session}
        window={this.props.window}
        filters={filters}
        columns={columns}
        sorting="description"
        parameters={this.state.parameters}
        urlFunction={this.getUrl}
      />
    );
  }
}
