import React, { Component } from "react";
import { DialogSection, EditDialog } from "../../common/Dialog";
import { Col, Row } from "react-bootstrap";
import Selector from "../../common/Selector";
import { InputWithLabel } from "../../common/theme/Theme";
import { Tabs } from "../../common/Tabs";
import { FormatInput, FormatOrganisation } from "../../common/Utils";
import { PortsAlert } from "../../common/PortsAlert";

class ContactDialog extends Component {
  create;
  options = ["Company", "Person"];

  constructor(props) {
    super(props);
    this.state = {
      conf: null,
      extensions: null,
      selectedOption: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.show &&
      prevProps.show !== this.props.show &&
      this.props.conf
    ) {
      this.setState({
        conf: this.props.conf,
        extensions: this.props.conf.object.contactExtensions,
        selectedOption: this.getSelectedOption(),
      });
      this.create = this.props.conf.object.id === null;
    }
  }

  getSelectedOption = () => {
    const company = "Company";
    const person = "Person";

    if (this.props.conf.object.id === null) {
      return company;
    } else if (this.props.conf.object.companyName === null) {
      return person;
    }
    return company;
  };

  updateField = (field, value) => {
    const conf = this.state.conf;
    conf.object[field] = value;
    this.setState({ conf: conf });
  };

  onFieldChange = (field) => {
    switch (field) {
      case "organisation":
        return (e) => {
          this.updateField("accountId", e.value);
        };
      case "country":
        return (e) => {
          this.updateField("country", e.value);
        };
      default:
        return (e) => {
          this.updateField(field, e.target.value);
        };
    }
  };

  onOptionChange = (changeEvent) => {
    this.updateFieldsToEmpty();
    this.setState({
      selectedOption: changeEvent.target.value,
    });
  };

  updateFieldsToEmpty = () => {
    this.updateField("companyName", "");
    this.updateField("orgNumber", "");
    this.updateField("vatNumber", "");
    this.updateField("personalNumber", "");
  };

  checked = (value) => {
    return !!(this.state.selectedOption && this.state.selectedOption === value);
  };

  getContent = () => {
    let content = [];

    content.push(this.getTabs());
    content.push(this.getNote());

    return content;
  };

  getTabs = () => {
    const tabs = [
      {
        description: "Basic Info",
        panel: this.getConfigurationBody(),
      },
      {
        description: "Parameters",
        panel: this.getExtensionsBody(),
      },
    ];

    return <Tabs tabs={tabs} />;
  };

  getExtensionsBody = () => {
    let body = [];

    if (this.state.conf.contactExtensionConfigurations) {
      this.state.conf.contactExtensionConfigurations.forEach((o) => {
        body.push(
          <Row key={"extensions-" + this.getContactExtensionLabel(o)}>
            <Col md={12}>{this.getExtensionInput(o)}</Col>
          </Row>,
        );
      });
    }

    return body;
  };

  onContactExtensionComboFieldChange = (contactExtensionConfiguration) => {
    return (e) =>
      this.updateContactExtensionField(contactExtensionConfiguration, e.value);
  };

  onContactExtensionFieldChange = (contactExtensionConfiguration) => {
    return (e) =>
      this.updateContactExtensionField(
        contactExtensionConfiguration,
        e.target.value,
      );
  };

  updateContactExtensionField = (contactExtensionConfiguration, value) => {
    this.state.extensions[contactExtensionConfiguration.extension] = value;
    const conf = this.state.conf;
    this.setState({ conf: conf });
  };

  getContactExtensionLabel = (contactExtensionConfiguration) => {
    return (
      contactExtensionConfiguration.extension +
      " (" +
      this.buildExtensionIds(contactExtensionConfiguration) +
      ")"
    );
  };

  buildExtensionIds = (contactExtensionConfiguration) => {
    return contactExtensionConfiguration.extensionIds.join(", ");
  };

  getContactExtensionValue = (contactExtensionConfiguration) => {
    return FormatInput(
      this.state.extensions[contactExtensionConfiguration.extension],
    );
  };

  getExtensionInput = (contactExtensionConfiguration) => {
    if (contactExtensionConfiguration.choices.length == 0) {
      return (
        <InputWithLabel
          label={this.getContactExtensionLabel(contactExtensionConfiguration)}
          placeholder=""
          name={""}
          onChange={this.onContactExtensionFieldChange(
            contactExtensionConfiguration,
          )}
          value={this.getContactExtensionValue(contactExtensionConfiguration)}
        />
      );
    } else if (contactExtensionConfiguration.choices.length == 1) {
      return (
        <InputWithLabel
          label={this.getContactExtensionLabel(contactExtensionConfiguration)}
          placeholder={contactExtensionConfiguration.choices[0].value}
          name={""}
          onChange={this.onContactExtensionFieldChange(
            contactExtensionConfiguration,
          )}
          value={this.getContactExtensionValue(contactExtensionConfiguration)}
        />
      );
    } else {
      return (
        <Selector
          label={this.getContactExtensionLabel(contactExtensionConfiguration)}
          placeholder=""
          options={contactExtensionConfiguration.choices}
          onChange={this.onContactExtensionComboFieldChange(
            contactExtensionConfiguration,
          )}
          defaultValue={this.getContactExtensionValue(
            contactExtensionConfiguration,
          )}
        />
      );
    }
  };

  getConfigurationBody = () => {
    let body = [];
    let options = this.state.conf.accounts.map((o) => {
      return {
        value: o.value,
        label: FormatOrganisation(
          o.label,
          this.props.session.getApplication().organisationPrefix,
        ),
      };
    });

    body.push([
      <Row key="organisation">
        <Col md={12}>
          <Selector
            label={"Organisation"}
            placeholder="Select organisation"
            options={options}
            onChange={this.onFieldChange("organisation")}
            defaultValue={this.state.conf.object["accountId"]}
            tabIndex="1"
          />
        </Col>
      </Row>,
    ]);

    if (this.create) {
      body.push(
        <div key="company-person" className="radio" tabIndex="2">
          <label>
            <input
              type="radio"
              value={this.options[0]}
              onChange={this.onOptionChange}
              checked={this.checked(this.options[0])}
            />
            {this.options[0]}
          </label>
          &nbsp;&nbsp;
          <label>
            <input
              type="radio"
              value={this.options[1]}
              onChange={this.onOptionChange}
              checked={this.checked(this.options[1])}
            />
            {this.options[1]}
          </label>
        </div>,
      );
    }

    let company = this.state.selectedOption === this.options[0];
    body.push([
      <Row key="row-1">
        <Col md={6}>
          <DialogSection title="Company Info">
            <InputWithLabel
              label={"Company Name"}
              placeholder="Enter company name here"
              name={"companyName"}
              onChange={this.onFieldChange("companyName")}
              value={FormatInput(this.state.conf.object["companyName"])}
              disabled={!company}
              tabIndex="3"
            />
            <InputWithLabel
              label={"Organisation Number"}
              placeholder="Enter organisation number here"
              name={"orgNumber"}
              onChange={this.onFieldChange("orgNumber")}
              value={FormatInput(this.state.conf.object["orgNumber"])}
              disabled={!company}
              tabIndex="3"
            />
            <InputWithLabel
              label={"VAT. Number"}
              placeholder="Enter VAT. number here"
              name={"vatNumber"}
              onChange={this.onFieldChange("vatNumber")}
              value={FormatInput(this.state.conf.object["vatNumber"])}
              disabled={!company}
              tabIndex="3"
            />
          </DialogSection>
        </Col>
        <Col md={6}>
          <DialogSection title="Person Info">
            <InputWithLabel
              label={"Person Name"}
              placeholder="Enter person name here"
              name={"personName"}
              onChange={this.onFieldChange("personName")}
              value={FormatInput(this.state.conf.object["personName"])}
              tabIndex="4"
            />
          </DialogSection>
          <InputWithLabel
            label={"Personal Number"}
            placeholder="Enter personal number here"
            name={"personalNumber"}
            onChange={this.onFieldChange("personalNumber")}
            value={FormatInput(this.state.conf.object["personalNumber"])}
            disabled={company}
            tabIndex="4"
          />
        </Col>
      </Row>,

      <Row key="row-5">
        <Col md={6}>
          <DialogSection title="Address Info">
            <InputWithLabel
              label={"Street 1"}
              placeholder="Enter street here"
              name={"street1"}
              onChange={this.onFieldChange("street1")}
              value={FormatInput(this.state.conf.object["street1"])}
              tabIndex="5"
            />
            <InputWithLabel
              label={"Street 2"}
              placeholder="Enter street here"
              name={"street2"}
              onChange={this.onFieldChange("street2")}
              value={FormatInput(this.state.conf.object["street2"])}
              tabIndex="5"
            />
            <InputWithLabel
              label={"Postal Code"}
              placeholder="Enter postal code here"
              name={"postcode"}
              onChange={this.onFieldChange("postalCode")}
              value={FormatInput(this.state.conf.object["postalCode"])}
              tabIndex="5"
            />
            <InputWithLabel
              label={"City"}
              placeholder="Enter city here"
              name={"city"}
              onChange={this.onFieldChange("city")}
              value={FormatInput(this.state.conf.object["city"])}
              tabIndex="5"
            />
            <InputWithLabel
              label={"State / Province"}
              placeholder="Enter state or province here"
              name={"stateProvince"}
              onChange={this.onFieldChange("stateProvince")}
              value={FormatInput(this.state.conf.object["stateProvince"])}
              tabIndex="5"
            />
            <Selector
              label={"Country"}
              placeholder="Select country"
              options={this.state.conf.countries}
              onChange={this.onFieldChange("country")}
              defaultValue={this.state.conf.object["country"]}
              tabIndex="5"
            />
          </DialogSection>
        </Col>
        <Col md={6}>
          <DialogSection title="Contact Info">
            <InputWithLabel
              label={"E-mail"}
              placeholder="Enter e-mail address here"
              name={"email"}
              onChange={this.onFieldChange("email")}
              value={FormatInput(this.state.conf.object["email"])}
              tabIndex="6"
            />
            <InputWithLabel
              label={"Phone"}
              placeholder="Enter phone number here"
              name={"phone"}
              onChange={this.onFieldChange("phone")}
              value={FormatInput(this.state.conf.object["phone"])}
              tabIndex="6"
            />
            <InputWithLabel
              label={"Fax"}
              placeholder="Enter fax number here"
              name={"fax"}
              onChange={this.onFieldChange("fax")}
              value={FormatInput(this.state.conf.object["fax"])}
              tabIndex="6"
            />
          </DialogSection>
        </Col>
      </Row>,
    ]);

    return body;
  };

  getNote = () => {
    if (this.create) {
      return <Row key="warning" />;
    }
    return (
      <Row key="warning">
        <Col md={12} className="note">
          <PortsAlert variant="warning" className="no-margin">
            <strong>Note:</strong> The changes will only take effect on the
            contact object in Abion Core's database, if you want the contact
            information on a domain updated you need to place an owner transfer
            or change contacts order.
          </PortsAlert>
        </Col>
      </Row>
    );
  };

  onCancel = () => {
    this.setState({
      conf: null,
      create: null,
    });
    this.props.onCancel();
  };

  render() {
    if (!this.state.conf) {
      return null;
    }

    let title = this.create ? "Create Contact" : "Edit Contact";

    return (
      <EditDialog
        show={this.props.show}
        title={title}
        content={this.getContent()}
        errorMessage={this.props.errorMessage}
        onCancel={this.onCancel}
        onConfirm={this.props.onConfirm}
        width="md"
        name="contact-details"
        submit={this.props.submit}
      />
    );
  }
}

export default ContactDialog;
