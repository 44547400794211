import React, { Component } from "react";
import EditDialog from "../../common/Dialog";
import Selector from "../../common/Selector";
import { FormatDateTime } from "../../common/Utils";

class SchedulePublishZonesDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      when: null,
    };
    this.onWhenChange = this.onWhenChange.bind(this);
    this.onOk = this.onOk.bind(this);
  }

  onWhenChange(event) {
    this.setState({ when: event.value });
  }

  onOk() {
    this.props.onOk({ when: this.state.when });
  }

  getWhenOptions() {
    const hour = 3600000;
    let date = new Date();
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    let options = [];
    for (let i = 0; i < 14 * 24; i++) {
      date.setTime(date.getTime() + hour);
      let value = date.toISOString();
      let description = FormatDateTime(date);
      options.push({ value: value, label: description });
    }
    return options;
  }

  render() {
    if (this.props.showDialog) {
      const content = [
        <Selector
          key="when"
          name="When"
          options={this.getWhenOptions()}
          placeholder="When"
          onChange={this.onWhenChange}
          required={true}
        />,
      ];
      return (
        <EditDialog
          title="Schedule publication"
          content={content}
          show={this.props.showDialog}
          onCancel={this.props.onCancel}
          onConfirm={this.onOk}
          submit={this.props.submit}
          height="md"
          errorMessage={this.props.errorMessage}
        />
      );
    }
    return "";
  }
}

export default SchedulePublishZonesDialog;
