import React, { Component } from "react";
import { Col } from "react-bootstrap";
import styled from "styled-components";

const DivStyle = styled.div`
  width: 400px;
  margin: 200px auto;
  font-size: 16px;

  > div {
    margin-bottom: 10px;
  }

  > .title {
    font-weight: 600;
  }

  a {
    text-decoration: none;
  }
`;

export default class QuoteItem extends Component {
  render() {
    if (!this.props.data.quote || !this.props.data.quote.text) {
      return null;
    }

    return (
      <Col xs={12}>
        <DivStyle>
          <div className="title">Quote of the Day</div>
          <div className="text">{this.props.data.quote.text}</div>
          <div>
            <a
              href={this.props.data.quote.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {this.props.data.quote.author}
            </a>
          </div>
        </DivStyle>
      </Col>
    );
  }
}
