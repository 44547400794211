import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Selector from "../../common/Selector";
import { InformationField } from "../../common/Information";
import CustomField from "./CustomField";

export default class SubscriptionParameterStep extends Component {
  getFields = () => {
    return this.props.conf.parameters.fields.map((o) => (
      <CustomField
        field={o}
        value={this.props.conf.object.parameters[o.id]}
        onChange={this.props.onParameterChange}
      />
    ));
  };

  render() {
    return (
      <Container fluid={true}>
        <Row className="section-title">
          <Col xs={12}>Parameters</Col>
        </Row>
        <Row className="section-description">
          <Col xs={12}>
            Configure the service by filling out the fields below.
          </Col>
        </Row>
        <Row className="section-field">
          <Col xs={12}>
            <InformationField>
              <Selector
                label={"Invoice Period"}
                placeholder="Select invoice period"
                options={this.props.conf.parameters.invoicePeriods}
                defaultValue={this.props.conf.object.parameters.invoicePeriodId}
                onChange={(v) =>
                  this.props.onParameterChange("invoicePeriodId", v.value)
                }
              />
            </InformationField>
          </Col>
        </Row>
        {this.getFields()}
      </Container>
    );
  }
}
