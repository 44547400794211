export const FormatChangeSummary = (changeSummary) => {
  if (changeSummary != null) {
    const split = changeSummary.toString().split("|");

    const added = "added";
    const removed = "removed";
    const updated = "updated";

    const setting = "setting";
    const record = "record";
    const redirect = "redirect";
    const server = "server";

    return [
      FormatChangeSummaryMember(setting, updated, split[0]),
      FormatChangeSummaryMember(record, added, split[1]),
      FormatChangeSummaryMember(record, removed, split[2]),
      FormatChangeSummaryMember(redirect, added, split[3]),
      FormatChangeSummaryMember(redirect, removed, split[4]),
      FormatChangeSummaryMember(server, added, split[5]),
      FormatChangeSummaryMember(server, removed, split[6]),
    ]
      .filter(Boolean)
      .join(", ");
  }
  return "";
};

const FormatChangeSummaryMember = (type, action, value) => {
  if (parseInt(value) > 0) {
    let s = "";
    if (parseInt(value) > 1) {
      s = "s";
    }

    return value + " " + type + s + " " + action;
  }
  return "";
};
