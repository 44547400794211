import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Redirect, Route, Switch } from "react-router-dom";
import { NavTab } from "react-router-tabs";
import { PageTabsNew } from "../common/PageTabs";
import Icon from "../common/theme/Icons";
import CertificatesTab from "./CertificatesTab";
import DomainsTab from "./DomainsTab";
import TrademarksTab from "./TrademarksTab";
import ServicesTab from "./ServicesTab";
import styled from "styled-components";
import Colors from "../common/theme/Colors";
import { rgba } from "polished";

const StyledDiv = styled.div`
  a.legal {
    background-color: ${rgba(Colors.categoryLegal, 0.15)};
  }

  a.digital {
    background-color: ${rgba(Colors.categoryDigital, 0.15)};
  }

  a.technical {
    background-color: ${rgba(Colors.categoryTechnical, 0.15)};
  }

  a.obp {
    background-color: ${rgba(Colors.categoryObp, 0.15)};
  }
`;

class PortfolioPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      activeTab: 1,
    };
  }

  componentDidMount() {
    this.setState({ activeTab: 1, loaded: true });
    let views = [
      {
        label: "Home",
        url: "/",
      },
      {
        label: "Portfolio",
        url: null,
      },
    ];

    this.props.window.setBreadcrumbViews(views);
    this.props.window.setPageTip(null);
  }

  handleTabSelect = (event) => {
    const clickedTab = event.target.getAttribute("data-tab");
    this.setState({ activeTab: clickedTab });
  };

  render() {
    const trademarks = () => {
      return (
        <TrademarksTab
          session={this.props.session}
          window={this.props.window}
        />
      );
    };
    const legalServices = () => {
      return (
        <ServicesTab
          session={this.props.session}
          window={this.props.window}
          category={"legalServices"}
        />
      );
    };
    const domains = () => {
      return (
        <DomainsTab
          history={this.props.history}
          session={this.props.session}
          window={this.props.window}
        />
      );
    };
    const digitalServices = () => {
      return (
        <ServicesTab
          session={this.props.session}
          window={this.props.window}
          category={"digitalServices"}
        />
      );
    };
    const certificates = () => {
      return (
        <CertificatesTab
          session={this.props.session}
          window={this.props.window}
        />
      );
    };
    const technicalServices = () => {
      return (
        <ServicesTab
          session={this.props.session}
          window={this.props.window}
          category={"technicalServices"}
        />
      );
    };
    const obpServices = () => {
      return (
        <ServicesTab
          session={this.props.session}
          window={this.props.window}
          category={"obpServices"}
        />
      );
    };
    const baseUrl = `/portfolio`;
    return (
      <StyledDiv>
        <Container fluid={true}>
          <Row>
            <Col xs={12} className={"no-padding"}>
              <PageTabsNew startPathWith={baseUrl} className={"nav nav-tabs"}>
                <NavTab
                  className="legal"
                  onClick={this.handleTabSelect}
                  data-tab={1}
                  to="/trademarks"
                >
                  <Icon className={"menu-icon"} name="gavel" />
                  Trademarks
                </NavTab>
                <NavTab
                  className="legal"
                  onClick={this.handleTabSelect}
                  data-tab={2}
                  to="/legalServices"
                >
                  <Icon className={"menu-icon"} name="balance-scale-right" />
                  Legal Services
                </NavTab>
                <NavTab
                  className="digital"
                  onClick={this.handleTabSelect}
                  data-tab={3}
                  to="/domains"
                >
                  <Icon className={"menu-icon"} name="globe" />
                  Domains
                </NavTab>
                <NavTab
                  className="digital"
                  onClick={this.handleTabSelect}
                  data-tab={4}
                  to="/digitalServices"
                >
                  <Icon className={"menu-icon"} name="laptop-code" />
                  Domain Services
                </NavTab>
                <NavTab
                  className="technical"
                  onClick={this.handleTabSelect}
                  data-tab={5}
                  to="/certificates"
                >
                  <Icon className={"menu-icon"} name="shield-alt" />
                  Certificates
                </NavTab>
                <NavTab
                  className="technical"
                  onClick={this.handleTabSelect}
                  data-tab={6}
                  to="/technicalServices"
                >
                  <Icon className={"menu-icon"} name="server" />
                  Web Security Services
                </NavTab>
                <NavTab
                  className="obp"
                  onClick={this.handleTabSelect}
                  data-tab={7}
                  to="/obpServices"
                >
                  <Icon className={"menu-icon"} name="video" />
                  Brand Protection Services
                </NavTab>
              </PageTabsNew>
              <Switch>
                <Route
                  exact={true}
                  path={"/portfolio"}
                  render={() => (
                    <Redirect replace to={"/portfolio/trademarks"} />
                  )}
                />
                <Route path={`${baseUrl}/trademarks`} component={trademarks} />
                <Route
                  path={`${baseUrl}/legalServices`}
                  component={legalServices}
                />
                <Route path={`${baseUrl}/domains`} component={domains} />
                <Route
                  path={`${baseUrl}/digitalServices`}
                  component={digitalServices}
                />
                <Route
                  path={`${baseUrl}/certificates`}
                  component={certificates}
                />
                <Route
                  path={`${baseUrl}/technicalServices`}
                  component={technicalServices}
                />
                <Route
                  path={`${baseUrl}/obpServices`}
                  component={obpServices}
                />
              </Switch>
            </Col>
          </Row>
        </Container>
      </StyledDiv>
    );
  }
}

export default PortfolioPage;
