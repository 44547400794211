import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { InputLabel, InputWithLabel } from "../../common/theme/Theme";
import { FormatInput } from "../../common/Utils";
import AccountField from "./AccountField";

export default class ManualParameterStep extends Component {
  render() {
    return (
      <Container fluid={true}>
        <Row className="section-title">
          <Col md={12}>Parameters</Col>
        </Row>
        <Row className="section-description">
          <Col xs={12}>
            Fill out the fields below to describe the service want you want to
            order.
          </Col>
        </Row>
        <AccountField {...this.props} />
        <Row className="section-field">
          <Col md={12}>
            <InputWithLabel
              label={"Summary"}
              placeholder="Summarize desired service in own sentence or keyword"
              value={FormatInput(this.props.conf.object.parameters.description)}
              onChange={(e) =>
                this.props.onParameterChange("description", e.target.value)
              }
            />
          </Col>
        </Row>
        <Row className="section-field-space">
          <Col md={12}>
            <InputLabel>Details</InputLabel>
            <textarea
              rows="6"
              placeholder="Provide the details of the desired service"
              value={FormatInput(
                this.props.conf.object.parameters.configuration,
              )}
              onChange={(e) =>
                this.props.onParameterChange("configuration", e.target.value)
              }
            />
          </Col>
        </Row>
      </Container>
    );
  }
}
