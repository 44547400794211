import { clearFix } from "polished";
import React, { Component } from "react";
import {
  Col,
  Container,
  OverlayTrigger,
  Popover,
  Row,
  Tooltip,
} from "react-bootstrap";
import styled from "styled-components";
import {
  ActionButton,
  DeleteButton,
  MainActionButton,
} from "../../common/Buttons";
import Icon from "../../common/theme/Icons";
import {
  Header,
  HeaderMeta,
  HeaderMetaIcon,
  HeaderMetaLabel,
  HeaderMetaLastRow,
  HeaderMetaText,
  Organisation,
  PaddedContent,
  TitleWrapper,
  TruncatedTitle,
} from "../../common/theme/Theme";
import ReviewDialog from "./ReviewDialog";
import { FormatDateTime } from "../../common/Utils";
import { InternalLink } from "../../common/Link";

const ZoneButtons = styled.div`
  display: block;
  margin-top: 15px;
`;

const ClearFix = styled.div`
  ${clearFix()}
`;

const StatusMessage = styled(Popover)`
  && {
    white-space: pre;
    max-width: 100%;
    font-size: 11px;
  }
`;

class ZoneHeader extends Component {
  constructor(props) {
    super(props);
    this.startDnsSec = this.startDnsSec.bind(this);
    this.stopDnsSec = this.stopDnsSec.bind(this);
    this.deleteZone = this.deleteZone.bind(this);
    this.state = {
      showZoneReview: false,
    };
  }

  getZoneMeta() {
    let master = this.props.zone.zoneType.value === "MASTER";
    let fields = [];

    let row1 = [];
    let row2 = [];

    if (master) {
      this.appendMasterServices(row1);
      this.appendMasterStatuses(row2);
      this.appendDomain(row2)
    } else {
      this.appendSlaveStatuses(row1);
      this.appendDomain(row1)
    }

    fields.push(<Row>{row1}</Row>);
    if (row2.length > 0) {
      fields.push(<Row>{row2}</Row>)
    }

    return fields;
  }

  appendMasterStatuses(fields) {
    this.appendZoneStatus(fields);
    this.appendPublishStatus(fields);

    fields.push(
      <HeaderMetaLastRow key="dnssec-status" >
        <HeaderMetaLabel>DNSSEC Status</HeaderMetaLabel>
        <HeaderMetaText
          className={this.getDnsSecStatusClassName(
            this.props.zone.dnsSecStatus.value,
          )}
        >
          {this.props.zone.dnsSecStatus.label}
        </HeaderMetaText>
      </HeaderMetaLastRow>
    );
  }

  getDnsSecStatusClassName(value) {
    if (value.startsWith("STARTED") || value.startsWith("STOPPED")) {
      return "dnsSecStatusPending";
    } else if (value !== "DISABLED") {
      return "dnsSecStatusOk";
    } else {
      return "dnsSecStatusDisabled";
    }
  }

  appendDomain(fields) {
    fields.push(
        <HeaderMetaLastRow key="zone-domain" >
          <HeaderMetaLabel>Domain</HeaderMetaLabel>
          {this.getDomain(this.props.zone.domain)}
        </HeaderMetaLastRow>
    );
  }

  getDomain = (domain) => {
    if (domain && domain.accountPrivileged && domain.rolePrivileged) {
      return <InternalLink to= {"/domainadmin/domains/" + domain.id} text={domain.name} className="header-link" />
    } else {
      let text;
      let tooltip;
      if (!domain) {
        text = "N/A";
        tooltip = (<Tooltip style={{ whiteSpace: "pre-line", wordBreak: "break-word" }}>
          Domain does not exist
        </Tooltip>)
      } else if (!domain.accountPrivileged || !domain.rolePrivileged) {
        text = domain.name;
        tooltip = (<Tooltip style={{ whiteSpace: "pre-line", wordBreak: "break-word" }}>
          Your user account does not have privileges to view the domain. Contact your client manager to activate this functionality.
        </Tooltip>)
      }
      return <OverlayTrigger
          placement="bottom"
          overlay={tooltip}>
        <HeaderMetaText className ="lower">{text}</HeaderMetaText>
      </OverlayTrigger>
    }
  }


  appendMasterServices(fields) {
    let dnsType = this.props.zone.dnsType;
    let dnsSecService = this.props.zone.dnsSecService;
    let redirectService = this.props.zone.redirectService;
    let redirectCertificate = this.props.zone.redirectCertificateService;

    const MetaCheck = (props) => {
      return (
        <HeaderMeta key={props.label} >
          <HeaderMetaLabel>{props.label}</HeaderMetaLabel>
          <HeaderMetaIcon
            className={
              props.enabled ? "serviceStatusEnabled" : "serviceStatusDisabled"
            }
          >
            <IconCheck enabled={props.enabled} />
          </HeaderMetaIcon>
        </HeaderMeta>
      );
    };

    const IconCheck = (props) => {
      if (props.enabled) {
        return <Icon name="check" iconsize={20} />;
      } else {
        return <Icon name="times" iconsize={20} />;
      }
    };

    let dnsTypeTooltip = (
      <Tooltip style={{ whiteSpace: "pre-line", wordBreak: "break-word" }}>
        Abion provides Unicast, Anycast and Enterprise levels of DNS service,
        and you can upgrade to a higher level from the Portfolio page
      </Tooltip>
    );
    fields.push(
      <HeaderMeta key="dnsType" >
        <HeaderMetaLabel>DNS Service</HeaderMetaLabel>
        {dnsType ? (
          <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="bottom"
            overlay={dnsTypeTooltip}
          >
            <HeaderMetaText className="dnsType">
              {dnsType}
              <Icon prefix="fa" name="info-circle" iconsize={14} />
            </HeaderMetaText>
          </OverlayTrigger>
        ) : (
          <HeaderMetaIcon className="serviceStatusDisabled">
            <Icon name="times" iconsize={20} />
          </HeaderMetaIcon>
        )}
      </HeaderMeta>
    );

    fields.push(
      <MetaCheck
        key="dnssec-service"
        label={"DNSSEC Service"}
        enabled={dnsSecService}
      />,
    );

    fields.push(
      <MetaCheck
        key="redirect-service"
        label={"Redirect Service"}
        enabled={redirectService}
      />,
    );

    fields.push(
      <MetaCheck
        key="redirect-certificate-service"
        label={"Redirect Certificate"}
        enabled={redirectCertificate}
      />,
    );
  }

  appendSlaveStatuses(fields) {
    this.appendPublishStatus(fields);
  }

  appendZoneStatus(fields) {
    const ZoneStatusText = () => {
      if (this.props.zone.validationErrors.length > 0) {
        const message = (
          <StatusMessage
            id="popover-trigger-hover-focus"
            title="Zone Validation Failed"
          >
            <Popover.Header as="h3">Zone Validation Failed</Popover.Header>
            <Popover.Body>{this.props.zone.validationErrors[0]}</Popover.Body>
          </StatusMessage>
        );
        return (
          <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="bottom"
            overlay={message}
          >
            <HeaderMetaText className="zoneStatusError">
              ERROR
              <Icon prefix="fa" name="info-circle" iconsize={16} />
            </HeaderMetaText>
          </OverlayTrigger>
        );
      } else if (this.props.zone.validationWarnings.length > 0) {
        let title = "Zone Validation Warning";
        if (this.props.zone.validationWarnings.length > 1) {
          title += "s";
        }
        const message = (
          <StatusMessage id="popover-trigger-hover-focus">
            <Popover.Header as="h3">{title}</Popover.Header>
            <Popover.Body>
              {this.props.zone.validationWarnings.join("\n")}
            </Popover.Body>
          </StatusMessage>
        );
        return (
          <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="bottom"
            overlay={message}
          >
            <HeaderMetaText className="zoneStatusWarning">
              WARNING
              <Icon prefix="fa" name="info-circle" iconsize={16} />
            </HeaderMetaText>
          </OverlayTrigger>
        );
      } else {
        return <HeaderMetaText className="zoneStatusOk">OK</HeaderMetaText>;
      }
    };

    fields.push(
      <HeaderMetaLastRow key="zone-status" >
        <HeaderMetaLabel>Zone Status</HeaderMetaLabel>
        <ZoneStatusText />
      </HeaderMetaLastRow>
    );
  }

  appendPublishStatus(fields) {
    const PublishStatusText = () => {
      if (
        this.props.zone.publishStatus.value === "CREATED" ||
        this.props.zone.publishStatus.value === "MODIFIED"
      ) {
        const message = (
          <StatusMessage
            id="popover-trigger-hover-focus"
            title="Zone Publish Needed"
          >
            <Popover.Header as="h3">Zone Publish Needed</Popover.Header>
            <Popover.Body>
              The zone has been modified but the changes have not yet been
              published on the Internet.
              <br />
              Use the "Review and Publish" button to make your changes take
              effect.
            </Popover.Body>
          </StatusMessage>
        );
        return (
          <OverlayTrigger
            trigger={["hover", "focus"]}
            placement="bottom"
            overlay={message}
          >
            <HeaderMetaText className="publishStatusDirty">
              {this.props.zone.publishStatus.label}
              <Icon prefix="fa" name="info-circle" iconsize={16} />
            </HeaderMetaText>
          </OverlayTrigger>
        );
      } else if (
        this.props.zone.publishStatus.value === "CURRENT" ||
        this.props.zone.publishStatus.value === "SCHEDULED"
      ) {
        return (
          <HeaderMetaText className="publishStatusOk">
            {this.props.zone.publishStatus.label}
          </HeaderMetaText>
        );
      } else {
        return (
          <HeaderMetaText className="publishStatusDeleted">
            {this.props.zone.publishStatus.label}
          </HeaderMetaText>
        );
      }
    };

    fields.push(
      <HeaderMetaLastRow key="publish-status" >
        <HeaderMetaLabel>Publish Status</HeaderMetaLabel>
        <PublishStatusText />
        <HeaderMetaText>{FormatDateTime(this.props.zone.scheduledDate)}</HeaderMetaText>
      </HeaderMetaLastRow>
    );
  }

  getDisabledZoneTooltip = (hasRole) => {
    if (!hasRole) {
      return "Your user account does not have privileges to Disable Zone. Contact your client manager to activate this functionality";
    }
  };

  getZoneButtons() {
    let buttons = [];
    const publishStatus = this.props.zone.publishStatus.value;

    //Review and Publish
    buttons.push(
      <MainActionButton
        key="review-publish"
        disabled={publishStatus === "DELETED" || publishStatus === "CURRENT"}
        icon={"paper-plane"}
        color={"primary"}
        prefix={"fas"}
        onClick={this.reviewZone}
        type={"button"}
        text={"Review and Publish"}
      />,
    );
    // DNSSec
    if (
      this.props.zone.dnsSecService ||
      this.props.zone.dnsSecStatus.value !== "DISABLED"
    ) {
      if (this.props.zone.dnsSecStatus.value === "DISABLED") {
        buttons.push(
          <ActionButton
            onClick={this.startDnsSec}
            text="Activate DNSSEC"
            icon="check"
            prefix={"fas"}
            key={"zoneButton-startDnsSec"}
            disabled={
              !this.props.session.hasRole("DNS_ADMIN_EDIT", "DNS_ADMIN_PUBLISH")
            }
            disabledTooltip={
              "Your user account does not have privileges to Activate DNSSEC. Contact your client manager to activate this functionality"
            }
          />,
        );
      } else {
        buttons.push(
          <ActionButton
            onClick={this.stopDnsSec}
            type="button"
            text="Deactivate DNSSEC"
            icon="stop-circle"
            prefix={"fas"}
            key={"zoneButton-stopDnsSec"}
            disabled={this.props.zone.dnsSecStatus.value !== "ENABLED"}
            disabledTooltip="You can't deactive DNSSEC when a process is in progress such as rolling a key, wait until that is done and status becomes Enabled (can take a few days)"
          />,
        );
      }
    }
    //Disable Zone
    if (publishStatus !== "CREATED") {
      buttons.push(
        <DeleteButton
          key="disable-zone"
          disabled={
            !this.props.session.hasRole(
              "DNS_ADMIN_EDIT",
              "DNS_ADMIN_PUBLISH",
            ) || publishStatus === "DELETED"
          }
          disabledTooltip={this.getDisabledZoneTooltip(
            this.props.session.hasRole("DNS_ADMIN_EDIT", "DNS_ADMIN_PUBLISH"),
          )}
          onClick={this.deleteZone}
          icon={"ban"}
          prefix={"fas"}
          type={"button"}
          text={"Disable Zone"}
        />,
      );
    }

    return buttons;
  }

  startDnsSec() {
    const errorFun = (msg) => {
      this.props.window.showErrorFunc(msg);
    };
    const message =
      "Are you sure you want to activate DNSSEC for this zone? A process that takes a few days will be started (but you can update and publish the zone during this process).";
    this.props.window.showConfirmFunc(message, () => {
      const url = this.props.zone.links.startDnsSec;
      this.props.session.backendPost(
        url,
        {},
        (_) => {
          this.props.onChange();
        },
        errorFun,
      );
    });
  }

  stopDnsSec() {
    const errorFun = (msg) => {
      this.props.window.showErrorFunc(msg);
    };
    const message =
      "Are you sure you want to deactivate DNSSEC for this zone? A process that takes a few days will be started (but you can update and publish the zone during this process).";
    this.props.window.showConfirmFunc(message, () => {
      const url = this.props.zone.links.stopDnsSec;
      this.props.session.backendPost(
        url,
        {},
        (_) => {
          this.props.onChange();
        },
        errorFun,
      );
    });
  }

  reviewZone = () => {
    this.setState({ showZoneReview: true });
  };

  closeReview = (reload) => {
    this.setState({ showZoneReview: false });
    if (reload === true) {
      this.props.onChange();
    }
  };

  deleteZone() {
    const errorFun = (msg) => {
      this.props.window.showErrorFunc(msg);
    };
    const callback = () => {
      this.props.window.showSuccessFunc(
        "Zone successfully disabled. To enable your zone again you can Publish the Zone or Restore it.",
      );
      this.props.onChange("table");
    };
    const message =
      "Are you sure you want to disable this zone? All your records and Zone data will be wiped clean.";
    this.props.window.showConfirmFunc(message, () => {
      const url = this.props.zone.links.self;
      this.props.session.backendDelete(url, callback, errorFun);
    });
  }

  render() {
    return (
      <div>
        <ReviewDialog
          session={this.props.session}
          zone={this.props.zone}
          window={this.props.window}
          show={this.state.showZoneReview}
          onClose={this.closeReview}
        />
        <Header>
          <Container fluid>
            <Row>
              <Col xs={6}>
                <Row>
                  <TitleWrapper>
                    <TruncatedTitle
                      data={this.props.title}
                      isTitle={true}
                      breakAfter={50}
                    ></TruncatedTitle>
                    <Organisation>{this.props.org}</Organisation>
                  </TitleWrapper>
                </Row>
                <Row>
                  <Col>
                    <ClearFix />
                    <ZoneButtons>{this.getZoneButtons()}</ZoneButtons>
                  </Col>
                </Row>
              </Col>
              <Col xs={6}>
                <PaddedContent padding={"0px 20px 0 0"}>
                  {this.getZoneMeta()}
                </PaddedContent>
              </Col>
            </Row>
          </Container>
          <ClearFix />
        </Header>
      </div>
    );
  }
}

export default ZoneHeader;
