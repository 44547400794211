import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import CertificateList from "./CertificateList";

class CertAdmin extends Component {
  render() {
    return (
      <Switch>
        <Route
          exact={true}
          path="/certadmin/certificates"
          render={(props) => (
            <CertificateList
              session={this.props.session}
              window={this.props.window}
              {...props}
            />
          )}
        />
      </Switch>
    );
  }
}

export default CertAdmin;
