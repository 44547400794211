import React, { Component } from "react";
import EditDialog from "../../common/Dialog";
import Selector from "../../common/Selector";
import { InputWithLabel } from "../../common/theme/Theme";

class RedirectDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conf: null,
    };
    this.updateField = this.updateField.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.conf && prevProps.conf !== this.props.conf) {
      this.setState({ conf: this.props.conf });
    }
  }

  updateField(field, value) {
    const conf = this.state.conf;
    conf.object[field] = value;
    this.setState({ conf: conf });
  }

  onClose = () => {
    this.setState({ conf: null });
    this.props.closeDialog();
  };

  render() {
    if (!this.state.conf) {
      return null;
    }

    const options = [
      { label: "Yes", value: true },
      { label: "No", value: false },
    ];

    const elements = [
      <Selector
        label={"Type"}
        name="redirectType"
        options={this.state.conf.redirectTypes}
        placeholder="Type"
        defaultValue={this.state.conf.object.redirectType}
        onChange={(selection) => this.updateField("redirectType", selection)}
        required={true}
      />,
      <InputWithLabel
        label={"Source"}
        type="text"
        name="source"
        placeholder="Source"
        value={this.state.conf.object.source || ""}
        onChange={(e) => this.updateField("source", e.target.value)}
        required={true}
      />,
      <InputWithLabel
        label={"Destination"}
        type="text"
        name="destination"
        placeholder="Destination"
        value={this.state.conf.object.destination || ""}
        onChange={(e) => this.updateField("destination", e.target.value)}
        required={true}
      />,
      <Selector
        label={"Managed Certificate"}
        name="certificate"
        options={options}
        placeholder="Managed Certificate"
        defaultValue={this.state.conf.object.certificate}
        onChange={(selection) =>
          this.updateField("certificate", selection.value)
        }
        required={true}
      />,
    ];
    return (
      <EditDialog
        content={elements}
        show={this.props.showDialog}
        title={(this.state.conf.object.id ? "Edit" : "Create") + " Redirect"}
        onCancel={this.onClose}
        onConfirm={this.props.handleSubmit}
        submit={this.props.submit}
        errorMessage={this.props.errorMessage}
      />
    );
  }
}

export default RedirectDialog;
