import React, { Component } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import styled from "styled-components";
import Colors from "./theme/Colors";

const LContainer = styled(LinkContainer)`
  background-color: initial !important;
  border-color: transparent !important;
  padding: 0px !important;

  &:hover {
    text-decoration: underline !important;
    color: ${Colors.secondary} !important;
    border-color: transparent !important;
    background-color: initial !important;
    padding: 0px !important;
  }
`;

const StyledButton = styled(Button)`
  background-color: initial !important;
  color: ${Colors.link} !important;
  border-color: transparent !important;
  padding: 0px !important;

  &:hover {
    text-decoration: underline !important;
    color: ${Colors.link} !important;
    border-color: transparent !important;
    background-color: initial !important;
    padding: 0px !important;
  }
`;

const StyledTableLinkButton = styled(Button)`
  background-color: initial !important;
  border-color: transparent !important;
  text-decoration: underline !important;
  padding: 0px !important;

  &:hover {
    text-decoration: underline !important;
    border-color: transparent !important;
    background-color: initial !important;
    padding: 0px !important;
  }
`;

class ExternalLink extends Component {
  getTooltip = (tooltip) => {
    return (
      <Tooltip id="tooltip" style={{ position: "fixed" }}>
        {tooltip}
      </Tooltip>
    );
  };

  render() {
    let button = (
      <a
        onClick={this.props.onClick}
        href={this.props.to}
        target={this.props.target}
        {...this.props}
      >
        {this.props.text}
      </a>
    );

    if (this.props.tooltip) {
      button = (
        <OverlayTrigger
          overlay={this.getTooltip(this.props.tooltip)}
          placement="bottom"
          delay={500}
        >
          {button}
        </OverlayTrigger>
      );
    }

    return button;
  }
}

class InternalLink extends Component {
  render() {
    return (
      <LContainer to={this.props.to} onClick={this.props.onClick}>
        <a href="dummy" className={this.props.className}>
          {this.props.text}
        </a>
      </LContainer>
    );
  }
}

class InternalLinkChild extends Component {
  render() {
    return <LContainer to={this.props.to}>{this.props.children}</LContainer>;
  }
}

class Link extends Component {
  render() {
    return (
      <StyledButton onClick={this.props.onClick} style={this.props.style}>
        {this.props.children}
      </StyledButton>
    );
  }
}

class TableLink extends Component {
  render() {
    return (
      <StyledTableLinkButton
        onClick={this.props.onClick}
        style={this.props.style}
        className="table-link"
      >
        {this.props.children}
      </StyledTableLinkButton>
    );
  }
}

export { ExternalLink, InternalLink, Link, InternalLinkChild, TableLink };
