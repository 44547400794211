import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import Icon from "../../common/theme/Icons";
import {
  FormatDiscountDescriptionMonospace,
  FormatFloatMonospace,
} from "../../common/Utils";
import Colors from "../../common/theme/Colors";

const StyledContainer = styled(Container)`
  .line-header-row {
    margin-top: 20px !important;
  }

  .total-row-top {
    margin-top: 20px !important;
  }

  .price-col {
    text-align: right;
    padding-right: 0;
  }

  .discount-col {
    text-align: right;
    padding-left: 5px;
    padding-right: 30px;
  }

  .line-price {
    font-weight: bold;
  }

  .total-label,
  .total-price,
  .total-credits {
    font-weight: bold;
    background-color: ${Colors.bodyBackground};
  }

  .single-row {
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;

export default class SummaryStep extends Component {
  isPriced = () => {
    return this.props.conf.price.amount != null;
  };

  getLines = () => {
    let lines = [];

    for (let i = 0; i < this.props.conf.price.lines.length; i++) {
      lines.push(this.getLine(this.props.conf.price.lines[i]));
    }

    return lines;
  };

  getLine = (line) => {
    let items = [];
    let baseAmount = null;
    let period = "";
    let discountDescription = null;

    for (let i = 0; i < line.parts.length; i++) {
      if (line.parts[i].description == null) {
        if (line.parts[i].amount != null) {
          if (baseAmount == null) {
            baseAmount = 0;
            discountDescription = line.parts[i].discountDescription;
          }
          baseAmount += line.parts[i].amount;
        }
      }
    }

    if (line.period > 0) {
      if (line.period % 12 === 0) {
        period = line.period / 12 + " years";
      } else {
        period = line.period + " months";
      }

      if (line.otherPeriod) {
        period += " (" + line.otherPeriod + ")";
      }
    }

    items.push(
      <Row key="class-name" className="section-field line-header-row">
        <Col xs={4}>{line.description}</Col>
        <Col xs={2}>{period}</Col>
        <Col xs={3}>Base Price:</Col>
        <Col xs={2} className="price-col">
          {FormatFloatMonospace(baseAmount, this.props.conf.price.currency)}
        </Col>
        <Col xs={1} className="discount-col">
          {FormatDiscountDescriptionMonospace(discountDescription)}
        </Col>
      </Row>,
    );

    for (let i = 0; i < line.parts.length; i++) {
      if (line.parts[i].description != null) {
        items.push(this.getPart(line.parts[i]));
      }
    }

    items.push(
      <Row key="section-field" className="section-field">
        <Col xs={4}></Col>
        <Col xs={2}></Col>
        <Col xs={3}>Line Price:</Col>
        <Col xs={2} className="price-col line-price">
          {FormatFloatMonospace(line.amount, this.props.conf.price.currency)}
        </Col>
      </Row>,
    );

    return items;
  };

  getPart = (part) => {
    let period = part.period == null ? null : <div>({part.period})</div>;

    return (
      <Row key="inner-section" className="section-field">
        <Col xs={4}></Col>
        <Col xs={2}></Col>
        <Col xs={3}>
          {part.description}:{period}
        </Col>
        <Col xs={2} className="price-col">
          {FormatFloatMonospace(part.amount, this.props.conf.price.currency)}
        </Col>
        <Col xs={1} className="discount-col">
          {FormatDiscountDescriptionMonospace(part.discountDescription)}
        </Col>
      </Row>
    );
  };

  getTotal = () => {
    let rows = [];
    if (!this.props.conf.price.hasVat) {
      rows.push(
        <Row key="total" className="section-field total-row-top">
          <Col xs={4}></Col>
          <Col xs={2}></Col>
          <Col
            xs={3}
            className={
              "total-label " +
              (this.props.conf.price.totalCredits ? "" : "single-row")
            }
          >
            Total Price:
          </Col>
          <Col
            xs={2}
            className={
              "price-col total-price " +
              (this.props.conf.price.totalCredits ? "" : "single-row")
            }
          >
            {FormatFloatMonospace(
              this.props.conf.price.totalAmount,
              this.props.conf.price.currency,
            )}
          </Col>
        </Row>,
      );
    } else {
      rows.push(
        <Row key="price" className="section-field total-row-top">
          <Col xs={4}></Col>
          <Col xs={2}></Col>
          <Col xs={3} className="total-label">
            Price (excl. VAT):
          </Col>
          <Col xs={2} className="price-col total-price">
            {FormatFloatMonospace(
              this.props.conf.price.amount,
              this.props.conf.price.currency,
            )}
          </Col>
        </Row>,
        <Row key="vat" className="section-field">
          <Col xs={4}></Col>
          <Col xs={2}></Col>
          <Col xs={3} className="total-label">
            VAT:
          </Col>
          <Col xs={2} className="price-col total-price">
            {FormatFloatMonospace(
              this.props.conf.price.vatAmount,
              this.props.conf.price.currency,
            )}
          </Col>
        </Row>,
        <Row key="total-price" className="section-field">
          <Col xs={4}></Col>
          <Col xs={2}></Col>
          <Col xs={3} className="total-label">
            Total Price:
          </Col>
          <Col xs={2} className="price-col total-price">
            {FormatFloatMonospace(
              this.props.conf.price.totalAmount,
              this.props.conf.price.currency,
            )}
          </Col>
        </Row>,
      );
    }
    if (this.props.conf.price.totalCredits) {
      rows.push(
        <Row className="section-field">
          <Col xs={4}></Col>
          <Col xs={2}></Col>
          <Col xs={3} className="total-label">
            Total Credits:
          </Col>
          <Col xs={2} className="price-col total-credits">
            {FormatFloatMonospace(
              this.props.conf.price.totalCredits,
              "Credits",
            )}
          </Col>
          <Col xs={1} className="discount-col">
            {FormatDiscountDescriptionMonospace(
              this.props.conf.price.creditsDiscountDescription,
            )}
          </Col>
        </Row>,
      );
    }

    return rows;
  };

  getBodyPriced = () => {
    let rows = [];

    rows.push(
      <Row key="section-description" className="section-description">
        <Col xs={12}>
          Please review summary and pricing below and then confirm by clicking
          the place order button. To see the details of this order again or to
          change something, use the Previous button to go back to previous
          steps.
        </Col>
      </Row>,
      <Row key="section-columns" className="section-title">
        <Col xs={4}>Description</Col>
        <Col xs={2}>Period</Col>
        <Col xs={5}>Price</Col>
      </Row>,
      this.getLines(),
      this.getTotal(),
    );

    return rows;
  };

  getBodyNotPriced = () => {
    let rows = [];

    rows.push(
      <Row key="bod-not-priced-1" className="section-description">
        <Col xs={12}>
          To review or change the details of this order, use the Previous button
          to return to the previous steps.
        </Col>
      </Row>,
      <Row key="bod-not-priced-2" className="section-description">
        <Col xs={12}>
          After the order is placed, Abion will contact you to collect the
          remaining information and provide a detailed pricing quote for the
          service.
        </Col>
      </Row>,
      <Row key="bod-not-priced-3" className="section-title">
        <Col xs={12}>Description</Col>
      </Row>,
    );

    for (let i = 0; i < this.props.conf.price.lines.length; i++) {
      rows.push(
        <Row key={"description-" + i} className="section-field line-header-row">
          <Col xs={12}>{this.props.conf.price.lines[i].description}</Col>
        </Row>,
      );
    }

    return rows;
  };

  render() {
    return (
      <StyledContainer fluid={true}>
        <Row className="section-message">
          <Col xs={12}>
            <Icon
              color="warning"
              iconsize="40"
              prefix="fas"
              name="check-circle"
            />
            <span>You are ready to place the order!</span>
          </Col>
        </Row>
        <Row className="section-title">
          <Col xs={12}>Summary</Col>
        </Row>
        {this.isPriced() ? this.getBodyPriced() : this.getBodyNotPriced()}
      </StyledContainer>
    );
  }
}
