import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Selector from "../../common/Selector";

export default class PaymentMethodStep extends Component {
  getField = () => {
    if (this.props.conf.parameters.paymentMethods == null) {
      return null;
    } else {
      return (
        <Row className="section-field">
          <Col xs={12}>
            <Selector
              label={"Payment Method"}
              placeholder="Select payment method"
              options={this.props.conf.parameters.paymentMethods}
              defaultValue={this.props.conf.object.parameters.paymentMethodId}
              onChange={(v) =>
                this.props.onParameterChange("paymentMethodId", v.value)
              }
            />
          </Col>
        </Row>
      );
    }
  };

  render() {
    return (
      <Container fluid={true}>
        <Row className="section-title">
          <Col xs={12}>Payment Method</Col>
        </Row>
        <Row className="section-description">
          <Col xs={12}>Choose the payment method.</Col>
        </Row>
        {this.getField()}
      </Container>
    );
  }
}
