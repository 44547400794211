import React, { Component } from "react";
import styled from "styled-components";
import { TableNavigationButton } from "./Buttons";
import {
  Col,
  Container,
  InputGroup,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { BorderColor, FilterInput, FilterInputAddon } from "./theme/Theme";
import Colors from "./theme/Colors";

/*
  Documentation for tables

  
  TableBase:

  const columns = [
    {
      name: "zonename"
      label: "Zone",
      sortable: true,
      style: {textAlign: "right"...},
      contentFunction: r => r.footer ? <b>r.zoneName</b> : r.zoneName
      footerFunction:
    }
  ];

  <TableBase
    loading={false}
    list={false}
    actions={[]}
    filters={[]}
    columns={columns}
    rows={[]}
    footer{[]}
    rowCount={0/null} // null hides text "showing x of y"
    sorting="-organisation"
    expanding={false}
    onSort={(sorting => {})}
    onExpand={(expanded) => {}}
  />

  
  TableFrontend:

  const columns = [
    {
      name: "zonename"
      label: "Zone",
      sortable: true,
      filterable: true,
      contentFunction: r => r.zoneName
      sortFunction: r => r.zoneName,
      filterFunction: r => r.zoneName
    }
  ];

  <TableFrontend
    session={this.props.session}
    window={this.props.window}
    list={false}
    actions={[]}
    filterable={true}
    columns={columns}
    sorting={"-zonename"}
    rows={[]} (either rows or url)
    url={""}
    responseFunction={(response) => {return []}}
    reload={token} // reload if token changes
  />


  TableBackend:

  const columns = [
    {
      name: "zonename"
      label: "Zone",
      sortable: true,
      contentFunction: r => r.zoneName
    }
  ];

  <TableBackend
    session={this.props.session}
    window={this.props.window}
    list={false}
    actions={[]}
    filters={[]}
    columns={columns}
    sorting={"-nisse"}
    parameters={{freetext: "", active: true}}
    urlFunction={(sorting,expanding,parameters) => {return url}}
    responseFunction={(response) => {return {objects: [], totalCount: 0}}}
  />

*/

const TableStyle = styled.div`
  > .container-fluid > .header {
    padding: 15px 0px 10px 0px;
    background: ${Colors.tableBackground};
    border-left: 1px solid ${BorderColor};
    border-right: 1px solid ${BorderColor};
    border-top: 1px solid ${BorderColor};
  }

  > .container-fluid > .header-empty-list {
    border-bottom: 1px solid ${BorderColor};
  }

  > .container-fluid > .footer {
    padding: 20px 0px 10px 0px;

    .footer-text {
      font-size: 16px;
    }
  }

  > .container-fluid > .row > table {
    width: 100%;
    table-layout: fixed;
    word-wrap: break-word;
    background: ${Colors.tableBackground};
    border: 1px solid ${BorderColor};

    > thead {
      th {
        font-weight: 600;
        font-size: 16px;
        padding: 10px 15px 10px 15px;
        vertical-align: top;
      }

      .sortable {
        cursor: pointer;
      }

      .sorted-asc:after {
        content: "▲";
        margin-left: 5px;
      }

      .sorted-desc:after {
        content: "▼";
        margin-left: 5px;
      }
    }

    > tbody {
      > tr:hover {
        background: ${Colors.tableHoverBackground};
      }

      > tr > td {
        padding: 7px 15px 7px 15px;
        border-top: 1px solid ${BorderColor};
        vertical-align: top;
      }
    }

    > tfoot {
      > tr > td {
        padding: 7px 15px 7px 15px;
        border-top: 1px solid ${BorderColor};
        vertical-align: top;
        font-weight: 700;
      }
    }
  }

  > .container-fluid > .list-row {
    padding: 15px 0px 10px 0px;
    background: ${Colors.tableBackground};
    border-left: 1px solid ${BorderColor};
    border-right: 1px solid ${BorderColor};
    border-bottom: 1px solid ${BorderColor};
  }

  > .container-fluid > .list-row-first {
    border-top: 1px solid ${BorderColor};
  }

  .table-filter {
    display: inline-block;
    width: 280px;

    &:focus {
      box-shadow:
        inset 0 1px 1px rgb(0 0 0 / 8%),
        0 0 8px rgb(102 175 233 / 60%);
    }
  }

  .table-link {
    text-decoration: underline;
    color: ${Colors.tertiary};
    font-weight: 600;
  }

  .table-action-section {
    float: left;
  }

  .table-filter-section {
    float: right;
  }
`;

export class TableFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timerId: null,
      reportedValue: "",
      changeValue: "",
      changeTime: 0,
    };
  }

  componentDidMount() {
    let timerId = setInterval(this.onTimer, 100);
    this.setState({ timerId: timerId });
  }

  componentWillUnmount() {
    let timerId = this.state.timerId;
    if (timerId) {
      clearInterval(timerId);
    }
  }

  onChange = (event) => {
    this.setState({
      changeValue: event.target.value,
      changeTime: new Date().getTime(),
    });
  };

  onTimer = () => {
    const { reportedValue, changeValue, changeTime } = this.state;
    const now = new Date().getTime();
    if (now - changeTime > 300 && reportedValue !== changeValue) {
      this.setState({ reportedValue: changeValue });
      this.props.onChange(changeValue.trim());
    }
  };

  render() {
    return (
      <div className="table-filter">
        <InputGroup>
          <FilterInputAddon>Filter</FilterInputAddon>
          <FilterInput
            placeholder={"Start typing to filter rows..."}
            onChange={this.onChange}
          />
        </InputGroup>
      </div>
    );
  }
}

export class TableBase extends Component {
  appendHeader = (parts) => {
    let hasActions = this.props.actions && this.props.actions.length;
    let hasFilters = this.props.filters && this.props.filters.length;
    let className =
      "header" +
      (this.props.list && this.props.rows.length === 0
        ? " header-empty-list"
        : "");

    if (hasActions && hasFilters) {
      parts.push(
        <Row key="table-menu" className={className}>
          <Col xs={12}>
            <div className="table-action-section">{this.props.actions}</div>
            <div className="table-filter-section">{this.props.filters}</div>
          </Col>
        </Row>,
      );
    } else if (hasActions) {
      parts.push(
        <Row key="table-actions" className={className}>
          <Col xs={12}>{this.props.actions}</Col>
        </Row>,
      );
    } else if (hasFilters) {
      parts.push(
        <Row key="table-filters" className={className}>
          <Col xs={12} className="text-right">
            {this.props.filters}
          </Col>
        </Row>,
      );
    }
  };

  getTable = () => {
    let tableHeader;
    let visibleColumns = this.props.columns.filter(
      (column) =>
        !column.showColumnIfAnyRowPredicate ||
        this.props.rows.find((row) => column.showColumnIfAnyRowPredicate(row)),
    );
    if (!this.props.noTableHeader) {
      tableHeader = this.getTableHeader(visibleColumns);
    }
    let tableBody = this.getTableBody(visibleColumns);
    let tableFooter = this.getTableFooter(visibleColumns);

    return (
      <Row key="get-table">
        <table>
          {tableHeader}
          {tableBody}
          {tableFooter}
        </table>
      </Row>
    );
  };

  getTableHeader = (visibleColumns) => {
    return <thead>{this.getTableHeaderRow(visibleColumns)}</thead>;
  };

  getTableHeaderRow = (visibleColumns) => {
    return <tr>{visibleColumns.map((o) => this.getTableHeaderColumn(o))}</tr>;
  };

  getTableHeaderColumn = (column) => {
    let headerColumn;
    if (column.sortable) {
      let className = "sortable";
      if (column.name === this.props.sorting) {
        className += " sorted-asc";
      } else if ("-" + column.name === this.props.sorting) {
        className += " sorted-desc";
      }
      headerColumn = (
        <th
          key={"header-" + column.label}
          onClick={() => this.props.onSort(column.name)}
          className={className}
          style={column.style}
        >
          {column.label}
        </th>
      );
    } else {
      headerColumn = (
        <th key={"header-" + column.label} style={column.style}>
          {column.label}
        </th>
      );
    }

    if (column.tooltip) {
      headerColumn = (
        <OverlayTrigger
          key={"header-" + column.label}
          overlay={
            <Tooltip style={{ position: "fixed" }}>{column.tooltip}</Tooltip>
          }
          placement="bottom"
          delay={200}
        >
          {headerColumn}
        </OverlayTrigger>
      );
    }

    return headerColumn;
  };

  getTableBody = (visibleColumns) => {
    return (
      <tbody>
        {this.props.rows.map((o, index) =>
          this.getTableBodyRow(o, visibleColumns, index),
        )}
      </tbody>
    );
  };

  getTableBodyRow = (row, visibleColumns, index) => {
    let rowData = visibleColumns.map((o) => this.getTableBodyColumn(o, row));

    let key;

    if (!row.id) {
      key = "row-" + index;
    } else {
      key = "row-" + row.id;
    }

    if (this.props.rowClassFunction) {
      let result = this.props.rowClassFunction(row);

      if (result) {
        return (
          <tr key={key} className={result}>
            {rowData}
          </tr>
        );
      }
    }
    return <tr key={key}>{rowData}</tr>;
  };

  getTableBodyColumn = (column, row) => {
    return (
      <td key={row.id + "-" + column.label} style={column.style}>
        {this.getContent(column, row)}
      </td>
    );
  };

  getTableFooter = (visibleColumns) => {
    if (this.props.footerRows) {
      return (
        <tfoot>
          {this.props.footerRows.map((o, index) =>
            this.getTableFooterRow(o, visibleColumns, index),
          )}
        </tfoot>
      );
    }
  };

  getTableFooterRow = (row, visibleColumns, index) => {
    let rowData = visibleColumns.map((o) => this.getTableFooterColumn(o, row));

    let key;

    if (!row.id) {
      key = "footer-row-" + index;
    } else {
      key = "footer-row-" + row.id;
    }
    return <tr key={key}>{rowData}</tr>;
  };

  getTableFooterColumn = (column, row) => {
    return (
      <td key={"footer-" + row.id + "-" + column.label} style={column.style}>
        {this.getContent(column, row)}
      </td>
    );
  };

  getList = () => {
    return this.props.rows.map((row, i) => this.getListRow(row, i));
  };

  getListRow = (row, index) => {
    let className = "list-row" + (index === 0 ? " list-row-first" : "");
    return (
      <Row key={index} className={className}>
        {this.getContent(this.props.columns[0], row)}
      </Row>
    );
  };

  appendFooter = (parts) => {
    let col1 = [];
    let col2 = [];
    let col3 = [];

    if (this.props.loading) {
      col1.push(
        <div key="loading" className="footer-text">
          Loading, please wait...
        </div>,
      );
    }

    if (
      this.props.rowCount != null &&
      (this.props.rows.length !== 0 || !this.props.loading)
    ) {
      col2.push(
        <div key="rows" className="footer-text">
          Showing {this.props.rows.length} of {this.props.rowCount} rows
        </div>,
      );
    }

    if (this.props.expanding) {
      col3.push(
        <TableNavigationButton
          key="show-less"
          onClick={() => this.props.onExpand(false)}
          text="Show Less"
        />,
      );
    } else if (
      this.props.rowCount != null &&
      this.props.rowCount > this.props.rows.length
    ) {
      col3.push(
        <TableNavigationButton
          key="show-all"
          onClick={() => this.props.onExpand(true)}
          text="Show All"
        />,
      );
    }

    parts.push(
      <Row key="footer" className="footer">
        <Col xs={4}>{col1}</Col>
        <Col xs={4} className="text-center">
          {col2}
        </Col>
        <Col xs={4} className="text-right">
          {col3}
        </Col>
      </Row>,
    );
  };

  getContent = (column, row) => {
    if (column.contentFunction) {
      return column.contentFunction(row);
    } else {
      return row[column.name];
    }
  };

  render() {
    let parts = [];

    this.appendHeader(parts);

    if (this.props.list) {
      parts.push(this.getList());
    } else {
      parts.push(this.getTable());
    }

    this.appendFooter(parts);

    return (
      <TableStyle>
        <Container fluid={true}>{parts}</Container>
      </TableStyle>
    );
  }
}

const StyledFilter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export class TableFrontend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      allRows: [],
      currentRows: [],
      filter: this.props.filter,
      sorting: this.props.sorting,
    };
  }

  componentDidMount() {
    this.reload();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.reload) !== JSON.stringify(this.props.reload)
    ) {
      this.reload();
    } else if (this.props.url && prevProps.url !== this.props.url) {
      this.reload();
    } else if (
      !this.props.url &&
      JSON.stringify(prevProps.rows) !== JSON.stringify(this.props.rows)
    ) {
      this.reload();
    }
  }

  reload = () => {
    this.setState({ loading: true });

    if (this.props.url) {
      this.props.session.backendGet(
        this.props.url,
        (response) => {
          this.reloadHelper(response);
        },
        (errorMessage) => {
          this.props.window.showErrorFunc(errorMessage);
        },
      );
    } else {
      this.reloadHelper(this.props.rows != null ? this.props.rows : []);
    }
  };

  reloadHelper = (response) => {
    if (this.props.responseFunction) {
      response = this.props.responseFunction(response);
    }

    response = this.sortRows(response, this.state.sorting);

    this.setState({
      loading: false,
      allRows: response,
      currentRows: response,
    });

    if (this.state.filter) {
      this.onFilter(this.state.filter);
    }
  };

  onSort = (name) => {
    let newSorting = name === this.state.sorting ? "-" + name : name;
    let newAllRows = this.sortRows(this.state.allRows, newSorting);
    let newCurrentRows = this.sortRows(this.state.currentRows, newSorting);

    this.setState({
      allRows: newAllRows,
      currentRows: newCurrentRows,
      sorting: newSorting,
    });
  };

  sortRows = (rows, sorting) => {
    if (!sorting) {
      return rows;
    }

    let name = sorting;
    let descending = false;

    if (sorting.substring(0, 1) === "-") {
      name = sorting.substring(1);
      descending = true;
    }

    let column = this.props.columns.find((o) => o.name === name);
    let newRows = rows.slice();

    newRows.sort((r1, r2) => {
      let v1 = this.getSort(column, r1);
      let v2 = this.getSort(column, r2);

      if (typeof v1 == "number") {
        return descending ? v2 - v1 : v1 - v2;
      } else {
        let s1 = v1 ? JSON.stringify(v1).toLowerCase() : "";
        let s2 = v2 ? JSON.stringify(v2).toLowerCase() : "";
        if (s1 < s2) {
          return descending ? 1 : -1;
        } else if (s1 > s2) {
          return descending ? -1 : 1;
        } else {
          return 0;
        }
      }
    });

    return newRows;
  };

  getSort = (column, row) => {
    if (column.sortFunction) {
      return column.sortFunction(row);
    } else {
      return row[column.name];
    }
  };

  onFilter = (freetext) => {
    let columns = this.props.columns.filter((o) => o.filterable);
    freetext = freetext.toLowerCase().trim();

    let newRows = this.state.allRows.filter((row) => {
      if (!freetext || columns.length === 0) {
        return true;
      }

      for (let i = 0; i < columns.length; i++) {
        let filter = JSON.stringify(
          this.getFilter(columns[i], row),
        ).toLowerCase();
        if (filter.includes(freetext)) {
          return true;
        }
      }

      return false;
    });

    this.setState({
      currentRows: newRows,
      filter: freetext,
    });
  };

  getFilter = (column, row) => {
    if (column.filterFunction) {
      return column.filterFunction(row);
    } else {
      return row[column.name];
    }
  };

  render() {
    let parts = [];

    if (this.props.otherFilters && this.props.otherFilters.length) {
      this.props.otherFilters.forEach((filter) => parts.push(filter));
    }

    if (this.props.filterable) {
      parts.push(
        <TableFilter key="table_filter" onChange={(o) => this.onFilter(o)} />,
      );
    }

    let filters = [<StyledFilter key="styled_filter">{parts}</StyledFilter>];

    return (
      <TableBase
        loading={this.state.loading}
        list={this.props.list}
        actions={this.props.actions}
        filters={filters}
        columns={this.props.columns}
        rows={this.state.currentRows}
        rowCount={this.state.currentRows.length}
        onSort={this.onSort}
        sorting={this.state.sorting}
        rowClassFunction={this.props.rowClassFunction}
      />
    );
  }
}

export class TableBackend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      rows: [],
      rowCount: 0,
      sorting: this.props.sorting,
      expanding: false,
    };
  }

  componentDidMount() {
    this.reload();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.parameters) !==
      JSON.stringify(this.props.parameters)
    ) {
      this.reload({});
    }
  }

  reload = (newState) => {
    this.setState({ loading: true });
    let futureState = Object.assign({}, this.state, newState);
    let url = this.props.urlFunction(
      futureState.sorting,
      futureState.expanding,
      this.props.parameters,
    );

    this.props.session.backendGet(
      url,
      (response) => {
        if (this.props.responseFunction) {
          response = this.props.responseFunction(response);
        }

        let rows = response.objects;

        if (this.props.sortFrontend) {
          rows = this.frontendSortRows(rows, this.state.sorting);
        }

        this.setState(
          Object.assign(futureState, {
            loading: false,
            rows: rows,
            rowCount: response.totalCount,
          }),
        );
      },
      (errorMessage) => {
        this.props.window.showErrorFunc(errorMessage);
      },
    );
  };

  frontendSortRows = (rows, sorting) => {
    if (!sorting) {
      return rows;
    }

    let name = sorting;
    let descending = false;

    if (sorting.substring(0, 1) === "-") {
      name = sorting.substring(1);
      descending = true;
    }

    let column = this.props.columns.find((o) => o.name === name);
    let newRows = rows.slice();

    newRows.sort((r1, r2) => {
      let v1 = this.getSort(column, r1);
      let v2 = this.getSort(column, r2);

      if (typeof v1 == "number") {
        return descending ? v2 - v1 : v1 - v2;
      } else {
        let s1 = v1 ? JSON.stringify(v1).toLowerCase() : "";
        let s2 = v2 ? JSON.stringify(v2).toLowerCase() : "";
        if (s1 < s2) {
          return descending ? 1 : -1;
        } else if (s1 > s2) {
          return descending ? -1 : 1;
        } else {
          return 0;
        }
      }
    });

    return newRows;
  };

  getSort = (column, row) => {
    if (column.sortFunction) {
      return column.sortFunction(row);
    } else {
      return row[column.name];
    }
  };

  onSort = (name) => {
    let newSorting = name === this.state.sorting ? "-" + name : name;

    if (this.props.sortFrontend) {
      let sortedRows = this.frontendSortRows(this.state.rows, newSorting);

      this.setState({
        rows: sortedRows,
        sorting: newSorting,
      });
    } else {
      this.reload({ sorting: newSorting });
    }
  };

  onExpand = (expanded) => {
    this.reload({ expanding: expanded });
  };

  render() {
    return (
      <TableBase
        loading={this.props.disableLoading ? null : this.state.loading}
        list={this.props.list}
        actions={this.props.actions}
        filters={this.props.filters}
        columns={this.props.columns}
        rows={this.state.rows}
        rowCount={this.state.rowCount}
        onSort={this.onSort}
        onExpand={this.onExpand}
        sorting={this.state.sorting}
        expanding={this.props.disableExpanding ? false : this.state.expanding}
        rowClassFunction={this.props.rowClassFunction}
      />
    );
  }
}
