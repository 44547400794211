import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import DomainWatchList from "./domainwatch/DomainWatchList";
import DomainWatchHitList from "./domainwatch/DomainWatchHitList";
import TrademarkWatchList from "./trademarkwatch/TrademarkWatchList";
import TrademarkWatchHitList from "./trademarkwatch/TrademarkWatchHitList";
import DomainMonitorList from "./domainmonitor/DomainMonitorList";
import DomainMonitorHitList from "./domainmonitor/DomainMonitorHitList";
import WebContentWatchList from "./webcontentwatch/WebContentWatchList";
import WebContentWatchHitList from "./webcontentwatch/WebContentWatchHitList";
import WatchList from "./WatchList";
import ProjectList from "../project/ProjectList";
import ProjectDetail from "../project/ProjectDetail";

class Monitoring extends Component {
  render() {
    return (
      <Switch>
        <Route
          exact={true}
          path="/monitoring/watches"
          render={(props) => (
            <WatchList
              session={this.props.session}
              window={this.props.window}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/monitoring/watches/domainwatches"
          render={(props) => (
            <DomainWatchList
              session={this.props.session}
              window={this.props.window}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/monitoring/watches/domainwatchhits"
          render={(props) => (
            <DomainWatchHitList
              session={this.props.session}
              window={this.props.window}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/monitoring/watches/webcontentwatches"
          render={(props) => (
            <WebContentWatchList
              session={this.props.session}
              window={this.props.window}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/monitoring/watches/webcontentwatchhits"
          render={(props) => (
            <WebContentWatchHitList
              session={this.props.session}
              window={this.props.window}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/monitoring/watches/trademarkwatches"
          render={(props) => (
            <TrademarkWatchList
              session={this.props.session}
              window={this.props.window}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/monitoring/watches/trademarkwatchhits"
          render={(props) => (
            <TrademarkWatchHitList
              session={this.props.session}
              window={this.props.window}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/monitoring/watches/domainmonitors"
          render={(props) => (
            <DomainMonitorList
              session={this.props.session}
              window={this.props.window}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/monitoring/watches/domainmonitorhits"
          render={(props) => (
            <DomainMonitorHitList
              session={this.props.session}
              window={this.props.window}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/monitoring/cases"
          render={(props) => (
            <ProjectList
              session={this.props.session}
              window={this.props.window}
              caseModule={true}
              {...props}
            />
          )}
        />
        <Route
          path="/monitoring/cases/:caseId"
          render={(props) => (
            <ProjectDetail
              session={this.props.session}
              window={this.props.window}
              caseModule={true}
              {...props}
            />
          )}
        />
      </Switch>
    );
  }
}

export default Monitoring;
