import React, { Component } from "react";
import styled from "styled-components";
import { UpdateCommentsIcon } from "../common/theme/Icons";

const CommentComponentStyle = styled.span`
  div svg {
    margin-left: 10px;
  }
`;

export default class CommentComponent extends Component {
  render() {
    return (
      <CommentComponentStyle>
        <div
          style={{
            whiteSpace: "pre-wrap",
            display: "inline-block",
            verticalAlign: "top",
          }}
        >
          {this.props.value}
        </div>
        <UpdateCommentsIcon
          onClick={() => this.props.onEdit()}
          title="Update comments for this hit"
        />
      </CommentComponentStyle>
    );
  }
}
