import React, { Component } from "react";
import { Col } from "react-bootstrap";
import DashboardSummary from "./DashboardSummary";
import Colors from "../common/theme/Colors";

export default class DigitalSummaryItem extends Component {
  getItems = () => {
    let portfolio = this.props.session.hasRole("PORTFOLIO");
    let internal = !this.props.data.countries;

    let tooltipPortfolio = internal ? "managed by Abion" : "in your portfolio";
    let tooltipClick = portfolio
      ? " Click for additional details."
      : internal
        ? ""
        : " Request role Portfolio to see additional details.";

    return [
      [
        "Domains",
        this.props.data.digitalRights,
        portfolio ? "/portfolio/domains" : null,
        "The number of domains " +
          tooltipPortfolio +
          ". Live domains are counted, also including any that will not be renewed." +
          tooltipClick,
      ],
      [
        "Services",
        this.props.data.digitalSubscriptions,
        portfolio ? "/portfolio/digitalServices" : null,
        "The number of domain services " +
          tooltipPortfolio +
          ". This includes DNS, Corporate Domain Management services, local contact services and private whois services." +
          tooltipClick,
      ],
    ];
  };

  render() {
    return (
      <Col>
        <DashboardSummary
          image={require("../common/img/dashboard/digital.svg").default}
          title="Domains"
          color={Colors.categoryDigital}
          items={this.getItems()}
        />
      </Col>
    );
  }
}
