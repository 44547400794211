import React, { Component } from "react";
import styled from "styled-components";
import { DetailsDialog } from "../../common/Dialog";

const DivStyle = styled.div`
  .field-label {
    display: inline-block;
    min-width: 300px;
    margin-right: 20px;
    font-weight: 700;
  }

  table {
    margin-bottom: 10px;
  }

  img {
    max-width: 700px;
  }
`;

export default class TrademarkWatchHitDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
  }

  componentDidMount() {
    this.reload();
  }

  componentDidUpdate(prevProps) {
    if (this.props.url && prevProps.url !== this.props.url) {
      this.reload();
    }
  }

  reload = () => {
    this.setState({ data: null });

    if (this.props.url) {
      this.props.session.backendGet(
        this.props.url,
        (response) => {
          this.setState({ data: response });
        },
        (errorMessage) => {
          this.props.window.showErrorFunc(errorMessage);
        },
      );
    }
  };

  render() {
    let body = null;

    if (this.state.data) {
      let html = this.state.data.html;
      html = html.replace("&amp;lt;", "&lt;");
      html = html.replace("&amp;amp;", "&amp;");
      body = <DivStyle dangerouslySetInnerHTML={{ __html: html }} />;
    } else {
      body = "Loading, please wait...";
    }

    return (
      <DetailsDialog
        show={this.props.show}
        title="Trademark Watch Hit Details"
        body={body}
        onClose={() => this.props.onClose()}
        width="lg"
        height="lg"
      />
    );
  }
}
