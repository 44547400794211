import React, { Component } from "react";
import EditDialog from "../../common/Dialog";
import { InputWithLabel } from "../../common/theme/Theme";

class ServerDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conf: null,
    };

    this.updateField = this.updateField.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.conf && prevProps.conf !== this.props.conf) {
      this.setState({ conf: this.props.conf });
    }
  }

  updateField(field, value) {
    const conf = this.state.conf;
    conf.object[field] = value;
    this.setState({ conf: conf });
  }

  render() {
    if (!this.state.conf) {
      return null;
    }

    const elements = [
      <InputWithLabel
        label={"IP Adress"}
        type="text"
        name="ip"
        placeholder="IP"
        value={this.state.conf.object.ip || ""}
        onChange={(e) => this.updateField("ip", e.target.value)}
        required={true}
      />,
    ];
    return (
      <EditDialog
        content={elements}
        show={this.props.showDialog}
        title={(this.state.conf.object.id ? "Edit" : "Create") + " Server"}
        onCancel={this.props.closeDialog}
        onConfirm={this.props.handleSubmit}
        submit={this.props.submit}
        errorMessage={this.props.errorMessage}
      />
    );
  }
}

export default ServerDialog;
