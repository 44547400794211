import React, { Component } from "react";
import { Loader } from "../../common/theme/Theme";
import DomainHeader from "./DomainHeader";
import ContactsTab from "./ContactsTab";
import { NavTab } from "react-router-tabs";
import Icon from "../../common/theme/Icons";
import { PageTabsNew } from "../../common/PageTabs";
import { Redirect, Route, Switch } from "react-router-dom";
import DsTab from "./DsTab";
import WhoisTab from "./WhoisTab";
import NameserversTab from "./NameserversTab";

class DomainPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      domain: null,
      baseUrl: null,
      activeTab: 1,
      errorMessage: null,
      submit: null,
    };
  }

  componentDidMount() {
    this.reload();
  }

  reload = () => {
    let url =
      this.props.session.getModules().domainadmin.links.domains +
      "/" +
      this.props.match.params.domainId;
    this.props.session.backendGet(url, (response) => {
      this.setState({
        domain: response,
        loaded: true,
        baseUrl: `/domainadmin/domains/${response.id}`,
      });
      let views = [
        {
          label: "Home",
          url: "/",
        },
        {
          label: "Domains",
          url: "/domainadmin/domains",
        },
        {
          label: response.name,
          url: null,
        },
      ];

      this.props.window.setBreadcrumbViews(views);
      this.props.window.setPageTip(null);
    });
  };

  handleTabSelect = (event) => {
    const clickedTab = event.target.getAttribute("data-tab");
    this.setState({ activeTab: clickedTab });
  };

  render() {
    const contacts = () => {
      return (
        <ContactsTab
          domain={this.state.domain}
          session={this.props.session}
          window={this.props.window}
        />
      );
    };
    const nameservers = () => {
      return (
        <NameserversTab
          domain={this.state.domain}
          session={this.props.session}
          window={this.props.window}
        />
      );
    };
    const ds = () => {
      return (
        <DsTab
          domain={this.state.domain}
          session={this.props.session}
          window={this.props.window}
        />
      );
    };
    const whois = () => {
      return (
        <WhoisTab
          domain={this.state.domain}
          session={this.props.session}
          window={this.props.window}
        />
      );
    };
    const Tabs = (props) => {
      let tabs = [];
      let tabIndex = 1;
      tabs.push(
        <NavTab
          onClick={this.handleTabSelect}
          key={"domain-contacts"}
          data-tab={tabIndex++}
          to={`${props.baseUrl}/contacts`}
        >
          <Icon name="address-book" /> Contacts
        </NavTab>,
      );
      tabs.push(
        <NavTab
          onClick={this.handleTabSelect}
          key={"domain-nameservers"}
          data-tab={tabIndex++}
          to={`${props.baseUrl}/nameservers`}
        >
          <Icon name="server" /> Nameservers
        </NavTab>,
      );
      tabs.push(
        <NavTab
          onClick={this.handleTabSelect}
          key={"domain-ds"}
          data-tab={tabIndex++}
          to={`${props.baseUrl}/ds`}
        >
          <Icon name="shield" /> DNSSEC
        </NavTab>,
      );
      tabs.push(
        <NavTab
          onClick={this.handleTabSelect}
          key={"domain-whois"}
          data-tab={tabIndex++}
          to={`${props.baseUrl}/whois`}
        >
          <Icon name="globe" /> Whois
        </NavTab>,
      );
      return tabs;
    };
    return (
      <Loader loaded={this.state.loaded}>
        <DomainHeader
          session={this.props.session}
          window={this.props.window}
          domain={this.state.domain}
          reloadDomain={this.reload}
        />
        <PageTabsNew className={"nav nav-tabs"}>
          <Tabs domain={this.state.domain} baseUrl={this.state.baseUrl} />
        </PageTabsNew>
        <Switch>
          <Route
            exact
            path={`${this.state.baseUrl}`}
            key={"route-information-redirect-1"}
            render={() => (
              <Redirect replace to={`${this.state.baseUrl}/contacts`} />
            )}
          />
          <Route
            path={`${this.state.baseUrl}/contacts`}
            component={contacts}
            key={"route-information"}
          />
          <Route
            path={`${this.state.baseUrl}/nameservers`}
            component={nameservers}
            key={"route-information"}
          />
          <Route
            path={`${this.state.baseUrl}/ds`}
            component={ds}
            key={"route-information"}
          />
          <Route
            path={`${this.state.baseUrl}/whois`}
            component={whois}
            key={"route-information"}
          />
        </Switch>
      </Loader>
    );
  }
}

export default DomainPage;
