import React, { Component } from "react";
import { MainActionButton, TableButton } from "../../common/Buttons";
import { TableFrontend } from "../../common/Tables";
import RecordDialog from "./RecordDialog";

class RecordTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conf: null,
      showDialog: false,
      submit: null,
      errorMessage: null,
    };
    this.onCreate = this.onCreate.bind(this);
    this.onEdit = this.onEdit.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.conf && prevProps.conf !== this.props.conf) {
      this.setState({ conf: this.props.conf });
    }
  }

  handleSubmit() {
    const url = this.props.zone.links.records;
    const successFun = () => {
      this.closeDialog();
      this.props.onChange();
      const message = "Record successfully added.";
      this.props.window.showSuccessFunc(message);
    };
    const successEditFun = () => {
      this.closeDialog();
      this.props.onChange();
      const message = "Record successfully edited.";
      this.props.window.showSuccessFunc(message);
    };
    const errorFun = (msg) => {
      this.setState({ submit: null, errorMessage: msg });
    };
    if (this.state.conf.object.id) {
      this.setState({ submit: true });
      const url = this.state.conf.object.links.self;
      this.props.session.backendPut(
        url,
        this.state.conf.object,
        successEditFun,
        errorFun,
      );
    } else {
      this.setState({ submit: true });
      this.props.session.backendPost(
        url,
        this.state.conf.object,
        successFun,
        errorFun,
      );
    }
  }

  onCreate() {
    let confUrl = this.props.zone.links.recordConfiguration;
    this.props.session.backendGet(confUrl, (response) => {
      this.setState({ conf: response, showDialog: true });
    });
  }

  onEdit(row) {
    let confUrl = row.links.configuration;
    this.props.session.backendGet(confUrl, (response) => {
      this.setState({ conf: response, showDialog: true });
    });
  }

  onRemove(row) {
    const message = (
      <div>
        Are you sure you want to remove the record?
        <br />
        <pre>
          {row.name} {row.ttl} {row.recordType} {row.data}
        </pre>
      </div>
    );
    this.props.window.showConfirmFunc(message, () => {
      let url = row.links.self;
      this.props.session.backendDelete(url, () => {
        this.props.window.showSuccessFunc("Record successfully deleted");
        this.props.onChange();
      });
    });
  }

  closeDialog() {
    this.setState({ showDialog: false, submit: false, errorMessage: null });
  }

  render() {
    const columns = [
      {
        label: "Name",
        name: "name",
        sortable: true,
        filterable: true,
        sortFunction: (r) => r.sortValue,
      },
      {
        label: "TTL",
        name: "ttl",
        sortable: true,
        style: { width: "100px" },
      },
      {
        label: "Type",
        name: "recordType",
        sortable: true,
        filterable: true,
      },
      {
        label: "Data",
        name: "data",
        sortable: true,
        filterable: true,
      },
      {
        label: "Comments",
        name: "comments",
        contentFunction: (r) => (
          <div style={{ wordWrap: "break-word", whiteSpace: "pre-wrap" }}>
            {r.comments}
          </div>
        ),
        sortable: true,
        filterable: true,
      },
      {
        style: { width: "210px" },
        contentFunction: (r) =>
          !this.props.session.hasRole(
            "DNS_ADMIN_EDIT",
            "DNS_ADMIN_PUBLISH",
          ) ? null : (
            <div>
              <TableButton
                onClick={() => this.onEdit(r)}
                icon="edit"
                prefix={"fas"}
                text="Edit"
                className={"secondary"}
              />
              <TableButton
                onClick={() => this.onRemove(r)}
                icon="trash-alt"
                prefix={"fas"}
                text="Remove"
                className="remove"
              />
            </div>
          ),
      },
    ];

    const url = this.props.zone.links.records;
    const actions = !this.props.session.hasRole(
      "DNS_ADMIN_EDIT",
      "DNS_ADMIN_PUBLISH",
    )
      ? null
      : [
          <MainActionButton
            key="create-record"
            onClick={this.onCreate}
            icon="plus"
            prefix={"fas"}
            text="Create Record"
          />,
        ];
    const { showDialog, conf, submit, errorMessage } = this.state;
    const { session, window } = this.props;

    return (
      <div>
        <RecordDialog
          session={session}
          showDialog={showDialog}
          closeDialog={this.closeDialog}
          conf={conf}
          createUrl={url}
          handleSubmit={this.handleSubmit}
          submit={submit}
          errorMessage={errorMessage}
        />
        <TableFrontend
          session={session}
          window={window}
          actions={actions}
          filterable={true}
          columns={columns}
          sorting="name"
          url={url}
          responseFunction={(o) => o.objects}
        />
      </div>
    );
  }
}

export default RecordTab;
