import React, { Component } from "react";
import styled from "styled-components";
import { UpdateCommentsIcon } from "../common/theme/Icons";
import Colors from "../common/theme/Colors";

const RiskComponentStyle = styled.span`
  .risk div {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    border: 1px solid ${Colors.riskborder};
  }
  .risk-WHITE div {
  }
  .risk-GREEN div {
    background-color: ${Colors.riskGreenBackground};
    border-color: ${Colors.riskGreenBorder};
  }
  .risk-YELLOW div {
    background-color: ${Colors.riskYellowBackground};
    border-color: ${Colors.riskYellowBorder};
  }
  .risk-RED div {
    background-color: ${Colors.riskRedBackground};
    border-color: ${Colors.riskRedBorder};
  }
  .risk svg {
    margin-left: 10px;
    margin-bottom: 4px;
  }
`;

export default class RiskComponent extends Component {
  render() {
    return (
      <RiskComponentStyle>
        <div className={"risk risk-" + this.props.value}>
          <div />
          <UpdateCommentsIcon
            onClick={() => this.props.onEdit()}
            title="Update risk for this hit"
          />
        </div>
      </RiskComponentStyle>
    );
  }
}
