import React, { Component } from "react";
import { MainActionButton } from "../common/Buttons";
import { TableFrontend } from "../common/Tables";
import UserRoleDialog from "./UserRoleDialog";

class RoleTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDialog: false,
    };
  }

  showDialog = () => {
    this.setState({ showDialog: true });
  };

  closeDialog = () => {
    this.setState({ showDialog: false });
    this.props.onChange();
  };

  render() {
    const columns = [
      {
        label: "Role",
        name: "label",
        sortable: true,
      },
    ];

    const actions = [
      <MainActionButton
        key="edit-roles"
        onClick={this.showDialog}
        prefix={"fas"}
        icon="edit"
        text="Edit Roles"
      />,
    ];

    return (
      <div>
        <UserRoleDialog
          size="lg"
          session={this.props.session}
          window={this.props.window}
          showDialog={this.state.showDialog}
          closeDialog={this.closeDialog}
          url={this.props.user.links.configuration}
        />
        <TableFrontend
          session={this.props.session}
          window={this.props.window}
          actions={actions}
          columns={columns}
          url={this.props.user.links.roles}
          sorting="label"
        />
      </div>
    );
  }
}

export default RoleTab;
