import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Selector from "../../common/Selector";
import { InformationField } from "../../common/Information";
import ContactField from "./ContactField";

export default class DomainRegisterParameterStep extends Component {
  render() {
    return (
      <Container fluid={true}>
        <Row className="section-title">
          <Col xs={12}>Parameters / Settings</Col>
        </Row>
        <Row className="section-description">
          <Col xs={12}>Enter the parameters below</Col>
        </Row>
        <Row className="section-field">
          <Col xs={12}>
            <InformationField>
              <Selector
                label={"Period"}
                placeholder="Select period"
                options={this.props.conf.parameters.periods}
                defaultValue={this.props.conf.object.parameters.periodId}
                onChange={(v) =>
                  this.props.onParameterChange("periodId", v.value)
                }
              />
            </InformationField>
          </Col>
        </Row>
        <Row className="section-field">
          <Col xs={12}>
            <InformationField tooltip="Nameservers are setup in advance under menu nameservers and then they become selectable in this field">
              <Selector
                label={"Nameservers"}
                placeholder="Select nameservers"
                options={this.props.conf.parameters.nameservers}
                defaultValue={this.props.conf.object.parameters.nameserverId}
                onChange={(v) =>
                  this.props.onParameterChange("nameserverId", v.value)
                }
              />
            </InformationField>
          </Col>
        </Row>
        <Row className="section-field">
          <Col xs={12}>
            <InformationField>
              <Selector
                label={"Local Contact"}
                placeholder="Select local contact"
                options={this.props.conf.parameters.localContactOptions}
                defaultValue={this.props.conf.object.parameters.localContactId}
                onChange={(v) =>
                  this.props.onParameterChange("localContactId", v.value, true)
                }
              />
            </InformationField>
          </Col>
        </Row>
        <Row className="section-field">
          <Col xs={12}>
            <InformationField>
              <Selector
                label={"Private Whois"}
                placeholder="Select private whois"
                options={this.props.conf.parameters.privateWhoisOptions}
                defaultValue={this.props.conf.object.parameters.privateWhoisId}
                onChange={(v) =>
                  this.props.onParameterChange("privateWhoisId", v.value)
                }
              />
            </InformationField>
          </Col>
        </Row>
        <ContactField type="registrant" {...this.props} />
        <ContactField type="admin" {...this.props} />
        <ContactField type="billing" {...this.props} />
        <ContactField type="tech" {...this.props} />
      </Container>
    );
  }
}
