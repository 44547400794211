import React, { Component } from "react";
import styled from "styled-components";
import { Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { BorderColor } from "../../common/theme/Theme";
import Colors from "../../common/theme/Colors";

const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  padding: 0px 0px 0px 0px;
`;

const TableStyle = styled.div`
  > .container-fluid > .header {
    padding: 15px 0px 10px 0px;
    background: ${Colors.white};
    border-top: 1px solid ${BorderColor};
    border-left: 1px solid ${BorderColor};
    border-right: 1px solid ${BorderColor};
  }

  > .container-fluid > .footer {
    padding: 10px 0px 10px 0px;

    .footer-text {
      font-size: 16px;
    }
  }

  > .container-fluid > .row > div > table {
    width: 100%;
    table-layout: fixed;
    background: ${Colors.white};
    border: 1px solid ${BorderColor};

    > tbody {
      > tr:hover {
        background: ${Colors.tableBackground};
      }

      > tr > td {
        padding: 7px 15px 7px 15px;
        border-top: 1px solid ${BorderColor};
        vertical-align: top;
      }

      > tr > th {
        border-top: 1px solid ${BorderColor};
        font-weight: 600;
        font-size: 16px;
        padding: 20px 15px 10px 15px;
        vertical-align: top;
      }
    }
  }

  .table-filter {
    display: inline-block;
    width: 280px;
  }

  .table-link {
    text-decoration: underline;
    color: ${Colors.tertiary};
    font-weight: 700;
  }

  .table-action-section {
    float: left;
  }

  .table-filter-section {
    float: right;
  }
`;

export class Grid extends Component {
  appendHeader = (parts) => {
    let hasActions = this.props.actions && this.props.actions.length;
    let hasFilters = this.props.filters && this.props.filters.length;
    let className = "header";

    if (hasActions && hasFilters) {
      parts.push(
        <Row key="actions_filters" className={className}>
          <Col xs={12}>
            <div className="table-action-section">{this.props.actions}</div>
            <div className="table-filter-section">{this.props.filters}</div>
          </Col>
        </Row>,
      );
    } else if (hasActions) {
      parts.push(
        <Row key="actions" className={className}>
          <Col xs={12}>{this.props.actions}</Col>
        </Row>,
      );
    } else if (hasFilters) {
      parts.push(
        <Row key="filter" className={className}>
          <Col xs={12} className="text-right">
            {this.props.filters}
          </Col>
        </Row>,
      );
    }
  };

  chunk(arr, len) {
    if (arr) {
      var chunks = [],
        i = 0,
        n = arr.length;

      while (i < n) {
        let slice = arr.slice(i, (i += len));

        while (slice.length < len) {
          slice.push([]);
        }
        chunks.push(slice);
      }

      return chunks;
    }
    return [];
  }

  appendTable = (parts) => {
    parts.push(this.getTable());
  };

  builtTable = (parts) => {
    let tempParts = [];

    if (this.props.columnsPerRow > 0) {
      const tempColumns = [...this.props.columns];

      tempColumns.shift();

      let columns = this.chunk(tempColumns, this.props.columnsPerRow);

      columns.forEach((c, index) => {
        c.unshift(this.props.columns[0]);
        tempParts.push(this.getTable(c, index));
      });
    } else {
      tempParts.push(this.getTable(this.props.columns, 0));
    }

    parts.push(this.getWholeTable(tempParts));
  };

  getWholeTable = (parts) => {
    return (
      <Row key="whole-table">
        <TableContainer>
          <table>{parts}</table>
        </TableContainer>
      </Row>
    );
  };

  getTable = (columns, index) => {
    return (
      <tbody key={"table-body-" + index}>
        {this.getTableHeaderRow(columns)}
        {this.getTableBody(columns)}
      </tbody>
    );
  };

  getTableHeaderRow = (columns) => {
    let headers = [];

    headers.push(
      <tr key="columns-headers">
        {columns.map((o) =>
          this.getTableHeaderColumn(
            o,
            columns.findIndex((j) => j === o),
            false,
          ),
        )}
      </tr>,
    );

    if (this.props.displayDescriptionLabel) {
      headers.push(
        <tr key="columns-description">
          {columns.map((o) =>
            this.getTableHeaderColumn(
              o,
              columns.findIndex((j) => j === o),
              true,
            ),
          )}
        </tr>,
      );
    }
    return <React.Fragment>{headers}</React.Fragment>;
  };

  getTableHeaderColumn = (column, index, descriptionLabel) => {
    let headerColumn = (
      <th key={"column-h-" + index + column.label} style={column.style}>
        {column.label}
      </th>
    );
    if (column.label && descriptionLabel) {
      headerColumn = (
        <td
          key={"column-h-" + index + column.descriptionLabel}
          style={column.style}
        >
          {column.descriptionLabel}
        </td>
      );
    }

    if (column.tooltip) {
      headerColumn = (
        <OverlayTrigger
          overlay={
            <Tooltip style={{ position: "fixed" }}>{column.tooltip}</Tooltip>
          }
          placement="bottom"
          delay={200}
        >
          {headerColumn}
        </OverlayTrigger>
      );
    }

    return headerColumn;
  };

  getTableBody = (columns) => {
    return this.props.rows.map((o) => this.getTableBodyRow(columns, o));
  };

  getTableBodyRow = (columns, row) => {
    let rowData = columns.map((o) =>
      this.getTableBodyColumn(
        o,
        row,
        columns.findIndex((j) => j === o),
      ),
    );
    return <tr key={row.keyword}>{rowData}</tr>;
  };

  getTableBodyColumn = (column, row, index) => {
    return (
      <td key={"keyword-" + row.keyword + "-" + index} style={column.style}>
        {this.getContent(column, row)}
      </td>
    );
  };

  appendFooter = (parts) => {
    let col1 = [];
    let col2 = [];
    let col3 = [];

    if (this.props.loading) {
      col1.push(
        <div key="loading" className="footer-text">
          Loading, please wait...
        </div>,
      );
    }

    parts.push(
      <Row key="footer" className="footer">
        <Col xs={4}>{col1}</Col>
        <Col xs={4} className="text-center">
          {col2}
        </Col>
        <Col xs={4} className="text-right">
          {col3}
        </Col>
      </Row>,
    );
  };

  getContent = (column, row) => {
    if (column.contentFunction) {
      return column.contentFunction(row);
    } else {
      return row[column.name];
    }
  };

  render() {
    let parts = [];

    this.appendHeader(parts);
    this.builtTable(parts);
    this.appendFooter(parts);

    return (
      <TableStyle>
        <Container fluid={true}>{parts}</Container>
      </TableStyle>
    );
  }
}
