import React, { Component } from "react";
import { Container } from "react-bootstrap";
import { ActionButton, MainActionButton } from "../common/Buttons";
import { FormatDateMonospace, FormatList } from "../common/Utils";
import UpdateDomainLifecycleDialog from "../common/UpdateDomainLifecycleDialog";
import ChangeAccountDialog from "../common/ChangeAccountDialog";
import { DetailsDialog, DialogSection } from "../common/Dialog";
import ReadOnlyForm from "../common/ReadOnlyForm";

export default class DomainDetailsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updateLifecycleDialogShow: false,
      changeAccountDomainDialogShow: false,
      services: null,
      serviceError: false,
    };
  }

  componentDidMount() {
    this.reloadServices();
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && prevProps.show !== this.props.show) {
      this.reloadServices();
    }
  }

  reloadServices = () => {
    this.setState({ services: null, serviceError: false });

    if (this.props.domain?.links.services) {
      this.props.session.backendGet(
        this.props.domain.links.services,
        (response) => {
          this.setState({ services: response });
        },
        () => {
          this.setState({ serviceError: true });
        },
      );
    }
  };

  getNameservers = (domain) => {
    let nameservers = [];
    if (domain.nameservers) {
      domain.nameservers.forEach((o) => {
        nameservers.push(o.name);
      });
    }
    return FormatList(nameservers);
  };

  getBasicInfo = (domain) => {
    const basicInfo = [
      {
        label: "Registrant",
        value: domain.owner,
      },
      {
        label: "Nameserver",
        value: this.getNameservers(domain),
      },
      {
        label: "",
        value: "",
      },
      {
        label: "Local Contact",
        value: domain.localContact,
        conditionalEnableDisable: true,
      },
      {
        label: "Private Whois",
        value: domain.privateWhois,
        conditionalEnableDisable: true,
      },
      {
        label: "Registry Lock",
        value: domain.registryLockType !== "NONE",
        conditionalEnableDisable: true,
      },
      {
        label: "",
        value: "",
      },
      {
        label: "Expiry Date",
        value: FormatDateMonospace(domain.expires),
      },
      {
        label: "On Expiry",
        value: domain.onExpire,
      },
      {
        label: "",
        value: "",
      },
      {
        label: "Organisation",
        value: domain.organisation,
      },
      {
        label: "",
        value: "",
      },
      {
        label: "Comments",
        value: domain.comments,
      },
    ];

    return (
      <DialogSection title="Basic Info">
        <ReadOnlyForm wide="true" content={basicInfo} />
      </DialogSection>
    );
  };

  getServicesByCategory = (services, category) => {
    const filtered = services.filter((o) => o.category === category);
    const label =
      category.charAt(0).toUpperCase() +
      category.slice(1).toLowerCase() +
      " Services";
    const fieldBody = [];

    if (filtered.length > 0) {
      filtered.forEach((o) => {
        fieldBody.push(<div key={"type-" + o.type}>{o.type}</div>);
      });
    } else {
      fieldBody.push(
        <div key="no-service" style={{ color: "${Colors.tertiary}" }}>
          No services configured
        </div>,
      );
    }
    return {
      label: label,
      value: fieldBody,
    };
  };

  getServices = () => {
    if (!this.state.services || this.state.services.length === 0) {
      return;
    }

    let content = [];
    content = content.concat(
      this.getServicesByCategory(this.state.services, "DIGITAL"),
    );
    content.push({
      label: "",
      value: "",
    });
    content = content.concat(
      this.getServicesByCategory(this.state.services, "TECHNICAL"),
    );

    return (
      <DialogSection title="Services">
        <ReadOnlyForm wide="true" content={content} />
      </DialogSection>
    );
  };

  getBody = () => {
    let parts = [];
    if (this.props.domain) {
      parts.push(this.getBasicInfo(this.props.domain));
      parts.push(this.getServices());
    }
    return <Container fluid={true}>{parts}</Container>;
  };

  showUpdateLifecycleDialog = () => {
    this.props.onClose();
    this.setState({
      updateLifecycleDialogShow: true,
      errorMessage: null,
      submit: null,
    });
  };

  hideUpdateLifecycleDialog = () => {
    this.props.onClose();
    this.setState({
      updateLifecycleDialogShow: false,
      errorMessage: null,
      submit: null,
    });
  };

  updateLifecycle = (resource) => {
    const errorFun = (msg) => {
      this.setState({ errorMessage: msg, submit: false });
    };
    const callback = () => {
      this.hideUpdateLifecycleDialog();
      this.setState({ errorMessage: null });
      let text;
      if (
        resource.relatedSubscriptionIds &&
        resource.relatedSubscriptionIds.length > 0
      ) {
        text = "Domain and related services are";
      } else {
        text = "Domain is";
      }
      this.props.window.showSuccessFunc(text + " updated successfully!");
    };
    this.setState({ submit: true });
    let url = this.props.domain.links.lifecycle;
    this.props.session.backendPut(url, resource, callback, errorFun);
    this.props.onClose();
  };
  showChangeAccountDialog = () => {
    this.props.onClose();
    this.setState({
      changeAccountDomainDialogShow: true,
      errorMessage: null,
      submit: null,
    });
  };

  hideChangeAccountDialog = () => {
    this.props.onClose();
    this.setState({
      changeAccountDomainDialogShow: false,
      errorMessage: null,
      submit: null,
    });
  };

  getTooltip = (hasRoleCancel, lifecycleUpdatable, renew, action) => {
    let tooltip;
    if (!hasRoleCancel) {
      return (
        "Your user account does not have privileges to " +
        action +
        " the domain on expiration date, contact your client manager to activate this functionality"
      );
    } else if (!lifecycleUpdatable) {
      if (renew) {
        tooltip =
          "The cancellation process has started. If you want to renew the domain, please contact your client manager as soon as possible. There is no guarantee that we can still renew the domain.";
      } else {
        tooltip =
          "The renewal process has started. You will be able to cancel the domain on expiration date after the initiated renewal has been completed. ";
      }
    } else {
      tooltip = action + " the domain on expiration date";
    }
    return tooltip;
  };

  getActionButtons = () => {
    let buttons = [];
    let hasRoleEdit =
      this.props.session.hasRole("ORDER_EDIT") &&
      this.props.session.hasRole("DOMAIN_ADMIN_EDIT");

    if (this.props.domain) {
      buttons.push(
        <ActionButton
          text={"Change Account"}
          onClick={() => this.showChangeAccountDialog()}
          disabled={!hasRoleEdit}
          disabledTooltip={
            "Your user account does not have privileges to change account on the domain, contact your client manager to activate this functionality"
          }
        />,
      );

      let lifecycleUpdatable = this.props.domain.dateLifecycleUpdatable;
      let renew = this.props.domain.onExpire !== "Renew";
      let action = renew ? "Renew" : "Cancel";
      let tooltip = this.getTooltip(
        hasRoleEdit,
        lifecycleUpdatable,
        renew,
        action,
      );
      let className =
        this.props.domain.onExpire === "Renew" ? "delete" : "main";

      buttons.push(
        <MainActionButton
          text={renew ? "Renew on Expiry" : "Cancel on Expiry"}
          tooltip={tooltip}
          className={className}
          onClick={() => this.showUpdateLifecycleDialog()}
          disabled={!hasRoleEdit || !lifecycleUpdatable}
          disabledTooltip={tooltip}
        />,
      );
    }
    return buttons;
  };

  render() {
    let title = "";
    if (this.props.domain) {
      title = this.props.domain.name;
    }

    return (
      <div>
        <DetailsDialog
          show={this.props.show}
          title={title}
          actions={this.getActionButtons()}
          body={this.getBody()}
          height="lg"
          width="lg"
          onClose={() => this.props.onClose()}
        />
        <UpdateDomainLifecycleDialog
          session={this.props.session}
          window={this.props.window}
          show={this.state.updateLifecycleDialogShow}
          errorMessage={this.state.errorMessage}
          submit={this.state.submit}
          domain={this.props.domain}
          onConfirm={this.updateLifecycle}
          onClose={this.hideUpdateLifecycleDialog}
        />
        {this.state.changeAccountDomainDialogShow && (
          <ChangeAccountDialog
            session={this.props.session}
            window={this.props.window}
            show={this.state.changeAccountDomainDialogShow}
            errorMessage={this.state.errorMessage}
            submit={this.state.submit}
            domainSubscriptionsUrl={this.props.domain.links.subscriptions}
            changeAccountUrl={this.props.domain.links.changeAccount}
            onClose={this.hideChangeAccountDialog}
            onChangeAccount={this.hideChangeAccountDialog}
          />
        )}
      </div>
    );
  }
}
