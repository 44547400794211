import React, { Component } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { InternalLinkChild } from "../../common/Link";
import { PortsAlert } from "../../common/PortsAlert";

export default class DomainNamesStep extends Component {
  getSearchToolNote = () => {
    if (!this.props.conf.parameters.searchToolNote) {
      return null;
    }

    return (
      <PortsAlert
        key="searchtool-note"
        variant="warning"
        style={{ marginTop: "20px", marginBottom: "0px" }}
      >
        <strong>Note:</strong> If you don't know exactly what domains you wish
        to register, you can use the search tool that allows you to search by
        keywords and endings/regions.{" "}
        <InternalLinkChild to="/domainadmin/searchtool">
          <Button onClick={this.props.onClose} variant="link">
            Switch to the Search Tool
          </Button>
        </InternalLinkChild>
        .
      </PortsAlert>
    );
  };

  render() {
    let items = [];

    items.push(
      <Container key="domain-names" fluid={true}>
        <Row className="section-title">
          <Col xs={12}>Domain Names</Col>
        </Row>
        <Row className="section-description">
          <Col xs={12}>
            Enter the domain names below. More than one domain name can be
            entered, in that case write one per line.
          </Col>
        </Row>
        <Row className="section-field">
          <Col xs={12}>
            <textarea
              rows="10"
              placeholder="Enter domain names here"
              defaultValue={this.props.conf.object.parameters.domainNames}
              onChange={(e) =>
                this.props.onParameterChange("domainNames", e.target.value)
              }
            />
          </Col>
        </Row>
      </Container>,
    );

    let searchToolNote = this.getSearchToolNote();

    if (searchToolNote != null) {
      items.push(searchToolNote);
    }

    return items;
  }
}
