import React, { Component } from "react";
import styled from "styled-components";
import { TableBackend, TableFilter } from "../../common/Tables";
import { InternalLink } from "../../common/Link";
import { SwitchCheckbox } from "../../common/theme/Theme";
import { FormatDateMonospace, FormatOrganisation } from "../../common/Utils";
import { MainActionButton } from "../../common/Buttons";
import OrganisationFilter from "../../common/OrganisationFilter";
import Colors from "../../common/theme/Colors";
import CreateOrderDialog from "../../order/CreateOrderDialog";

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

class ZoneList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parameters: { accountIds: "", freetext: "", onlyNotDeleted: true },
    };
  }

  componentDidMount() {
    let views = [
      {
        label: "Home",
        url: "/",
      },
      {
        label: "Zones",
        url: null,
      },
    ];

    this.props.window.setBreadcrumbViews(views);
    this.props.window.setPageTip(null);
  }

  getPublishStatusStyle(value) {
    let style = {};
    if (value === "CREATED" || value === "MODIFIED") {
      style.color = Colors.warning;
    } else if (value === "CURRENT" || value === "SCHEDULED") {
      style.color = Colors.success;
    }
    return style;
  }

  getDnsSecStatusStyle(value) {
    let style = {};
    if (value.startsWith("STARTED") || value.startsWith("STOPPED")) {
      style.color = Colors.warning;
    } else if (value !== "DISABLED") {
      style.color = Colors.success;
    }
    return style;
  }

  getUrl = (sorting, expanding, parameters) => {
    let url = this.props.session.getModules().dnsadmin.links.zones;
    url += "?sort=" + sorting;
    url += expanding ? "" : "&page[limit]=15";
    url += "&onlyNotDeletedFilter=" + parameters.onlyNotDeleted;
    url += "&accountIdFilter=" + parameters.accountIds;
    url += "&freetextFilter=" + encodeURIComponent(parameters.freetext);
    return url;
  };

  render() {
    let actions = [];

    actions.push(
      <MainActionButton
        key="order-dns-service"
        onClick={() => this.setState({ showCreateDialog: true })}
        text="Order DNS Service"
        icon="plus"
        prefix="fas"
        tooltip="Open the order dialog and fill in details to order a new DNS service."
        disabled={
          !this.props.session.hasRole("ORDER_CREATE") ||
          !this.props.session.hasRole("DNS_ADMIN_PUBLISH")
        }
        disabledTooltip="Your user account does not have privileges to order DNS services, contact your client manager to activate this functionality."
        invisible={this.props.session.hasRole("SYS_ADMIN")}
      />,
    );

    const filters = [
      <StyledDiv key="filters">
        {SwitchCheckbox(
          "Only display active zones",
          this.state.parameters.onlyNotDeleted,
          (o) =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                onlyNotDeleted: o.target.checked,
              }),
            }),
        )}
        <OrganisationFilter
          session={this.props.session}
          onChange={(o) =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                accountIds: o,
              }),
            })
          }
        />
        <TableFilter
          value={this.state.parameters.freetext}
          onChange={(o) =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                freetext: o,
              }),
            })
          }
        />
      </StyledDiv>,
    ];

    const columns = [
      {
        label: "Zone",
        name: "zoneName",
        sortable: true,
        contentFunction: (r) => (
          <InternalLink to={"/dnsadmin/zones/" + r.id} text={r.zoneName} className="table-link" />
        ),
      },
      {
        label: "Organisation",
        name: "organisation",
        sortable: true,
        contentFunction: (r) =>
          FormatOrganisation(
            r.organisation,
            this.props.session.getApplication().organisationPrefix,
          ),
      },
      {
        label: "Type",
        name: "zoneType",
        style: { width: "120px" },
        contentFunction: (r) => <div>{r.zoneType.label}</div>,
      },
      {
        label: "DNSSEC Status",
        name: "dnsSecStatus",
        style: { width: "120px" },
        contentFunction: (r) => (
          <div style={this.getDnsSecStatusStyle(r.dnsSecStatus.value)}>
            {r.zoneType.value === "MASTER" ? r.dnsSecStatus.label : ""}
          </div>
        ),
      },
      {
        label: "Publish Status",
        name: "publishStatus",
        style: { width: "120px" },
        contentFunction: (r) => (
          <div style={this.getPublishStatusStyle(r.publishStatus.value)}>
            {r.publishStatus.label}
          </div>
        ),
      },
      {
        label: "Publish Date",
        name: "published",
        sortable: true,
        style: { width: "120px" },
        contentFunction: (r) => FormatDateMonospace(r.publishedDate),
      },
    ];

    return (
      <div>
        <TableBackend
          session={this.props.session}
          window={this.props.window}
          actions={actions}
          filters={filters}
          columns={columns}
          sorting="-published"
          parameters={this.state.parameters}
          urlFunction={this.getUrl}
        />
        <CreateOrderDialog
          session={this.props.session}
          show={this.state.showCreateDialog}
          onClose={() => this.setState({ showCreateDialog: false })}
          step="OPERATION"
          operation="SUBSCRIPTION-IT_DNS"
        />
      </div>
    );
  }
}

export default ZoneList;
