import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { DropdownButton } from "../common/Buttons";
import styled from "styled-components";

const DocumentTypeFilterStyle = styled.span`
  .dropdown {
    vertical-align: top;
    margin-right: 20px;
  }
`;

const ITEMS = [
  ["invoice", "All Invoices"],
  ["receipt", "All Receipts"],
];

export default class DocumentTypeFilter extends Component {
  render() {
    let items = [];
    let index = 0;

    for (let i = 0; i < ITEMS.length; i++) {
      items.push(
        <Dropdown.Item
          key={ITEMS[i][1]}
          onClick={(o) => this.props.onChange(ITEMS[i][0])}
        >
          {ITEMS[i][1]}
        </Dropdown.Item>,
      );

      if (ITEMS[i][0] === this.props.value) {
        index = i;
      }
    }

    return (
      <DocumentTypeFilterStyle>
        <DropdownButton
          title={ITEMS[index][1]}
          style={{ verticalAlign: "top" }}
        >
          {items}
        </DropdownButton>
      </DocumentTypeFilterStyle>
    );
  }
}
