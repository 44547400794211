import React, { Component } from "react";
import { TableFrontend } from "../common/Tables";
import {
  FormatDate,
  FormatDateMonospace,
  FormatFloatMonospace,
} from "../common/Utils";
import { SwitchCheckbox } from "../common/theme/Theme";

class ActivitiesTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onlyDisplayActive: false,
    };
  }

  handleCheckboxOnChange = (event) => {
    this.setState({ onlyDisplayActive: event.target.checked });
  };

  filterByActive = (rows) => {
    if (this.state.onlyDisplayActive) {
      rows = rows.filter((o) => o.active);
    }
    return rows;
  };

  render() {
    const columns = [
      {
        label: "Description",
        name: "description",
        sortable: true,
        filterable: true,
      },
      {
        label: "Delivery Date",
        name: "deliverDate",
        sortable: true,
        filterable: true,
        contentFunction: (r) => FormatDateMonospace(r.deliverDate),
        filterFunction: (r) => FormatDate(r.deliverDate),
      },
      {
        label: "Unit Type",
        name: "unitType",
        sortable: true,
        filterable: true,
        contentFunction: (r) => r.unitType.label,
        sortFunction: (r) => r.unitType.label,
        filterFunction: (r) => r.unitType.label,
      },
      {
        label: "Unit Count",
        name: "unitCount",
        sortable: true,
        style: { textAlign: "right" },
        contentFunction: (r) => FormatFloatMonospace(r.unitCount),
      },
      {
        label: "Unit Price",
        name: "unitPrice",
        sortable: true,
        style: { textAlign: "right" },
        contentFunction: (r) => FormatFloatMonospace(r.unitPrice),
      },
      {
        label: "Total Price",
        name: "totalPrice",
        sortable: true,
        style: { textAlign: "right" },
        contentFunction: (r) => FormatFloatMonospace(r.totalPrice),
      },
      {
        label: "Currency",
        name: "currency",
        sortable: true,
        filterable: true,
        contentFunction: (r) => r.currency.label,
        sortFunction: (r) => r.currency.label,
        filterFunction: (r) => r.currency.label,
      },
    ];

    const { session, window } = this.props;
    const url = this.props.project.links.activities;

    const filters = [
      SwitchCheckbox(
        "Only display recent items",
        this.state.onlyDisplayActive,
        (o) => this.handleCheckboxOnChange(o),
      ),
    ];

    return (
      <TableFrontend
        session={session}
        window={window}
        otherFilters={filters}
        filterable={true}
        columns={columns}
        sorting="-deliverDate"
        url={url}
        responseFunction={this.filterByActive}
        reload={this.state.onlyDisplayActive}
      />
    );
  }
}

export default ActivitiesTab;
