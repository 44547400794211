import React, { Component } from "react";
import { UpdateCommentsIcon } from "../common/theme/Icons";

export default class ProjectComponent extends Component {
  render() {
    if (this.props.projectId) {
      return (
        <a
          href={
            this.props.session.getDomasUrl() +
            "/#!project/" +
            this.props.projectId
          }
          target="_blank"
          title="Open the case in the Domas project module"
        >
          {this.props.projectNumber}
        </a>
      );
    } else {
      return (
        <UpdateCommentsIcon
          onClick={() => this.props.onEdit()}
          title="Create a case for this hit"
        />
      );
    }
  }
}
