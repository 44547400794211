import React, { Component } from "react";

import OperationStep from "./steps/OperationStep";
import SummaryStep from "./steps/SummaryStep";
import ResultStep from "./steps/ResultStep";
import PrivilegeStep from "./steps/PrivilegeStep";

import AccountStep from "./steps/AccountStep";
import DomainNamesStep from "./steps/DomainNamesStep";
import DescriptionsStep from "./steps/DescriptionsStep";
import ExtensionDomainStep from "./steps/ExtensionDomainStep";
import ExtensionContactStep from "./steps/ExtensionContactStep";
import AgreementStep from "./steps/AgreementStep";

import ManualParameterStep from "./steps/ManualParameterStep";
import ManualContactStep from "./steps/ManualContactStep";

import TrademarkRegisterParameterStep from "./steps/TrademarkRegisterParameterStep";
import TrademarkRegisterRegionStep from "./steps/TrademarkRegisterRegionStep";
import TrademarkRegisterClassStep from "./steps/TrademarkRegisterClassStep";

import DomainRegisterParameterStep from "./steps/DomainRegisterParameterStep";
import DomainRegistrarTransferParameterStep from "./steps/DomainRegistrarTransferParameterStep";
import DomainOwnerTransferParameterStep from "./steps/DomainOwnerTransferParameterStep";
import DomainSwitchContactParameterStep from "./steps/DomainSwitchContactParameterStep";
import DomainDelegateParameterStep from "./steps/DomainDelegateParameterStep";

import SubscriptionParameterStep from "./steps/SubscriptionParameterStep";

import CertificateParameterStep from "./steps/CertificateParameterStep";
import CertificateContentStep from "./steps/CertificateContentStep";
import CertificateOrganizationStep from "./steps/CertificateOrganizationStep";
import CertificateContactStep from "./steps/CertificateContactStep";
import PaymentMethodStep from "./steps/PaymentMethodStep";
import PaymentParameterStep from "./steps/PaymentParameterStep";
import CreditsParameterStep from "./steps/CreditsParameterStep";

export default class CreateOrderDispatcher extends Component {
  render() {
    switch (this.props.conf.object.step) {
      case "OPERATION":
        return (
          <OperationStep
            session={this.props.session}
            conf={this.props.conf}
            onOperationChange={this.props.onOperationChange}
          />
        );
      case "SUMMARY":
        return (
          <SummaryStep session={this.props.session} conf={this.props.conf} />
        );
      case "RESULT":
        return (
          <ResultStep
            session={this.props.session}
            conf={this.props.conf}
            onNextOperation={this.props.onNextOperation}
          />
        );
      case "PRIVILEGE":
        return (
          <PrivilegeStep session={this.props.session} conf={this.props.conf} />
        );

      case "ACCOUNT":
        return (
          <AccountStep
            session={this.props.session}
            conf={this.props.conf}
            onParameterChange={this.props.onParameterChange}
          />
        );
      case "DOMAIN_NAMES":
        return (
          <DomainNamesStep
            session={this.props.session}
            conf={this.props.conf}
            onParameterChange={this.props.onParameterChange}
            onClose={this.props.onClose}
          />
        );
      case "DESCRIPTIONS":
        return (
          <DescriptionsStep
            session={this.props.session}
            conf={this.props.conf}
            onParameterChange={this.props.onParameterChange}
          />
        );
      case "EXTENSION_DOMAIN":
        return (
          <ExtensionDomainStep
            session={this.props.session}
            conf={this.props.conf}
            onParameterChange={this.props.onParameterChange}
          />
        );
      case "EXTENSION_CONTACT":
        return (
          <ExtensionContactStep
            session={this.props.session}
            conf={this.props.conf}
            onParameterChange={this.props.onParameterChange}
          />
        );
      case "AGREEMENT":
        return (
          <AgreementStep
            session={this.props.session}
            conf={this.props.conf}
            onParameterChange={this.props.onParameterChange}
          />
        );
      case "PAYMENT_METHOD":
        return (
          <PaymentMethodStep
            session={this.props.session}
            conf={this.props.conf}
            onParameterChange={this.props.onParameterChange}
          />
        );
      case "PAYMENT_PARAMETER":
        return (
          <PaymentParameterStep
            session={this.props.session}
            conf={this.props.conf}
            onParameterChange={this.props.onParameterChange}
            onSuccess={this.props.onNext}
          />
        );

      case "MANUAL_PARAMETER":
        return (
          <ManualParameterStep
            session={this.props.session}
            conf={this.props.conf}
            onParameterChange={this.props.onParameterChange}
          />
        );
      case "MANUAL_CONTACT":
        return (
          <ManualContactStep
            session={this.props.session}
            conf={this.props.conf}
            onParameterChange={this.props.onParameterChange}
          />
        );

      case "TRADEMARK_REGISTER_PARAMETER":
        return (
          <TrademarkRegisterParameterStep
            session={this.props.session}
            conf={this.props.conf}
            onParameterChange={this.props.onParameterChange}
          />
        );
      case "TRADEMARK_REGISTER_REGION":
        return (
          <TrademarkRegisterRegionStep
            session={this.props.session}
            conf={this.props.conf}
            onParameterChange={this.props.onParameterChange}
          />
        );
      case "TRADEMARK_REGISTER_CLASS":
        return (
          <TrademarkRegisterClassStep
            session={this.props.session}
            conf={this.props.conf}
            onParameterChange={this.props.onParameterChange}
          />
        );

      case "DOMAIN_REGISTER_PARAMETER":
        return (
          <DomainRegisterParameterStep
            session={this.props.session}
            conf={this.props.conf}
            onParameterChange={this.props.onParameterChange}
          />
        );
      case "DOMAIN_REGISTRAR_TRANSFER_PARAMETER":
        return (
          <DomainRegistrarTransferParameterStep
            session={this.props.session}
            conf={this.props.conf}
            onParameterChange={this.props.onParameterChange}
          />
        );
      case "DOMAIN_OWNER_TRANSFER_PARAMETER":
        return (
          <DomainOwnerTransferParameterStep
            session={this.props.session}
            conf={this.props.conf}
            onParameterChange={this.props.onParameterChange}
          />
        );
      case "DOMAIN_SWITCH_CONTACT_PARAMETER":
        return (
          <DomainSwitchContactParameterStep
            session={this.props.session}
            conf={this.props.conf}
            onParameterChange={this.props.onParameterChange}
          />
        );
      case "DOMAIN_DELEGATE_PARAMETER":
        return (
          <DomainDelegateParameterStep
            session={this.props.session}
            conf={this.props.conf}
            onParameterChange={this.props.onParameterChange}
          />
        );
      case "SUBSCRIPTION_PARAMETER":
        return (
          <SubscriptionParameterStep
            session={this.props.session}
            conf={this.props.conf}
            onParameterChange={this.props.onParameterChange}
          />
        );

      case "CERTIFICATE_PARAMETER":
        return (
          <CertificateParameterStep
            session={this.props.session}
            conf={this.props.conf}
            onParameterChange={this.props.onParameterChange}
          />
        );
      case "CERTIFICATE_CONTENT":
        return (
          <CertificateContentStep
            session={this.props.session}
            conf={this.props.conf}
            onParameterChange={this.props.onParameterChange}
          />
        );
      case "CERTIFICATE_ORGANIZATION":
        return (
          <CertificateOrganizationStep
            session={this.props.session}
            conf={this.props.conf}
            onParameterChange={this.props.onParameterChange}
          />
        );
      case "CERTIFICATE_CONTACT":
        return (
          <CertificateContactStep
            session={this.props.session}
            conf={this.props.conf}
            onParameterChange={this.props.onParameterChange}
          />
        );

      case "CREDITS_PARAMETER":
        return (
          <CreditsParameterStep
            session={this.props.session}
            conf={this.props.conf}
            onParameterChange={this.props.onParameterChange}
          />
        );

      default:
        return (
          <div>Error, no handler for step: {this.props.conf.object.step}</div>
        );
    }
  }
}
