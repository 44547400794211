import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Selector from "../../common/Selector";
import { InputWithLabel } from "../../common/theme/Theme";
import { FormatInput } from "../../common/Utils";

export default class CertificateOrganizationStep extends Component {
  render() {
    return (
      <Container fluid={true}>
        <Row className="section-title">
          <Col md={12}>Organisation</Col>
        </Row>
        <Row className="section-description">
          <Col xs={12}>
            Please enter organisation details. This information is use in the
            required OV/EV validation processes. It is important that everything
            is corret and complete and matches information found in public
            records.
          </Col>
        </Row>
        <Row className="section-field">
          <Col md={12}>
            <InputWithLabel
              label="Name"
              type="text"
              placeholder="Enter full legal name of your organization"
              value={FormatInput(
                this.props.conf.object.parameters.organizationName,
              )}
              onChange={(e) =>
                this.props.onParameterChange("organizationName", e.target.value)
              }
            />
          </Col>
        </Row>
        <Row className="section-field">
          <Col md={12}>
            <InputWithLabel
              label="Street 1"
              type="text"
              placeholder="Enter street 1"
              value={FormatInput(
                this.props.conf.object.parameters.organizationStreet1,
              )}
              onChange={(e) =>
                this.props.onParameterChange(
                  "organizationStreet1",
                  e.target.value,
                )
              }
            />
          </Col>
        </Row>
        <Row className="section-field">
          <Col md={12}>
            <InputWithLabel
              label="Street 2"
              type="text"
              placeholder="Enter street 2"
              value={FormatInput(
                this.props.conf.object.parameters.organizationStreet2,
              )}
              onChange={(e) =>
                this.props.onParameterChange(
                  "organizationStreet2",
                  e.target.value,
                )
              }
            />
          </Col>
        </Row>
        <Row className="section-field">
          <Col md={12}>
            <InputWithLabel
              label="Postal Code"
              type="text"
              placeholder="Enter postal Code"
              value={FormatInput(
                this.props.conf.object.parameters.organizationPostalCode,
              )}
              onChange={(e) =>
                this.props.onParameterChange(
                  "organizationPostalCode",
                  e.target.value,
                )
              }
            />
          </Col>
        </Row>
        <Row className="section-field">
          <Col md={12}>
            <InputWithLabel
              label="City"
              type="text"
              placeholder="Enter the city or locality where your organization is legally incorporated, do not abbreviate"
              value={FormatInput(
                this.props.conf.object.parameters.organizationCity,
              )}
              onChange={(e) =>
                this.props.onParameterChange("organizationCity", e.target.value)
              }
            />
          </Col>
        </Row>
        <Row className="section-field">
          <Col md={12}>
            <InputWithLabel
              label="State"
              type="text"
              placeholder="Enter state or province where your organization is legally incorporated, do not abbreviate"
              value={FormatInput(
                this.props.conf.object.parameters.organizationState,
              )}
              onChange={(e) =>
                this.props.onParameterChange(
                  "organizationState",
                  e.target.value,
                )
              }
            />
          </Col>
        </Row>
        <Row className="section-field">
          <Col md={12}>
            <Selector
              label="Country"
              placeholder="Select country where your organization is legally incorporated"
              options={this.props.conf.parameters.countries}
              defaultValue={FormatInput(
                this.props.conf.object.parameters.organizationCountry,
              )}
              onChange={(v) =>
                this.props.onParameterChange("organizationCountry", v.value)
              }
            />
          </Col>
        </Row>
        <Row className="section-field">
          <Col md={12}>
            <InputWithLabel
              label="Phone"
              type="text"
              placeholder="Enter organisation phone number"
              value={FormatInput(
                this.props.conf.object.parameters.organizationPhone,
              )}
              onChange={(e) =>
                this.props.onParameterChange(
                  "organizationPhone",
                  e.target.value,
                )
              }
            />
          </Col>
        </Row>
      </Container>
    );
  }
}
