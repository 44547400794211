import React, { Component } from "react";
import EditDialog from "../common/Dialog";
import { Container, Row } from "react-bootstrap";
import { MultiSelect } from "../common/Selector";
import { PortsAlert } from "../common/PortsAlert";

class UserZoneDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conf: null,
      selectedZoneIds: null,
      showConfirm: false,
      submit: false,
      errorMessage: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.conf && prevProps.conf !== this.props.conf) {
      this.setState({ conf: this.props.conf });
    }
  }

  handleSubmit() {
    const url = this.props.createUrl;
    this.setState({ submit: true });

    let selectedZoneIds = this.state.selectedZoneIds
      ? this.state.selectedZoneIds
      : this.getZoneIds(this.state.conf.accessibleZones);

    this.props.session.backendPut(
      url,
      selectedZoneIds,
      () => {
        this.onClose();
        this.setState({ submit: false });
        this.props.window.showSuccessFunc("Zones are updated successfully!");
      },
      (message) => {
        this.setState({ submit: false, errorMessage: message });
      },
    );
  }

  getZoneIds(zones) {
    return zones.map((zone) => zone.value);
  }

  onChange(selectedZoneIds) {
    this.setState({
      selectedZoneIds: selectedZoneIds,
    });
  }

  onClose() {
    this.setState({ errorMessage: null });
    this.props.closeDialog();
  }

  render() {
    if (!this.state.conf) {
      return null;
    }

    const content = [
      <Container key="selected-zones" fluid={true}>
        <Row>
          <MultiSelect
            options={this.state.conf.zones}
            value={this.getZoneIds(this.state.conf.accessibleZones)}
            selectedLabel={"Selected Zones"}
            notSelectedLabel={"Not Selected Zones"}
            size={15}
            onChange={this.onChange}
          />
        </Row>
        <Row>
          <div>
            <br />
            <br />
            <PortsAlert variant="warning">
              <strong>Note:</strong> If you select no zones, the user will have
              access to all zones under the organisations, including newly
              created zones.
            </PortsAlert>
          </div>
        </Row>
      </Container>,
    ];

    return (
      <div>
        <EditDialog
          content={content}
          show={this.props.showDialog}
          title="Edit Zones"
          onCancel={this.onClose}
          onConfirm={this.handleSubmit}
          width="lg"
          submit={this.state.submit}
          errorMessage={this.state.errorMessage}
        />
      </div>
    );
  }
}

export default UserZoneDialog;
