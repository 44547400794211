import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ContactField from "./ContactField";

export default class DomainSwitchContactParameterStep extends Component {
  render() {
    return (
      <Container fluid={true}>
        <Row className="section-title">
          <Col xs={12}>Parameters / Settings</Col>
        </Row>
        <Row className="section-description">
          <Col xs={12}>Enter the parameters below</Col>
        </Row>
        <ContactField type="registrant" {...this.props} />
        <ContactField type="admin" {...this.props} />
        <ContactField type="billing" {...this.props} />
        <ContactField type="tech" {...this.props} />
      </Container>
    );
  }
}
