import React, { Component } from "react";
import { InputWithLabel } from "./theme/Theme";
import EditDialog from "./Dialog";

class AuthCodeDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newAuthCode: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && prevProps.show !== this.props.show) {
      this.setState({ newAuthCode: this.props.authCode });
    }
  }

  onSave = () => {
      this.props.onSave(this.state.newAuthCode);
  };

  render() {
    let element = [
      <InputWithLabel
        key="input"
        label="Auth Code"
        type="textarea"
        name="authcode"
        rows="1"
        placeholder="Enter auth code here"
        onChange={(e) => this.setState({ newAuthCode: e.target.value })}
        autoFocus
      />,
    ];


    return (
      <EditDialog
        content={element}
        show={this.props.show}
        title="Update Auth Code"
        onCancel={this.props.onClose}
        onConfirm={this.onSave}
        session={this.props.session}
        errorMessage={this.props.errorMessage}
      />
    );
  }
}

export default AuthCodeDialog;
