import React, { Component } from "react";
import styled from "styled-components";
import { DropdownButton } from "../../common/Buttons";
import { Dropdown } from "react-bootstrap";

const StatusFilterStyle = styled.span`
  .dropdown {
    vertical-align: top;
    margin-right: 20px;
  }
`;
const ALL = "Any Status";

export default class StatusFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: [],
      title: ALL,
    };
  }

  onChange(event) {
    if (event) {
      this.setState({ selection: event });
    }
    this.props.onChange(event);
  }

  render() {
    if (!this.state.options || this.state.options.length === 0) {
      this.props.session.backendGet(this.props.url, (response) => {
        this.setState({ options: response.objects });
      });
      return null;
    }

    let items = [];

    items.push(
      <Dropdown.Item
        key={"all"}
        onClick={(o) => {
          this.setState({ title: ALL });
          this.props.onChange("");
        }}
      >
        {ALL}
      </Dropdown.Item>,
    );

    for (let i = 0; i < this.state.options.length; i++) {
      items.push(
        <Dropdown.Item
          key={this.state.options[i].label}
          onClick={(o) => {
            this.setState({ title: this.state.options[i].label + " Domains" });
            this.props.onChange(this.state.options[i].value);
          }}
        >
          {this.state.options[i].label} Domains
        </Dropdown.Item>,
      );
    }

    return (
      <StatusFilterStyle>
        <DropdownButton
          title={this.state.title}
          style={{ verticalAlign: "top" }}
        >
          {items}
        </DropdownButton>
      </StatusFilterStyle>
    );
  }
}
