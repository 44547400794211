import React, { Component } from "react";
import { WizardDialog } from "../common/Dialog";
import CreateOrderDispatcher from "./CreateOrderDispatcher";

export default class CreateOrderDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conf: null,
      errorMessage: null,
      ct: 0,
    };
  }

  componentDidMount() {
    if (this.props.show) {
      this.init();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && !prevProps.show) {
      this.init();
    } else if (!this.props.show && prevProps.show) {
      this.clear();
    }
  }

  init = () => {
    let url = this.props.session.getModules().order.links.createOrderStart;

    let order = {
      step: this.props.step,
      operationId: this.props.operation,
      parameters: this.props.parameters,
    };

    this.props.session.backendPost(
      url,
      order,
      (response) => {
        this.setState({
          conf: response,
          errorMessage: null,
          ct: Date.now(),
        });
      },
      (errorMessage) => {
        this.setState({
          errorMessage: errorMessage,
          ct: Date.now(),
        });
      },
    );
  };

  next = () => {
    const url = this.props.session.getModules().order.links.createOrderNext;
    this.props.session.backendPost(
      url,
      this.state.conf.object,
      (response) => {
        this.setState({
          conf: response,
          errorMessage: null,
          ct: Date.now(),
        });
      },
      (errorMessage) => {
        this.setState({
          errorMessage: errorMessage,
          ct: Date.now(),
        });
      },
    );
  };

  update = () => {
    const url = this.props.session.getModules().order.links.createOrderUpdate;
    this.props.session.backendPost(
      url,
      this.state.conf.object,
      (response) => {
        this.setState({
          conf: response,
          errorMessage: null,
          ct: Date.now(),
        });
      },
      (errorMessage) => {
        this.setState({
          errorMessage: errorMessage,
          ct: Date.now(),
        });
      },
    );
  };

  previous = () => {
    const url = this.props.session.getModules().order.links.createOrderPrevious;
    this.props.session.backendPost(
      url,
      this.state.conf.object,
      (response) => {
        this.setState({
          conf: response,
          errorMessage: null,
          ct: Date.now(),
        });
      },
      (errorMessage) => {
        this.setState({
          errorMessage: errorMessage,
          ct: Date.now(),
        });
      },
    );
  };

  onOperationChange = (value) => {
    let conf = this.state.conf;
    conf.object.operationId = value;
    this.setState({ conf: conf });
  };

  onParameterChange = (name, value, update) => {
    let parameters = this.state.conf.object.parameters;
    parameters[name] = value;
    this.setState({
      conf: Object.assign({}, this.state.conf, {
        conf: { object: { parameters: parameters } },
      }),
    });
    if (update) {
      this.update();
    }
  };

  clear = () => {
    this.setState({
      conf: null,
      errorMessage: null,
      ct: 0,
    });
  };

  onNextOperation = (operationId) => {
    let object = this.state.conf.object;
    object.operationId = operationId;
    object.start = null;
    object.step = "OPERATION";

    const url = this.props.session.getModules().order.links.createOrderStart;
    this.props.session.backendPost(
      url,
      object,
      (response) => {
        this.setState({
          conf: response,
          errorMessage: null,
        });
      },
      (errorMessage) => {
        this.setState({
          errorMessage: errorMessage,
        });
      },
    );
  };

  getTitle = () => {
    if (!this.state.conf) {
      return "Create Order";
    }

    if (this.state.conf.object.step === "OPERATION") {
      return "Create Order";
    }

    return "Create Order - " + this.state.conf.operation.description;
  };

  getBody = () => {
    if (!this.state.conf) {
      return <div>Loading...</div>;
    }

    return (
      <CreateOrderDispatcher
        session={this.props.session}
        conf={this.state.conf}
        onOperationChange={this.onOperationChange}
        onParameterChange={this.onParameterChange}
        onNextOperation={this.onNextOperation}
        onNext={this.next}
        onClose={this.props.onClose}
      />
    );
  };

  getErrors = () => {
    if (!this.state.conf) {
      return null;
    }

    return this.state.conf.errors;
  };

  getWarnings = () => {
    if (!this.state.conf) {
      return null;
    }

    return this.state.conf.warnings;
  };

  getNotes = () => {
    if (!this.state.conf) {
      return null;
    }

    return this.state.conf.notes;
  };

  render() {
    let cancelButton = false;
    let closeButton = false;
    let previousButton = false;
    let nextButton = false;
    let createButton = false;

    if (this.state.conf?.object.step) {
      switch (this.state.conf.object.step) {
        case "OPERATION":
        case "CREDITS_PARAMETERS":
        case this.state.conf.object.start:
          cancelButton = true;
          nextButton = true;
          break;
        case "MANUAL":
        case "PRIVILEGE":
          previousButton = true;
          closeButton = true;
          break;
        case "SUMMARY":
          previousButton = true;
          createButton = true;
          break;
        case "RESULT":
        case "PAYMENT_PARAMETER":
          closeButton = true;
          break;
        default:
          previousButton = true;
          nextButton = true;
          break;
      }
    }

    return (
      <WizardDialog
        show={this.props.show}
        title={this.getTitle()}
        body={this.getBody()}
        width="lg"
        height="lg"
        errorMessage={this.state.errorMessage}
        errorMessages={this.getErrors()}
        warningMessages={this.getWarnings()}
        noteMessages={this.getNotes()}
        onCancel={cancelButton ? this.props.onClose : null}
        onClose={closeButton ? this.props.onClose : null}
        onPrevious={previousButton ? this.previous : null}
        onNext={nextButton ? this.next : null}
        onCreate={createButton ? this.next : null}
        reload={this.state.ct}
      />
    );
  }
}
