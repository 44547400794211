import React, { Component } from "react";
import {
  DeleteButton,
  BlockingMainActionButton,
  NavigationButton,
} from "../../common/Buttons";
import SchedulePublishZonesDialog from "../common/SchedulePublishZonesDialog";
import { DetailsDialog } from "../../common/Dialog";
import ZoneComparison from "../common/ZoneComparison";

class ReviewDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      loading: false,
      showSchedulePublishZonesDialog: false,
      errorMessage: null,
    };
  }

  componentDidMount() {
    this.reload();
  }

  componentDidUpdate(prevProps) {
    if (this.props.zone && prevProps.zone !== this.props.zone) {
      this.reload();
    }
  }

  reload = () => {
    if (this.props.zone) {
      this.setState({ loading: true });
      this.props.session.backendGet(
        this.props.zone.links.review,
        (response) => {
          this.setState({
            loading: false,
            data: response,
          });
        },
      );
    }
  };

  getPublishNowTooltip = (hasRole) => {
    if (!hasRole) {
      return "Your user account does not have privileges to publish changes. Contact your client manager to activate this functionality";
    }
    return "";
  };

  getButtons = () => {
    let buttons = [];
    buttons.push(
      <NavigationButton
        key="close"
        onClick={this.onClose}
        text="Close"
        type={"button"}
      />,
    );

    const publishStatus = this.props.zone.publishStatus.value;

    if (
      this.props.session.hasRole("DNS_ADMIN_EDIT", "DNS_ADMIN_PUBLISH") &&
      publishStatus !== "CREATED" &&
      publishStatus !== "CURRENT"
    ) {
      buttons.push(
        <DeleteButton
          onClick={this.restoreZone}
          text={"Revert Changes"}
          key={"ZoneButton-restore"}
        />,
      );
    }

    if (
      this.props.session.hasRole("DNS_ADMIN_PUBLISH") &&
      publishStatus !== "DELETED" &&
      publishStatus !== "CURRENT" &&
      publishStatus !== "SCHEDULED"
    ) {
      buttons.push(
        <BlockingMainActionButton
          key="schedule-changes"
          onClick={this.schedule}
          color={"primary"}
          text="Schedule Publish"
        />,
      );
    } else if (
      this.props.session.hasRole("DNS_ADMIN_PUBLISH") &&
      publishStatus === "SCHEDULED"
    ) {
      buttons.push(
        <DeleteButton
          key="cancel-schedule-changes"
          onClick={this.unSchedule}
          text="Cancel Scheduled Publish"
        />,
      );
    }

    buttons.push(
      <BlockingMainActionButton
        disabled={
          !this.props.session.hasRole("DNS_ADMIN_PUBLISH") ||
          publishStatus === "DELETED" ||
          publishStatus === "CURRENT"
        }
        disabledTooltip={this.getPublishNowTooltip(
          this.props.session.hasRole("DNS_ADMIN_PUBLISH"),
        )}
        onClick={this.publishZone}
        text={"Publish Now"}
        key={"ZoneButton-publish"}
        reload={this.state.errorMessage}
      />,
    );

    return <div>{buttons}</div>;
  };

  getBody = () => {
    return <ZoneComparison data={this.state.data} />;
  };

  onClose = () => {
    this.setState({ errorMessage: null });
    this.props.onClose(false);
  };

  schedule = () => {
    this.onClose();
    this.setState({ showSchedulePublishZonesDialog: true });
  };

  onScheduleCancel = () => {
    this.setState({
      showSchedulePublishZonesDialog: false,
      errorMessage: null,
    });
  };

  onScheduleOk = (data) => {
    const errorFun = (msg) => {
      this.setState({ errorMessage: msg });
    };
    const callback = () => {
      this.setState({
        showSchedulePublishZonesDialog: false,
        errorMessage: null,
      });
      const message = "Scheduled zone publication successfully";
      this.props.window.showSuccessFunc(message);
      this.props.onClose(true);
    };
    let url = this.props.zone.links.schedule;
    this.props.session.backendPost(
      url,
      {
        when: data.when,
      },
      callback,
      errorFun,
    );
  };

  unSchedule = () => {
    const errorFun = (msg) => {
      this.setState({ errorMessage: msg });
    };
    const callback = () => {
      this.setState({ errorMessage: null });
      const message = "Cancelled zone publication successfully";
      this.props.window.showSuccessFunc(message);
      this.props.onClose(true);
    };
    let url = this.props.zone.links.unSchedule;
    this.props.session.backendPost(url, {}, callback, errorFun);
  };

  publishZone = () => {
    const errorFun = (msg) => {
      this.setState({ errorMessage: msg });
    };
    const callback = () => {
      this.setState({ errorMessage: null });
      const message = "Zone successfully published";
      this.props.window.showSuccessFunc(message);
      this.props.onClose(true);
    };
    this.setState({ errorMessage: null });
    const url = this.props.zone.links.publish;
    this.props.session.backendPost(url, {}, callback, errorFun);
  };

  restoreZone = () => {
    const errorFun = (msg) => {
      this.setState({ errorMessage: msg });
    };
    const callback = () => {
      this.setState({ errorMessage: null });
      const message = "Zone successfully restored";
      this.props.window.showSuccessFunc(message);
      this.props.onClose(true);
    };

    const url = this.props.zone.links.restore;
    this.props.session.backendPost(url, {}, callback, errorFun);
  };

  render() {
    return (
      <div>
        <SchedulePublishZonesDialog
          showDialog={this.state.showSchedulePublishZonesDialog}
          onCancel={this.onScheduleCancel}
          onOk={this.onScheduleOk}
          errorMessage={this.state.errorMessage}
        />
        <DetailsDialog
          show={this.props.show}
          size="large"
          title="Review and Publish"
          body={this.getBody()}
          footer={this.getButtons()}
          width="lg"
          height="lg"
          errorMessage={this.state.errorMessage}
        />
      </div>
    );
  }
}

export default ReviewDialog;
