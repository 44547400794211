import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Selector from "../../common/Selector";
import { InputWithLabel } from "../../common/theme/Theme";
import { FormatInput } from "../../common/Utils";

export default class CertificateContentStep extends Component {
  getBody = () => {
    if (this.props.conf.object.parameters.mode == null) {
      return null;
    }
    if (this.props.conf.object.parameters.mode === "csr") {
      return this.getModeCsr();
    } else {
      return this.getModeDetails();
    }
  };

  getModeCsr = () => {
    let rows = [];

    rows.push(
      <Row key="csr-text" className="section-title">
        <Col md={12}>CSR Text</Col>
      </Row>,
    );

    rows.push(
      <Row key="csr-text-input" className="section-field">
        <Col xs={12}>
          <textarea
            rows="15"
            placeholder="Paste CSR text here"
            value={FormatInput(this.props.conf.object.parameters.csr)}
            onChange={(e) =>
              this.props.onParameterChange("csr", e.target.value, true)
            }
          />
        </Col>
      </Row>,
    );

    if (this.props.conf.object.parameters.csr != null) {
      rows.push(
        <Row key="csr-fields" className="section-title">
          <Col xs={12}>CSR Fields</Col>
        </Row>,
      );

      this.appendReadonlyField(
        rows,
        "Common Name",
        this.props.conf.object.parameters.commonName,
      );
      this.appendReadonlyField(
        rows,
        "Subject Alternative Names",
        this.props.conf.object.parameters.sans,
      );
      this.appendReadonlyField(
        rows,
        "Organization",
        this.props.conf.object.parameters.organization,
      );
      this.appendReadonlyField(
        rows,
        "Organizational Unit",
        this.props.conf.object.parameters.organizationalUnit,
      );
      this.appendReadonlyField(
        rows,
        "City / Locality",
        this.props.conf.object.parameters.locality,
      );
      this.appendReadonlyField(
        rows,
        "State",
        this.props.conf.object.parameters.state,
      );
      this.appendReadonlyField(
        rows,
        "Country",
        this.props.conf.object.parameters.countryId,
      );
    }

    return rows;
  };

  appendReadonlyField(rows, label, value) {
    if (value == null) {
      return;
    }

    rows.push(
      <Row key={"field-" + label} className="section-field">
        <Col md={5}>
          <b>{label}:</b>
        </Col>
        <Col md={7} style={{ whiteSpace: "pre-line" }}>
          {value}
        </Col>
      </Row>,
    );
  }

  getModeDetails = () => {
    let parameters = this.props.conf.object.parameters;
    let rows = [];

    rows.push(
      <Row key="csr-field" className="section-title">
        <Col md={12}>CSR Fields</Col>
      </Row>,
    );

    if (this.props.conf.parameters.fieldCommonName) {
      rows.push([
        <Row key="csr-common-name" className="section-field">
          <Col md={12}>
            <InputWithLabel
              type="text"
              label={"Common Name"}
              placeholder="Enter fully-qualified domain name you want to secure with the certificate"
              value={FormatInput(parameters.commonName)}
              onChange={(e) =>
                this.props.onParameterChange("commonName", e.target.value)
              }
            />
          </Col>
        </Row>,
      ]);
    }

    if (this.props.conf.parameters.fieldSans) {
      rows.push([
        <Row key="subject-alternative-names" className="section-field">
          <Col md={12}>
            <InputWithLabel
              type="textarea"
              rows="4"
              label={"Subject Alternative Names"}
              placeholder="Enter subject alternative names, one per line"
              value={FormatInput(parameters.sans)}
              onChange={(e) =>
                this.props.onParameterChange("sans", e.target.value)
              }
            />
          </Col>
        </Row>,
      ]);
    }

    rows.push([
      <Row key="organisation" className="section-field">
        <Col md={12}>
          <InputWithLabel
            type="text"
            label={"Organization"}
            placeholder="Enter full legal name of your organization"
            value={FormatInput(parameters.organization)}
            onChange={(e) =>
              this.props.onParameterChange("organization", e.target.value)
            }
          />
        </Col>
      </Row>,
      <Row key="organisation-unit" className="section-field">
        <Col md={12}>
          <InputWithLabel
            type="text"
            label={"Organizational Unit"}
            placeholder="Enter your department, e.g., Information Technology"
            value={FormatInput(parameters.organizationalUnit)}
            onChange={(e) =>
              this.props.onParameterChange("organizationalUnit", e.target.value)
            }
          />
        </Col>
      </Row>,
      <Row key="city" className="section-field">
        <Col md={12}>
          <InputWithLabel
            type="text"
            label="City / Locality"
            placeholder="Enter the city or locality where your organization is legally incorporated, do not abbreviate"
            value={FormatInput(parameters.locality)}
            onChange={(e) =>
              this.props.onParameterChange("locality", e.target.value)
            }
          />
        </Col>
      </Row>,
      <Row key="state" className="section-field">
        <Col md={12}>
          <InputWithLabel
            type="text"
            label="State / Province"
            placeholder="Enter state or province where your organization is legally incorporated, do not abbreviate"
            value={FormatInput(parameters.state)}
            onChange={(e) =>
              this.props.onParameterChange("state", e.target.value)
            }
          />
        </Col>
      </Row>,
      <Row key="country" className="section-field">
        <Col md={12}>
          <Selector
            label="Country"
            placeholder="Select country where your organization is legally incorporated"
            options={this.props.conf.parameters.countries}
            defaultValue={FormatInput(parameters.countryId)}
            onChange={(v) => this.props.onParameterChange("countryId", v.value)}
          />
        </Col>
      </Row>,
    ]);

    return rows;
  };

  onModeChange = (e) => {
    this.props.onParameterChange("mode", e.target.value, true);
  };

  render() {
    return (
      <Container fluid={true}>
        <Row className="section-title">
          <Col xs={12}>Certificate Contents</Col>
        </Row>
        <Row className="section-field">
          <Col md={12}>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  name="mode"
                  value="csr"
                  onChange={this.onModeChange}
                  checked={this.props.conf.object.parameters.mode === "csr"}
                />
                Provide a Certificate Signing Request (CSR) you have created
                yourself (recommended)
              </label>
            </div>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  name="mode"
                  value="details"
                  onChange={this.onModeChange}
                  checked={this.props.conf.object.parameters.mode === "details"}
                />
                Provide details and let us create the CSR (a private key will be
                sent to you)
              </label>
            </div>
          </Col>
        </Row>
        {this.getBody()}
      </Container>
    );
  }
}
