import React, { Component } from "react";
import { FormatDateMonospace } from "./Utils";
import styled from "styled-components";
import Colors from "./theme/Colors";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const FormatClass = (trademark) => {
  const classes = [];
  trademark.classes.forEach((o) => {
    classes.push(<TrademarkClass id={o.id} text={o.text} />);
  });
  return <div>{classes}</div>;
};

export const FormatCountry = (trademark) => {
  const countries = [];
  trademark.countries.forEach((o) => {
    countries.push(
      <TrademarkCountry
        id={o.id}
        country={o.country}
        status={o.status}
        progress={o.progress}
      />,
    );
  });
  return <div>{countries}</div>;
};

export const FormatNumber = (trademark) => {
  let number = [];
  const applicationNumber = (
    <div>
      {trademark.applicationNumber === null
        ? "(blank)"
        : trademark.applicationNumber}
    </div>
  );
  const registrationNumber = (
    <div>
      {trademark.registrationNumber === null
        ? "(blank)"
        : trademark.registrationNumber}
    </div>
  );
  number.push(applicationNumber);
  number.push(registrationNumber);
  return number;
};

export const FormatDate = (trademark) => {
  let date = [];
  const applicationDate = (
    <div>
      {trademark.applicationDate === null
        ? "(blank)"
        : FormatDateMonospace(trademark.applicationDate)}
    </div>
  );
  const registrationDate = (
    <div>
      {trademark.registrationDate === null
        ? "(blank)"
        : FormatDateMonospace(trademark.registrationDate)}
    </div>
  );
  date.push(applicationDate);
  date.push(registrationDate);
  if (trademark.renewalDate != null) {
    date.push(<div>{FormatDateMonospace(trademark.renewalDate)}</div>);
  }
  return date;
};

const ClassTrigger = styled.button`
  margin-right: 5px;
  margin-bottom: 5px;
  background: ${Colors.tertiary};
  color: ${Colors.white};
  padding: 7px;
  border-radius: 3px;
  border: none;
  font-size: 11px;
  line-height: 10px;
  font-weight: 700;
`;

const CountryTrigger = styled(ClassTrigger)`
  && {
    color: ${Colors.white};
    &.PARTIAL {
      background: ${Colors.warning};
    }
    &.SUCCESS {
      background: ${Colors.success};
    }
    &.FAILURE {
      background: ${Colors.error};
    }
    &.PENDING,
    &.NA {
      background: ${Colors.tertiary};
      color: ${Colors.white};
    }
  }
`;

class TrademarkCountry extends Component {
  render() {
    const tooltip = (
      <Tooltip id="tooltip" style={{ position: "fixed" }}>
        {this.props.country + " - " + this.props.status}
      </Tooltip>
    );

    return (
      <OverlayTrigger placement="bottom" overlay={tooltip}>
        <CountryTrigger className={this.props.progress}>
          {this.props.id}
        </CountryTrigger>
      </OverlayTrigger>
    );
  }
}

class TrademarkClass extends Component {
  render() {
    const popover = (
      <Tooltip id="tooltip" style={{ position: "fixed" }}>
        {this.props.text}
      </Tooltip>
    );

    return (
      <OverlayTrigger placement={"bottom"} overlay={popover}>
        <ClassTrigger>{this.props.id}</ClassTrigger>
      </OverlayTrigger>
    );
  }
}
