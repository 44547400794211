import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { NavTab } from "react-router-tabs";
import { PageTabsNew } from "../common/PageTabs";
import Icon from "../common/theme/Icons";
import { Loader } from "../common/theme/Theme";
import ActivitiesTab from "./ActivitiesTab";
import OrdersTab from "./OrdersTab";
import ProjectHeader from "./ProjectHeader";
import DocumentsTab from "./DocumentsTab";
import ActionsTab from "./ActionsTab";
import ProjectInformationTab from "./ProjectInformationTab";

class ProjectDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      project: null,
      baseUrl: null,
      activeTab: 1,
    };
  }

  componentDidMount() {
    this.reloadProject();
  }

  reloadProject = () => {
    let url;
    if (this.props.caseModule) {
      url =
        this.props.session.getModules().monitoring.links.cases +
        "/" +
        this.props.match.params.caseId;
    } else {
      url =
        this.props.session.getModules().project.links.projects +
        "/" +
        this.props.match.params.projectId;
    }
    this.props.session.backendGet(
      url,
      (response) => {
        this.setState({
          loaded: true,
          project: response,
          baseUrl: this.props.caseModule
            ? `/monitoring/cases/${response.id}`
            : `/project/projects/${response.id}`,
        });

        let views = [
          {
            label: "Home",
            url: "/",
          },
          {
            label: this.props.caseModule ? "Cases" : "Projects",
            url: this.props.caseModule
              ? "/monitoring/cases"
              : "/project/projects",
          },
          {
            label: response.name,
            url: null,
          },
        ];

        this.props.window.setBreadcrumbViews(views);
        this.props.window.setPageTip(null);
      },
      (msg) => {
        this.props.window.showErrorFunc(msg);
      },
    );
  };

  handleTabSelect = (event) => {
    const clickedTab = event.target.getAttribute("data-tab");
    this.setState({ activeTab: clickedTab });
  };

  render() {
    const information = () => {
      return (
        <ProjectInformationTab
          project={this.state.project}
          session={this.props.session}
          window={this.props.window}
        />
      );
    };

    const actions = () => {
      return (
        <ActionsTab
          project={this.state.project}
          session={this.props.session}
          window={this.props.window}
        />
      );
    };
    const activities = () => {
      return (
        <ActivitiesTab
          project={this.state.project}
          session={this.props.session}
          window={this.props.window}
        />
      );
    };
    const orders = () => {
      return (
        <OrdersTab
          project={this.state.project}
          session={this.props.session}
          window={this.props.window}
        />
      );
    };
    const documents = () => {
      return (
        <DocumentsTab
          project={this.state.project}
          session={this.props.session}
          onChange={this.reloadProject}
          window={this.props.window}
        />
      );
    };
    const Tabs = (props) => {
      let tabs = [];
      let tabIndex = 1;
      if (props.project.typeCase) {
        tabs.push(
          <NavTab
            onClick={this.handleTabSelect}
            key={"project-" + props.project.id + "-information"}
            data-tab={tabIndex++}
            to={`${props.baseUrl}/information`}
          >
            <Icon name="bars" /> Information
          </NavTab>,
        );
        tabs.push(
          <NavTab
            onClick={this.handleTabSelect}
            key={"project-" + props.project.id + "-actions"}
            data-tab={tabIndex++}
            to={`${props.baseUrl}/actions`}
          >
            <Icon name="square-check" /> Actions
          </NavTab>,
        );
      }

      tabs.push(
        <NavTab
          onClick={this.handleTabSelect}
          key={"project-" + props.project.id + "-activities"}
          data-tab={tabIndex++}
          to={`${props.baseUrl}/activities`}
        >
          <Icon name="tasks" /> Activities
        </NavTab>,
      );
      tabs.push(
        <NavTab
          onClick={this.handleTabSelect}
          key={"project-" + props.project.id + "-orders"}
          data-tab={tabIndex++}
          to={`${props.baseUrl}/orders`}
        >
          <Icon name="angle-double-right" /> Orders
        </NavTab>,
      );
      tabs.push(
        <NavTab
          onClick={this.handleTabSelect}
          key={"project-" + props.project.id + "-documents"}
          data-tab={tabIndex++}
          to={`${props.baseUrl}/documents`}
        >
          <Icon name="file-alt" /> Documents
        </NavTab>,
      );
      return tabs;
    };

    const { project, loaded, baseUrl } = this.state;
    const { session, window } = this.props;
    const projectName = project ? project.name : "Loading name...";
    const projectOrg = project
      ? project.organisation
      : "Loading organisation...";

    if (this.state.project) {
      return (
        <Loader loaded={loaded}>
          <ProjectHeader
            session={session}
            caseModule={this.props.caseModule}
            project={project}
            window={window}
            title={projectName}
            org={projectOrg}
          />
          <PageTabsNew className={"nav nav-tabs"}>
            <Tabs project={project} baseUrl={baseUrl} />
          </PageTabsNew>
          <Switch>
            {this.state.project.typeCase && (
              <Route
                exact
                path={`${baseUrl}`}
                key={"route-information-redirect-1"}
                render={() => (
                  <Redirect replace to={`${baseUrl}/information`} />
                )}
              />
            )}
            {!this.state.project.typeCase && (
              <Route
                exact
                path={`${baseUrl}`}
                key={"route-activities-redirect-1"}
                render={() => <Redirect replace to={`${baseUrl}/activities`} />}
              />
            )}
            <Route
              path={`${baseUrl}/information`}
              component={information}
              key={"route-information"}
            />
            <Route
              path={`${baseUrl}/actions`}
              component={actions}
              key={"route-actions"}
            />
            <Route
              path={`${baseUrl}/activities`}
              component={activities}
              key={"route-activities"}
            />
            <Route
              path={`${baseUrl}/orders`}
              component={orders}
              key={"route-orders"}
            />
            ;
            <Route
              path={`${baseUrl}/documents`}
              component={documents}
              key={"route-documents"}
            />
            ;
            <Route
              path={`${baseUrl}/*`}
              key={"route-activities-redirect-2"}
              render={() => (
                <Redirect replace to={`${this.state.baseUrl}/activities`} />
              )}
            />
          </Switch>
        </Loader>
      );
    }
    return null;
  }
}

export default ProjectDetail;
