import React, { Component } from "react";
import { Route } from "react-router-dom";
import DashboardPage from "./DashboardPage";

export default class Dashboard extends Component {
  render() {
    return (
      <Route
        path="/dashboard"
        render={(props) => (
          <DashboardPage
            session={this.props.session}
            window={this.props.window}
            {...props}
          />
        )}
      />
    );
  }
}
