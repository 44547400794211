import React, { Component } from "react";
import { Form } from "react-bootstrap";
import EditDialog from "../../common/Dialog";
import { PortsAlert } from "../../common/PortsAlert";

export default class UpdateDsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      action: null,
    };
  }

  onConfirm = () => {
    this.props.onSave(this.state.action);
  };

  onCancel = () => {
    this.props.onCancel();
    this.setState({
      action: null,
    });
  };

  onChangeSync = (event) => {
    if (event.target.value) {
      this.setState({
        action: "sync",
      });
    }
  };

  onChangeClear = (event) => {
    if (event.target.value) {
      this.setState({
        action: "clear",
      });
    }
  };

  render() {
    let title = "";
    if (this.props.title) {
      title = this.props.title;
    }

    const content = [
      <Form.Check
        name="groupOptions"
        onChange={this.onChangeSync}
        label="Sync all ds data from zone to registry"
      />,
      <Form.Check
        name="groupOptions"
        onChange={this.onChangeClear}
        label="Clear all ds data at registry"
      />,
      <PortsAlert variant="warning">
        <strong>Note:</strong> DS data will be updated immediately after you
        proceed with SAVE
      </PortsAlert>,
    ];

    return (
      <EditDialog
        title={title}
        content={content}
        show={this.props.show}
        onCancel={this.props.onCancel}
        onConfirm={this.onConfirm}
        submit={this.props.submit}
        errorMessage={this.props.errorMessage}
      />
    );
  }
}
