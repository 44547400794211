import React, { Component } from "react";
import { MessageDialog } from "./MessageDialog";
import styled from "styled-components";
import { darken, rgba } from "polished";
import Colors from "../common/theme/Colors";
import Icon from "../common/theme/Icons";
import { FormatDateTime } from "../common/Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const Label = styled.span`
  display: block;
  font-size: 0.85em;
  color: ${rgba(Colors.black, 0.5)};
  text-align: center;
`;

const Area = styled.div`
  height: 240px;
  border-style: solid;
  border-color: ${Colors.border};
  border-width: thin;
  margin-top: 5px;
`;

const Buttons = styled.div`
  text-align: center;
  vertical-align: middle;
`;

const IconButton = styled(FontAwesomeIcon)`
  && {
    color: ${Colors.tertiary};
    font-size: 20px;
    cursor: pointer;
    margin-left: 30px;
    margin-top: 10px;

    &:hover {
      color: ${darken(0.5, Colors.tertiary)};
    }
  }
`;

const Messages = styled.div`
  height: 200px;
  display: flex;
  flex-direction: column-reverse;
  align-self: flex-start;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const MessageRows = styled.div`
  display: flex;
  flex-direction: column;
`;

const Message = styled.div`
  border-radius: 0.8rem;
  max-width: 75%;
  padding: 0.2rem 0.875rem;
  position: relative;
  text-wrap: initial;
  width: fit-content;
  margin: 0.2rem 0.8rem;
  white-space: pre-line;

  &.left {
    align-items: flex-start;
    background-color: ${Colors.messageLeftBackground};
    color: ${Colors.messageLeftText};
  }

  &.right {
    align-self: flex-end;
    background-color: ${Colors.messageRightBackground};
    color: ${Colors.messageRightText};
  }
`;

export class ProjectMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      showMessageDialog: false,
      submit: false,
      errorMessage: null,
    };
  }
  componentDidMount() {
    this.reload();
  }

  reload = () => {
    this.props.session.backendGet(
      this.props.url,
      (response) => {
        this.setState({ submit: false, messages: response });
      },
      (msg) => {
        this.props.window.showErrorFunc(msg);
      },
    );
  };

  getButtons = () => {
    let buttons = [];
    const tooltipApprove = (
      <Tooltip style={{ position: "fixed" }}>Approve the above message</Tooltip>
    );
    const tooltipDecline = (
      <Tooltip style={{ position: "fixed" }}>Decline the above message</Tooltip>
    );
    const tooltipCreate = (
      <Tooltip style={{ position: "fixed" }}>Send message</Tooltip>
    );
    buttons.push(
      <OverlayTrigger overlay={tooltipApprove} placement={"bottom"}>
        <IconButton icon={["fas", "thumbs-up"]} onClick={this.onApprove} />
      </OverlayTrigger>,
    );
    buttons.push(
      <OverlayTrigger overlay={tooltipDecline} placement={"bottom"}>
        <IconButton icon={["fas", "thumbs-down"]} onClick={this.onDecline} />
      </OverlayTrigger>,
    );
    buttons.push(
      <OverlayTrigger overlay={tooltipCreate} placement={"bottom"}>
        <IconButton icon={["fas", "pen"]} onClick={this.onOpenMessageDialog} />
      </OverlayTrigger>,
    );
    return <Buttons>{buttons}</Buttons>;
  };

  onApprove = () => {
    this.setState({ submit: true });
    this.props.session.backendPost(
      this.props.url,
      {
        projectId: this.props.projectId,
        messageType: "CUSTOMER_APPROVE",
        content: null,
      },
      this.reload,
      (msg) => {
        this.setState({ submit: false });
        this.props.window.showErrorFunc(msg);
      },
    );
  };

  onDecline = () => {
    this.setState({ submit: true });
    this.props.session.backendPost(
      this.props.url,
      {
        projectId: this.props.projectId,
        messageType: "CUSTOMER_DECLINE",
        content: null,
      },
      this.reload,
      (msg) => {
        this.setState({ submit: false });
        this.props.window.showErrorFunc(msg);
      },
    );
  };

  onOpenMessageDialog = () => {
    this.setState({
      showMessageDialog: true,
    });
  };

  onCloseMessageDialog = () => {
    this.setState({
      showMessageDialog: false,
      submit: false,
      errorMessage: null,
    });
  };

  onSendMessage = (content) => {
    this.setState({ submit: true });
    this.props.session.backendPost(
      this.props.url,
      {
        projectId: this.props.projectId,
        messageType: "CUSTOMER_COMMENT",
        content: content,
      },
      () => {
        this.onCloseMessageDialog();
        this.reload();
      },
      (msg) => {
        this.setState({ submit: false, errorMessage: msg });
      },
    );
  };

  getBody = () => {
    let body = [];
    body.push(<Label>Messages</Label>);
    body.push(<Area>{this.getMessageArea()}</Area>);
    return body;
  };

  getMessageArea = () => {
    let fields = [];
    fields.push(this.getMessages());
    fields.push(this.getButtons());
    return fields;
  };

  getMessages = () => {
    let messages = [];
    this.state.messages.forEach((o) => {
      messages.push(this.getMessageRow(o));
    });
    return (
      <Messages>
        <MessageRows>{messages}</MessageRows>
      </Messages>
    );
  };

  getMessageRow = (message) => {
    let content = <div>{message.content}</div>;
    if (message.messageType === "CUSTOMER_APPROVE") {
      content = <Icon prefix="far" name="thumbs-up" />;
    } else if (message.messageType === "CUSTOMER_DECLINE") {
      content = <Icon prefix="far" name="thumbs-down" />;
    }
    let date = FormatDateTime(message.created);
    let className = message.fromCustomer ? "right" : "left";
    return (
      <Message className={className}>
        <div>{message.createdBy + " " + date + ":"}</div>
        {content}
      </Message>
    );
  };

  render() {
    return (
      <div>
        {this.getBody()}
        <MessageDialog
          session={this.props.session}
          show={this.state.showMessageDialog}
          onCancel={this.onCloseMessageDialog}
          onSend={this.onSendMessage}
          submit={this.state.submit}
          errorMessage={this.state.errorMessage}
        />
      </div>
    );
  }
}
