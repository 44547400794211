import React, { Component } from "react";
import Icon from "../common/theme/Icons";
import { Col, Container, Row } from "react-bootstrap";
import { PaddedContent } from "../common/theme/Theme";
import {
  Curtain,
  FilterStyle,
  StyledChart,
  WidgetContent,
  WidgetInner,
  WidgetPanel,
  WidgetTitle,
} from "./DashboardUtil";
import AccountsForecastDialog from "../invoice/AccountsForecastDialog";
import AccountForecastDialog from "../invoice/AccountForecastDialog";
import { getCurrencyFilter } from "../common/InvoiceForecastUtils";
import { FormatFloat } from "../common/Utils";

export default class InvoiceForecastItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      forecast: null,
      currency: null,
      dialogSelectedCurrency: null,
      dialogSelectedAccountIds: "",
      dialogSelectedOrganisation: null,
      invoiceForecastDialogShow: false,
      accountForecastDialogShow: false,
      accountForecastUrl: null,
      loading: true,
      loadingCounts: false,
      errorMessage: null,
    };
  }
  componentDidMount() {
    this.reload();
  }

  reload = () => {
    if (!this.props.session.getModules().invoice) {
      return;
    }
    this.setState({ loadingCounts: true });
    let url = this.props.session.getModules().invoice.links.forecastAccounts;
    url += "?accountIdFilter=";
    url += "&includeAccountDetails=" + false;
    url += "&currency=";
    this.props.session.backendGet(
      url,
      (response) => {
        this.setState({
          forecast: response,
          currency: this.getDefaultCurrency(response),
          loadingCounts: false,
        });
      },
      (msg) => {
        this.setState({ errorMessage: msg });
      },
    );
  };

  getCurrencies = (forecast) => {
    if (!forecast || !forecast.amounts) {
      return null;
    }
    return Object.keys(forecast.amounts);
  };

  getCurrencyFilter = () => {
    const currencies = this.getCurrencies(this.state.forecast);
    const onClick = (currency) => this.setState({ currency: currency });
    return (
      <FilterStyle>
        {getCurrencyFilter(currencies, this.state.currency, onClick)}
      </FilterStyle>
    );
  };

  getDefaultCurrency = (forecast) => {
    if (!forecast || !forecast.amounts) {
      return null;
    }
    const currencies = this.getCurrencies(forecast);
    return currencies[0];
  };

  getData = () => {
    let data = [];
    const columns = [
      {
        type: "string",
        label: "Month",
      },
      {
        type: "number",
        label: "Amount",
      },
      {
        type: "string",
        role: "tooltip",
        p: {
          html: true,
        },
      },
    ];
    data.push(columns);

    const forecast = this.state.forecast;
    const currency = this.state.currency;
    if (!forecast || !forecast.months) {
      return data;
    }

    const months = forecast.months;
    const amounts = forecast.amounts;
    const result = amounts[currency];
    for (let i = 0; i < months.length; i++) {
      let tooltip = "<b>" + months[i] + "</b>";
      let amount = FormatFloat(result[i], 0);
      tooltip += "<br><br>Amount:&nbsp;" + amount + "&nbsp;" + currency;
      data.push([months[i], result[i], tooltip]);
    }
    return data;
  };

  handleReady = ({ chartWrapper, google }) => {
    if (this.state.loading) {
      this.setState({ loading: false });
    }
    const chart = chartWrapper.getChart();
    google.visualization.events.addListener(chart, "click", (e) => {
      this.setState({ invoiceForecastDialogShow: true });
    });
  };

  hideDialog = () => {
    this.setState({
      invoiceForecastDialogShow: false,
      accountForecastDialogShow: false,
      dialogSelectedCurrency: null,
      dialogSelectedAccountIds: "",
      dialogSelectedOrganisation: null,
      accountForecastUrl: null,
    });
  };

  onClickAccount = (url, accountIds, currency, organisation) => {
    this.setState({
      invoiceForecastDialogShow: false,
      accountForecastDialogShow: true,
      dialogSelectedCurrency: currency,
      dialogSelectedAccountIds: accountIds,
      dialogSelectedOrganisation: organisation,
      accountForecastUrl: url,
    });
  };

  reshowInvoiceForecastDialog = (currency) => {
    this.setState({
      invoiceForecastDialogShow: true,
      accountForecastDialogShow: false,
      dialogSelectedCurrency: currency,
      accountForecastUrl: null,
    });
  };

  getSelectedCurrency = () => {
    if (this.state.dialogSelectedCurrency) {
      return this.state.dialogSelectedCurrency;
    } else if (this.state.currency) {
      return this.state.currency;
    } else {
      return this.getDefaultCurrency(this.state.forecast);
    }
  };

  render() {
    if (this.props.data.quote || !this.props.session.hasRole("INVOICE")) {
      return null;
    }

    const options = {
      legend: {
        position: "none",
      },
      tooltip: {
        isHtml: true,
      },
      vAxis: {
        format: "#",
      },
    };

    const chartEvents = [
      {
        eventName: "ready",
        callback: this.handleReady,
      },
    ];

    let curtain = (
      <Curtain
        className={
          this.state.loading || this.state.loadingCounts ? "active" : ""
        }
      >
        <Icon
          className={
            this.state.loading || this.state.loadingCounts ? "show" : "hide"
          }
          name={"spinner"}
          spin={true}
          size={"3x"}
        />
      </Curtain>
    );

    let title = (
      <WidgetTitle>
        <Container fluid={true}>
          <Row>
            <Col xs={8} className={"title"}>
              Invoice Forecast
            </Col>
            <Col xs={4} className={"controller text-right"}>
              {this.getCurrencyFilter()}
            </Col>
          </Row>
        </Container>
      </WidgetTitle>
    );

    let formatters = [
      {
        type: "NumberFormat",
        column: 1,
        options: {
          groupingSymbol: " ",
          fractionDigits: 0,
        },
      },
    ];

    let chart = (
      <StyledChart
        chartType="ColumnChart"
        width="104%"
        data={this.getData()}
        chartEvents={chartEvents}
        options={options}
        formatters={formatters}
      />
    );

    return (
      <div>
        <WidgetPanel xs={12} sm={12} md={12} lg={12} style={this.props.style}>
          <WidgetInner>
            {curtain}
            {title}
            <WidgetContent>
              <PaddedContent padding={"0 0 15px 0"}>{chart}</PaddedContent>
            </WidgetContent>
          </WidgetInner>
        </WidgetPanel>
        <AccountsForecastDialog
          session={this.props.session}
          show={this.state.invoiceForecastDialogShow}
          accountIds={this.state.dialogSelectedAccountIds}
          organisation={this.state.dialogSelectedOrganisation}
          currency={this.getSelectedCurrency()}
          onClose={this.hideDialog}
          onClickAccount={(url, accountIds, currency, organisation) =>
            this.onClickAccount(url, accountIds, currency, organisation)
          }
        />
        <AccountForecastDialog
          session={this.props.session}
          show={this.state.accountForecastDialogShow}
          url={this.state.accountForecastUrl}
          currency={this.state.dialogSelectedCurrency}
          onClose={this.hideDialog}
          onPrevious={(currency) => this.reshowInvoiceForecastDialog(currency)}
        />
      </div>
    );
  }
}
