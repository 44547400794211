import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import Selector from "../../common/Selector";
import { FormatOrganisation } from "../../common/Utils";

export default class AccountField extends Component {
  render() {
    if (this.props.conf.parameters.accounts == null) {
      return null;
    }

    let options = this.props.conf.parameters.accounts.map((o) => {
      return {
        value: o.value,
        label: FormatOrganisation(
          o.label,
          this.props.session.getApplication().organisationPrefix,
        ),
      };
    });

    return (
      <Row className="section-field">
        <Col xs={12}>
          <Selector
            label={"Organisation"}
            placeholder="Select organisation"
            options={options}
            defaultValue={this.props.conf.object.parameters.accountId}
            onChange={(v) => this.props.onParameterChange("accountId", v.value)}
          />
        </Col>
      </Row>
    );
  }
}
