import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import UserList from "./UserList";
import UserPage from "./UserPage";
import OrganisationList from "./OrganisationList";
import IdentityProviderList from "./IdentityProviderList";

class UserAdmin extends Component {
  render() {
    return (
      <Switch>
        <Route
          exact={true}
          path={"/useradmin"}
          render={() => <Redirect replace to={"/useradmin/users"} />}
        />
        <Route
          exact={true}
          path="/useradmin/users"
          render={(props) => (
            <UserList
              session={this.props.session}
              window={this.props.window}
              {...props}
            />
          )}
        />
        <Route
          path="/useradmin/users/:userName"
          render={(props) => (
            <UserPage
              session={this.props.session}
              window={this.props.window}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/useradmin/accounts"
          render={(props) => (
            <OrganisationList
              session={this.props.session}
              window={this.props.window}
              {...props}
            />
          )}
        />
        <Route
          exact={true}
          path="/useradmin/identityproviders"
          render={(props) => (
            <IdentityProviderList
              session={this.props.session}
              window={this.props.window}
              {...props}
            />
          )}
        />
      </Switch>
    );
  }
}

export default UserAdmin;
