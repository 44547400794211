import React, { Component } from "react";
import styled from "styled-components";
import Colors from "./theme/Colors";

const StyledTable = styled.table`
  .field-label {
    display: inline-block;
    margin-right: 20px;
    font-weight: 700;
  }

  .field-label-wide {
    min-width: 270px;
  }

  .field-value {
    white-space: pre-line;
    padding-right: 20px;
  }

  .field-value .enabled {
    color: ${Colors.success};
  }

  .field-value .disabled {
    color: ${Colors.tertiary};
  }
`;

export default class ReadOnlyForm extends Component {
  formatLabel = (row) => {
    if (row.labelStyle) {
      return <div style={row.labelStyle}>{row.label}</div>;
    }
    return row.label;
  };

  formatValue = (row) => {
    if (row.conditionalEnableDisable) {
      if (row.value) {
        return <div className={"enabled"}>Enabled</div>;
      } else {
        return <div className={"disabled"}>Disabled</div>;
      }
    }

    if (row.valueStyle) {
      return <div style={row.valueStyle}>{row.value}</div>;
    }

    return row.value;
  };

  getRow = (row) => {
    if (row) {
      let labelClassName = "field-label";
      if (this.props.wide) {
        labelClassName += " field-label-wide";
      }

      return (
        <tr key={row.label ? row.label.toLowerCase().replace(" ", "-") : ""}>
          <td className={labelClassName}>{this.formatLabel(row)}</td>
          <td className="field-value">{this.formatValue(row)}</td>
        </tr>
      );
    }
  };

  getContent = () => {
    let rows = [];

    this.props.content.forEach((row) => {
      rows.push(this.getRow(row));
    });

    return rows;
  };

  render() {
    return (
      <StyledTable>
        <tbody>{this.getContent()}</tbody>
      </StyledTable>
    );
  }
}
