import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { InputWithLabel } from "../../common/theme/Theme";
import Selector from "../../common/Selector";
import { FormatInput } from "../../common/Utils";
import AccountField from "./AccountField";

export default class TrademarkRegisterParameterStep extends Component {
  render() {
    return (
      <Container fluid={true}>
        <Row className="section-title">
          <Col md={12}>Parameters</Col>
        </Row>
        <Row className="section-description">
          <Col xs={12}>
            Fill out the fields below to best describe the trademark you want to
            apply for and our experienced team will contact you, verify the
            details and do the rest.
          </Col>
        </Row>
        <AccountField {...this.props} />
        <Row className="section-field">
          <Col md={12}>
            <InputWithLabel
              label={"Summary / Brand"}
              placeholder="Summarize desired trademark registration in own sentence or brand keyword"
              value={FormatInput(this.props.conf.object.parameters.description)}
              onChange={(e) =>
                this.props.onParameterChange("description", e.target.value)
              }
            />
          </Col>
        </Row>
        <Row className="section-field">
          <Col xs={12}>
            <Selector
              label={"Type of Mark"}
              placeholder="Select type of trademark"
              options={this.props.conf.parameters.types}
              defaultValue={FormatInput(
                this.props.conf.object.parameters.typeId,
              )}
              onChange={(v) => this.props.onParameterChange("typeId", v.value)}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}
