import React, { Component } from "react";
import OrderList from "./OrderList";
import { withRouter } from "react-router";
import { Route } from "react-router-dom";

class Order extends Component {
  render() {
    return (
      <Route
        exact={true}
        path="/order/orderLines"
        render={(props) => (
          <OrderList
            session={this.props.session}
            window={this.props.window}
            {...props}
          />
        )}
      />
    );
  }
}

export default withRouter(Order);
