import React, { Component } from "react";
import { EditDialog } from "../../common/Dialog";
import ZoneFileTextArea from "./ZoneFileTextArea.js";

class ZoneFileDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      zoneFile: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.zoneFile && prevProps.zoneFile !== this.props.zoneFile) {
      this.setState({ zoneFile: this.props.zoneFile });
    }
  }

  render() {
    const elements = [
      <ZoneFileTextArea
        key="zone_file_text_area"
        value={this.state.zoneFile}
        onChange={(e) => this.setState({ zoneFile: e.target.value })}
      />,
    ];

    return (
      <div>
        <EditDialog
          content={elements}
          show={this.props.showDialog}
          title="Edit Zone File"
          width="xl"
          onCancel={this.props.onCancel}
          onConfirm={() => this.props.onConfirm(this.state.zoneFile)}
          submit={this.props.submit}
        />
      </div>
    );
  }
}

export default ZoneFileDialog;
