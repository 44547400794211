import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { InputLabel, InputWithLabel } from "../../common/theme/Theme";
import { InformationField } from "../../common/Information";
import { FormatInput } from "../../common/Utils";

export default class CertificateContactStep extends Component {
  render() {
    return (
      <Container fluid={true}>
        <Row className="section-title">
          <Col md={12}>Contact Person</Col>
        </Row>
        <Row className="section-field">
          <Col md={6}>
            <InputWithLabel
              label="First Name"
              type="text"
              placeholder="First Name"
              value={FormatInput(
                this.props.conf.object.parameters.contactFirstName,
              )}
              onChange={(e) =>
                this.props.onParameterChange("contactFirstName", e.target.value)
              }
            />
          </Col>
          <Col md={6}>
            <InputWithLabel
              label="Last Name"
              type="text"
              placeholder="Last Name"
              value={FormatInput(
                this.props.conf.object.parameters.contactLastName,
              )}
              onChange={(e) =>
                this.props.onParameterChange("contactLastName", e.target.value)
              }
            />
          </Col>
        </Row>
        <Row className="section-field">
          <Col md={12}>
            <InputWithLabel
              label="Job Title"
              type="text"
              placeholder="Job Title"
              value={FormatInput(
                this.props.conf.object.parameters.contactJobTitle,
              )}
              onChange={(e) =>
                this.props.onParameterChange("contactJobTitle", e.target.value)
              }
            />
          </Col>
        </Row>
        <Row className="section-field">
          <Col md={12}>
            <InputWithLabel
              label="Phone"
              type="text"
              placeholder="Phone"
              value={FormatInput(
                this.props.conf.object.parameters.contactPhone,
              )}
              onChange={(e) =>
                this.props.onParameterChange("contactPhone", e.target.value)
              }
            />
          </Col>
        </Row>
        <Row className="section-field">
          <Col md={12}>
            <InputWithLabel
              label="Email"
              type="email"
              placeholder="Email"
              value={FormatInput(
                this.props.conf.object.parameters.contactEmail,
              )}
              onChange={(e) =>
                this.props.onParameterChange("contactEmail", e.target.value)
              }
            />
          </Col>
        </Row>
        <Row className="section-title-space">
          <Col md={12}>Notification Parameters</Col>
        </Row>
        <Row className="section-field-space">
          <Col md={12}>
            <InformationField tooltip="You can input email adddresses of recipients, who will get an extended set of emails related to the certificate lifecyle.">
              <InputLabel>Recipients of Additional Emails</InputLabel>
              <textarea
                rows="4"
                placeholder="Enter email addresses, one per line"
                value={FormatInput(
                  this.props.conf.object.parameters.contactAdditionalEmails,
                )}
                onChange={(e) =>
                  this.props.onParameterChange(
                    "contactAdditionalEmails",
                    e.target.value,
                  )
                }
              />
            </InformationField>
          </Col>
        </Row>
        <Row className="section-field-space">
          <Col md={12}>
            <InformationField tooltip="If you want you can submit an extra text message that will be included in the emails sent out when it is time to renew the certificate.">
              <InputLabel>Custom Renewal Message</InputLabel>
              <textarea
                rows="4"
                placeholder="Enter custom renewal message"
                value={FormatInput(
                  this.props.conf.object.parameters.contactRenewalMessage,
                )}
                onChange={(e) =>
                  this.props.onParameterChange(
                    "contactRenewalMessage",
                    e.target.value,
                  )
                }
              />
            </InformationField>
          </Col>
        </Row>
      </Container>
    );
  }
}
