import React, { Component } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import fileDownload from "js-file-download";
import { FormatDateMonospace, FormatOrganisation } from "../common/Utils";
import { TableBackend, TableFilter } from "../common/Tables";
import {
  ActionButton,
  BlockingActionButton,
  TableButton,
} from "../common/Buttons";
import { ExternalLink } from "../common/Link";
import OrganisationFilter from "../common/OrganisationFilter";
import InvoiceDateAgeFilter from "./InvoiceDateAgeFilter";
import PaymentDialog from "../common/PaymentDialog";
import Colors from "../common/theme/Colors";
import AccountsForecastDialog from "./AccountsForecastDialog";
import AccountForecastDialog from "./AccountForecastDialog";
import DocumentTypeFilter from "./DocumentTypeFilter";

const DivStyle = styled.div`
  .download-link {
    cursor: pointer;
    text-decoration: underline;
    color: ${Colors.tertiary};
  }
  .checkbox {
    margin: 0px;
  }
`;

class DocumentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parameters: {
        accountIds: "",
        invoiceDateAge: 0,
        type: "invoice",
        freetext: "",
      },
      selectedIds: [],
      allIds: [],
      exported: 0,
      invoiceId: null,
      paymentDialogShow: false,
      organisation: null,
      invoiceForecastDialogShow: false,
      accountForecastDialogShow: false,
      dialogSelectedAccountIds: "",
      dialogSelectedOrganisation: null,
      dialogSelectedCurrency: null,
      accountForecastUrl: null,
    };
  }

  componentDidMount() {
    let views = [
      {
        label: "Home",
        url: "/",
      },
      {
        label: "Invoices",
        url: null,
      },
    ];

    this.props.window.setBreadcrumbViews(views);
  }

  getUrl = (sorting, expanding, parameters) => {
    let type = parameters.type;
    let url;
    if (type === "invoice") {
      url = this.props.session.getModules().invoice.links.invoices;
    } else {
      url = this.props.session.getModules().invoice.links.receipts;
    }
    url += "?sort=" + sorting;
    url += expanding ? "" : "&page[limit]=15";
    url += "&accountIdFilter=" + parameters.accountIds;
    url += "&invoiceDateAgeFilter=" + parameters.invoiceDateAge;
    url += "&freetextFilter=" + encodeURIComponent(parameters.freetext);
    return url;
  };

  onResponse = (response) => {
    let allIds = [];
    response.objects
      .filter((r) => r.supportExportSpecification)
      .forEach((r) => allIds.push(r.id));
    let selectedIds = this.state.selectedIds.filter((o) => allIds.includes(o));
    this.setState({ selectedIds: selectedIds, allIds: allIds });
    return response;
  };

  getLink = (row) => {
    let type = "Invoice";
    if (this.state.parameters.type === "receipt") {
      type = "Receipt";
    }
    return (
      <ExternalLink
        onClick={() =>
          this.props.session.backendGetFile(row.links.file, (r) =>
            fileDownload(r, row.fileName),
          )
        }
        tooltip={"Download " + type + " as PDF"}
        text={row.id}
        className={"download-link"}
      />
    );
  };

  getCheckbox = (row) => {
    if (row.supportExportSpecification) {
      return (
        <Form.Check
          checked={this.isSelected(row.id)}
          onChange={() => this.toggleOne(row.id)}
        />
      );
    }
  };

  isSelected = (id) => {
    return this.state.selectedIds.includes(id);
  };

  toggleOne = (id) => {
    let selectedIds = this.state.selectedIds;

    if (selectedIds.includes(id)) {
      selectedIds = selectedIds.filter((o) => o !== id);
    } else {
      selectedIds.push(id);
    }

    this.setState({ selectedIds: selectedIds });
  };

  toggleAll = (event) => {
    let selectedIds = [];

    if (event.target.checked) {
      selectedIds = this.state.allIds;
    }

    this.setState({ selectedIds: selectedIds });
  };

  exportSpecification = () => {
    let url =
      this.props.session.getModules().invoice.links.specification +
      "?invoiceNumbers=" +
      this.state.selectedIds.join(",");

    this.props.session.backendGetFile(
      url,
      (response) => {
        fileDownload(response, "Invoice Specification.xlsx");
        this.setState({ exported: Date.now() });
      },
      (error) => {
        this.props.window.showErrorFunc(error);
        this.setState({ exported: Date.now() });
      },
    );
  };

  showPaymentDialog = (invoiceNumber) => {
    this.setState({
      paymentDialogShow: true,
      invoiceId: invoiceNumber,
    });
  };

  hidePaymentDialog = () => {
    this.setState({
      paymentDialogShow: false,
      invoiceId: null,
    });
    this.reloadPage();
  };

  getActionButton = (invoice) => {
    if (invoice.supportCreditCardPayment) {
      return (
        <TableButton
          icon="eye"
          prefix="fas"
          className="secondary"
          text="Pay"
          tooltip="Pay the invoice by credit card"
          onClick={() => this.showPaymentDialog(invoice.id)}
        />
      );
    }
  };

  reloadPage = () => {
    this.setState({
      parameters: Object.assign({}, this.state.parameters, {
        reloadFlag: !this.state.parameters.reloadFlag,
      }),
    });
  };

  showInvoiceForecastDialog = () => {
    this.setState({
      invoiceForecastDialogShow: true,
    });
  };

  reshowInvoiceForecastDialog = (currency) => {
    this.setState({
      invoiceForecastDialogShow: true,
      accountForecastDialogShow: false,
      dialogSelectedCurrency: currency,
      accountForecastUrl: null,
    });
  };

  hideDialog = () => {
    this.setState({
      invoiceForecastDialogShow: false,
      accountForecastDialogShow: false,
      dialogSelectedAccountIds: "",
      dialogSelectedCurrency: null,
      dialogSelectedOrganisation: null,
      accountForecastUrl: null,
    });
  };

  onClickAccount = (url, accountIds, currency, organisation) => {
    this.setState({
      invoiceForecastDialogShow: false,
      accountForecastDialogShow: true,
      dialogSelectedAccountIds: accountIds,
      dialogSelectedCurrency: currency,
      dialogSelectedOrganisation: organisation,
      accountForecastUrl: url,
    });
  };

  getSelectedAccountIds = () => {
    if (this.state.dialogSelectedAccountIds.length === 0) {
      return this.state.parameters.accountIds;
    }
    return this.state.dialogSelectedAccountIds;
  };

  getSelectedOrganisation = () => {
    if (this.state.dialogSelectedOrganisation === null) {
      return this.state.organisation;
    }
    return this.state.dialogSelectedOrganisation;
  };

  render() {
    let actions = [];

    actions.push(
      <BlockingActionButton
        key="export"
        onClick={this.exportSpecification}
        reload={this.state.exported}
        text="Export Invoice Specification"
        icon="cloud-download"
        prefix="fas"
        tooltip="Exports the invoice lines of the selected invoices to an Excel file."
        disabled={
          this.state.selectedIds == null || this.state.selectedIds.length === 0
        }
        disabledTooltip="You start by selecting invoices, then this function will be available."
      />,
    );

    actions.push(
      <ActionButton
        key="forecast"
        onClick={this.showInvoiceForecastDialog}
        text="Invoice Forecast"
        icon="chart-line"
        prefix="fas"
        tooltip="Preview invoices for the coming 12 months for the selected organisations"
      />,
    );

    const filters = [
      <OrganisationFilter
        key="organisation-filter"
        session={this.props.session}
        onChange={(o, v) =>
          this.setState({
            parameters: Object.assign({}, this.state.parameters, {
              accountIds: o,
            }),
            organisation: v,
          })
        }
      />,
      <DocumentTypeFilter
        key="type"
        value={this.state.parameters.type}
        onChange={(o) =>
          this.setState({
            parameters: Object.assign({}, this.state.parameters, {
              type: o,
            }),
          })
        }
      />,
      <InvoiceDateAgeFilter
        key="age"
        value={this.state.parameters.invoiceDateAge}
        onChange={(o) =>
          this.setState({
            parameters: Object.assign({}, this.state.parameters, {
              invoiceDateAge: o,
            }),
          })
        }
      />,
      <TableFilter
        key="table-filter"
        value={this.state.parameters.freetext}
        onChange={(o) =>
          this.setState({
            parameters: Object.assign({}, this.state.parameters, {
              freetext: o,
            }),
          })
        }
      />,
    ];

    let columns = [];
    if (this.state.parameters.type === "invoice") {
      columns.push({
        label: <Form.Check onChange={this.toggleAll} />,
        style: { width: "50px" },
        contentFunction: (r) => this.getCheckbox(r),
      });
    }

    columns.push(
      {
        label: "Id",
        name: "id",
        sortable: true,
        contentFunction: (r) => this.getLink(r),
      },
      {
        label: "Type",
        name: "type",
        sortable: true,
        contentFunction: (r) => r.type,
      },
      {
        label: "Organisation",
        name: "organisation",
        sortable: true,
        contentFunction: (r) =>
          FormatOrganisation(
            r.organisation,
            this.props.session.getApplication().organisationPrefix,
          ),
      },
      {
        label: "Invoice Date",
        name: "date",
        sortable: true,
        contentFunction: (r) => FormatDateMonospace(r.date),
      },
      {
        label: "Invoice Status",
        name: "status",
        sortable: true,
        contentFunction: (r) => r.status,
      },
      {
        style: { width: "150px" },
        contentFunction: (r) => this.getActionButton(r),
      },
    );

    return (
      <DivStyle>
        <TableBackend
          session={this.props.session}
          window={this.props.window}
          actions={actions}
          filters={filters}
          columns={columns}
          sorting="-date"
          parameters={this.state.parameters}
          urlFunction={this.getUrl}
          responseFunction={this.onResponse}
        />
        <PaymentDialog
          show={this.state.paymentDialogShow}
          type="invoice"
          objectId={this.state.invoiceId}
          session={this.props.session}
          onClose={this.hidePaymentDialog}
        />
        <AccountsForecastDialog
          session={this.props.session}
          show={this.state.invoiceForecastDialogShow}
          accountIds={this.getSelectedAccountIds()}
          organisation={this.getSelectedOrganisation()}
          currency={this.state.dialogSelectedCurrency}
          onClose={this.hideDialog}
          onClickAccount={(url, accountIds, currency, organisation) =>
            this.onClickAccount(url, accountIds, currency, organisation)
          }
        />
        <AccountForecastDialog
          session={this.props.session}
          show={this.state.accountForecastDialogShow}
          url={this.state.accountForecastUrl}
          currency={this.state.dialogSelectedCurrency}
          onClose={this.hideDialog}
          onPrevious={(currency) => this.reshowInvoiceForecastDialog(currency)}
        />
      </DivStyle>
    );
  }
}

export default DocumentList;
