import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { DetailsDialog } from "./Dialog";
import Icon from "./theme/Icons";

export default class ChangeAccountResult extends Component {
  constructor(props) {
    super(props);
  }

  onChangeAccount = () => {
    this.props.onChangeAccount();
  };

  getBody = () => {
    return (
      <Container fluid={true}>
        <Row className="section-message">
          <Col xs={12}>
            <Icon
              color="primary"
              iconsize="40"
              prefix="fas"
              name="check-circle"
            />
            <span>Transfer request created successfully!</span>
          </Col>
        </Row>
        <Row className="section-description">
          <Col xs={12}>
            Note that some transfers can take a few minutes to take effect,
            before you see the changes in the user interface.
          </Col>
        </Row>
      </Container>
    );
  };

  render() {
    return (
      <DetailsDialog
        show={true}
        title="Change Account"
        body={this.getBody()}
        onClose={this.onChangeAccount}
      />
    );
  }
}
