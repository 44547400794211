import React, { Component } from "react";
import {
  FormatFloatWithFractionDigitsMonospace,
  FormatOrganisation,
} from "../common/Utils";
import { TableBase } from "../common/Tables";
import OrganisationFilter from "../common/OrganisationFilter";
import { DetailsDialog } from "../common/Dialog";
import { TableLink } from "../common/Link";
import { getColumns, getCurrencyFilter } from "../common/InvoiceForecastUtils";
import { BlockingActionButton } from "../common/Buttons";
import fileDownload from "js-file-download";

export default class AccountsForecastDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      accountIds: "",
      currency: null,
      loading: true,
      currencies: [],
      forecast: null,
      initialOrganisation: null,
      initialCurrency: null,
      organisation: null,
      exported: 0,
      errorMessage: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && prevProps.show !== this.props.show) {
      this.setState(
        {
          accountIds: this.props.accountIds,
          currency: this.props.currency,
          initialOrganisation: this.props.organisation,
          initialCurrency: this.props.currency,
        },
        function () {
          this.reload();
        },
      );
    }
  }

  reload = () => {
    this.setState({
      loading: true,
      currencies: [],
      forecast: null,
      exported: 0,
      errorMessage: null,
    });

    let url = this.props.session.getModules().invoice.links.forecastAccounts;
    url += "?accountIdFilter=" + this.state.accountIds;
    url += "&currency=" + this.state.currency;
    url += "&includeAccountDetails=" + true;

    this.props.session.backendGet(url, (response) => {
      const currencies = this.getCurrencies(response);
      this.setState({
        currencies: currencies,
        forecast: response,
        loading: false,
      });
    });
  };

  onClose = () => {
    this.setState({
      accountIds: "",
      currency: null,
      currencies: [],
      forecast: null,
      exported: 0,
      errorMessage: null,
      initialOrganisation: null,
      initialCurrency: null,
    });
    this.props.onClose();
  };

  getCurrencies = (forecast) => {
    if (!forecast || !forecast.amounts) {
      return [];
    }
    return Object.keys(forecast.amounts);
  };

  getOrganisationFilter = () => {
    return (
      <OrganisationFilter
        key="organisation-filter"
        session={this.props.session}
        initialTitle={this.state.initialOrganisation}
        onChange={(o, v) => {
          this.setState(
            {
              accountIds: o,
              organisation: v,
            },
            function () {
              this.reload();
            },
          );
        }}
      />
    );
  };

  getCurrencyFilter = () => {
    const onClick = (currency) => {
      this.setState(
        {
          currency: currency,
        },
        function () {
          this.reload();
        },
      );
    };
    return getCurrencyFilter(
      this.state.currencies,
      this.state.currency,
      onClick,
    );
  };

  getFilters = () => {
    const filters = [];
    filters.push(this.getOrganisationFilter());
    filters.push(this.getCurrencyFilter());
    return filters;
  };

  getActionButtons = () => {
    let parts = [];
    parts.push(
      <BlockingActionButton
        key="ExportAccounts"
        onClick={(e = false) => this.onExport(e)}
        text="Export Accounts"
        icon={"cloud-download"}
        prefix={"fas"}
        reload={this.state.exported}
        tooltip="Exports the accounts to an Excel file. Note that only rows matching current filter are exported."
      />,
    );
    parts.push(
      <BlockingActionButton
        key="ExportServices"
        onClick={(e = true) => this.onExport(e)}
        text="Export Services"
        icon={"cloud-download"}
        prefix={"fas"}
        reload={this.state.exported}
        tooltip="Exports the services to an Excel file. Note that only rows matching current filter are exported."
      />,
    );
    return parts;
  };

  onExport = (service) => {
    let url;
    if (service) {
      url =
        this.props.session.getModules().invoice.links.exportForecastServices;
    } else {
      url =
        this.props.session.getModules().invoice.links.exportForecastAccounts;
    }

    url += "?accountIdFilter=" + this.state.accountIds;
    url += "&currency=" + this.getSelectedCurrency();
    this.props.session.backendGetFile(
      url,
      (response) => {
        fileDownload(response, "invoice_forecast.xlsx");
        this.setState({ exported: Date.now() });
      },
      (error) => {
        this.setState({ exported: Date.now(), errorMessage: error });
      },
    );
  };

  getTableRows = () => {
    let rows = [];
    if (this.state.forecast === null) {
      return rows;
    }

    const months = this.state.forecast.months;
    const accounts = this.state.forecast.accountForecasts;

    if (!accounts) {
      return rows;
    }
    accounts.forEach((account) => {
      const accountAmounts = account.amounts;
      let link = account.links.self;
      let data = {
        description: FormatOrganisation(
          account.organisation,
          this.props.session.getApplication().organisationPrefix,
        ),
        link: link,
      };
      for (let i = 0; i < months.length; i++) {
        let key = months[i];
        data[key] = FormatFloatWithFractionDigitsMonospace(
          accountAmounts[i],
          null,
          0,
        );
      }
      rows.push(data);
    });
    return rows;
  };

  getFooterRows = () => {
    let rows = [];
    if (this.state.forecast === null) {
      return rows;
    }

    let currency = this.getSelectedCurrency();
    const months = this.state.forecast.months;
    const accounts = this.state.forecast.accountForecasts;
    const totalAmounts = this.state.forecast.amounts[currency];

    let description = "Total (" + currency + "):";

    if (!accounts) {
      return rows;
    }
    let total = {
      description: description,
    };
    for (let i = 0; i < months.length; i++) {
      let key = months[i];
      total[key] = FormatFloatWithFractionDigitsMonospace(
        totalAmounts[i],
        null,
        0,
      );
    }
    rows.push(total);
    return rows;
  };

  getSelectedCurrency = () => {
    if (!this.state.currency && this.state.currencies.length !== 0) {
      return this.state.currencies[0];
    }
    return this.state.currency;
  };

  onClickAccount = (link, currency) => {
    this.onClose();
    this.props.onClickAccount(
      link,
      this.state.accountIds,
      currency,
      this.state.organisation,
    );
  };

  accountForecastLink = (r) => {
    if (r.link) {
      return (
        <TableLink
          onClick={() =>
            this.onClickAccount(
              r.link,
              this.getSelectedCurrency(this.state.currencies),
            )
          }
          style={{ textAlign: "left" }}
        >
          {r.description}
        </TableLink>
      );
    }
    return r.description;
  };

  getTable = () => {
    if (this.state.loading) {
      return <div>Loading...</div>;
    } else {
      return (
        <TableBase
          columns={getColumns(this.state.forecast, (r) =>
            this.accountForecastLink(r),
          )}
          rows={this.getTableRows()}
          footerRows={this.getFooterRows()}
        />
      );
    }
  };

  getNotes = () => {
    let notes = [];
    notes.push(
      "The invoice forecast is calculated from your current portfolio and may change due to added/cancelled services, changed invoice preferences, price adjustments and currency rate changes. It doesn't include one off services such as consultancy or domain owner transfers.",
    );
    return notes;
  };

  render() {
    return (
      <DetailsDialog
        show={this.props.show}
        title="Invoice Forecast"
        actions={this.getActionButtons()}
        filters={this.getFilters()}
        body={this.getTable()}
        onClose={() => this.onClose()}
        noteMessages={this.getNotes()}
        width="xl"
        height="lg"
      />
    );
  }
}
