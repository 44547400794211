import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { NavTab } from "react-router-tabs";
import { PageTabsNew } from "../common/PageTabs";
import Icon from "../common/theme/Icons";
import { Loader } from "../common/theme/Theme";
import OrganisationTab from "./OrganisationTab";
import RoleTab from "./RoleTab";
import UserHeader from "./UserHeader";
import ZoneTab from "./ZoneTab";

class UserPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      user: null,
      baseUrl: null,
    };
    this.reloadUser = this.reloadUser.bind(this);
    this.handleTabSelect = this.handleTabSelect.bind(this);
  }

  componentDidMount() {
    this.reloadUser();
  }

  reloadUser() {
    let url =
      this.props.session.getModules().useradmin.links.users +
      "/" +
      this.props.match.params.userName;
    this.props.session.backendGet(
      url,
      (response) => {
        this.setState({
          loaded: true,
          user: response,
          baseUrl: `/useradmin/users/${response.id}`,
        });
        let views = [
          {
            label: "Home",
            url: "/",
          },
          {
            label: "Users",
            url: "/useradmin/users",
          },
          {
            label: response.email,
            url: null,
          },
        ];

        this.props.window.setBreadcrumbViews(views);
        this.props.window.setPageTip(null);
      },
      (msg) => {
        this.props.window.showErrorFunc(msg);
      },
    );
  }

  handleTabSelect(event) {
    const clickedTab = event.target.getAttribute("data-tab");
    this.setState({ activeTab: clickedTab });
  }

  getHeader() {
    return this.state.user ? (
      <UserHeader
        session={this.props.session}
        user={this.state.user}
        onChange={this.reloadUser}
        window={this.props.window}
      />
    ) : null;
  }

  getDisplayName() {
    return this.state.user ? this.state.user.email : null;
  }

  render() {
    const RolesTab = () => (
      <RoleTab
        user={this.state.user}
        onChange={this.reloadUser}
        window={this.props.window}
        session={this.props.session}
      />
    );
    const OrganisationsTab = () => (
      <OrganisationTab
        user={this.state.user}
        onChange={this.reloadUser}
        window={this.props.window}
        session={this.props.session}
      />
    );
    const ZonesTab = () => (
      <ZoneTab
        user={this.state.user}
        onChange={this.reloadUser}
        session={this.props.session}
        window={this.props.window}
      />
    );
    const UserHeader = () => this.getHeader();
    const Tabs = (props) => {
      let tabs = [];
      if (!this.state.user) {
        return tabs;
      }
      tabs.push(
        <NavTab
          onClick={this.handleTabSelect}
          key={this.state.user.id + "-roles"}
          to={`${props.baseUrl}/roles`}
          data-tab={1}
        >
          <Icon className={"menu-icon"} name="user-circle" />
          Roles
        </NavTab>,
      );
      tabs.push(
        <NavTab
          onClick={this.handleTabSelect}
          key={this.state.user.id + "-organisations"}
          to={`${props.baseUrl}/organisations`}
          data-tab={2}
        >
          <Icon className={"menu-icon"} name="sitemap" /> Organisations
        </NavTab>,
      );
      tabs.push(
        <NavTab
          onClick={this.handleTabSelect}
          key={this.state.user.id + "-zones"}
          to={`${props.baseUrl}/zones`}
          data-tab={3}
        >
          <Icon className={"menu-icon"} name="folder-open" /> Zones
        </NavTab>,
      );
      return tabs;
    };
    return (
      <Loader loaded={this.state.loaded}>
        <UserHeader />
        <PageTabsNew className={"nav nav-tabs"}>
          <Tabs baseUrl={this.state.baseUrl} />
        </PageTabsNew>
        <Switch>
          <Route
            path={`${this.state.baseUrl}/roles`}
            component={RolesTab}
            key={"route-roles"}
          />
          <Route
            path={`${this.state.baseUrl}/organisations`}
            component={OrganisationsTab}
            key={"route-organisations"}
          />
          <Route
            path={`${this.state.baseUrl}/zones`}
            component={ZonesTab}
            key={"route-zones"}
          />
          <Route
            exact
            path={`${this.state.baseUrl}`}
            key={"route-users-redirect-1"}
            render={() => (
              <Redirect replace to={`${this.state.baseUrl}/roles`} />
            )}
          />
          <Route
            path={`${this.state.baseUrl}/*`}
            key={"route-users-redirect-2"}
            render={() => (
              <Redirect replace to={`${this.state.baseUrl}/roles`} />
            )}
          />
        </Switch>
      </Loader>
    );
  }
}

export default UserPage;
