import React, { Component } from "react";
import { EditDialog } from "../common/Dialog";
import { InputWithLabel } from "../common/theme/Theme";

export default class UpdateProjectDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      description: "",
      comments: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && prevProps.show !== this.props.show) {
      this.setState({
        description: "",
        comments: "",
      });
    }
  }

  getTitle = () => {
    if (this.props.nbrOfHits == 1) {
      return "Create Case";
    } else {
      return "Create Cases";
    }
  };

  getDescription = () => {
    if (this.props.nbrOfHits == 1) {
      return "Create a case for the hit.";
    } else {
      return "Create cases for the " + this.props.nbrOfHits + " selected hits.";
    }
  };

  getFields = () => {
    return [
      <InputWithLabel
        key="description"
        label="Description"
        type="textarea"
        rows="7"
        name="description"
        placeholder="Enter case description, the customer will be able to see this text"
        value={this.state.description}
        onChange={(e) => this.setState({ description: e.target.value })}
        autoFocus
      />,
      <InputWithLabel
        key="comments"
        label="Internal Notes"
        type="textarea"
        rows="7"
        name="comments"
        placeholder="Enter internal notes, only your colleagues will be able to see these notes"
        value={this.state.comments}
        onChange={(e) => this.setState({ comments: e.target.value })}
      />,
    ];
  };

  onSave = () => {
    this.props.onSave(this.state.description, this.state.comments);
  };

  render() {
    return (
      <EditDialog
        show={this.props.show}
        title={this.getTitle()}
        description={this.getDescription()}
        content={this.getFields()}
        onConfirm={this.onSave}
        onCancel={this.props.onCancel}
        submit={this.props.submit}
        errorMessage={this.props.errorMessage}
      />
    );
  }
}
