import React from "react";
import { Dropdown } from "react-bootstrap";
import { DropdownButton } from "./Buttons";

export const getCurrencyFilter = (currencies, selectedCurrency, onClick) => {
  if (!currencies || currencies.length === 0) {
    return null;
  }
  let title = selectedCurrency;
  if (!title || title.length === 0) {
    title = currencies[0];
  }
  return (
    <DropdownButton id="currency" title={title}>
      {getCurrencyDropdownItems(currencies, onClick)}
    </DropdownButton>
  );
};

const getCurrencyDropdownItems = (currencies, onClick) => {
  let content = [];
  currencies.forEach((currency) => {
    content.push(
      <Dropdown.Item key={currency} onClick={() => onClick(currency)}>
        {currency}
      </Dropdown.Item>,
    );
  });
  return content;
};

export const getColumns = (forecast, descrContentFunc) => {
  let columns = [];
  if (!forecast) {
    return columns;
  }
  columns.push({
    name: "description",
    style: { width: "250px", padding: "5px 10px 5px 10px" },
    contentFunction: (r) => descrContentFunc(r),
  });

  const months = forecast.months;
  for (const element of months) {
    columns.push({
      label: element,
      name: element,
      style: { textAlign: "right", whiteSpace: "nowrap" },
    });
  }
  return columns;
};
