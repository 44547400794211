import React, { Component } from "react";
import { SearchBar } from "./SearchBar";
import { SearchResult } from "./SearchResult";

export default class SearchTool extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parameters: {
        reloadFlag: false,
        searchText: "",
        extensionGroup: "",
        autoLayout: false,
        grid: false,
      },
      keywords: "",
      queryRunAt: "",
      queryRunning: false,
      fetchKeywords: false,
    };
  }

  componentDidMount() {
    let views = [
      {
        label: "Home",
        url: "/",
      },
      {
        label: "Search Tool",
        url: null,
      },
    ];

    this.props.window.setBreadcrumbViews(views);
    this.props.window.setPageTip(null);

    if (this.props.location.state && this.props.location.state.analyze) {
      this.setState({
        queryRunning: true,
        fetchKeywords: true,
      });
    }
  }

  componentDidUpdate() {
    if (this.state.fetchKeywords) {
      this.fetchKeywords();
    }
  }

  fetchKeywords = () => {
    const url = this.props.session.getModules().domainadmin.links.keywords;

    this.props.session.backendGet(
      url,
      (response) => {
        this.query(response.value, "EMPTY", true, false);
      },
      (errorMessage) => {
        this.props.window.showErrorFunc(errorMessage);
      },
    );

    this.setState({
      fetchKeywords: false,
    });
  };

  getKeywordsFromSearchText = (searchText) => {
    let result = [];
    searchText.forEach((s) => {
      if (!s.startsWith(".")) {
        let keyword = s;
        if (keyword.includes(".")) {
          keyword = keyword.substring(0, keyword.indexOf("."));
        }
        if (!result.includes(keyword)) {
          result.push(keyword);
        }
      }
    });
    return result;
  };

  query = (searchText, extensionGroup, autoLayout, grid) => {
    let useGrid = grid;

    if (!searchText) {
      searchText = "";
    }

    if (autoLayout) {
      useGrid =
        this.getKeywordsFromSearchText(searchText.trim().split(/[\t\r\n,;:\s]/))
          .length > 1;
    }

    this.setState({
      parameters: Object.assign(
        {},
        this.state.parameters,
        { extensionGroup: extensionGroup },
        { searchText: searchText },
        { autoLayout: autoLayout },
        { grid: useGrid },
      ),
      queryRunning: true,
    });
  };

  reloadPage() {
    this.setState({
      parameters: Object.assign({}, this.state.parameters, {
        reloadFlag: !this.state.parameters.reloadFlag,
      }),
    });
  }

  queryRunAtFunction = () => {
    this.setState({
      queryRunAt: Date.now(),
      queryRunning: false,
    });
  };

  render() {
    return (
      <div>
        <SearchBar
          {...this.props}
          onChange={(searchString, extensionGroup, grid, autoLayout) =>
            this.query(searchString, extensionGroup, grid, autoLayout)
          }
          extensionGroup={this.state.parameters.extensionGroup}
          searchString={this.state.parameters.searchText}
          queryTimeAt={this.state.queryRunAt}
          queryRunning={this.state.queryRunning}
        />
        <SearchResult
          session={this.props.session}
          window={this.props.window}
          parameters={this.state.parameters}
          queryFunction={this.queryRunAtFunction}
          grid={this.state.parameters.grid}
          queryRunning={this.state.queryRunning}
        />
      </div>
    );
  }
}
