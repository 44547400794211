import React, { Component, useState } from "react";
import { Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import styled from "styled-components";
import Breadcrumb from "./Breadcrumb";
import Colors from "./theme/Colors";
import Theme, { Logo } from "./theme/Theme";
import { lighten, rgba } from "polished";
import { ActionButton, LogoutButton, MainActionButton } from "./Buttons";
import CreateOrderDialog from "../order/CreateOrderDialog";
import Offcanvas from "react-bootstrap/Offcanvas";
import PrimaryMenu from "./Menu";

const TopBarWrapper = styled.div`
  nav {
    height: ${Theme.topbarHeight};
    background-color: ${Colors.topbarBackground};
    color: ${Colors.tertiary};
    border: none;
    box-shadow: 0 2px 6px -2px ${rgba(Colors.black, 0.15)};
    margin-bottom: 0;
    z-index: 99;
    position: fixed;
    width: 100%;
    padding: 7px 10px 7px 10px;

    &:after {
      clear: both;
    }

    ul {
      li {
        a {
          color: ${Colors.tertiary};
        }
        .fa {
          color: ${Colors.primary};
          font-size: 1.5em;
        }
      }
    }
  }
`;

const Username = styled.span`
  && {
    font-weight: 600;
    margin-right: 15px;
    font-size: 14px;
    position: relative;
  }
`;

const StyledActionButton = styled(ActionButton)`
  && {
    padding: 5px 12px;
    margin-bottom: 0px;
  }

  &.highlight {
    animation-name: highlightBackground;
    animation-duration: 2s;
    animation-iteration-count: 3;
    animation-direction: revert;
    animation-timing-function: ease-in-out;

    @keyframes highlightBackground {
      0% {
        background: ${Colors.actionButtonBackground};
      }
      50% {
        background: ${lighten(0.2, Colors.actionButtonBackground)};
      }
      100% {
        background: ${Colors.actionButtonBackground};
      }
    }
  }
`;

const UserWrapper = styled.span`
  position: relative;
  display: block;
  font-size: 14px;
  margin-right: -15px;

  svg {
    color: ${(props) => props.primary};
    font-size: 18px;
    margin-right: 20px;

    &:hover {
      color: ${(props) => props.secondary};
      cursor: pointer;
    }
  }
`;

function MobileMenu(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <LogoutButton
        onClick={handleShow}
        hidetext="true"
        text=""
        prefix={"far"}
        icon={"bars"}
        style={{ padding: "5px 12px", marginBottom: "0px" }}
      />

      <Offcanvas
        show={show}
        onHide={handleClose}
        backdrop="static"
        style={{ width: Theme.sidebarWidth }}
      >
        <Offcanvas.Header
          style={{
            paddingLeft: "10px",
            paddingTop: "0px",
            paddingRight: "0px",
            marginTop: "-4px",
          }}
        >
          <Offcanvas.Title>
            <Logo height={"25px"} />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body
          style={{
            fontFamily: "Libre Franklin, sans-serif",
            fontWeight: "700",
          }}
        >
          <PrimaryMenu
            session={props.session}
            window={props.window}
            pageTipStatus={props.pageTipStatus}
            onClick={handleClose}
          />
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      showDialog: false,
      submit: null,
      showCreateOrderDialog: false,
    };

    this.handleSettingsDialog = this.handleSettingsDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
  }

  componentDidMount() {
    this.setState({ loaded: true });
  }

  closeDialog() {
    this.setState({ showDialog: false, submit: null, errorMessage: null });
  }

  getUserInfo() {
    return (
      <Username>{this.props.session.getApplication().userDescription}</Username>
    );
  }

  handleSettingsDialog(e) {
    e.preventDefault();
    this.setState({ showDialog: !this.state.showDialog });
  }

  handleLogout() {
    const message = "Are you sure you want to logout?";
    this.props.window.showConfirmFunc(message, () => {
      this.props.session.logout();
    });
  }

  render() {
    const UserInfo = () => {
      return (
        <UserWrapper primary={Colors.primary} secondary={Colors.secondary}>
          {this.getUserInfo()}
        </UserWrapper>
      );
    };
    const Logout = () => {
      return (
        <LogoutButton
          hidetext="true"
          text="Logout"
          prefix={"far"}
          icon={"power-off"}
          onClick={this.handleLogout}
          style={{ padding: "5px 12px", marginBottom: "0px" }}
        />
      );
    };

    return (
      <TopBarWrapper>
        <Navbar>
          <Nav className="container-fluid">
            <Navbar.Brand>
              <Logo height={"25px"} />
            </Navbar.Brand>
            <Navbar.Collapse>
              <Navbar.Text className="d-none d-md-block">
                <Breadcrumb views={this.props.breadcrumbViews} />
              </Navbar.Text>
              <Navbar.Text className="ms-auto" />
              <Navbar.Text>
                <UserInfo />
              </Navbar.Text>
              <Navbar.Text>
                <MainActionButton
                  onClick={() => this.setState({ showCreateOrderDialog: true })}
                  icon="plus"
                  prefix="fas"
                  hidetext="true"
                  text="Create Order"
                  tooltip="Order a new service from Abion, for example a domain registration or trademark registration"
                  style={{ padding: "5px 12px", marginBottom: "0px" }}
                  focus="hover"
                  disabled={this.props.session.hasRole("SYS_ADMIN")}
                  disabledTooltip="Internal users can't place orders in Abion Core"
                />
              </Navbar.Text>
              {Object.values(this.props.session.getModules()).map((module) =>
                module.toolbarItems.map((toolbarItem) => (
                  <Navbar.Text key={toolbarItem.label + "-text"}>
                    <LinkContainer
                      to={toolbarItem.path}
                      key={toolbarItem.label}
                    >
                      <StyledActionButton
                        hidetext="true"
                        text={toolbarItem.label}
                        className={
                          toolbarItem.highlighted === true ? "highlight" : ""
                        }
                        icon="question"
                        prefix="fas"
                      />
                    </LinkContainer>
                  </Navbar.Text>
                )),
              )}
              <Navbar.Text>
                <Logout />
              </Navbar.Text>
              <Navbar.Text className="d-block d-md-none">
                <MobileMenu
                  session={this.props.session}
                  window={this.props.window}
                  pageTipStatus={this.props.pageTipStatus}
                />
              </Navbar.Text>
            </Navbar.Collapse>
          </Nav>
        </Navbar>
        <CreateOrderDialog
          session={this.props.session}
          show={this.state.showCreateOrderDialog}
          onClose={() => this.setState({ showCreateOrderDialog: false })}
        />
      </TopBarWrapper>
    );
  }
}

export default TopBar;
