import fileDownload from "js-file-download";
import React, { Component } from "react";
import styled from "styled-components";
import {
  BlockingActionButton,
  DropdownButton,
  MainActionButton,
  TableButton,
} from "../common/Buttons";
import { TableBackend, TableFilter } from "../common/Tables";
import { FormatDateMonospace, FormatOrganisation } from "../common/Utils";
import { UpdateCommentsIcon } from "../common/theme/Icons";
import UpdateCommentsDialog from "../common/UpdateCommentsDialog";
import OrganisationFilter from "../common/OrganisationFilter";
import CertificateDetailsDialog from "./CertificateDetailsDialog";
import CreateOrderDialog from "../order/CreateOrderDialog";
import { Dropdown } from "react-bootstrap";

const PageStyle = styled.div`
  .dropdown {
    vertical-align: top;
    margin-right: 20px;
  }
`;

class CertificatesTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parameters: {
        accountIds: "",
        freetext: "",
        onExpire: "ALL",
        reloadFlag: false,
      },
      certificate: null,
      showDetailsDialog: false,
      showUpdateCommentsDialog: false,
      showCreateDialog: false,
      comments: null,
      commentsUrl: null,
      submit: false,
      errorMessage: null,
      exported: 0,
    };
  }

  getUrl = (sorting, expanding, parameters) => {
    let url =
      this.props.session.getModules().portfolio.links.portfolio +
      "/certificates";
    url += "?sort=" + sorting;
    url += expanding ? "" : "&page[limit]=15";
    url += "&accountIdFilter=" + parameters.accountIds;
    url += "&freetextFilter=" + encodeURIComponent(parameters.freetext);
    url += "&onExpireFilter=" + parameters.onExpire;
    return url;
  };

  getOnExpireLabel = (onExpire) => {
    if (onExpire === "RENEW") {
      return "Certificates that will be Renewed";
    } else if (onExpire === "CANCEL") {
      return "Certificates that will be Cancelled";
    } else {
      return "All On Service Expiry";
    }
  };

  onEditComments = (url, comments) => {
    this.setState({
      showUpdateCommentsDialog: true,
      commentsUrl: url,
      comments: comments,
    });
  };

  onUpdateComments = (newComments) => {
    const message = "Comments updated successfully";
    if (
      newComments === null ||
      (newComments.trim() === "" && this.state.comments === null) ||
      this.state.comments === newComments.trim()
    ) {
      this.props.window.showSuccessFunc(message);
      this.closeDialogs();
    } else {
      this.setState({ submit: true });
      const errorFun = (msg) => {
        this.setState({ submit: false, errorMessage: msg });
      };
      this.props.session.backendPut(
        this.state.commentsUrl,
        {
          comments: newComments.trim(),
        },
        () => {
          this.props.window.showSuccessFunc(message);
          this.closeUpdateCommentsDialog();
          this.reloadPage();
        },
        errorFun,
      );
    }
  };

  reloadPage = () => {
    this.setState({
      parameters: Object.assign({}, this.state.parameters, {
        reloadFlag: !this.state.parameters.reloadFlag,
      }),
    });
  };

  openDetailsDialog = (certificate) => {
    this.setState({
      certificate: certificate,
      showDetailsDialog: true,
    });
  };

  closeDetailsDialog = () => {
    this.setState({
      showDetailsDialog: false,
    });
  };

  closeUpdateCommentsDialog = () => {
    this.setState({
      commentsUrl: null,
      comments: null,
      showUpdateCommentsDialog: false,
      submit: false,
      errorMessage: null,
    });
  };

  onChangeAccount = () => {
    this.reloadPage();
  };

  exportData = () => {
    let url =
      this.props.session.getModules().portfolio.links.exportCertificates;
    url += "?accountIdFilter=" + this.state.parameters.accountIds;
    url +=
      "&freetextFilter=" + encodeURIComponent(this.state.parameters.freetext);
    url += "&onExpireFilter=" + this.state.parameters.onExpire;

    this.props.session.backendGetFile(
      url,
      (response) => {
        fileDownload(response, "Certificates.xlsx");
        this.setState({ exported: Date.now() });
      },
      (error) => {
        this.props.window.showErrorFunc(error);
        this.setState({ exported: Date.now() });
      },
    );
  };

  getDescription = (commonName, sans) => {
    let elements = [];
    if (commonName) {
      elements.push(
        <div key={commonName}>
          {commonName}
          <br></br>
        </div>,
      );
    }
    if (sans) {
      let list = sans;
      list.sort();
      list.forEach((v) => {
        if (v !== commonName) {
          elements.push(
            <div key={v}>
              {v}
              <br></br>
            </div>,
          );
        }
      });
    }
    if (elements.length > 4) {
      elements = elements.slice(0, 4);
      elements.push(
        <div key="extend">
          ...<br></br>
        </div>,
      );
    }
    return elements;
  };

  onUpdateLifecycle = (serviceDescription, lifecycle, url) => {
    const text = serviceDescription ? serviceDescription + " " : "";
    const message =
      "Are you sure you want to " +
      lifecycle.toLowerCase() +
      " the certificate service " +
      text +
      "when it expires?";
    this.props.window.showConfirmFunc(message, () =>
      this.updateLifecycle(lifecycle, url),
    );
  };

  updateLifecycle = (lifecycle, url) => {
    const callback = () => {
      this.props.window.showSuccessFunc("Service is updated successfully!");
      this.reloadPage();
    };
    let renew = lifecycle.toLowerCase() === "renew";
    this.props.session.backendPut(
      url,
      {
        renew: renew,
      },
      callback,
      this.props.window.showErrorFunc,
    );
  };

  render() {
    let actions = [];

    actions.push(
      <MainActionButton
        key="order-certificate"
        onClick={() => this.setState({ showCreateDialog: true })}
        text="Order Certificate"
        icon="plus"
        prefix="fas"
        tooltip="Open the order dialog and fill in details to order a new certificate."
        disabled={
          !this.props.session.hasRole("ORDER_CREATE") ||
          !this.props.session.hasRole("CERT_ADMIN_EDIT")
        }
        disabledTooltip="Your user account does not have privileges to order certificates, contact your client manager to activate this functionality."
        invisible={this.props.session.hasRole("SYS_ADMIN")}
      />,
    );

    if (!this.props.session.hasRole("SYS_ADMIN")) {
      actions.push(
        <BlockingActionButton
          key="export"
          onClick={this.exportData}
          text={"Export"}
          icon={"cloud-download"}
          prefix={"fas"}
          tooltip="Exports the certificates to an Excel file. Note that only rows matching current filter are exported."
          reload={this.state.exported}
        />,
      );
    }

    const filters = [
      <OrganisationFilter
        key="organisation-filter"
        session={this.props.session}
        onChange={(o) =>
          this.setState({
            parameters: Object.assign({}, this.state.parameters, {
              accountIds: o,
            }),
          })
        }
      />,
      <DropdownButton
        key="type-filter"
        title={this.getOnExpireLabel(this.state.parameters.onExpire)}
        style={{ verticalAlign: "top" }}
      >
        <Dropdown.Item
          onClick={() =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                onExpire: "ALL",
              }),
            })
          }
        >
          {this.getOnExpireLabel("ALL")}
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                onExpire: "RENEW",
              }),
            })
          }
        >
          {this.getOnExpireLabel("RENEW")}
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                onExpire: "CANCEL",
              }),
            })
          }
        >
          {this.getOnExpireLabel("CANCEL")}
        </Dropdown.Item>
      </DropdownButton>,
      <TableFilter
        key="table-filter"
        value={this.state.parameters.freetext}
        onChange={(o) =>
          this.setState({
            parameters: Object.assign({}, this.state.parameters, {
              freetext: o,
            }),
          })
        }
      />,
    ];

    const columns = [
      {
        label: "Description",
        name: "description",
        sortable: true,
        contentFunction: (r) => (
          <div>{this.getDescription(r.commonName, r.sans)}</div>
        ),
      },
      {
        label: "Organisation",
        name: "organisation",
        sortable: true,
        contentFunction: (r) =>
          FormatOrganisation(
            r.organisation,
            this.props.session.getApplication().organisationPrefix,
          ),
      },
      {
        label: "Type",
        name: "certificateType",
        sortable: true,
        style: { width: "200px" },
        contentFunction: (r) => r.certificateTypeDescription,
      },
      {
        label: "Expiry Date",
        name: "expiryDate",
        sortable: true,
        style: { width: "120px" },
        contentFunction: (r) => FormatDateMonospace(r.expiryDate),
      },
      {
        label: "Service Expiry Date",
        name: "invoiceDate",
        sortable: true,
        style: { width: "130px" },
        contentFunction: (r) => FormatDateMonospace(r.invoiceDate),
      },
      {
        label: "On Service Expiry",
        name: "onExpire",
        style: { width: "120px" },
      },
      {
        label: "Comments",
        name: "comments",
        style: { width: "270px" },
        contentFunction: (r) => (
          <div style={{ "word-break": "break-word", whiteSpace: "pre-wrap" }}>
            <tr>
              <td width="230px">{r.comments}</td>
              <td style={{ verticalAlign: "top" }}>
                <UpdateCommentsIcon
                  onClick={() =>
                    this.onEditComments(r.links.comments, r.comments)
                  }
                />
              </td>
            </tr>
          </div>
        ),
      },
      {
        style: { width: "130px" },
        contentFunction: (r) => (
          <TableButton
            onClick={() => this.openDetailsDialog(r)}
            icon="eye"
            prefix={"fas"}
            text="Details"
            className={"secondary"}
          />
        ),
      },
    ];

    return (
      <PageStyle>
        <TableBackend
          session={this.props.session}
          window={this.props.window}
          actions={actions}
          filters={filters}
          columns={columns}
          sorting="description"
          parameters={this.state.parameters}
          urlFunction={this.getUrl}
        />
        <CertificateDetailsDialog
          session={this.props.session}
          window={this.props.window}
          show={this.state.showDetailsDialog}
          certificate={this.state.certificate}
          onClose={this.closeDetailsDialog}
          onChangeAccount={this.onChangeAccount}
          onUpdateLifecycle={this.onUpdateLifecycle}
        />
        <UpdateCommentsDialog
          session={this.props.session}
          window={this.props.window}
          comments={this.state.comments}
          show={this.state.showUpdateCommentsDialog}
          onCancel={this.closeUpdateCommentsDialog}
          onSave={this.onUpdateComments}
          submit={this.state.submit}
          errorMessage={this.state.errorMessage}
        />
        <CreateOrderDialog
          session={this.props.session}
          show={this.state.showCreateDialog}
          onClose={() => this.setState({ showCreateDialog: false })}
          step="OPERATION"
          operation="SUBSCRIPTION-IT_CERTIFICATE"
        />
      </PageStyle>
    );
  }
}

export default CertificatesTab;
