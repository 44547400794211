import React, { Component } from "react";
import { TableBase } from "../../common/Tables";
import styled from "styled-components";
import { SwitchCheckbox } from "../../common/theme/Theme";
import Colors from "../../common/theme/Colors";

const ChangeLog = styled.div`
  margin-bottom: 30px;
  h3 {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 1.5px;
  }
`;

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 5px;
`;

class ZoneComparison extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onlyChanged: true,
    };
  }

  getStatusStyle = (status) => {
    switch (status) {
      case "added":
        return { color: Colors.success };
      case "removed":
      case "updated":
        return { color: Colors.error };
      default:
        return {};
    }
  };

  getZoneRows = (data) => {
    let rows = [];
    for (let i = 0; i < data.length; i++) {
      let row = data[i];
      if (this.state.onlyChanged === true && row.operation === "unchanged") {
        // Skip unchanged
      } else {
        rows.push(row);
      }
    }

    return rows;
  };

  getTableRows = (data) => {
    let rows = [];
    for (let i = 0; i < data.length; i++) {
      let record = data[i];
      if (this.state.onlyChanged === true && record.operation === "unchanged") {
        // Skip unchanged
      } else {
        let row = {
          operation: record.operation,
        };
        for (let j = 0; j < record.columns.length; j++) {
          let recordColumn = record.columns[j];
          if (record.operation === "added") {
            row[recordColumn.field] = recordColumn.newValue;
          } else {
            row[recordColumn.field] = recordColumn.oldValue;
          }
        }
        rows.push(row);
      }
    }
    return rows;
  };

  render = () => {
    const zoneColumns = [
      {
        label: "Field",
        name: "field",
      },
      {
        label: "Old value",
        name: "oldValue",
      },
      {
        label: "New value",
        name: "newValue",
      },
      {
        label: "Status",
        style: { width: "120px" },
        contentFunction: (r) => (
          <div style={this.getStatusStyle(r.operation)}>{r.operation}</div>
        ),
      },
    ];

    const zoneRecordColumns = [
      {
        label: "Name",
        name: "name",
      },
      {
        label: "TTL",
        name: "ttl",
      },
      {
        label: "Type",
        name: "recordType",
      },
      {
        label: "Data",
        name: "data",
      },
      {
        label: "Status",
        style: { width: "120px" },
        contentFunction: (r) => (
          <div style={this.getStatusStyle(r.operation)}>{r.operation}</div>
        ),
      },
    ];

    const zoneRedirectsColumns = [
      {
        label: "Type",
        name: "type",
      },
      {
        label: "Name",
        name: "name",
      },
      {
        label: "Path",
        name: "path",
      },
      {
        label: "Destination",
        name: "destination",
      },
      {
        label: "Certificate",
        name: "certificate",
        contentFunction: (r) => (r.certificate ? "Yes" : "No"),
      },
      {
        label: "Status",
        style: { width: "120px" },
        contentFunction: (r) => (
          <div style={this.getStatusStyle(r.operation)}>{r.operation}</div>
        ),
      },
    ];

    const zoneServersColumns = [
      {
        label: "IP",
        name: "ip",
      },
      {
        label: "Status",
        style: { width: "120px" },
        contentFunction: (r) => (
          <div style={this.getStatusStyle(r.operation)}>{r.operation}</div>
        ),
      },
    ];

    const zoneSetting = this.getZoneRows(this.props.data.zone);
    const zoneRecord = this.getTableRows(this.props.data.records);
    const zoneRedirect = this.getTableRows(this.props.data.redirects);
    const zoneServer = this.getTableRows(this.props.data.servers);

    let settingDiv = null;
    if (zoneSetting.length > 0) {
      settingDiv = (
        <ChangeLog>
          <h3>Settings</h3>
          <TableBase columns={zoneColumns} rows={zoneSetting} />
        </ChangeLog>
      );
    }

    let recordDiv = null;
    if (zoneRecord.length > 0) {
      recordDiv = (
        <ChangeLog>
          <h3>Zone Records</h3>
          <TableBase columns={zoneRecordColumns} rows={zoneRecord} />
        </ChangeLog>
      );
    }

    let redirectDiv = null;
    if (zoneRedirect.length > 0) {
      redirectDiv = (
        <ChangeLog>
          <h3>Zone Redirects</h3>
          <TableBase columns={zoneRedirectsColumns} rows={zoneRedirect} />
        </ChangeLog>
      );
    }

    let serverDiv = null;
    if (zoneServer.length > 0) {
      serverDiv = (
        <ChangeLog>
          <h3>Zone Servers</h3>
          <TableBase columns={zoneServersColumns} rows={zoneServer} />
        </ChangeLog>
      );
    }

    return (
      <div>
        <StyledDiv>
          {SwitchCheckbox(
            "Only display changed items",
            this.state.onlyChanged,
            (o) => this.setState({ onlyChanged: o.target.checked }),
          )}
        </StyledDiv>
        {settingDiv}
        {recordDiv}
        {redirectDiv}
        {serverDiv}
      </div>
    );
  };
}

export default ZoneComparison;
