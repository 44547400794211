import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { DialogSection } from "../../common/Dialog";
import styled from "styled-components";
import Colors from "../../common/theme/Colors";
import { TableBase } from "../../common/Tables";
import { ActionButton } from "../../common/Buttons";
import UpdateDsDialog from "./UpdateDsDialog";

const DivStyle = styled.div`
  border: 1px solid ${Colors.border};
  background-color: ${Colors.white};

  p {
    padding-top: 10px;
  }
  .sub-section-status {
    padding-top: 10px;
  }
  .section-title {
    color: ${Colors.tertiary};
    margin-top: 10px;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 1.5px;
  }
  > .container-fluid > .actions {
    padding: 15px 0px 10px 0px;
  }
`;

class DsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ds: null,
      updateDsDialogShow: false,
      loadError: null,
      updateError: null,
      submit: null,
    };
  }

  componentDidMount() {
    this.reload();
  }

  reload = () => {
    this.setState({ ds: null, loadError: null });

    if (this.props.domain?.links.ds) {
      this.props.session.backendGet(
          this.props.domain.links.ds,
          (response) => {
            this.setState({ ds: response });
          },
          (error) => {
            this.setState({ loadError: error });
          }
      );
    }
  };

  showUpdateDsDialog = () => {
    this.setState({
      updateDsDialogShow: true,
      updateError: null,
      submit: null,
    });
  };

  hideUpdateDsDialog = () => {
    this.setState({
      updateDsDialogShow: false,
      updateError: null,
      submit: null,
    });
  };

  getDs = () => {
    let parts = [];

    let loading = (
        <Row key="loading">
          <Col xs={12}>
            <p>Loading, please wait</p>
          </Col>
        </Row>
    );

    let error = (
        <Row key="error">
          <Col xs={12}>
            <p>Failed fetching data. Please try again later.</p>
          </Col>
        </Row>
    );

    if (this.state.loadError) {
      parts.push(error);
    } else if (this.state.ds === null) {
      parts.push(loading);
    } else {
      parts.push(this.getDsStatus());
      parts.push(this.getDsFromSource("dns"));
      parts.push(this.getDsFromSource("epp"));
    }

    return parts;
  };

  getDsStatus = () => {
    return [
      <Row key="dsStatus">
        <Col xs={12}>
          <div className="sub-section-status">
            <b>Status: </b> {this.state.ds.description}
            <br />
            <br />
          </div>
        </Col>
      </Row>,
    ];
  };

  getDsFromSource = (source) => {
    const dsColumns = [
      {
        name: "keyTag",
        label: "Key Tag",
        style: { width: "100px" },
      },
      {
        name: "digest",
        label: "Digest",
      },
      {
        name: "alg",
        label: "Algorithm",
        style: { width: "150px" },
      },
      {
        name: "digestType",
        label: "Digest Type",
        style: { width: "140px" },
      },
    ];

    const keyColumns = [
      {
        name: source === "epp" ? "digest" : "pubKey",
        label: "Public Key",
      },
      {
        name: "alg",
        label: "Algorithm",
        style: { width: "150px" },
      },
    ];

    let title;
    let content;
    if (source === "dns") {
      title = "DS Data from Zone";
      content = (
          <TableBase
              columns={this.state.ds.publicKey ? keyColumns : dsColumns}
              rows={this.state.ds.zoneDsRecords}
          />
      );
    } else {
      title = "DS Data from Registry";
      content = (
          <TableBase
              columns={this.state.ds.publicKey ? keyColumns : dsColumns}
              rows={this.state.ds.parentDsRecords}
          />
      );
    }

    return <DialogSection title={title}>{content}</DialogSection>;
  };

  getDisabledDsTooltip = (hasRoleEdit, dsSyncable) => {
    if (!hasRoleEdit) {
      return "Your user account does not have privileges to Update DS Data, contact your client manager to activate this functionality";
    } else if (!dsSyncable) {
      return "You can't update DS data as either the TLD doesn't support automatic DS data update (but you can contact us to do it) or a zone object exists (implying only Abions managed DNSSEC solution is available)";
    }
  };

  getActionButtons = () => {
    let parts = [];
    let hasRoleEdit =
        this.props.session.hasRole("ORDER_CREATE") &&
        this.props.session.hasRole("DOMAIN_ADMIN_EDIT");
    let dsSyncable = this.props.domain.ds?.syncable;

    parts.push(
        <ActionButton
            key="DsData"
            text={"Update DS Data"}
            icon={"edit"}
            prefix={"fas"}
            disabled={!hasRoleEdit || !dsSyncable}
            disabledTooltip={this.getDisabledDsTooltip(hasRoleEdit, dsSyncable)}
            onClick={() => this.showUpdateDsDialog()}
        />
    );
    return (<Row key="ds-actions" className="actions">
      <Col xs={12}>{parts}</Col>
    </Row>);
  };

  updateDs = (action) => {
    if (this.props.domain.registryLockType !== "NONE") {
      this.setState({
        updateError:
            "This domain has Registry Lock. Please contact us for further assistance",
        submit: false,
      });
      return;
    }

    if (action === null) {
      this.setState({
        updateError: "Please select one action",
        submit: false,
      });
      return;
    }

    const errorFun = (msg) => {
      this.setState({ updateError: msg, submit: false });
    };
    const callback = () => {
      this.hideUpdateDsDialog();
      this.reload();
      this.setState({ updateError: null });
      const message = "DS data updated successfully";
      this.props.window.showSuccessFunc(message);
    };

    let url = "";
    if (this.props.domain?.links.syncDs && this.props.domain?.links.clearDs) {
      if (action === "sync") {
        url = this.props.domain.links.syncDs;
      } else {
        url = this.props.domain.links.clearDs;
      }
      this.setState({ submit: true });
      this.props.session.backendPost(url, {}, callback, errorFun);
    }
  };

  render() {
    return (
        <DivStyle>
          <Container key="parts" fluid={true}>
            {this.getActionButtons()}
            {this.getDs()}
          </Container>
          <UpdateDsDialog
              session={this.props.session}
              window={this.props.window}
              show={this.state.updateDsDialogShow}
              title={this.props.domain.name}
              errorMessage={this.state.updateError}
              submit={this.state.submit}
              onSave={this.updateDs}
              onCancel={this.hideUpdateDsDialog}
          />
        </DivStyle>
    );
  }
}

export default DsTab;
