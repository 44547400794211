import React, { Component } from "react";
import { DropdownButton } from "../common/Buttons";
import styled from "styled-components";

const WatchFilterStyle = styled.span`
  .dropdown {
    vertical-align: top;
    margin-left: 0px !important;
    margin-right: 20px;
  }
`;

export default class WatchFilter extends Component {
  render() {
    return (
      <WatchFilterStyle>
        <DropdownButton title={this.props.title}>
          {this.props.options}
        </DropdownButton>
      </WatchFilterStyle>
    );
  }
}
