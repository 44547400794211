import React, { Component } from "react";
import { Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import styled from "styled-components";
import Colors from "../../common/theme/Colors";
import { rgba } from "polished";
import { FormatInput } from "../../common/Utils";

const StyledContainer = styled(Container)`
  table {
    width: 100%;
  }

  table td {
    vertical-align: top;
    padding: 10px 10px 5px 15px;
    font-size: 14px;
  }

  .r1 span,
  .r4 span {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 1.5px;
    margin-left: 12px;
  }

  .r1 .c1,
  .r1 .c3,
  .r4 .c1,
  .r4 .c3 {
    border-radius: 10px 10px 0px 0px;
  }

  .r2 .c1,
  .r2 .c3,
  .r5 .c1,
  .r5 .c3 {
    border-radius: 0px 0px 10px 10px;
  }

  .c2 {
    width: 0px;
  }

  .r3 .c1 {
    height: 20px;
  }

  .r1 .c1,
  .r2 .c1 {
    background-color: ${rgba(Colors.categoryLegal, 0.15)};
  }

  .r1 .c3,
  .r2 .c3 {
    background-color: ${rgba(Colors.categoryDigital, 0.15)};
  }

  .r4 .c1,
  .r5 .c1 {
    background-color: ${rgba(Colors.categoryTechnical, 0.15)};
  }

  .r4 .c3,
  .r5 .c3 {
    background-color: ${rgba(Colors.categoryObp, 0.15)};
  }

  .radio input[type="radio"] {
    position: absolute;
    line-height: normal;
    margin-top: 4px;
    margin-left: -20px;
  }

  .radio label {
    cursor: pointer;
    font-weight: 400;
    margin-bottom: 0;
    min-height: 20px;
    padding-left: 20px;
  }

  .radio + .radio {
    margin-top: -5px;
  }

  .radio {
    margin-bottom: 10px;
    margin-top: 10px;
  }
`;

export default class OperationStep extends Component {
  getTable = () => {
    return (
      <table>
        <tbody>
          <tr className="r1">
            {this.getHeader("Legal", "legal", "c1")}
            <td className="c2"></td>
            {this.getHeader("Domains", "digital", "c3")}
          </tr>

          <tr className="r2">
            {this.getSection("LEGAL", "c1")}
            <td className="c2"></td>
            {this.getSection("DIGITAL", "c3")}
          </tr>

          <tr className="r3">
            <td className="c1" colspan="3"></td>
          </tr>

          <tr className="r4">
            {this.getHeader("Web Security", "technical", "c1")}
            <td className="c2"></td>
            {this.getHeader("Brand Protection", "obp", "c3")}
          </tr>

          <tr className="r5">
            {this.getSection("TECHNICAL", "c1")}
            <td className="c2"></td>
            {this.getSection("OBP", "c3")}
          </tr>
        </tbody>
      </table>
    );
  };

  getHeader = (label, image, clazz) => {
    let file = require("../../common/img/order/" + image + ".svg");
    console.log(file);
    return (
      <td className={clazz}>
        <img src={file} height={20} />
        <span>{label}</span>
      </td>
    );
  };

  getSection = (category, clazz) => {
    return (
      <td className={clazz}>
        {this.getOperations(category, true)}
        {this.getOperations(category, false)}
      </td>
    );
  };

  getOperations = (category, prioritized) => {
    let operations = this.props.session.getPortfolioOperations();
    let items = [];

    for (const element of operations) {
      if (prioritized !== element.priority > 0) {
        continue;
      }
      if (element.category === category) {
        items.push(
          this.getOperation(
            element.id,
            element.description,
            element.explanation,
          ),
        );
      }
    }

    return items;
  };

  getOperation = (id, description, explanation) => {
    let radio = (
      <div key={id} className="radio">
        <label>
          <input
            type="radio"
            value={FormatInput(id)}
            onChange={() => this.props.onOperationChange(id)}
            checked={id === this.props.conf.object.operationId}
          />
          {description}
        </label>
      </div>
    );

    if (explanation == null) {
      return radio;
    }

    let tooltip = (
      <Tooltip style={{ whiteSpace: "pre-wrap", position: "fixed" }}>
        {explanation}
      </Tooltip>
    );

    return (
      <OverlayTrigger key={id} overlay={tooltip} placement="bottom" delay={500}>
        {radio}
      </OverlayTrigger>
    );
  };

  render() {
    return (
      <StyledContainer fluid={true}>
        <Row className="section-description">
          <Col xs={12}>
            Select the service you wish to order below, then press the next
            button to start entering the details of the order.
          </Col>
        </Row>
        <Row className="section-field">
          <Col xs={12}>{this.getTable()}</Col>
        </Row>
      </StyledContainer>
    );
  }
}
