import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import { DropdownButton } from "../common/Buttons";
import styled from "styled-components";

const StyledSpan = styled.span`
  .dropdown {
    vertical-align: top;
    margin-right: 20px;
  }
`;

export default class ProjectMessageTypeFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: this.getTitle(this.props.onlyPending, this.props.caseModule),
    };
  }

  getTitle = (onlyPending, caseModule) => {
    if (onlyPending) {
      return caseModule
        ? "Cases waiting for reply"
        : "Projects waiting for reply";
    } else {
      return "Any Message Status";
    }
  };

  render() {
    let items = [];
    items.push(
      <Dropdown.Item
        key="all_project_types"
        onClick={() => {
          this.setState({ title: this.getTitle(false, this.props.caseModule) });
          this.props.onChange(false);
        }}
      >
        {this.getTitle(false, this.props.caseModule)}
      </Dropdown.Item>,
    );
    items.push(
      <Dropdown.Item
        key="pending_project_types"
        onClick={() => {
          this.setState({
            title: this.getTitle(true, this.props.caseModule),
          });
          this.props.onChange(true);
        }}
      >
        {this.getTitle(true, this.props.caseModule)}
      </Dropdown.Item>,
    );

    return (
      <StyledSpan>
        <DropdownButton
          title={this.state.title}
          style={{ verticalAlign: "top" }}
        >
          {items}
        </DropdownButton>
      </StyledSpan>
    );
  }
}
