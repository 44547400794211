import React, { Component } from "react";
import { Col } from "react-bootstrap";
import DashboardSummary from "./DashboardSummary";
import Colors from "../common/theme/Colors";

export default class LegalSummaryItem extends Component {
  getItems = () => {
    let portfolio = this.props.session.hasRole("PORTFOLIO");
    let internal = !this.props.data.countries;

    let tooltipPortfolio = internal ? "managed by Abion" : "in your portfolio";
    let tooltipClick = portfolio
      ? " Click for additional details."
      : internal
        ? ""
        : " Request role Portfolio to see additional details.";

    return [
      [
        "Trademarks",
        this.props.data.legalRights,
        portfolio ? "/portfolio/trademarks" : null,
        "The number of trademarks " +
          tooltipPortfolio +
          ". Trademarks are counted only once, even though they exist in multiple countries. Also any design rights and patents are included." +
          tooltipClick,
      ],
      [
        "Services",
        this.props.data.legalSubscriptions,
        portfolio ? "/portfolio/legalServices" : null,
        "The number of legal services " +
          tooltipPortfolio +
          ". This includes the Corporate Trademark Management services." +
          tooltipClick,
      ],
    ];
  };

  render() {
    return (
      <Col>
        <DashboardSummary
          image={require("../common/img/dashboard/legal.svg").default}
          title="Legal"
          color={Colors.categoryLegal}
          items={this.getItems()}
        />
      </Col>
    );
  }
}
