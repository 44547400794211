import React, { Component } from "react";
import { TableFrontend } from "../../common/Tables";
import { InternalLink } from "../../common/Link";

class RelationsTab extends Component {
  render() {
    const columns = [
      {
        label: "Zone",
        name: "zoneName",
        sortable: true,
        filterable: true,
        sortFunction: (r) => r.zoneName,
        contentFunction: (r) => (
          <InternalLink
            to={"/dnsadmin/zones/" + r.id}
            onClick={() => this.props.onClick(r.id)}
            text={r.zoneName}
            className="table-link"
          />
        ),
      },
    ];

    const { session, window } = this.props;
    const url = this.props.zone.links.relations;

    return (
      <TableFrontend
        session={session}
        window={window}
        filterable={true}
        columns={columns}
        sorting="zoneName"
        url={url}
      />
    );
  }
}

export default RelationsTab;
