import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CustomField from "./CustomField";

export default class ExtensionContactStep extends Component {
  getFields = () => {
    return this.props.conf.parameters.fields.map((o) => (
      <CustomField
        field={o}
        value={this.props.conf.object.parameters[o.id]}
        onChange={this.props.onParameterChange}
      />
    ));
  };

  render() {
    return (
      <Container fluid={true}>
        <Row className="section-title">
          <Col xs={12}>Contact Parameters</Col>
        </Row>
        <Row className="section-description">
          <Col xs={12}>
            Some registries require some extra contact information, enter this
            by filling out the fields below.
          </Col>
        </Row>
        {this.getFields()}
      </Container>
    );
  }
}
