import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
import styled from "styled-components";
import Colors from "./theme/Colors";
import Icon from "./theme/Icons";

const CrumbLink = styled.a`
  && {
    font-weight: 500;
    color: ${Colors.tertiary};
    text-decoration: none;
    font-size: 14px;
    &:hover {
      color: ${Colors.secondary};
    }
  }
`;

const Text = styled.span`
  && {
    font-size: 14px;
  }
`;

class Breadcrumb extends Component {
  getItems() {
    let views = this.props.views;
    let items = [];

    if (this.props.views) {
      for (let i = 0; i < views.length; i++) {
        if (i > 0) {
          items.push(
            <Icon
              name={"angle-right"}
              key={i}
              prefix={"fas"}
              className={"crumb"}
              iconsize={12}
            />,
          );
        }
        items.push(this.getItem(views[i]));
      }
    }

    return items;
  }

  getItem(view) {
    if (view.url) {
      return (
        <LinkContainer key={view.label} to={view.url}>
          <CrumbLink>{view.label}</CrumbLink>
        </LinkContainer>
      );
    } else {
      return <Text key={view.label}>{view.label}</Text>;
    }
  }

  render() {
    return this.getItems();
  }
}

export default Breadcrumb;
