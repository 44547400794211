import React, { Component } from "react";
import { EditDialog } from "../../common/Dialog";
import { Col, Row } from "react-bootstrap";
import Selector from "../../common/Selector";
import { CheckBox, InputWithLabel } from "../../common/theme/Theme";
import { FormatInput, FormatOrganisation } from "../../common/Utils";
import { PortsAlert } from "../../common/PortsAlert";

class NameserverSetDialog extends Component {
  create;

  constructor(props) {
    super(props);
    this.state = {
      conf: null,
      nameservers: [],
      validate: true,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.show &&
      prevProps.show !== this.props.show &&
      this.props.conf
    ) {
      this.setState({
        conf: this.props.conf,
        nameservers: this.props.conf.object.nameservers,
      });
      this.create = this.props.conf.object.id === null;
    }
  }

  updateField = (field, value) => {
    const conf = this.state.conf;
    conf.object[field] = value;
    this.setState({ conf: conf });
  };

  onFieldChange = (field) => {
    switch (field) {
      case "organisation":
        return (e) => {
          this.updateField("accountId", e.value);
        };
      case "nameservers":
        return (e) => {
          let ns = this.isNullOrEmpty(e.target.value) ? "" : e.target.value;
          let value = ns.split(/[\r?\n]+/);
          this.updateField("nameservers", value);
        };
      case "validate":
        return (e) => {
          this.setState({ validate: e.target.checked });
        };
      default:
        return (e) => {
          this.updateField(field, e.target.value);
        };
    }
  };

  getNameservers = () => {
    const nameservers = this.state.conf.object["nameservers"];
    return nameservers !== null ? nameservers.join("\n") : "";
  };

  isNullOrEmpty = (input) => {
    return input === null || input.trim().length === 0;
  };

  getBody = () => {
    let options = this.state.conf.organisations.map((o) => {
      return {
        value: o.value,
        label: FormatOrganisation(
          o.label,
          this.props.session.getApplication().organisationPrefix,
        ),
      };
    });

    return [
      <Row key="organisation" name="organisation-row" className="section-field">
        <Col md={12}>
          <Selector
            label={"Organisation"}
            placeholder="Select organisation"
            options={options}
            onChange={this.onFieldChange("organisation")}
            defaultValue={FormatInput(this.state.conf.object["accountId"])}
          />
        </Col>
      </Row>,
      <Row key="nameservers" name="nameservers-row">
        <Col md={12}>
          <InputWithLabel
            label="Nameservers"
            type="textarea"
            rows="6"
            placeholder="Enter nameservers, one per line"
            value={this.getNameservers()}
            onChange={this.onFieldChange("nameservers")}
          />
        </Col>
      </Row>,
      <Row key="comments" name="comments-row">
        <Col md={12}>
          <InputWithLabel
            label={"Comments"}
            type="textarea"
            rows="7"
            placeholder="Enter optional comments here"
            name={"comments"}
            onChange={this.onFieldChange("comments")}
            value={FormatInput(this.state.conf.object["comments"])}
          />
        </Col>
      </Row>,
      <Row key="checkbox" name="checkbox-row">
        <Col md={12}>
          <CheckBox
            type="checkbox"
            defaultChecked={true}
            onChange={this.onFieldChange("validate")}
            label="Validate Nameservers on Save"
          />
        </Col>
      </Row>,
      this.getNote(),
    ];
  };

  getNote = () => {
    if (this.create) {
      return <Row key="notes" name="notes-row" />;
    }
    return (
      <Row key="notes" name="notes-row">
        <Col md={12} className="note">
          <PortsAlert variant="warning" className="no-margin">
            <strong>Note:</strong> The changes will only take effect on the
            nameservers object in Abion Core's database, if you want the
            nameserver information on a domain updated you need to place a
            delegation order.
          </PortsAlert>
        </Col>
      </Row>
    );
  };

  onCancel = () => {
    this.setState({
      conf: null,
    });
    this.props.onCancel();
  };

  onSubmit = () => {
    this.props.onConfirm(this.state.validate);
  };

  render() {
    if (!this.state.conf) {
      return null;
    }

    let title = this.create ? "Create Nameservers" : "Edit Nameservers";

    return (
      <EditDialog
        show={this.props.show}
        title={title}
        content={this.getBody()}
        name="nameserver-set"
        onCancel={this.props.onCancel}
        onConfirm={this.onSubmit}
        submit={this.props.submit}
        errorMessage={this.props.errorMessage}
      />
    );
  }
}

export default NameserverSetDialog;
