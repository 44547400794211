import styled from "styled-components";
import { Alert } from "react-bootstrap";
import Colors from "./theme/Colors";

const PortsAlert = styled(Alert)`
  &.alert-warning {
    --bs-alert-bg: ${Colors.alertBackground};
    --bs-alert-color: ${Colors.alert};
    --bs-alert-border-color: ${Colors.alertBorder};
  }

  &.alert-danger {
    --bs-alert-bg: ${Colors.dangerBackground};
    --bs-alert-color: ${Colors.danger};
    --bs-alert-border-color: ${Colors.dangerBorder};
  }
`;

export { PortsAlert };
