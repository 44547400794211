import React, { Component } from "react";
import { WizardDialog } from "../../common/Dialog";

export default class GenerateAuthCodeDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      authCode: null,
      errorMessage: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.show &&
      !prevProps.show &&
      (this.state.authCode != null || this.state.errorMessage != null)
    ) {
      this.setState({ authCode: null, errorMessage: null });
    }
  }

  generateAuthCode = () => {
    let url = this.props.domain.links.generateAuthCode;
    this.props.session.backendGet(
      url,
      (response) => {
        this.setState({
          authCode: response.authCode,
          errorMessage: response.errorMessage,
        });
      },
      (errorMessage) => {
        this.setState({ authCode: errorMessage });
      },
    );
  };

  getTitle = () => {
    let title = "Transfer Out";
    if (this.props.domain) {
      title += " - " + this.props.domain.name;
    }
    return title;
  };

  getNotes = () => {
    let notes = [];

    if (this.state.authCode == null && this.state.errorMessage == null) {
      notes.push(
        "Any previously generated authorization code will become invalid when you do this.",
      );
    } else if (this.state.errorMessage == null) {
      notes.push("It will only be shown once.");
    }

    return notes;
  };

  getBody = () => {
    if (this.state.authCode == null && this.state.errorMessage == null) {
      return (
        <div>
          This operation will generate an authorization code that you need to
          transfer the domain to another registrar.
        </div>
      );
    } else if (this.state.errorMessage == null) {
      return (
        <div>
          <div>Below is your authorization code:</div>
          <label>{this.state.authCode}</label>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  render() {
    const firstPage =
      this.state.authCode == null && this.state.errorMessage == null;

    return (
      <WizardDialog
        key="scrollingDialog"
        show={this.props.show}
        title={this.getTitle()}
        body={this.getBody()}
        errorMessage={this.state.errorMessage}
        noteMessages={this.getNotes()}
        onCancel={firstPage ? this.props.onClose : null}
        onNext={firstPage ? this.generateAuthCode : null}
        onClose={!firstPage ? this.props.onClose : null}
      />
    );
  }
}
