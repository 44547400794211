import React, { Component } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import styled from "styled-components";
import { FormatInteger } from "../common/Utils";

const DivStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0px;
  padding: 5px;
  border-radius: 10px;
  color: white;

  .title {
    flex-grow: 1;
  }

  .title > div {
    margin: 5px 10px;
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 16pt;
    font-weight: bold;
  }

  .title img {
    margin: 2px;
  }

  .items {
    flex-grow: 100;
    text-align: center;
    align-self: center;
  }

  .items table {
    margin: 10px 10px 5px 10px;
    display: inline-block;
    text-align: left;
    font-size: 12pt;
    color: black;
  }

  .items table td:first-child {
    text-align: right;
    padding-right: 10px;
  }

  .items .item {
    margin: 3px 10px;
    cursor: default;
    transition: transform 0.3s;
  }

  .items .item-link:hover {
    -webkit-transform: scale(1.1);
  }

  .items .item-link {
    cursor: pointer;
  }
`;

export default class DashboardSummary extends Component {
  getTitle = () => {
    return (
      <div className="title">
        <div>
          <img src={this.props.image} alt={this.props.title} height={36} />
          {this.props.title}
        </div>
      </div>
    );
  };

  getItems = () => {
    return (
      <div className="items">
        <table>{this.props.items.map((o) => this.getItem(o))}</table>
      </div>
    );
  };

  getItem = (item) => {
    let label = <span>{item[0]}</span>;
    let number = <span>{FormatInteger(item[1])}</span>;
    let path = item[2];
    let tooltip = item[3];

    if (tooltip) {
      label = this.getTooltip(label, tooltip);
      number = this.getTooltip(number, tooltip);
    }

    let tr = (
      <tr className={path ? "item item-link" : "item"}>
        <td>{number}</td>
        <td>{label}</td>
      </tr>
    );

    if (path) {
      tr = <LinkContainer to={path}>{tr}</LinkContainer>;
    }

    return tr;
  };

  getTooltip = (element, text) => {
    let overlay = <Tooltip style={{ position: "fixed" }}>{text}</Tooltip>;

    return (
      <OverlayTrigger overlay={overlay} placement="bottom" delay={500}>
        {element}
      </OverlayTrigger>
    );
  };

  render() {
    return (
      <DivStyle style={{ backgroundColor: this.props.color }}>
        {this.getTitle()}
        {this.getItems()}
      </DivStyle>
    );
  }
}
