import React, { Component } from "react";
import { InputWithLabel } from "./theme/Theme";
import EditDialog from "./Dialog";
import { FormatInput } from "./Utils";

class UpdateCommentsDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      newComments: null,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.show && prevProps.show !== this.props.show) {
      this.setState({ newComments: this.props.comments });
    }
  }

  onSave = () => {
    this.props.onSave(this.state.newComments);
  };

  render() {
    let element = [
      <InputWithLabel
        key="input"
        label={"Comments"}
        type="textarea"
        name="comments"
        rows="7"
        placeholder="Enter comments here"
        value={FormatInput(this.state.newComments)}
        onChange={(e) => this.setState({ newComments: e.target.value })}
        autoFocus
      />,
    ];

    let noteMessages = [];
    noteMessages.push(
      "Abion will not receive any notifications about the comments.",
    );

    return (
      <EditDialog
        content={element}
        show={this.props.show}
        title="Update Comments"
        onCancel={this.props.onCancel}
        onConfirm={this.onSave}
        submit={this.props.submit}
        session={this.props.session}
        errorMessage={this.props.errorMessage}
        noteMessages={noteMessages}
      />
    );
  }
}

export default UpdateCommentsDialog;
