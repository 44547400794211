import React, { Component } from "react";
import { withRouter } from "react-router";
import PortfolioPage from "./PortfolioPage";

class Portfolio extends Component {
  render() {
    return (
      <PortfolioPage
        history={this.props.history}
        session={this.props.session}
        window={this.props.window}
      />
    );
  }
}

export default withRouter(Portfolio);
