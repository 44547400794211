import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { WizardDialog } from "./Dialog";
import { FormatOrganisation } from "./Utils";
import Selector from "./Selector";

export default class SelectAccountDialog extends Component {
  constructor(props) {
    super(props);
    this.resetState();
  }

  resetState() {
    this.state = {
      accountTitle: "Select Account",
      accountId: null,
    };
  }

  onConfirm = () => {
    const accountId = this.state.accountId;
    this.props.onConfirm(accountId);
  };

  onClose = () => {
    this.resetState();
    this.props.onClose();
  };
  onParameterChange = (accountId) => {
    this.setState({ accountId: accountId });
  };

  getOrganizationBody = () => {
    let orgs = this.props.organisations;
    if (!orgs || orgs.length === 0) {
      return null;
    }

    let orgsOptions = orgs
      .filter((org) => org.serviceAccountId !== null && org.accessible)
      .map((o) => {
        return {
          value: o.serviceAccountId,
          label: FormatOrganisation(
            o.fullDescription,
            this.props.session.getApplication().organisationPrefix,
          ),
        };
      });

    return (
      <Row className="section-field">
        <Col xs={12}>
          <Selector
            label={"Organisation"}
            placeholder="Select organisation"
            options={orgsOptions}
            onChange={(v) => this.onParameterChange(v.value)}
          />
        </Col>
      </Row>
    );
  };

  render() {
    return (
      <WizardDialog
        show={this.props.show}
        title="Change Account"
        body={this.getOrganizationBody()}
        errorMessage={this.props.errorMessage}
        height="sm"
        onCancel={this.onClose}
        onCreate={this.onConfirm}
        onCreateDisabled={this.state.accountId === null}
        onCreateDisabledTooltip={"You must first select organisation"}
      />
    );
  }
}
