import React, { Component } from "react";
import EditDialog from "../../common/Dialog";
import Selector from "../../common/Selector";
import { InputWithLabel } from "../../common/theme/Theme";

class RecordDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conf: null,
    };
    this.updateField = this.updateField.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.conf && prevProps.conf !== this.props.conf) {
      this.setState({ conf: this.props.conf });
    }
  }

  updateField(field, value) {
    const conf = this.state.conf;
    conf.object[field] = value;
    this.setState({ conf: conf });
  }

  render() {
    if (!this.state.conf) {
      return null;
    }

    const elements = [
      <InputWithLabel
        key="name"
        label={"Name"}
        type="text"
        name="name"
        placeholder="Enter record name"
        value={this.state.conf.object.name || ""}
        onChange={(e) => this.updateField("name", e.target.value)}
        required={false}
      />,
      <InputWithLabel
        key="ttl"
        label={"TTL"}
        type="number"
        name="ttl"
        placeholder="Time To Live"
        value={this.state.conf.object.ttl || ""}
        onChange={(e) => this.updateField("ttl", e.target.value)}
      />,
      <Selector
        key="recordType"
        label={"Type"}
        name="recordType"
        options={this.state.conf.recordTypes}
        placeholder="Type"
        value={this.state.conf.object.recordType}
        onChange={(selection) =>
          this.updateField("recordType", selection.value)
        }
        required={true}
      />,
      <InputWithLabel
        key="data"
        label={"Data"}
        type="text"
        name="data"
        placeholder="Enter data string"
        value={this.state.conf.object.data || ""}
        onChange={(e) => this.updateField("data", e.target.value)}
        required={true}
      />,
      <InputWithLabel
        key="comments"
        label={"Comments"}
        type="textarea"
        rows="7"
        name="comments"
        placeholder="Enter comments"
        value={this.state.conf.object.comments || ""}
        onChange={(e) => this.updateField("comments", e.target.value)}
        required={false}
      />,
    ];

    return (
      <EditDialog
        content={elements}
        show={this.props.showDialog}
        title={(this.state.conf.object.id ? "Edit" : "Create") + " Record"}
        onCancel={this.props.closeDialog}
        onConfirm={this.props.handleSubmit}
        submit={this.props.submit}
        errorMessage={this.props.errorMessage}
      />
    );
  }
}

export default RecordDialog;
