import React, { Component } from "react";
import { TableBackend, TableFilter } from "../common/Tables";
import styled from "styled-components";
import { Tag } from "../common/theme/Theme";
import fileDownload from "js-file-download";
import {
  BlockingActionButton,
  DropdownButton,
  MainActionButton,
  TableButton,
} from "../common/Buttons";
import { FormatDateMonospace, FormatOrganisation } from "../common/Utils";
import { Dropdown } from "react-bootstrap";
import OrganisationFilter from "../common/OrganisationFilter";
import CreateOrderDialog from "../order/CreateOrderDialog";
import ServiceDetailsDialog from "./ServiceDetailsDialog";

const PageStyle = styled.div`
  .dropdown {
    vertical-align: top;
    margin-right: 20px;
  }
`;

const Parameters = styled.span`
  .badge {
    margin-right: 5px;
  }
`;

class ServicesTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parameters: {
        accountIds: "",
        freetext: "",
        onExpire: "ALL",
        reloadFlag: false,
        showCreateDialog: false,
        showDetailsDialog: false,
        showUpgradeDialog: false,
        upgradeParameters: null,
        upgradeOperationId: null,
        upgradeStep: null,
        service: null,
        exported: 0,
      },
    };
  }

  getUrl = (sorting, expanding, parameters) => {
    let url =
      this.props.session.getModules().portfolio.links.portfolio +
      "/" +
      this.props.category;
    url += "?sort=" + sorting;
    url += expanding ? "" : "&page[limit]=15";
    url += "&onExpireFilter=" + parameters.onExpire;
    url += "&accountIdFilter=" + parameters.accountIds;
    url += "&freetextFilter=" + encodeURIComponent(parameters.freetext);
    return url;
  };

  getOnExpireLabel = (onExpire) => {
    if (onExpire === "RENEW") {
      return "Services that will be Renewed";
    } else if (onExpire === "CANCEL") {
      return "Services that will be Cancelled";
    } else {
      return "All On Expiry";
    }
  };

  getParameters = (parameters) => {
    let contents = [];
    if (parameters) {
      parameters.forEach((o) => {
        contents.push(
          <div key={"parameter-" + o}>
            <Tag className={"parameter"}>{o}</Tag>
            <br></br>
          </div>,
        );
      });
    }
    return <Parameters>{contents}</Parameters>;
  };

  exportTechnical = () => {
    let url =
      this.props.session.getModules().portfolio.links.exportTechnicalServices;
    url += "?onExpireFilter=" + this.state.parameters.onExpire;
    url += "&accountIdFilter=" + this.state.parameters.accountIds;
    url +=
      "&freetextFilter=" + encodeURIComponent(this.state.parameters.freetext);

    this.props.session.backendGetFile(
      url,
      (response) => {
        fileDownload(response, "Web Security Services.xlsx");
        this.setState({ exported: Date.now() });
      },
      (error) => {
        this.props.window.showErrorFunc(error);
        this.setState({ exported: Date.now() });
      },
    );
  };

  exportRedirect = () => {
    let url = this.props.session.getModules().portfolio.links.exportRedirects;
    url += "?accountIdFilter=" + this.state.parameters.accountIds;

    this.props.session.backendGetFile(
      url,
      (response) => {
        fileDownload(response, "Redirects.xlsx");
        this.setState({ exported: Date.now() });
      },
      (error) => {
        this.props.window.showErrorFunc(error);
        this.setState({ exported: Date.now() });
      },
    );
  };

  exportLegal = () => {
    let url =
      this.props.session.getModules().portfolio.links.exportLegalServices;
    url += "?onExpireFilter=" + this.state.parameters.onExpire;
    url += "&accountIdFilter=" + this.state.parameters.accountIds;
    url +=
      "&freetextFilter=" + encodeURIComponent(this.state.parameters.freetext);

    this.props.session.backendGetFile(
      url,
      (response) => {
        fileDownload(response, "Legal Services.xlsx");
        this.setState({ exported: Date.now() });
      },
      (error) => {
        this.props.window.showErrorFunc(error);
        this.setState({ exported: Date.now() });
      },
    );
  };

  exportDigital = () => {
    let url =
      this.props.session.getModules().portfolio.links.exportDigitalServices;
    url += "?onExpireFilter=" + this.state.parameters.onExpire;
    url += "&accountIdFilter=" + this.state.parameters.accountIds;
    url +=
      "&freetextFilter=" + encodeURIComponent(this.state.parameters.freetext);

    this.props.session.backendGetFile(
      url,
      (response) => {
        fileDownload(response, "Domain Services.xlsx");
        this.setState({ exported: Date.now() });
      },
      (error) => {
        this.props.window.showErrorFunc(error);
        this.setState({ exported: Date.now() });
      },
    );
  };

  exportObp = () => {
    let url = this.props.session.getModules().portfolio.links.exportObpServices;
    url += "?onExpireFilter=" + this.state.parameters.onExpire;
    url += "&accountIdFilter=" + this.state.parameters.accountIds;
    url +=
      "&freetextFilter=" + encodeURIComponent(this.state.parameters.freetext);

    this.props.session.backendGetFile(
      url,
      (response) => {
        fileDownload(response, "Brand Protection Services.xlsx");
        this.setState({ exported: Date.now() });
      },
      (error) => {
        this.props.window.showErrorFunc(error);
        this.setState({ exported: Date.now() });
      },
    );
  };

  getInvoicePeriod = (period) => {
    if (period > 1) {
      return <div>{period + " months"}</div>;
    } else {
      return <div>{period + " month"}</div>;
    }
  };

  openDetailsDialog = (r) => {
    this.setState({
      service: r,
      showDetailsDialog: true,
    });
  };

  closeDetailsDialog = () => {
    this.setState({
      service: null,
      showDetailsDialog: false,
    });
  };

  upgradeSubscription = (parameters, operationId, step) => {
    this.setState({
      upgradeParameters: parameters,
      upgradeOperationId: operationId,
      upgradeStep: step,
      showUpgradeDialog: true,
    });
  };

  reloadRows = () => {
    this.setState({
      parameters: Object.assign({}, this.state.parameters, {
        reloadFlag: !this.state.parameters.reloadFlag,
      }),
    });
  };

  onUpdateLifecycle = (serviceType, serviceDescription, lifecycle, url) => {
    const message =
      "Are you sure you want to " +
      lifecycle.toLowerCase() +
      " the " +
      serviceType +
      " service " +
      serviceDescription +
      " when it expires?";
    this.props.window.showConfirmFunc(message, () =>
      this.updateLifecycle(lifecycle, url),
    );
  };

  updateLifecycle = (lifecycle, url) => {
    const callback = () => {
      this.props.window.showSuccessFunc("Service is updated successfully!");
      this.reloadRows();
    };
    let renew = lifecycle.toLowerCase() === "renew";
    this.props.session.backendPut(
      url,
      {
        renew: renew,
      },
      callback,
      this.props.window.showErrorFunc,
    );
  };

  onChangeAccount = () => {
    this.reloadRows();
  };

  render() {
    let columns = [
      {
        label: "Description",
        name: "description",
        sortable: true,
      },
      {
        label: "Type",
        name: "type",
        sortable: true,
      },
      {
        label: "Organisation",
        name: "organisation",
        sortable: true,
        contentFunction: (r) =>
          FormatOrganisation(
            r.organisation,
            this.props.session.getApplication().organisationPrefix,
          ),
      },
      {
        label: "Expiry Date",
        name: "expiryDate",
        sortable: true,
        contentFunction: (r) => FormatDateMonospace(r.expiryDate),
      },
      {
        label: "On Expiry",
        name: "onExpiration",
        sortable: false,
        style: { width: "120px" },
      },
      {
        label: "Invoice Period",
        name: "unitCount",
        sortable: true,
        contentFunction: (r) => this.getInvoicePeriod(r.unitCount),
      },
    ];

    const filters = [
      <OrganisationFilter
        key="organissation-filter"
        session={this.props.session}
        onChange={(o) =>
          this.setState({
            parameters: Object.assign({}, this.state.parameters, {
              accountIds: o,
            }),
          })
        }
      />,
      <DropdownButton
        key="on-expire"
        title={this.getOnExpireLabel(this.state.parameters.onExpire)}
        style={{ verticalAlign: "top" }}
      >
        <Dropdown.Item
          onClick={() =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                onExpire: "ALL",
              }),
            })
          }
        >
          {this.getOnExpireLabel("ALL")}
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                onExpire: "RENEW",
              }),
            })
          }
        >
          {this.getOnExpireLabel("RENEW")}
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() =>
            this.setState({
              parameters: Object.assign({}, this.state.parameters, {
                onExpire: "CANCEL",
              }),
            })
          }
        >
          {this.getOnExpireLabel("CANCEL")}
        </Dropdown.Item>
      </DropdownButton>,
      <TableFilter
        key="table-filter"
        value={this.state.parameters.freetext}
        onChange={(o) =>
          this.setState({
            parameters: Object.assign({}, this.state.parameters, {
              freetext: o,
            }),
          })
        }
      />,
    ];

    let actions = [];

    const tooltip =
      "Exports the services to an Excel file. Note that only rows matching current filter are exported.";
    if (this.props.category === "legalServices") {
      actions.push(
        <MainActionButton
          key="order"
          onClick={() => this.setState({ showCreateDialog: true })}
          text="Order Legal Service"
          icon="plus"
          prefix="fas"
          tooltip="Open the order dialog and fill in details to order a new legal service."
          disabled={!this.props.session.hasRole("ORDER_CREATE")}
          disabledTooltip="Your user account does not have privileges to order legal services, contact your client manager to activate this functionality."
          invisible={this.props.session.hasRole("SYS_ADMIN")}
        />,
      );

      actions.push([
        <BlockingActionButton
          key="export"
          onClick={this.exportLegal}
          text={"Export"}
          icon={"cloud-download"}
          prefix={"fas"}
          tooltip={tooltip}
          reload={this.state.exported}
        />,
      ]);
    } else if (this.props.category === "digitalServices") {
      actions.push(
        <MainActionButton
          key="order"
          onClick={() => this.setState({ showCreateDialog: true })}
          text="Order Digital Service"
          icon="plus"
          prefix="fas"
          tooltip="Open the order dialog and fill in details to order a new domain service."
          disabled={!this.props.session.hasRole("ORDER_CREATE")}
          disabledTooltip="Your user account does not have privileges to order domain services, contact your client manager to activate this functionality."
          invisible={this.props.session.hasRole("SYS_ADMIN")}
        />,
      );
      actions.push([
        <BlockingActionButton
          key="export"
          onClick={this.exportDigital}
          text={"Export"}
          icon={"cloud-download"}
          prefix={"fas"}
          tooltip={tooltip}
          reload={this.state.exported}
        />,
      ]);
      columns.push({
        label: "Parameters",
        name: "parameters",
        sortable: false,
        style: { width: "200px" },
        contentFunction: (r) => this.getParameters(r.parameters),
      });
    } else if (this.props.category === "technicalServices") {
      actions.push(
        <MainActionButton
          key="order"
          onClick={() => this.setState({ showCreateDialog: true })}
          text="Order Web Security Service"
          icon="plus"
          prefix="fas"
          tooltip="Open the order dialog and fill in details to order a new web security service."
          disabled={!this.props.session.hasRole("ORDER_CREATE")}
          disabledTooltip="Your user account does not have privileges to order web security services, contact your client manager to activate this functionality."
          invisible={this.props.session.hasRole("SYS_ADMIN")}
        />,
      );
      actions.push([
        <BlockingActionButton
          key="export-technical"
          onClick={this.exportTechnical}
          text={"Export Services"}
          icon={"cloud-download"}
          prefix={"fas"}
          tooltip={tooltip}
          reload={this.state.exported}
        />,
        <BlockingActionButton
          key="export-redirect"
          onClick={this.exportRedirect}
          text={"Export Redirects"}
          icon={"cloud-download"}
          prefix={"fas"}
          tooltip={tooltip}
          reload={this.state.exported}
        />,
      ]);

      columns.push({
        label: "Parameters",
        name: "parameters",
        sortable: false,
        style: { width: "200px" },
        contentFunction: (r) => this.getParameters(r.parameters),
      });
    } else if (this.props.category === "obpServices") {
      actions.push(
        <MainActionButton
          key="order"
          onClick={() => this.setState({ showCreateDialog: true })}
          text="Order Brand Protection Service"
          icon="plus"
          prefix="fas"
          tooltip="Open the order dialog and fill in details to order a new brand protection service."
          disabled={!this.props.session.hasRole("ORDER_CREATE")}
          disabledTooltip="Your user account does not have privileges to order brand protection services, contact your client manager to activate this functionality."
          invisible={this.props.session.hasRole("SYS_ADMIN")}
        />,
      );
      actions.push([
        <BlockingActionButton
          key="export-obp"
          onClick={this.exportObp}
          text={"Export Services"}
          icon={"cloud-download"}
          prefix={"fas"}
          tooltip={tooltip}
          reload={this.state.exported}
        />,
      ]);

      columns.push({
        label: "Parameters",
        name: "parameters",
        sortable: false,
        style: { width: "200px" },
        contentFunction: (r) => this.getParameters(r.parameters),
      });
    }

    columns.push({
      style: { width: "150px" },
      contentFunction: (r) => (
        <TableButton
          onClick={() => this.openDetailsDialog(r)}
          icon="eye"
          prefix={"fas"}
          text="Details"
          className={"secondary"}
        />
      ),
    });

    return (
      <PageStyle>
        <TableBackend
          session={this.props.session}
          window={this.props.window}
          columns={columns}
          sorting="description"
          parameters={this.state.parameters}
          filters={filters}
          actions={actions}
          urlFunction={this.getUrl}
        />
        <ServiceDetailsDialog
          session={this.props.session}
          show={this.state.showDetailsDialog}
          onClose={this.closeDetailsDialog}
          onUpgrade={this.upgradeSubscription}
          onUpdateLifecycle={this.onUpdateLifecycle}
          onChangeAccount={this.onChangeAccount}
          service={this.state.service}
        />
        <CreateOrderDialog
          session={this.props.session}
          show={this.state.showCreateDialog}
          onClose={() => this.setState({ showCreateDialog: false })}
        />
        <CreateOrderDialog
          session={this.props.session}
          show={this.state.showUpgradeDialog}
          onClose={() =>
            this.setState({
              showUpgradeDialog: false,
              upgradeParameters: null,
              upgradeOperationId: null,
            })
          }
          step={this.state.upgradeStep}
          operation={this.state.upgradeOperationId}
          parameters={this.state.upgradeParameters}
        />
      </PageStyle>
    );
  }
}

export default ServicesTab;
