import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import AccountField from "./AccountField";

export default class AccountStep extends Component {
  render() {
    return (
      <Container fluid={true}>
        <Row className="section-title">
          <Col xs={12}>Account</Col>
        </Row>
        <Row className="section-description">
          <Col xs={12}>Choose the account of the new service.</Col>
        </Row>
        <AccountField {...this.props} />
      </Container>
    );
  }
}
