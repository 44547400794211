import React, { Component } from "react";
import { MessageDialog } from "./Dialog";

class SessionTimeoutControl extends Component {
  static defaultProps = {
    // this warning must be smaller than the Access Token Lifespan in keycloak
    warningMinutes: 3,
  };

  constructor(props) {
    super(props);
    this.state = {
      showSessionWarningDialog: false,
    };
  }

  componentDidMount() {
    this.interval = setInterval(() => this.executeCheck(), 10000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  executeCheck = () => {
    let expirationTime = this.props.session.getSessionTimeout() * 1000;
    if (expirationTime > 0) {
      let warningTime = expirationTime - this.props.warningMinutes * 60 * 1000;

      if (expirationTime <= Date.now().valueOf()) {
        this.props.session.logout();
      } else if (
        !this.state.showSessionWarningDialog &&
        warningTime <= Date.now().valueOf()
      ) {
        this.setState({ showSessionWarningDialog: true });
      }
    }
  };

  extendSession = () => {
    this.props.session.handleSessionTimeout(() =>
      this.setState({ showSessionWarningDialog: false }),
    );
  };

  render() {
    const style = {
      backgroundColor: "${Colors.blackalpha}",
    };

    return (
      <MessageDialog
        message="Your Abion Core session is about to expire due to inactivity. Press Close to indicate that you are still active and the session will be extended. Otherwise you will shortly by logged out automatically."
        title="Session Expiration Warning"
        show={this.state.showSessionWarningDialog}
        onClose={this.extendSession}
        style={style}
        {...this.props}
      />
    );
  }
}
export default SessionTimeoutControl;
