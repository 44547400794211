import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Loader } from "../common/theme/Theme";
import styled from "styled-components";
import LegalSummaryItem from "./LegalSummaryItem";
import DigitalSummaryItem from "./DigitalSummaryItem";
import TechnicalSummaryItem from "./TechnicalSummaryItem";
import ObpSummaryItem from "./ObpSummaryItem";
import MonitoringItem from "./MonitoringItem";
import OngoingItem from "./OngoingItem";
import MapItem from "./MapItem";
import QuoteItem from "./QuoteItem";
import InvoiceForecastItem from "./InvoiceForecastItem";

const PageStyle = styled.div`
  .container-fluid {
    max-width: 1600px;
  }
`;

export default class DashboardPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      data: null,
    };
  }

  componentDidMount() {
    this.reloadData();

    let views = [
      {
        label: "Home",
        url: "/",
      },
      {
        label: "Dashboard",
        url: null,
      },
    ];

    this.props.window.setBreadcrumbViews(views);
    this.props.window.setPageTip(null);
  }

  reloadData = () => {
    let url = this.props.session.getModules().dashboard.links.dashboard;
    this.props.session.backendGet(
      url,
      (response) => {
        this.setState({ data: response, loaded: true });
      },
      (msg) => {
        this.props.window.showErrorFunc(msg);
      },
    );
  };

  render() {
    return (
      <Loader loaded={this.state.loaded}>
        <PageStyle>
          <Container fluid={true}>
            <Row>
              <LegalSummaryItem
                session={this.props.session}
                data={this.state.data}
              />
              <DigitalSummaryItem
                session={this.props.session}
                data={this.state.data}
              />
              <TechnicalSummaryItem
                session={this.props.session}
                data={this.state.data}
              />
              <ObpSummaryItem
                session={this.props.session}
                data={this.state.data}
              />
            </Row>
            <Row style={{ marginTop: "15px" }}>
              <Col lg="8">
                <MapItem
                  session={this.props.session}
                  window={this.props.window}
                  data={this.state.data}
                />
                <div
                  className="d-block d-lg-none"
                  style={{ marginTop: "30px" }}
                />
              </Col>
              <Col lg="4">
                <OngoingItem
                  session={this.props.session}
                  data={this.state.data}
                />
                <MonitoringItem
                  session={this.props.session}
                  data={this.state.data}
                  style={{ marginTop: "30px" }}
                />
                <InvoiceForecastItem
                  session={this.props.session}
                  data={this.state.data}
                  style={{ marginTop: "30px" }}
                />
                <div
                  className="d-block d-lg-none"
                  style={{ marginTop: "30px" }}
                />
              </Col>
            </Row>
            <Row>
              <QuoteItem session={this.props.session} data={this.state.data} />
            </Row>
          </Container>
        </PageStyle>
      </Loader>
    );
  }
}
