import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Icon from "../../common/theme/Icons";

export default class PrivilegeStep extends Component {
  render() {
    return (
      <Container fluid={true}>
        <Row className="section-message">
          <Col xs={12}>
            <Icon color="error" iconsize="40" prefix="fas" name="ban" />
            <span>You don't have enough privileges!</span>
          </Col>
        </Row>
        <Row className="section-title">
          <Col xs={12}>Result</Col>
        </Row>
        <Row className="section-description">
          <Col xs={12}>
            To create this order you need privileges that your user account does
            not have.
          </Col>
        </Row>
        <Row className="section-description">
          <Col xs={12}>
            Contact your client manager to extend your privileges and activate
            this functionality.
          </Col>
        </Row>
      </Container>
    );
  }
}
