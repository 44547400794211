import React, { Component } from "react";
import { TableFrontend } from "../common/Tables";
import { MainActionButton } from "../common/Buttons";
import Icon from "../common/theme/Icons";
import UserZoneDialog from "./UserZoneDialog";

class ZoneTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      conf: null,
      showDialog: false,
    };
    this.onRemove = this.onRemove.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
  }

  onUpdate(event) {
    let confUrl = this.props.user.links.zoneConfiguration;
    this.props.session.backendGet(confUrl, (response) => {
      this.setState({ conf: response, showDialog: true });
    });
  }

  onRemove(event) {
    let url = this.props.user.links.zones + "/" + event.original.id;
    this.props.session.backendDelete(url, (_) => {
      this.props.onChange();
    });
  }

  closeDialog() {
    this.setState({ showDialog: false });
    this.props.onChange();
  }

  getAccessible(value) {
    const yes = <Icon name="check" className="primary" />;
    const no = <Icon name="times" className="error" />;
    return value ? yes : no;
  }

  render() {
    const columns = [
      {
        label: "Zone",
        name: "zoneName",
        sortable: true,
        filterable: true,
      },
      {
        label: "Accessible",
        name: "accessible",
        sortable: true,
        style: { width: "200px" },
        contentFunction: (r) => <div>{this.getAccessible(r.accessible)}</div>,
      },
    ];

    const actions = [
      <MainActionButton
        onClick={this.onUpdate}
        prefix={"fas"}
        icon="edit"
        text="Edit Zones"
        type={"button"}
      />,
    ];
    const url = this.props.user.links.zones;
    const { showDialog, conf } = this.state;
    const { session, window } = this.props;

    return (
      <div>
        <UserZoneDialog
          session={session}
          showDialog={showDialog}
          closeDialog={this.closeDialog}
          conf={conf}
          createUrl={url}
          window={window}
        />
        <TableFrontend
          session={session}
          window={window}
          actions={actions}
          filterable={true}
          columns={columns}
          sorting="zoneName"
          url={url}
        />
      </div>
    );
  }
}

export default ZoneTab;
