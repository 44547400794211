import React, { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";

export default class DescriptionsStep extends Component {
  render() {
    return (
      <Container fluid={true}>
        <Row className="section-title">
          <Col xs={12}>Descriptions</Col>
        </Row>
        <Row className="section-description">
          <Col xs={12}>
            Enter the descriptions below. More than one description can be
            entered, in that case write one per line.
          </Col>
        </Row>
        <Row className="section-field">
          <Col xs={12}>
            <textarea
              rows="10"
              placeholder="Enter descriptions here"
              defaultValue={this.props.conf.object.parameters.descriptions}
              onChange={(e) =>
                this.props.onParameterChange("descriptions", e.target.value)
              }
            />
          </Col>
        </Row>
      </Container>
    );
  }
}
