import React, { Component } from "react";
import EditDialog from "../common/Dialog";
import { Col, Row } from "react-bootstrap";
import { InputWithLabel } from "../common/theme/Theme";
import Selector from "../common/Selector";
import { InformationField } from "../common/Information";

class IdentityProviderDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organisations: [],
      identityProvider: this.props.identityProvider,
      availableRoles: this.props.availableRoles,
    };
  }

  updateField(field, value) {
    const idp = this.state.identityProvider;
    idp[field] = value;
    this.setState({ identityProvider: idp });
  }

  filterOrganisations(organisations) {
    let orgOptions = organisations
      .filter((org) => org.accessible)
      .map((o) => {
        return {
          value: o.id,
          label: o.fullDescriptionWithIds,
        };
      });
    return orgOptions;
  }

  getOrganisationOptions = (freetext, callback) => {
    let url = this.props.session.getApplication().links.organisations;
    url += "?freetext=" + freetext;

    this.props.session.backendGet(url).then((response) => {
      callback(this.filterOrganisations(response));
    });
  };

  getOrganisationDefaultValue = () => {
    if (this.state.identityProvider.organisationId == null) {
      return null;
    } else {
      return {
        value: this.state.identityProvider.organisationId,
        label: this.state.identityProvider.organisationDescription,
      };
    }
  };

  isDisabled() {
    return !this.props.create;
  }

  defaultRolesOptions(identityProviderRoleIds) {
    return this.state.availableRoles.filter((userRole) =>
      identityProviderRoleIds.find(
        (identityProviderRoleId) => userRole.value === identityProviderRoleId,
      ),
    );
  }

  getIdTooltip() {
    return "The ID to use to identify the identity provider. This is usually your company name or similar which uniquely identifies the IDP integration in Abion Core.";
  }

  getOrganisationTooltip() {
    return "Select organisation the identity provider should be mapped to. We recommend to use the highest hierarchy level in your organisation.";
  }

  getEmailMapperAttributeTooltip() {
    return "Specify how the Email attribute should be mapped. Typically it will have the following names when AD FS or Azure AD is used: http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress. For Okta or Google G-Suite they are often just named: Email";
  }

  getFirstNameMapperAttributeTooltip() {
    return "Specify how the First Name attribute should be mapped. Typically it will have the following names when AD FS or Azure AD is used: http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname. For Okta or Google G-Suite they are often just named: FirstName";
  }

  getLastNameMapperAttributeTooltip() {
    return "Specify how the Last name attribute should be mapped. Typically it will have the following names when AD FS or Azure AD is used: http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname. For Okta or Google G-Suite they are often just named: LastName";
  }

  getCertificateTooltip() {
    return "The Base64 encoded X509 Certificate in PEM format with or without the the BEGIN CERTIFICATE header/END CERTIFICATE footer). Multiple certificates can be entered, separated by comma (,).";
  }

  handleSubmit = () => {
    this.props.handleSubmit(this.state.identityProvider);
  };

  format = (value) => {
    if (value == null) {
      return "";
    }
    return value;
  };

  createElements() {
    const id = this.format(this.state.identityProvider.id);
    const singleSignOnUrl = this.format(
      this.state.identityProvider.singleSignOnUrl,
    );
    const certificateText = this.format(
      this.state.identityProvider.certificates,
    );
    const emailMapperAttribute = this.format(
      this.state.identityProvider.emailMapperAttribute,
    );
    const firstNameMapperAttribute = this.format(
      this.state.identityProvider.firstNameMapperAttribute,
    );
    const lastNameMapperAttribute = this.format(
      this.state.identityProvider.lastNameMapperAttribute,
    );

    const defaultRolesOptions = this.defaultRolesOptions(
      this.state.identityProvider.roleIds,
    );

    return [
      <Row key="identity-provider-id" name={"identityprovider-id-row"}>
        <Col sm={12} name={"identityprovider-id-container"}>
          <InformationField tooltip={this.getIdTooltip()}>
            <InputWithLabel
              label={"Identity Provider ID"}
              type="text"
              name="Id"
              placeholder="Identity Provider ID"
              disabled={this.isDisabled()}
              value={id}
              onChange={(e) => this.updateField("id", e.target.value)}
              required="required"
            />
          </InformationField>
        </Col>
      </Row>,
      <Row key="organisation" name={"organisaion-selector-row"}>
        <Col sm={12} name={"organization-selector-container"}>
          <InformationField
            style={{ width: "100%" }}
            tooltip={this.getOrganisationTooltip()}
          >
            <Selector
              label={"Organisation"}
              ignoreAccents={false}
              removeSelected={true}
              isAsync
              required={true}
              loadOptions={this.getOrganisationOptions}
              defaultValue={this.getOrganisationDefaultValue}
              disabled={this.isDisabled()}
              onChange={(selection) =>
                this.updateField("organisationId", selection.value)
              }
              placeholder="Select Organisation"
            />
          </InformationField>
        </Col>
      </Row>,
      <Row key="roles" name={"role-selector-row"}>
        <Col sm={12} name={"role-selector-container"}>
          <InformationField>
            <Selector
              label={"Roles"}
              name="roles"
              options={this.props.availableRoles}
              placeholder="Select Roles"
              isMulti
              closeOnSelect={true}
              removeSelected={true}
              defaultValue={defaultRolesOptions}
              onChange={(selection) =>
                this.updateField(
                  "roleIds",
                  selection.map((roleOption) => roleOption.value),
                )
              }
              required={true}
            />
          </InformationField>
        </Col>
      </Row>,
      <Row key="sso" name={"sso-url-row"}>
        <Col sm={12} name={"sso-url-container"}>
          <InformationField>
            <InputWithLabel
              label={"Single Sign On URL"}
              type="url"
              name="SSO URL"
              placeholder="Single Sign On URL"
              value={singleSignOnUrl}
              onChange={(e) =>
                this.updateField("singleSignOnUrl", e.target.value)
              }
            />
          </InformationField>
        </Col>
      </Row>,
      <Row key="email-attribute" name={"email-attribute-mapper-row"}>
        <Col sm={12} name={"email-attribute-mapper-container"}>
          <InformationField
            style={{ width: "100%" }}
            tooltip={this.getEmailMapperAttributeTooltip()}
          >
            <InputWithLabel
              label={"Email Attribute"}
              type="text"
              name="Email Attribute"
              placeholder="Email Attribute"
              value={emailMapperAttribute}
              onChange={(e) =>
                this.updateField("emailMapperAttribute", e.target.value)
              }
              required="required"
            />
          </InformationField>
        </Col>
      </Row>,
      <Row key="first-name-attribute" name={"first-name-attribute-mapper-row"}>
        <Col sm={12} name={"first-name-attribute-mapper-container"}>
          <InformationField tooltip={this.getFirstNameMapperAttributeTooltip()}>
            <InputWithLabel
              label={"First Name Attribute"}
              type="text"
              name="First Name Attribute"
              placeholder="First Name Attribute"
              value={firstNameMapperAttribute}
              onChange={(e) =>
                this.updateField("firstNameMapperAttribute", e.target.value)
              }
              required="required"
            />
          </InformationField>
        </Col>
      </Row>,
      <Row key="last-name-attribute" name={"last-name-attribute-mapper-row"}>
        <Col sm={12} name={"last-name-attribute-mapper-container"}>
          <InformationField tooltip={this.getLastNameMapperAttributeTooltip()}>
            <InputWithLabel
              label={"Last Name Attribute"}
              type="text"
              name="Last Name Attribute"
              placeholder="Last Name Attribute"
              value={lastNameMapperAttribute}
              onChange={(e) =>
                this.updateField("lastNameMapperAttribute", e.target.value)
              }
              required="required"
            />
          </InformationField>
        </Col>
      </Row>,
      <Row key="certificate" name={"certificate-text-row"}>
        <Col sm={12} name={"certificate-text-container"}>
          <InformationField tooltip={this.getCertificateTooltip()}>
            <InputWithLabel
              label="Certificates"
              type="textarea"
              style={{ width: "100%" }}
              rows="5"
              placeholder="Paste Certificate text here"
              value={certificateText}
              onChange={(e) => this.updateField("certificates", e.target.value)}
            />
          </InformationField>
        </Col>
      </Row>,
    ];
  }

  render() {
    const elements = this.createElements();

    return (
      <EditDialog
        content={elements}
        show={this.props.showDialog}
        title={
          this.props.identityProvider.id
            ? "Edit Identity Provider"
            : "Create Identity Provider"
        }
        name={"identity-provider"}
        onCancel={this.props.closeDialog}
        onConfirm={this.handleSubmit}
        submit={this.props.submit}
        errorMessage={this.props.errorMessage}
      />
    );
  }
}

export default IdentityProviderDialog;
