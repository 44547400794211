import React, { Component } from "react";
import { darken } from "polished";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import { Chart } from "react-google-charts";
import { TableBackend } from "../common/Tables";
import Colors from "../common/theme/Colors";
import Icon from "../common/theme/Icons";
import {
  FormatClass,
  FormatCountry,
  FormatDate,
  FormatNumber,
} from "../common/TrademarkUtils";
import { PaddedContent } from "../common/theme/Theme";
import { DropdownButton, MainActionButton } from "../common/Buttons";
import OrganisationFilter from "../common/OrganisationFilter";
import { DetailsDialog, DialogSection } from "../common/Dialog";
import CreateOrderDialog from "../order/CreateOrderDialog";
import {
  Curtain,
  FilterStyle,
  Frontside,
  GhostCol,
  WidgetContent,
  WidgetInner,
  WidgetPanel,
  WidgetTitle,
} from "./DashboardUtil";

const ALL_RIGHTS = "All Rights";
const TRADEMARKS = "Trademarks";
const DOMAINS = "Domains";

class RightsController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRightType: ALL_RIGHTS,
    };
  }

  render() {
    const options = [
      { right: ALL_RIGHTS, type: "all" },
      { right: TRADEMARKS, type: "parent" },
      { right: DOMAINS, type: "parent" },
    ];

    const { setRight } = this.props;

    return (
      <FilterStyle>
        <DropdownButton id="right" title={this.state.selectedRightType}>
          {this.getRightOptions(options, setRight)}
        </DropdownButton>
      </FilterStyle>
    );
  }

  getRightOptions = (options, setRight) => {
    let content = [];

    options.forEach((o) => {
      content.push(
        <Dropdown.Item
          key={o.right}
          onClick={() =>
            this.setState({ selectedRightType: o.right }, setRight(o.right))
          }
          className={o.type}
        >
          {o.right}
        </Dropdown.Item>,
      );
    });
    return content;
  };
}

class RegionController extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRegion: "world",
      selectedRegionName: "Worldwide",
    };
  }

  render() {
    const options = [
      { region: "Worldwide", type: "world", code: "world" },
      { region: "Africa", type: "parent", code: "002" },
      { region: "Northern Africa", type: "child", code: "015" },
      { region: "Western Africa", type: "child", code: "011" },
      { region: "Middle Africa", type: "child", code: "017" },
      { region: "Eastern Africa", type: "child", code: "014" },
      { region: "Southern Africa", type: "child", code: "018" },
      { region: "Europe", type: "parent", code: "150" },
      { region: "Northern Europe", type: "child", code: "154" },
      { region: "Western Europe", type: "child", code: "155" },
      { region: "Eastern Europe", type: "child", code: "151" },
      { region: "Southern Europe", type: "child", code: "039" },
      { region: "Americas", type: "parent", code: "019" },
      { region: "Northern America", type: "child", code: "021" },
      { region: "Caribbean", type: "child", code: "029" },
      { region: "Central America", type: "child", code: "013" },
      { region: "South America", type: "child", code: "005" },
      { region: "Asia", type: "parent", code: "142" },
      { region: "Central Asia", type: "child", code: "143" },
      { region: "Eastern Asia", type: "child", code: "030" },
      { region: "Southern Asia", type: "child", code: "034" },
      { region: "South-Eastern Asia", type: "child", code: "035" },
      { region: "Western Asia", type: "child", code: "145" },
      { region: "Oceania", type: "parent", code: "009" },
      {
        region: "Australia and New Zealand\t",
        type: "child",
        code: "053",
      },
      { region: "Melanesia", type: "child", code: "054" },
      { region: "Micronesia", type: "child", code: "057" },
      { region: "Polynesia", type: "child", code: "061" },
    ];

    const { setRegion } = this.props;

    return (
      <FilterStyle>
        <DropdownButton id="region" title={this.state.selectedRegionName}>
          {this.getRegionOptions(options, setRegion)}
        </DropdownButton>
      </FilterStyle>
    );
  }

  getRegionOptions = (options, setRegion) => {
    let content = [];

    options.forEach((o) => {
      content.push(
        <Dropdown.Item
          key={o.code}
          onClick={() =>
            this.setState(
              { selectedRegion: o.code, selectedRegionName: o.region },
              setRegion(o.code),
            )
          }
          className={o.type}
        >
          {o.region}
        </Dropdown.Item>,
      );
    });
    return content;
  };
}

class GeoMap extends Component {
  dataColumns = [
    {
      type: "string",
      label: "Country Code",
    },
    {
      type: "string",
      label: "Country",
    },
    {
      type: "number",
      label: "Score",
    },
    {
      type: "string",
      role: "tooltip",
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      region: null,
      loading: true,
      loadingCounts: false,
      mapData: [this.dataColumns],
      draw: false,
      accountIds: "",
      rightType: null,
      countryData: [],
    };
    this.handleSetRegion = this.handleSetRegion.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.drawMap = this.drawMap.bind(this);
    this.fetchMapData = this.fetchMapData.bind(this);
    this.handleDrawer = this.handleDrawer.bind(this);
    this.handleSetRight = this.handleSetRight.bind(this);
  }

  componentDidMount() {
    this.fetchMapData(this.state.accountIds);
  }

  componentDidUpdate(_prevProps, prevState) {
    if (prevState.accountIds !== this.state.accountIds) {
      this.fetchMapData(this.state.accountIds);
    } else if (prevState.rightType !== this.state.rightType) {
      this.setState({ loadingCounts: true });
      this.populateMapData(this.state.countryData);
    }
  }

  populateMapData = (countryData) => {
    const mapData = [];
    mapData.push(this.dataColumns);
    Object.keys(countryData).forEach((country) => {
      const c = countryData[country];
      mapData.push(this.getMapEntry(c));
    });
    mapData.push([null, null, 2, null]); // Dummy item to force 2 as max value for score
    this.setState({
      countryData: countryData,
      mapData: mapData,
      loadingCounts: false,
    });
  };

  fetchMapData = (accountIds) => {
    this.setState({ loadingCounts: true });
    let url =
      this.props.session.getModules().dashboard.links.countryRightCounts;
    url += "?accountIdFilter=" + accountIds;
    this.props.session.backendGet(
      url,
      (response) => {
        this.populateMapData(response);
      },
      (errorMessage) => {
        this.props.window.showErrorFunc(errorMessage);
      },
    );
  };

  getScore(domainScore, trademarkScore) {
    if (this.state.rightType === "Domains") {
      return domainScore;
    } else if (this.state.rightType === "Trademarks") {
      return trademarkScore;
    }
    return domainScore + trademarkScore;
  }

  getMapEntry(countryEntry) {
    let domainCount = countryEntry.domains;
    let trademarkCount = countryEntry.trademarks;
    let domainScore = domainCount > 0 ? 1 : 0;
    let trademarkScore = trademarkCount > 0 ? 1 : 0;

    let score = this.getScore(domainScore, trademarkScore);
    let tooltip =
      "\nTrademarks: " + trademarkCount + "\nDomains: " + domainCount + "\n";

    tooltip += "\nccTLD: " + countryEntry.extensions;

    let cols = [];
    cols.push(countryEntry.countryCode);
    cols.push(countryEntry.countryName);
    cols.push(score);
    cols.push(tooltip);

    return cols;
  }

  handleSetRight(rightType) {
    this.setState({
      rightType: rightType,
    });
  }

  handleSetRegion(code) {
    this.setState({
      region: code,
    });
  }

  handleSetAccountIds = (accountIds) => {
    this.setState({
      accountIds: accountIds.split(",").map((str) => Number(str)),
    });
  };

  handleShow(Chart) {
    const selectedCountry = Chart.chartWrapper.getChart().getSelection();
    const country = this.state.mapData[selectedCountry[0].row + 1];

    this.props.onSelection(country[0], country[1], this.state.accountIds);
  }

  handleDrawer() {
    if (this.state.loading) {
      this.setState({ loading: false });
    }
  }

  getLegend = () => {
    let legends = [];

    let filterRights = true;

    if (!this.state.rightType || this.state.rightType === ALL_RIGHTS) {
      filterRights = false;
      legends.push(
        <div className="item">
          <div className="image two"></div>
          <div className="text">Trademarks and Domains</div>
        </div>,
      );
    }
    legends.push(
      <div className="item">
        <div className="image one"></div>
        <div className="text">
          {filterRights ? this.state.rightType : "Trademarks or Domains"}
        </div>
      </div>,
    );
    legends.push(
      <div className="item">
        <div className="image zero"></div>
        <div className="text">
          {filterRights ? "No " + this.state.rightType : "No Rights"}
        </div>
      </div>,
    );
    return <div className="legend">{legends}</div>;
  };

  drawMap() {
    const options = {
      displayMode: "region",
      region: this.state.region,
      colorAxis: {
        colors: [
          Colors.bodyBackground,
          Colors.primary,
          darken(0.2, Colors.primary),
        ],
      },
      datalessRegionColor: Colors.bodyBackground,
      keepAspectRatio: true,
      legend: false,
      tooltip: {
        textStyle: {
          fontSize: "13",
        },
      },
    };

    const chartEvents = [
      {
        eventName: "select",
        callback: this.handleShow,
      },
      {
        eventName: "ready",
        callback: this.handleDrawer,
      },
    ];

    return (
      <WidgetPanel xs={12} sm={12} md={12} lg={12} className={"no-padding"}>
        <WidgetInner>
          <Curtain
            className={
              this.state.loading || this.state.loadingCounts ? "active" : ""
            }
          >
            <Icon
              className={
                this.state.loading || this.state.loadingCounts ? "show" : "hide"
              }
              name={"spinner"}
              spin={true}
              size={"3x"}
            />
          </Curtain>
          <WidgetTitle>
            <Container fluid={true}>
              <Row>
                <Col xs={4} className={"title"}>
                  Map
                </Col>
                <Col xs={8} className={"controller text-right"}>
                  <OrganisationFilter
                    key="organisationFilter"
                    session={this.props.session}
                    onChange={this.handleSetAccountIds}
                  />
                  <RightsController setRight={this.handleSetRight} />
                  <RegionController setRegion={this.handleSetRegion} />
                </Col>
              </Row>
            </Container>
          </WidgetTitle>
          <WidgetContent>
            <PaddedContent padding={"15px"}>
              <Chart
                chartType={"GeoChart"}
                width={"100%"}
                height={"100%"}
                data={this.state.mapData}
                options={options}
                graph_id="GeoChart"
                chartEvents={chartEvents}
                mapsApiKey={"AIzaSyCG9sDFq3AhydOl9Koh5YMVLpBfUsi_Eds"}
              />
            </PaddedContent>
            {this.getLegend()}
          </WidgetContent>
        </WidgetInner>
      </WidgetPanel>
    );
  }

  render() {
    return (
      <GhostCol
        xs={this.props.xs}
        sm={this.props.sm}
        md={this.props.md}
        lg={this.props.lg}
      >
        <WidgetPanel className={"no-padding"}>
          <Frontside>{this.drawMap()}</Frontside>
        </WidgetPanel>
      </GhostCol>
    );
  }
}

export default class MapItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      parameters: {
        selectedCountryCode: "",
        selectedAccountIds: "",
        selectedCountryName: "",
      },
      showDetailsDialog: false,
      showCreateTrademarkDialog: false,
      showCreateDomainDialog: false,
    };
  }

  displaySelection = (countryCode, countryName, accountIds) => {
    this.setState({
      parameters: Object.assign(
        {},
        {
          selectedCountryCode: countryCode,
          selectedCountryName: countryName,
          selectedAccountIds: accountIds,
        },
      ),
      showDetailsDialog: true,
    });
  };

  onDialogClose = () => {
    this.setState({
      parameters: Object.assign(
        {},
        {
          selectedCountryCode: "",
          selectedCountryName: "",
          selectedAccountIds: "",
        },
      ),
      showDetailsDialog: false,
    });
  };

  getDialogDomains = () => {
    const domainColumns = [
      {
        label: "Name",
        name: "name",
        sortable: true,
      },
      {
        label: "Registrant",
        name: "owner",
        sortable: true,
      },
      {
        label: "On Expire",
        name: "onExpire",
        sortable: true,
      },
    ];

    return (
      <DialogSection title="Domains">
        <TableBackend
          session={this.props.session}
          window={this.props.window}
          columns={domainColumns}
          parameters={this.state.parameters}
          urlFunction={this.getDomainUrl}
          responseFunction={this.responseFunction}
          sortFrontend="true"
          sorting="name"
        />
      </DialogSection>
    );
  };

  getDialogLabel = (label) => {
    return [
      <span key="label" style={{ fontWeight: "600", fontSize: "16px" }}>
        {label}
      </span>,
    ];
  };

  getDialogTrademarks = () => {
    const trademarksColumns = [
      {
        label: "Description",
        name: "catchWord",
        sortable: true,
      },
      {
        label: "Applicant",
        name: "applicantName",
        sortable: true,
      },
      {
        label: "Appl/Reg Number",
        name: "number",
        sortable: false,
        contentFunction: (r) => FormatNumber(r),
      },
      {
        label: "Appl/Reg/Renew Date",
        name: "date",
        sortable: false,
        contentFunction: (r) => FormatDate(r),
      },
      {
        label: "Class",
        name: "classes",
        sortable: false,
        contentFunction: (r) => FormatClass(r),
      },
      {
        label: "Country",
        name: "countries",
        sortable: false,
        contentFunction: (r) => FormatCountry(r),
      },
    ];

    return (
      <DialogSection title="Trademarks">
        <TableBackend
          session={this.props.session}
          window={this.props.window}
          columns={trademarksColumns}
          parameters={this.state.parameters}
          urlFunction={this.getTrademarkUrl}
          responseFunction={this.responseFunction}
          sortFrontend="true"
          sorting="catchWord"
        />
      </DialogSection>
    );
  };

  responseFunction = (response) => {
    return {
      objects: response,
      totalCount: response.length,
    };
  };

  getTrademarkUrl = (_sorting, _expanding, parameters) => {
    let url =
      this.props.session.getModules().dashboard.links.legalRights +
      "/" +
      parameters.selectedCountryCode;
    url += "?accountIdFilter=" + parameters.selectedAccountIds;
    return url;
  };

  getDomainUrl = (_sorting, _expanding, parameters) => {
    let url =
      this.props.session.getModules().dashboard.links.digitalRights +
      "/" +
      parameters.selectedCountryCode;
    url += "?accountIdFilter=" + parameters.selectedAccountIds;
    return url;
  };

  getDialogBody = () => {
    let body = [];
    if (this.state.showDetailsDialog) {
      body.push(this.getDialogTrademarks());
      body.push(this.getDialogDomains());
    }
    return body;
  };

  getActionButtons = () => {
    let actions = [];

    actions.push(
      <MainActionButton
        key="register-trademark"
        onClick={() =>
          this.setState({
            showCreateTrademarkDialog: true,
            showDetailsDialog: false,
          })
        }
        text="Register Trademark"
        icon="plus"
        prefix="fas"
        focus="hover"
        tooltip="Open the order dialog and fill in details to order a new trademark registration."
        disabled={!this.props.session.hasRole("ORDER_CREATE")}
        disabledTooltip="Your user account does not have privileges to order trademarks, contact your client manager to activate this functionality."
        invisible={this.props.session.hasRole("SYS_ADMIN")}
      />,
    );

    actions.push(
      <MainActionButton
        key="register-domain"
        onClick={() =>
          this.setState({
            showCreateDomainDialog: true,
            showDetailsDialog: false,
          })
        }
        text="Register Domain"
        icon="plus"
        prefix="fas"
        focus="hover"
        tooltip="Open the order dialog and fill in details to order a new domain registration."
        disabled={!this.props.session.hasRole("ORDER_CREATE")}
        disabledTooltip="Your user account does not have privileges to order domain registrations, contact your client manager to activate this functionality."
        invisible={this.props.session.hasRole("SYS_ADMIN")}
      />,
    );

    return actions;
  };

  render() {
    if (this.props.data.quote) {
      return null;
    }

    let body = [];

    body.push(
      <GeoMap
        lg={12}
        md={12}
        sm={12}
        xs={12}
        key={"map"}
        session={this.props.session}
        window={this.props.window}
        className={"no-padding"}
        onSelection={(countryCode, countryName, accountIds) =>
          this.displaySelection(countryCode, countryName, accountIds)
        }
      />,
    );

    body.push(
      <DetailsDialog
        key="details_dialog"
        show={this.state.showDetailsDialog}
        title={this.state.parameters.selectedCountryName}
        actions={this.getActionButtons()}
        body={this.getDialogBody()}
        onClose={this.onDialogClose}
        width="lg"
        height="lg"
      />,
    );
    body.push(
      <CreateOrderDialog
        session={this.props.session}
        show={this.state.showCreateTrademarkDialog}
        onClose={() => this.setState({ showCreateTrademarkDialog: false })}
        step="OPERATION"
        operation="MANUAL-TRADEMARK_REGISTRATION"
      />,
    );
    body.push(
      <CreateOrderDialog
        session={this.props.session}
        show={this.state.showCreateDomainDialog}
        onClose={() => this.setState({ showCreateDomainDialog: false })}
        step="OPERATION"
        operation="DOMAIN-REGISTER_DOMAIN"
      />,
    );

    return body;
  }
}
