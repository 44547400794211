import React, { Component } from "react";
import { Col } from "react-bootstrap";
import DashboardSummary from "./DashboardSummary";
import Colors from "../common/theme/Colors";

export default class ObpSummaryItem extends Component {
  getItems = () => {
    let portfolio = this.props.session.hasRole("PORTFOLIO");
    let internal = !this.props.data.countries;

    let tooltipPortfolio = internal ? "managed by Abion" : "in your portfolio";
    let tooltipClick = portfolio
      ? " Click for additional details."
      : internal
        ? ""
        : " Request role Portfolio to see additional details.";

    return [
      [
        "Services",
        this.props.data.obpSubscriptions,
        portfolio ? "/portfolio/obpServices" : null,
        "The number of brand protection services " +
          tooltipPortfolio +
          ". This includes Trademark Watch, Domain Watch, Web Content Watch, Domain Monitor and many more." +
          tooltipClick,
      ],
    ];
  };

  render() {
    return (
      <Col>
        <DashboardSummary
          image={require("../common/img/dashboard/obp.svg").default}
          title="Brand Protection"
          color={Colors.categoryObp}
          items={this.getItems()}
        />
      </Col>
    );
  }
}
