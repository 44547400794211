import { Container } from "react-bootstrap";
import styled from "styled-components";
import Theme from "./theme/Theme";

const Main = styled.div`
  && {
    display: block;
    position: relative;
    padding-left: ${Theme.mainWrapperPadding};
    padding-top: calc(${Theme.topbarHeight} + ${Theme.mainWrapperPadding});
    padding-right: ${Theme.mainWrapperPadding};
    padding-bottom: ${Theme.mainWrapperPadding};
    margin-left: ${Theme.sidebarWidth};
    width: auto;
    overflow: auto;

    @media (max-width: 768px) {
      margin-left: 0;
    }
  }
`;

const Wrapper = styled(Container)`
  && {
    padding: 0;
  }
`;

export default Wrapper;
export { Main };
