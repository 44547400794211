import React, { Component } from "react";
import { DetailsDialog } from "../../common/Dialog";
import ZoneComparison from "./ZoneComparison";

class ZoneComparisonDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      loading: false,
    };
  }

  componentDidMount() {
    this.reload();
  }

  componentDidUpdate(prevProps) {
    if (this.props.url && prevProps.url !== this.props.url) {
      this.reload();
    }
  }

  reload = () => {
    if (this.props.url) {
      this.setState({ loading: true });
      this.props.session.backendGet(this.props.url, (response) => {
        this.setState({
          loading: false,
          data: response,
        });
      });
    }
  };

  render() {
    let body;
    if (this.state.data) {
      body = <ZoneComparison data={this.state.data} />;
    } else {
      body = <div>Loading, please wait...</div>;
    }

    return (
      <DetailsDialog
        show={this.props.show}
        title={this.props.title}
        body={body}
        onClose={this.props.onClose}
        width="lg"
        height="lg"
      />
    );
  }
}

export default ZoneComparisonDialog;
